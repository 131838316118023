/** @jsx h */

import { h, Component, Fragment } from 'preact'
import addonCallback from './addon-callback'

export default class AddonSizeSelector extends Component {
  setSelectedVariant = (selectedVariant) => {
    if (typeof this.props.onSelectVariant === 'function') {
      this.props.onSelectVariant(selectedVariant)
    }
  }

  addToCart = () => {
    if (typeof this.props.onAddToCart === 'function') {
      this.props.onAddToCart()
    }
  }

  render() {
    const SizeSelector = addonCallback('sizeSelector', this.props.type)
    const props = {
      ...this.props,
      onSelectVariant: this.setSelectedVariant,
      onAddToCart: this.addToCart
    }

    if (SizeSelector) {
      return <SizeSelector {...props} />
    }

    return ''
  }
}