import '@/scripts/flying-scripts';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/rias/ls.rias';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/blur-up/ls.blur-up';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import Swiper, { Navigation } from 'swiper';

import {focusHash, bindInPageLinks} from '@shopify/theme-a11y';
//import {cookiesEnabled} from '@shopify/theme-cart';

window.slate = window.slate || {};
window.theme = window.theme || {};

import '@/scripts/react/theme';
import headerNav from '@/scripts/headerNav';
import global from '@/scripts/global';
import jsTabs from '@/scripts/jsTabs';
import custmerAccount from '@/scripts/custmerAccount';
import searchHeader from '@/scripts/searchHeader';
import modalExit from '@/scripts/modalExit'
import offCanvas from '@/scripts/offCanvas';
import sellMyDataModal from '@/scripts/sellMyDataModal';
import searchContent from '@/scripts/searchContent';
import shoGun from '@/scripts/shogun';
import popup from '@/scripts/popup';
import contentLinks from '@/scripts/content-links';
import carouselScroll from '@/scripts/carousel-scroll';
import topNav from '@/scripts/top-nav';
import cordialTracking from '@/scripts/cordial-tracking';
import carousel from '@/scripts/carousel';
import newsletterSignupForm from '@/scripts/newsletter-signup-form';
import { getPriceWithoutCurrency } from '../scripts/react/theme/cart/AB/utils';
import * as moneyUtils from '@/scripts/utils/money';
import countryPopup from '@/scripts/country-popup';
import languageForcer from '@/scripts/language-forcer';
import { getBundleDiscountAmount, cleanupCart } from '@/scripts/react/theme/pdp/kids-bundle.js';

window.GLOBAL = window.GLOBAL || {};
window.GLOBAL.moneyUtils = moneyUtils;
window.GLOBAL.formatMoney = getPriceWithoutCurrency;
window.GLOBAL.getBundleDiscountAmount = getBundleDiscountAmount;
window.GLOBAL.bundleCleanupCart = cleanupCart;
window.GLOBAL.Swiper = Swiper;
window.GLOBAL.SwiperNavigation = Navigation;

import pdpInfluence from '@/scripts/pdp-influence';
import heroCarousel from '@/scripts/hero-carousel';
import reviewCarousel from '@/scripts/review-carousel';
import instaCarousel from '@/scripts/insta-carousel';
import ibiFeatures from '@/scripts/ibi-features';
import tshirtTileCarousel from '@/scripts/tshirt-tile-carousel';
import cartCarousel from '@/scripts/cart-carousel';
import collectionCarousel from '@/scripts/collection-carousel';
import stockistCarousel from '@/scripts/stockist-carousel';
import crowdPleasersCarousel from '@/scripts/crowd-pleasers-carousel';
import stickyNav from '@/scripts/sticky-nav';
import Video from '@/scripts/video';
import asSeenIn from '@/scripts/asseenin-media';
import videoPlayButton from '@/scripts/video-play-button';
import inlineVideoPlayer from '@/scripts/inline-video-player';
import collaborationCarousel from '@/scripts/collaboration-carousel';
import homeHero from '@/scripts/home-hero';
import currencySelectorPopup from '@/scripts/currency-selector-popup';
import footerMenu from '@/scripts/footer-menu';
import bestSellers from '../scripts/best-sellers';
import homepageProductCategory from '../scripts/homepage-product-category';

// Common a11y fixes
focusHash();
bindInPageLinks();
headerNav();
jsTabs();
custmerAccount();
global();
searchHeader();
modalExit();
offCanvas();
sellMyDataModal();
searchContent();

shoGun();
popup();
contentLinks();
carouselScroll();
topNav();
cordialTracking();
carousel();
newsletterSignupForm();
pdpInfluence();
heroCarousel();
reviewCarousel();
instaCarousel();
ibiFeatures();
tshirtTileCarousel();
cartCarousel();
collectionCarousel();
stockistCarousel();
crowdPleasersCarousel();
stickyNav();
countryPopup();
Video();
asSeenIn();
videoPlayButton();
inlineVideoPlayer();
collaborationCarousel();
homeHero();
currencySelectorPopup();
footerMenu();
bestSellers();
homepageProductCategory();
languageForcer();