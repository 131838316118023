import $ from 'jquery'

export default function sellMyDataModal() {

    $('.c-sellMyDataModal__cross').on('click', function(){
        $('.c-sellMyDataModal').removeClass('c-sellMyDataModal__layer--open')
    })

    // $('.c-sellMyDataModal__closeWindowButton').on('click', function(){
    //     $('.c-sellMyDataModal').removeClass('c-sellMyDataModal__layer--open')
    // })

    $('#open-do-not-sell-my-data').on('click', function(){
        $('.c-sellMyDataModal').addClass('c-sellMyDataModal__layer--open')
    })

    try{   
        var timer = setInterval(function() {
            if (typeof KlaviyoSubscribe !== 'undefined') {
                KlaviyoSubscribe.attachToForms("#sell-my-data-modal-signup", {
                    hide_form_on_success: true,
                    custom_success_message: false,
                    extra_properties:{
                        $source: 'Modal Submission',
                    },
                    success: function() {
                        $('.c-sellMyDataModal__innerModalHeader').html('Email Submitted');
                        $('.c-sellMyDataModal__message').text('Your preference has been recorded and we will process it as quickly as possible; however we may need to obtain additional information from you in order to validate and process your request. If we are unable to process your request, we will notify you.');
                        $('.c-sellMyDataModal__innerModal').addClass('c-modalCenter');
                        $('.c-sellMyDataModal__form').hide();
                        $('.c-sellMyDataModal__closeWindowButton').show();
                    }
                });
                clearInterval(timer)
            }
        }, 1000);
    } catch(e){
        console.log(e, 222)
    }
}
   

