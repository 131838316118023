import Swiper, { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const init = el => {
  let swiper

  const breakpointChecker = function() {
    if (window.innerWidth < 1023) return enableSwiper()
    if (swiper !== undefined) swiper.destroy(true, true)
  }

  const enableSwiper = () => {
    if (swiper && !swiper.hasOwnProperty('destroyed')) return

    swiper = new Swiper(el, {
      modules: [
        Pagination
      ],
      cssMode: false,
      slidesPerView: 2,
      slidesPerGroup: 2,
      loop: true,
      speed: 600,
      pagination: {
        el: el.querySelector('.swiper-pagination'),
        clickable: true
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        }
      }
    })
  }

  window.addEventListener('resize', () => breakpointChecker())
  breakpointChecker()
}

const initSwiper = wrapper => {
  const carousels = wrapper.querySelectorAll('.swiper')

  carousels.forEach(carousel => {
    const parent = carousel.parentNode
    const prevEl = parent.querySelector('.swiper-button-prev')
    const nextEl = parent.querySelector('.swiper-button-next')

    const swiper = new Swiper(carousel, {
      modules: [
        Navigation
      ],
      cssMode: false,
      slidesPerView: 2,
      watchOverflow: true,
      speed: 600,
      navigation: {
        prevEl,
        nextEl
      },
      breakpoints: {
        768: {
          slidesPerView: 4
        }
      }
    })
  })
}

export default () => {
  document.querySelectorAll('#js-crowdPleasersSlider').forEach(el => init(el))
  document.querySelectorAll('.js-crowd-pleasers-carousel').forEach(el => initSwiper(el))
}
