/** @jsx h */

import { h, Component } from 'preact'

const languages = window.pdpLanguages ?? {}

class SizeSelectorUnisex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      variants: window.sizeSelector?.variants
    }
  }

  componentDidMount() {
    $(document).on('sizeselectorunisex:ready', (_, variants) => {
      this.setState({
        variants: [...variants]
      })
    })
  }

	isMen() {
		return !window.location.pathname.includes('women')
	}

  sizeSelected = (event) => {
    const variant = this.state.variants.find(variant => variant.size + "" === event.target.value)
    this.props.CurrentVariant(variant, "notNode");
  }

  getSizeLabel(size) {
    return ' US ' + size
  }

  render() {
    return (
      <div class={"size-selector " + (this.state.variants && this.state.variants.length === 1 ? 'size-selector--one-size' : '')}>
        <div class="size-selector__wrapper">
          <div class="size-selector__label">
            {languages.size_label}
          </div>
          <div class="size-selector__value">
            {this.state.variants && this.state.variants.length === 1 ? (
              <span>{languages.one_size}</span>
            ) : (
              <span>
                {this.state.variants !== null ? this.getSizeLabel(this.props.currentSize) : languages.select_size}
              </span>
            )}
          </div>
          <select
            class="size-selector__select"
            value={this.props.currentSize}
            onChange={this.sizeSelected}
          >
            {this.state.variants && this.state.variants.map((variant, index) => (
              <option key={variant.id + index} value={variant.size}>
                {this.getSizeLabel(variant.size)}
              </option>
            ))}
          </select>
        </div>
      </div>
    )
  }
}

export default SizeSelectorUnisex;
