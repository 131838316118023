/**
 *
 * @prettier
 */

import { h } from 'preact';
/** @jsx h */
import { useState, useEffect } from 'preact/hooks';

const languages = window.pdpLanguages ?? {}

const UniSexTabs = (props) => {
    const [menActive, setMenActive] = useState(true);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('gender');
    if(myParam=="women"){
        changeTabs(false);
    }else{
        changeTabs(true);
    }
    }, []);
    function changeTabs(isMen){
        if(isMen){
            setMenActive(true);
            props.changeGender(true);
        }else{
            setMenActive(false);
            props.changeGender(false);

        }
    }
	return (
        <div class="c-pdp__genderWrap" >
            <a class={`c-abGender__btn  c-men ${menActive ? 'is-active' : ''}`} href={`${window.location.pathname}?gender=men`}  onClick={()=>{changeTabs(true);}} ><span>{languages.men}</span></a>
            <a  class={`c-abGender__btn c-women ${menActive ? '' : 'is-active'} `}href={`${window.location.pathname}?gender=women`} onClick={()=>{changeTabs(false)}} ><span>{languages.women}</span></a>
        </div>

	);
};

export default UniSexTabs;

