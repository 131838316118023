/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact'
import { formatMoney } from '../../../utils/money'

const productInfoCache = {};

export default class AddonUpsell extends Component {
  constructor(props) {
    super(props)

    this.state = {
      laceCollection: '',
      showErrorMessage: '',
      isSkateShoe: this.props.productHandle.includes('skate')
    }
  }

  componentDidMount() {
    this.getLaceCollection()
  }

  getProductInfo = async (productHandle) => {
    return new Promise(resolve => {
      if (productHandle in productInfoCache) {
        resolve(productInfoCache[productHandle])
        return
      }

      fetch(`/products/${productHandle}?view=info`)
        .then(response => response.json())
        .then(json => {
          productInfoCache[productHandle] = json
          resolve(productInfoCache[productHandle])
        })
        .catch(() => resolve({}))
    })
  }

  getLaceCollection = async () => {
    if (!this.props.productHandle) return
    const info = await this.getProductInfo(this.props.productHandle)
    this.setState({
      laceCollection: info.laceUpsellCollection
    })
  }

  isMen() {
    return this.props.isMen ?? !window.location.pathname.includes('women')
  }

  getItems () {
    if (!Array.isArray(this.props.data.items) || !this.props.data.items.length > 0) return []
    return this.props.data.items
  }

  getButtonAttributes(item) {
    switch (item.type) {
      case 'crew-socks':
      case 'no-show-socks':
      case 'skate-socks':
        return {
          'data-type': 'socks',
          'data-collection-handle': 'socks',
          'data-target': item.type.replace('-socks', ''),
          'data-selected-id': item.product.id
        }
      case 'shoe-bag':
        return {
          'data-type': 'shoebag',
          'data-collection-handle': 'shoe-bag',
          'data-selected-id': item.product.id
        }
      case 'insole':
        return {
          'data-type': 'insole',
          'data-collection-handle': 'insole-1',
          'data-main-product-id': this.props.selectedVariant.productID,
          'data-main-product-size': this.props.selectedVariant.actualSize,
          'data-main-product-size-label': this.props.selectedVariant.size,
          'data-main-product-gender': this.isMen() ? 'Men' : 'Women',
          'data-selected-id': item.product.id
        }
      case 'regular-laces':
      case 'wide-laces':
      case 'oca_low-laces':
      case 'oca_high-laces':
      case 'skate-laces':
      case 'skate_high-laces':
      case 'ibi-laces':
        return {
          'data-type': 'laces',
          'data-collection-handle': this.state.laceCollection,
          'data-main-product-id': this.props.selectedVariant.productID,
          'data-main-product-size': this.props.selectedVariant.actualSize,
          'data-main-product-gender': this.isMen() ? 'Men' : 'Women',
          'data-target': item.type.replace('-laces', ''),
          'data-selected-id': item.product.id
        }
    }
  }

  handleOnClick = (e) => {
    if (this.props.isAddedToCart) {
      this.setState({ showErrorMessage: false })
      return
    }
    e.stopPropagation()
    this.setState({ showErrorMessage: true })
  }

  render() {
    return this.props.data && (
      <div className="pdp-add-on-upsell">
        {this.props.data.title && <p className="pdp-add-on-upsell__title">{this.props.data.title}</p>}
        {this.props.data.description && <p className="pdp-add-on-upsell__description">{this.props.data.description}</p>}
        {Array.isArray(this.props.data.items) && this.props.data.items.length > 0 && <div className="pdp-add-on-upsell__list">
          {this.getItems().map((item, index) => (
            <div className="pdp-add-on-upsell__item" key={index}>
              <div className="pdp-add-on-upsell__item-image ratio ro-1">
                {item.product?.featured_image && (
                  <img src={item.product.featured_image} alt={item.title} />
                )}
              </div>
              <p className="pdp-add-on-upsell__item-title">{item.title}</p>
              <p className="pdp-add-on-upsell__item-price">{formatMoney(item.product.price_max, Shopify.currency.active, true)}</p>
              <button
                className={`pdp-add-on-upsell__item-add js-upsell-opener${this.props.isAddedToCart ? '' : ' disabled'}`}
                {...this.getButtonAttributes(item)}
                onClick={this.handleOnClick}
              >
                ADD +
              </button>
            </div>
          ))}
        </div>}
        {this.state.showErrorMessage && !this.props.isAddedToCart && <p className="pdp-add-on-upsell__error">*Add shoe to bag before selecting Add-Ons</p>}
      </div>
    )
  }
}