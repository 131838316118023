/** @jsx h */

import { h, Component, Fragment, createRef } from "preact";
import Swatch from "./swatch";
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

class SocksImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelected: this.props.currentSelected ? this.props.currentSelected : null
    };

    this.elRef = createRef()

    this.swiperIns = null
    this.swiperNavIns = null
  }

  componentDidMount() {}

  componentWillUpdate(nextProps, nextState) {
    if (this.state.currentSelected !== nextState.currentSelected) {
      // this.removeSwiper();
    }

    if (nextProps && this.props.currentSelected !== nextProps.currentSelected) {
      this.setState({
        currentSelected: nextProps.currentSelected
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.currentSelected !== prevState.currentSelected) {
      // this.removeSwiper();
      // this.addNewSwiper();
    }
  }
  addNewSwiper = () => {
    const _this = this
    const slider = this.elRef.current.querySelector('.c-upsell__productImageSocks')
    const sliderNav = this.elRef.current.querySelector('.slider-nav-socks')

    if (!slider || slider.querySelectorAll('picture').length < 2 || (this.swiperIns && !this.swiperIns.hasOwnProperty('destroyed'))) return

    _this.swiperNavIns = new Swiper(sliderNav, {
      loop: sliderNav.querySelectorAll('picture').length > 3,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true
    })

    _this.swiperIns = new Swiper(slider.querySelector('.swiper'), {
      modules: [
        Navigation,
        Thumbs
      ],
      loop: true,
      slidesPerView: 1,
      speed: 600,
      navigation: {
        nextEl: _this.elRef.current.querySelector('.swiper-button-next'),
        prevEl: _this.elRef.current.querySelector('.swiper-button-prev')
      },
      thumbs: {
        swiper: _this.swiperNavIns
      }
    })
  };
  removeSwiper = () => {
    this.swiperIns && this.swiperIns.destroy()
    this.swiperNavIns && this.swiperNavIns.destroy()
  };
  ChangeSocksColor = (id, socksData) => {
    if(id==this.state.currentSelected.node.id){
      return
    }
    $(".c-productVariant__item__socks").removeClass("is-active");
    $(`.c-productVariant__item__socks__${id}`).addClass("is-active");

    this.setState({
      currentSelected: socksData,
    }, ()=>{
      this.props.getCurrentSelected(this.state.currentSelected);
    });
  };
  render() {
    return (
      <Fragment>
        {this.state.currentSelected != null ? (
          <Fragment>
            <div
              key={this.state.currentSelected.node.id}
              class={`c-upsell__productBlock__socks c-upsell__SocksBlock__${
                this.state.currentSelected.node.id
              } ${"shown"}`}
              data-key={this.state.currentSelected.node.id}
              ref={this.elRef}
            >
              <div class="c-upsell__productImageSocks">
                <div class="swiper">
                  <div class="swiper-wrapper">
                    {this.state.currentSelected.node.images.edges.map((image) => {
                      if (image.node.altText == null) {
                        return (
                          <div class="swiper-slide">
                            <div class="image-el">
                              <picture>
                                <source
                                  srcset={image.node.originalSrc}
                                  media="(min-width: 900px)"
                                />
                                <source
                                  srcset={image.node.originalSrc}
                                  media="(min-width: 320px)"
                                />
                                <img
                                  class="c-upsell__innerImage"
                                  loading="lazy"
                                  src={image.node.originalSrc}
                                  alt="Cariuma "
                                />
                              </picture>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
                {this.state.currentSelected.node.images.edges.length > 1 ?
                  <Fragment>
                    <div class="swiper-button swiper-button-prev"></div>
                    <div class="swiper-button swiper-button-next"></div>
                  </Fragment>
                : ''}
              </div>
              <div
                className={(this.state.currentSelected.node.images.edges.length > 1) ? 'slider-nav-socks swiper' : 'hidden' }
              >
                <div class="swiper-wrapper">
                  {this.state.currentSelected.node.images.edges.map((image) => {
                    if (image.node.altText == null) {
                      return (
                        <div class="swiper-slide">
                          <div class="image-el">
                            <picture>
                              <source
                                srcset={image.node.originalSrc}
                                media="(min-width: 900px)"
                              />
                              <source
                                srcset={image.node.originalSrc}
                                media="(min-width: 320px)"
                              />
                              <img
                                class="c-upsell__innerImage"
                                loading="lazy"
                                src={image.node.originalSrc}
                                alt="Cariuma "
                              />
                            </picture>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div class="c-upsell__productTitle c-upsell__productTitle__socks">
                <div class="c-cart__itemUpsell__socks">
                  Select Color
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div class="c-upsell__productBlock__socks shimmer shown"></div>
        )}
        <div class={`c-upsell__variantBlock c-upsell__productGroup__${this.props.activeGroupIdx}`}>
          {this.props.socksData &&
            this.props.socksData.map((socksData, index) => {
              return (
                <div
                  key={socksData.node.id}
                  class={`c-productVariant__item__socks c-productVariant__item__socks__${socksData.node.id}  show ${
                    index == 0 ? "is-select is-active " : ""
                  }`}
                  data-key={socksData.node.id}
                >
                  <Swatch
                    productData={socksData}
                    index={index}
                    changeColor={this.ChangeSocksColor}
                  />
                </div>
              );
            })}
        </div>
      </Fragment>
    );
  }
}

export default SocksImageSlider;
