import Cookies from 'js-cookie'
import $ from 'jquery';
	
export default function jsTabs() {	

	$('.jsTabs li').click(function(){
		var tab_id = $(this).attr('data-tab');
		$(this).siblings().removeClass('current');
		$(this).closest('.c-tabs').find('.c-tabsCon').removeClass('current');
		$(this).addClass('current');
		$(this).closest('.c-tabs').find(".c-tabsCon--"+tab_id).addClass('current');
		var cookie = $(this).parent().attr('data-cookie')
		if (cookie) {
			var value = $(this).attr('data-value')
			Cookies.set(cookie, value);
		}
	})

	$(document).ready(function () {
    $('.jsTabs').each(function() {
			var cookie = $(this).attr('data-cookie')
			if (cookie) {
				var value = Cookies.get(cookie)
				if (value) $(this).find('[data-value='+value+']').trigger('click')
			}
		})
	});

	var url = location.href;

	if ( url.indexOf( '#ourstory' ) !== -1 ) { 
		$('#tab-1').removeClass('current');
		$('.js-tabone').removeClass('current');
		$('#tab-2').addClass('current');
		$('.js-tabtwo').addClass('current');
	} else {
		$('#tab-1').addClass('current');
		$('.js-tabone').addClass('current');
		$('#tab-2').removeClass('current');
		$('.js-tabtwo').removeClass('current');
	}

	$('.js-ourstory').on("click", function () {
		$('#tab-1').removeClass('current');
		$('.js-tabone').removeClass('current');
		$('#tab-2').addClass('current');
		$('.js-tabtwo').addClass('current');
		$('html, body').animate({
			scrollTop: $(".jsTabs").offset().top - 150
		}, 500)
	});

	$('.jsourway').on("click", function () {
		$('#tab-1').addClass('current');
		$('.js-tabone').addClass('current');
		$('#tab-2').removeClass('current');
		$('.js-tabtwo').removeClass('current');
		$('html, body').animate({
			scrollTop: $(".jsTabs").offset().top - 150
		}, 500)
	});

	$('.js-currencySelector').on("click", function () {
		$(this).toggleClass('active');
		$(this).next().toggleClass('is-open');
	});

  $(document).on('click', function(event) {
    var $target = $(event.target);

    if (!$target.closest('.footer__currency-select').length) {
      $('.js-currencySelector').removeClass('active'); 
      $('.js-currencySelector').next().removeClass('is-open');
    }
  })

	$('.js-recentDesk h3').on("click", function () {

		var tab_id = $(this).attr('data-tab');

    var $this = $(this);
    $this.parent().parent().find('h3').removeClass('current');
    $this.parent().parent().find('.c-tabsCon').removeClass('current');

    $this.addClass('current');
    $this.parent().parent().find(`#${tab_id}`).addClass('current');
	});

	$('.js-recentMob h3').click(function(){
		var tab_id = $(this).attr('data-tab');
	
		$('.js-recentMob h3').removeClass('current');
		$('.c-tabsCon').removeClass('current');
	
		$(this).addClass('current');
		$("#"+tab_id).addClass('current');
	});
}