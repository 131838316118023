/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact'

class OptionsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hasNavigation: false,
      hasRightItems: false
    }

    this.innerRef = createRef()
  }

  componentDidMount() {
    const el = this.innerRef.current

    if (el) {
      this.reupdateState()

      el.addEventListener('scroll', () => {
        this.reupdateState()
      })

      window.addEventListener('resize', () => {
        this.reupdateState()
      })
    }
  }

  reupdateState() {
    const el = this.innerRef.current

    if (el) {
      el.classList.remove('option-container__inner-has-navigation')

      const width = el.parentNode.clientWidth
      const scrollWidth = el.scrollWidth
      const scrollLeft = el.scrollLeft
      const hasNavigation = width < scrollWidth
      const hasRightItems = width + scrollLeft < scrollWidth - 1

      this.setState({
        hasNavigation,
        hasRightItems
      })
    }
  }

  scrollInner = () => {
    const el = this.innerRef.current

    if (el) {
      const countChildren = el.children.length
      const scrollStep = el.scrollWidth / countChildren
      const left = el.scrollLeft + scrollStep * (this.props.numItemsToMove || 1);

      el.scroll({
        left,
        behavior: 'smooth'
      })
    }
  }

  resetInnerScroll = () => {
    const el = this.innerRef.current

    if (el) {
      el.scroll({
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  render() {
    return (
      <div class="option-container">
        <div ref={this.innerRef} className={`option-container__inner ${this.state.hasNavigation ? 'option-container__inner--has-navigation' : ''}`}>
          {this.props.children}
        </div>

        {this.state.hasNavigation && (
          <Fragment>
            {this.state.hasRightItems ? (
              <button
                class="option-container__navigation option-container__navigation--next"
                aria-label="Next"
                onClick={this.scrollInner}
              ></button>
            ) : (
              <button
                class="option-container__navigation option-container__navigation--back"
                aria-label="Back to begining"
                onClick={this.resetInnerScroll}
              ></button>
            )}
          </Fragment>
        )}
      </div>
    )
  }
}

export default OptionsContainer
