import { debounce } from 'lodash-es'

export default function() {
  document.addEventListener('DOMContentLoaded', () => {
    const listEl = document.querySelectorAll('.js-as-seen-in')
    listEl && initWidth(listEl)
    listEl && document.addEventListener('resize', debounce(() => initWidth(listEl), 20))
  })
  const initWidth = (els) => {
    if (window.innerWidth < 768) {
      els.forEach(el => {
        const width = el.scrollWidth
        el.style.minWidth = `${width}px`
      })
    }
  }
}
