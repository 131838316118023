/** @jsx h */

if (import.meta.env.DEV) {
  import('preact/debug')
}

import { h, render, Fragment } from 'preact';

//import SizeDropdown from "./components/SizeDropdown";
// !!! NOT MIGRATED TO PREACT !!! //import WheelForm from "./sections/WheelForm";
// !!! NOT MIGRATED TO PREACT !!! //import SubscriptionForm from "./components/SubscriptionForm";
import NoticeBar from "./components/NoticeBar";
import Footer from "./components/Footer";

import Pdp from "./pdp/Pdp";
import PdpAlt from "./pdp/PdpAlt";
/* Old Cart [Start] */
	// import Laces from './cart/Laces';
	// import Socks from './cart/Socks';
	// import Insole from './cart/Insole';
	// import CatibaProLaces from './cart/catibaProLaces';
/* Old Cart [End] */

import PdpUnisex from "./pdp/PdpUnisex";
import PdpBackpack from "./pdp/PdpBackpack";
import Pdp4Ocean from "./pdp/Pdp4Ocean";
import PdpKids from './pdp/PdpKids';

import SocksAB from './cart/AB/socks';
//import CatibaProLacesAB from './cart/AB/catibaProLaces';
//import HighLacesAB from './cart/AB/highLaces';

import IBILaces from './cart/AB/ibiLaces';
import AddonWrapper from './cart/addon/AddonWrapper';

import ShippingEstimate from './components/ShippingEstimate';
//import React from "react";
//import ReactDOM from 'react-dom';


// Collection is intentionally here, not in templates/collection.js
// fue to slate problems (JS not working when build for production)
import './collection';
import fetch from "cross-fetch";

import CartUpsellPopup from './cart/upsell/Popup';

/*
const wheelForm = document.querySelector('#react-wheel-form');
wheelForm && ReactDOM.render(<WheelForm/>, wheelForm);
*/

const Pdppage = document.querySelector('#js-pdpPageContent');
const PdpAltPage = document.querySelector('.pdp-alt #js-pdpPageContent')
Pdppage && render(PdpAltPage ? <PdpAlt /> : <Pdp />, Pdppage);

//for unisex product
const PdpUnisexPage = document.querySelector('#js-pdpUnisexPageContent');
PdpUnisexPage && render(<PdpUnisex />, PdpUnisexPage);

//for backpack product
const PdpbackPackPage = document.querySelector('#js-pdpBackpackPageContent');
PdpbackPackPage && render(<PdpBackpack />, PdpbackPackPage);

//for 4Ocean product
const Pdp4OceanPage = document.querySelector('#js-pdp4OceanPageContent');
Pdp4OceanPage && render(<Pdp4Ocean />, Pdp4OceanPage);

// for bundles product
const pdpKidsPage = document.querySelector('#js-pdpKidsPageContent');
pdpKidsPage && render(<PdpKids />, pdpKidsPage);

/* Old Cart [Start] */
	// const cartLace = document.querySelector('#js-cartLace');
	// cartLace && render(<Laces />, cartLace);

	// const cartLaceCatibaPro = document.querySelector('#js-cartLaceCatibaPro');
	// cartLaceCatibaPro && render(<CatibaProLaces />, cartLaceCatibaPro);

	// const cartSocks = document.querySelector('#js-cartSocks');
	// cartSocks && render(<Socks />, cartSocks);

	// const insole = document.querySelector('#js-insole');
	// insole && render(<Insole />, insole);
/* Old Cart [End] */

/* AB */
setTimeout(() => {
  const addonWrapper = document.querySelector('#js-addon-wrapper');
  addonWrapper && render(<AddonWrapper />, addonWrapper)
}, 500)

window.refreshLace = function() {
  const cartLaceAB = document.querySelector('#js-cartLace-ab');
  // cartLaceAB && render(<LacesAB />, cartLaceAB);
}
window.refreshIBILace = function() {
  const iBILaces = document.querySelector('#js-cartIBILaces-ab');
  if ((iBILaces != null) && (typeof iBILaces != 'undefined')) {
    iBILaces && render(<IBILaces />, iBILaces);
  }
}

setTimeout(() => {
  const cartSocksAB = document.querySelector('#js-cartSocks-ab');
  cartSocksAB && render(<SocksAB />, cartSocksAB);

  const shippingEstimate = document.querySelector("#js-cartShippingEstimate");
  shippingEstimate && render(<ShippingEstimate/>, shippingEstimate);

  window.refreshLace();
  // window.refreshIBILace();

	// const cartLaceCatibaProAB = document.querySelector('#js-cartLaceCatibaPro-ab');
	// cartLaceCatibaProAB && render(<CatibaProLacesAB />, cartLaceCatibaProAB);

	// const cartLaceHighAB = document.querySelector('#js-cartLaceHigh-ab');
	// cartLaceCatibaProAB && render(<HighLacesAB />, cartLaceHighAB);
}, 600)

const noticeBar = document.querySelector('#notice-bar');
if (noticeBar) {
	noticeBar && render(<NoticeBar/>, noticeBar);
}

const noticeBarAB = document.querySelector('#notice-bar-ab');
if (noticeBarAB) {
	noticeBarAB && render(<NoticeBar/>, noticeBarAB);
}

const footer = document.querySelector('footer');
if (footer) {
	footer && render(<Footer/>, footer);
}

const cartUpsellPopup = document.querySelector('#cart-upsell-popup');
if (cartUpsellPopup) {
	cartUpsellPopup && render(<CartUpsellPopup />, cartUpsellPopup);
}