import $ from 'jquery'

const BREAKPOINT = 990
const CAROUSEL_CLASS = 'top-nav-subnav__carousel'

export default function topNav() {
  const $topNav = $('.js-top-nav')
  const $topNavClose = $('.js-top-nav-close')
  const $topNavBack = $('.js-top-nav-back')
  const $topNavTitle = $('.js-top-nav-title')
  const $topNavSubnavTrigger = $('.js-top-nav-subnav-trigger')

  $topNavClose.on('click', () => {
    $topNav.removeClass('is-active')
  })

  $topNavBack.on('click', () => {
    $topNav.removeClass('is-subnav-active')
    $('.top-nav__item.is-subnav-active').removeClass('is-subnav-active')
    $topNavTitle.text('')
  })

  $topNavSubnavTrigger.on('click', function (event) {
    if (window.innerWidth < BREAKPOINT) {
      event.preventDefault()
      event.stopPropagation()

      $(this).closest('.top-nav__item').addClass('is-subnav-active')
      $topNav.addClass('is-subnav-active')
      $topNavTitle.text($(this).text())
    }
  })

  const hideSubNav = () => {
    $topNav.removeClass('is-subnav-active')
    $('.top-nav__item').removeClass('is-subnav-active')
  }

  $('.top-nav__link').on('mouseenter', function () {
    if (window.innerWidth >= BREAKPOINT) {
      const $this = $(this)

      hideSubNav();

      if ($this.hasClass('js-top-nav-subnav-trigger')) {
        $(this).closest('.top-nav__item').addClass('is-subnav-active')
        $topNav.addClass('is-subnav-active')
        $topNavTitle.text($(this).text())
      }
    }
  })

  $topNav.on('mouseenter', () => {
    $topNav.addClass('is-active')
  })

  $topNav.on('mouseleave', () => {
    $topNav.removeClass('is-active')

    if (window.innerWidth >= BREAKPOINT) {
      hideSubNav();
    }
  })


  const $body = $(document.body)
  const $window = $(window)

  if ($body.hasClass('template-index') || $body.hasClass('template-skate') || $body.hasClass('template-surf') || $body.hasClass('template-reforestation')) {
    $window.on('scroll', () => {
      if ($window.scrollTop() >= 20) {
        $topNav.addClass('is-white')
      } else {
        $topNav.removeClass('is-white')
      }
    })
  }

  const updateCarouselStatus = ($carousel) => {
    const $list = $carousel.find('.top-nav-subnav__list')

    if (!$carousel.length || !$list.length) {
      return
    }

    const list = $list[0]
    const offset = 1

    if (list.scrollWidth > list.clientWidth) {
      $carousel.toggleClass(`${CAROUSEL_CLASS}--has-previous`, list.scrollLeft > offset)
      $carousel.toggleClass(`${CAROUSEL_CLASS}--has-next`, list.scrollLeft + list.clientWidth < list.scrollWidth - offset)
    }
  }

  const setupCarousel = () => {
    const $carousels = $(`.${CAROUSEL_CLASS}`)

    $carousels.each(function() {
      const $carousel = $(this)
      const initClass = `${CAROUSEL_CLASS}--is-initialized`
      const $list = $carousel.find('.top-nav-subnav__list')

      if ($carousel.hasClass(initClass) || !$list.length) {
        return
      }

      $carousel.addClass(initClass)

      $carousel.append(`
        <button class="${CAROUSEL_CLASS}-button ${CAROUSEL_CLASS}-button--previous" aria-label="previous"></button>
        <button class="${CAROUSEL_CLASS}-button ${CAROUSEL_CLASS}-button--next" aria-label="next"></button>
      `)


      $carousel.find(`.${CAROUSEL_CLASS}-button`).on('click', function() {
        const $this = $(this)
        const direction = $this.hasClass(`${CAROUSEL_CLASS}-button--next`) ? 1 : -1
        const itemWidth = $list.find('.top-nav-subnav__item:not(:hidden)').width()

        $list[0].scrollLeft += direction * itemWidth

        setTimeout(() => {
          updateCarouselStatus($carousel)
        }, 500)
      })

      updateCarouselStatus($carousel)

      $list.on('scroll', () => {
        updateCarouselStatus($carousel)
      })
    })
  }

  setupCarousel()
}