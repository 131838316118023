/** @jsx h */

import { h, Component } from 'preact'
import translate from '@/scripts/utils/translate';

const countrySizeLabelMap = {
  uk_size: 'UK',
  eur_size: 'EUR',
  aus_size: 'AUS',
  br_size: 'BR',
}

const languages = window.pdpLanguages ?? {}

class SizeSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      variants: null,
      currentCountrySizeActive: null
    }
  }

  componentDidMount() {
    $(document).on('sizeselector:ready', (_, variants, currentCountrySizeActive) => {
      this.setState({
        variants,
        currentCountrySizeActive
      })
    })
  }

	isMen() {
		return this.props.isMen ?? !window.location.pathname.includes('women')
	}

  sizeSelected = (event) => {
    const variant = this.state.variants?.find(variant => variant.size + "" === event.target.value)
    this.props.CurrentVariant(variant, "notNode")
  }

  getSizeLabel(size) {
    if (this.state.currentCountrySizeActive in countrySizeLabelMap) {
      return translate(this.isMen() ? languages.country_size_men : languages.country_size_women, { country: countrySizeLabelMap[this.state.currentCountrySizeActive], size })
    }

    return translate(this.isMen() ? languages.country_size_men : languages.country_size_women, { country: 'US', size })
  }

  getCurrentSizeLabel() {
    const variant = this.state.variants?.find(variant => variant.size + '' === this.props.currentSize)
    return this.getSizeLabelByVariant(variant)
  }

  getSizeLabelByVariant(variant) {
    if (variant) {
      let size = variant.size

      if (variant.multiCountrySize) {
        size = this.isMen() ? variant.multiCountrySize.sizeMen : variant.multiCountrySize.sizeWomen
      }

      return this.getSizeLabel(size)
    }

    return languages.select_size
  }

  getSizeConversion() {
    const variant = this.state.variants?.find(variant => variant.size + '' === this.props.currentSize)

    if (!variant?.multiCountrySize || !(this.state.currentCountrySizeActive in countrySizeLabelMap)) {
      return ''
    }

    const genderLabel = this.isMen() ? 'M' : 'W'
    const countrySizeLabel = countrySizeLabelMap[this.state.currentCountrySizeActive]
    const size = this.isMen() ? variant.multiCountrySize.sizeMen : variant.multiCountrySize.sizeWomen
    const sizeUs = variant.multiCountrySize.sizeUs

    return `${genderLabel} ${countrySizeLabel} ${size} = ${genderLabel} US ${sizeUs}`
  }

  render() {
    const sizeConversion = this.getSizeConversion()

    return (
      <div class={"size-selector " + (this.state.variants && this.state.variants.length === 1 ? 'size-selector--one-size' : '')}>
        <div class="size-selector__wrapper">
          <div class="size-selector__label">
            {languages.size_label}
          </div>
          <div class="size-selector__value">
            {this.state.variants && this.state.variants.length === 1 ? (
              <span>{languages.one_size}</span>
            ) : (
              <span>
                {this.state.variants !== null ? this.getCurrentSizeLabel() : languages.select_size}
              </span>
            )}
          </div>
          <select
            class="size-selector__select"
            value={this.props.currentSize}
            onChange={this.sizeSelected}
          >
            {this.state.variants && this.state.variants.map((variant, index) => (
              <option key={variant.id + index} value={variant.size}>
                {this.getSizeLabelByVariant(variant)}
              </option>
            ))}
          </select>
        </div>

        {sizeConversion && (
          <div class="size-selector__conversion abStickyAddToCartSizeConversionSegment">
            {sizeConversion}
          </div>
        )}
      </div>
    )
  }
}

export default SizeSelector;
