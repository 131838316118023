import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default function() {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-ibi-influence__slider').forEach(el => {
      init(el)
    })
  })

  const init = el => {
    const swiper = new Swiper(el, {
      modules: [
        Navigation
      ],
      cssMode: false,
      slidesPerView: 'auto',
      watchOverflow: true,
      loop: false,
      speed: 600,
      navigation: {
        nextEl: el.querySelector('.swiper-button-next'),
        prevEl: el.querySelector('.swiper-button-prev')
      },
    })
  }
}