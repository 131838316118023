import { formatMoney } from '../../../../utils/money'

export default function getShoeSize(data) {
  if (window.clickedUpsellProduct) {
    const { size, gender } = window.clickedUpsellProduct;
    let shoelaceSize ;
    if (gender == "Women") {
      if (size >= 5 && size <= 6.5) {
        shoelaceSize = 1;
      } else if (size >= 7 && size <= 9) {
        shoelaceSize = 2;
      } else if (size >= 9.5 && size <= 11.5) {
        shoelaceSize = 3;
      } else if (size >= 12 && size <= 14.5) {
        shoelaceSize = 4;
      }
    } else {
      //men
      if (size >= 3.5 && size <= 5) {
        shoelaceSize = 1;
      } else if (size >= 5.5 && size <= 7.5) {
        shoelaceSize = 2;
      } else if (size >= 8 && size <= 10) {
        shoelaceSize = 3;
      } else if (size >= 10.5 && size <= 13) {
        shoelaceSize = 4;
      }
    }
    return shoelaceSize
  } else {
    return ''
  }
}

export const getPriceWithoutCurrency = (price, currency) => {
  return {
    tax: formatMoney(price, currency),
    noneTax: formatMoney(price, currency, true)
  }
}