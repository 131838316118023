/** @jsx h */

import { h, Component, Fragment, createRef } from "preact";
import $ from "jquery";

class SocksSizeSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVariant: null,
      CartBtnText: "ADD TO CART",
      currentSelectedSize: "XS",
      showSizeGuidePopup: false,
      errorMsg: null,
    };
    this.setSocksSizeSelect = this.setSocksSizeSelect.bind(this);
  }

  getErrorMessageSetting() {
    return ((window.OUT_STOCK_MESSAGE) && (window.OUT_STOCK_MESSAGE.socks)) ?
          window.OUT_STOCK_MESSAGE.socks : "Try a different color?"
  }

  SocksAddtoCart = (e) => {
    e.preventDefault();
    if(!this.state.selectedVariant.node.quantityAvailable){
      this.setState({
        CartBtnText: "OUT OF STOCK",
      });
      return ;
    }
    this.setState({
      CartBtnText: "ADDING TO CART",
    });
    let variantID = this.state.selectedVariant.node.id;
    if (variantID.indexOf('/') != -1) {
      variantID = variantID.substring(variantID.indexOf("t/") + 2)
    }

    let size = this.state.selectedVariant.node.selectedOptions[2].value;
    let ref = this;
    let quantity;

    CartJS.addItem(
      variantID,
      (quantity = 1),
      {
        size: size,
      },
      {
        error: function(jqXHR, textStatus, errorThrown) {
          ref.setState({
            errorMsg: ref.getErrorMessageSetting(),
            CartBtnText : "OUT OF STOCK"
          });
        },
        success: function(jqXHR, textStatus, errorThrown) {
          ref.setState({
            CartBtnText: "ADD TO CART",
            errorMsg : null
          },()=>{
              //checking cached socks and check the checkbox
            //check the checkbox for socks
            if(!$('.js-upsellOpener--socks').find('.hidden').hasClass('active')){
                $('.js-upsellOpener--socks').find('.hidden').attr("class", "active");
                if(!$('.c-socks-ctr').hasClass('add-more-socks-active')){
                  $('.c-socks-ctr').addClass('add-more-socks-active')
                }
            }
            localStorage.setItem('cachedSocks', JSON.stringify(variantID));
          });
        },
      }
    );
  };
  componentDidMount() {
    this.setSocksSizeSelect(this.props.currentSelected.node.variants[0])
  }
  componentWillUpdate(nextProps, nextState) {
    if (this.props.currentSelected !== nextProps.currentSelected) {
      let checkAvailableVariant = nextProps.currentSelected.node.variants.find((v)=>v.node.quantityAvailable>0);
      this.setSocksSizeSelect(checkAvailableVariant);
    }
  }
  setSocksSizeSelect (variant) {
    if(typeof(variant) !== "undefined"){
        //check for other available sizes
      this.setState({
        selectedVariant: variant,
        errorMsg : variant.node.quantityAvailable > 0 ?  null : this.getErrorMessageSetting(),
        CartBtnText : variant.node.quantityAvailable > 0 ?  "ADD TO CART" : "Out of Stock"
      });
    }else{
      this.setState({
        selectedVariant: null,
        errorMsg : this.getErrorMessageSetting(),
        CartBtnText : "Out of Stock"
      });
    }
  };
  handleSizeGuide = () => {
    this.setState({
      showSizeGuidePopup: !this.state.showSizeGuidePopup,
    }, () => {
      if (this.state.showSizeGuidePopup) {
        $('.js-upsell--socks.open').addClass('cart-overflow-hidden')
      } else {
        $('.js-upsell--socks.open').removeClass('cart-overflow-hidden')
      }
    });
  };
  getColorName = () => {
    if (this.props.currentSelected && this.props.currentSelected.node.alternativeTitle) {
      const arr = this.props.currentSelected.node.alternativeTitle.value
      return arr
    } else {
      return ""
    }
  };
  render() {
    return (
      <Fragment>
        <div class="c-upsell__selected-size">
          {((this.state.selectedVariant != null) && (this.state.selectedVariant.node.quantityAvailable > 0)) ? (
            <span>
              <b>
                {this.state.selectedVariant.node.selectedOptions[2].value}
              </b>
              <br />
              {this.getColorName()}
            </span>
          ) : (
            <span class="c-upsell__selected-size">OUT OF STOCK</span>
          )}
        </div>
        <div class="c-upsell-size-options-wrapper">
          <div class="c-upsell-size-options">
            {this.props.currentSelected != null
              ? this.props.currentSelected.node.variants.map((variant) => {
                  return (
                    <div
                      key={variant.node.id}
                      class={`c-upsell-size-box ${
                        this.state.selectedVariant != null ?
                          variant.node.quantityAvailable <= 0 ? 'disabled' :
                           variant.node.id == this.state.selectedVariant.node.id
                            ? "active"
                            : ""
                          : "disabled"
                      } `}
                      onClick={() => {
                        this.setSocksSizeSelect(variant);
                      }}
                    >
                      {variant.node.selectedOptions[2].value}
                    </div>
                  );
                })
              : ""}
          </div>
          <div class="c-upsell__size-guide" onClick={this.handleSizeGuide}>
            {"Size & Fit"}
          </div>
        </div>
        {this.state.showSizeGuidePopup ? (
          <div class="c-upsell__size-guide-popup">
            <div class="close-icon" onClick={this.handleSizeGuide}>
              x
            </div>
            <div class="c-upsell__size-guide-popup-heading">SELECT SIZE US</div>
            <div class="c-upsell-size-options-popup">
              {this.props.currentSelected != null
                ? this.props.currentSelected.node.variants.map((variant) => {
                    return (
                      <div
                        key={variant.node.id}
                        class={`c-upsell-size-box-popup ${
                          this.state.selectedVariant != null ?
                          variant.node.quantityAvailable <= 0 ? 'disabled' :
                             variant.node.id ==
                              this.state.selectedVariant.node.id
                              ? "active"
                              : ""
                            : ""
                        } `}
                        onClick={() => {
                          this.setSocksSizeSelect(variant);
                        }}
                      >
                        <span class="radio__control"></span>
                        <b>{variant.node.selectedOptions[3].actualSize}</b>
                        {variant.node.selectedOptions[3].actualSizeInDetail ? `(${variant.node.selectedOptions[3].actualSizeInDetail})` : ''}
                      </div>
                    );
                  })
                : ""}
              <div class="c-upsell__atc">
                <a
                  class={`js-addtocartLaces c-btn ${
                    this.state.CartBtnText == "ADD TO CART"
                      ? ""
                      : "btn-disabled"
                  }`}
                  onClick={this.SocksAddtoCart}
                >
                  {this.state.CartBtnText} -
                  {' '}
                  <span class="c-taxInclusive">
                    {this.props.currentPrice}
                  </span>
                  <span class="c-taxExclusive">
                    {this.props.currentPriceWithoutTax}
                  </span>
                </a>
              </div>
              <p className={this.state.errorMsg != null ? 'error-message' : 'hidden'}>
                {" "}
                {this.state.errorMsg != null ? this.state.errorMsg : ""}
              </p>
            </div>
          </div>
        ) : null}
        <p className={this.state.errorMsg != null ? 'error-message' : 'hidden'}>
          {" "}
          {this.state.errorMsg != null ? this.state.errorMsg : ""}
        </p>
        <div class="c-upsell__atc">
          <a
            class={`js-addtocartLaces c-btn ${
                this.state.selectedVariant == null ? "btn-disabled" :
                this.state.CartBtnText == "ADD TO CART" ? "" : "btn-disabled"
            }`}
            onClick={this.SocksAddtoCart}
          >
            {this.state.CartBtnText} -
            {' '}
            <span class="c-taxInclusive">
              {this.props.currentPrice}
            </span>
            <span class="c-taxExclusive">
              {this.props.currentPriceWithoutTax}
            </span>
          </a>
        </div>
      </Fragment>
    );
  }
}

export default SocksSizeSelect;
