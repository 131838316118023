import { debounce } from 'lodash-es'

const init = wrapper => {
  const videoEl = wrapper.querySelector('.js-video')
  const triggerEl = wrapper.querySelector('.js-trigger')
  const pauseEl = wrapper.querySelector('.js-pause')
  const playEl = wrapper.querySelector('.js-play')
  const videoSrcMobile = wrapper.getAttribute('data-src-mobile')
  const videoSrcDesktop = wrapper.getAttribute('data-src-desktop')
  const videoSource = wrapper.querySelector('#videoSource')

  if (videoEl && triggerEl && pauseEl && playEl) {
    triggerEl.addEventListener('click', (e) => {
      e.preventDefault()
      const status = triggerEl.getAttribute('data-status')
      if (status === 'pause') {
        videoEl.pause()
        pauseEl.classList.remove('active')
        playEl.classList.add('active')
        triggerEl.setAttribute('data-status','resume')
      } else {
        videoEl.play()
        playEl.classList.remove('active')
        pauseEl.classList.add('active')
        triggerEl.setAttribute('data-status', 'pause')
      }
    })

    if (window.innerWidth <= 768) {
      videoSource.src = videoSrcMobile
    } else {
      videoSource.src = videoSrcDesktop
    }

    videoEl.load()

    window.addEventListener('resize', debounce(() => {
      if (window.innerWidth <= 768) {
        videoSource.src = videoSrcMobile
      } else {
        videoSource.src = videoSrcDesktop
      }

      videoEl.load()
    }, 10))
  }
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-video-module').forEach(el => {
      init(el)
    })
  })
}