/** @jsx h */

import { h, Component, Fragment } from 'preact'
import { graphQLRequest } from '../../collection/request'
import OptionsContainer from './OptionsContainer'
import Swatch from '../AB/swatch'
import { formatMoney, attempDecode } from './utils'

class Laces extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: null,
      selectedProduct: null,
      isAddingToCart: false,
      isAddedToCart: false
    }

    this.getData()
  }

  getData = () => {
    if (this.props.handle) {
      this.useDataBasic(this.props.handle)
    }
  }

  useDataBasic = async (handle) => {
    const collectionDataQuery = /* GraphQL */ `
      query collectionByHandle($handle: String!) {
        collectionByHandle(handle: $handle) {
          title
          products(first: 30) {
            edges {
              node {
                id
                title
                handle
                alternativeTitle: metafield(namespace: "c_f", key: "name") {
                  value
                }
                images(first: 20) {
                  edges {
                    node {
                      originalSrc
                      altText
                    }
                  }
                }
                variants(first: 30) {
                  edges {
                    node {
                      id
                      sku
                      title
                      price
                      availableForSale
                      quantityAvailable
                      currentlyNotInStock
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                metafields(first: 50) {
                  edges {
                    node {
                      namespace
                      key
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    `

    this.setState({ loading: true })
    const data = await graphQLRequest(collectionDataQuery, { handle })

    if (data.errors?.length > 0) {
      console.log('Shopify API Error')
    } else {
      try {
        const products = data.data.collectionByHandle.products.edges.map(({ node: product }) => {
          const clonedProduct = {
            ...product,
            id: attempDecode(product.id),
            variants: {}
          }

          clonedProduct.variants.edges = product.variants.edges.map(({ node: variant }) => {
            return {
              node: {
                ...variant,
                id: attempDecode(variant.id)
              }
            }
          })

          return {
            node: clonedProduct
          }
        })

        const selectedProduct = products[0].node

        this.setState({
          loading: false,
          products,
          selectedProduct
        })
      } catch (err) {
        console.log('Data Error ', err)
      }
    }
  }

  getSwatchVariant(product) {
    if (!product) {
      return null
    }

    const shoeSize = this.getLaceSize(this.props.mainProduct)
    const variant = product.variants.edges.find(variant => (
      parseInt(variant.node.selectedOptions[2].value) === shoeSize
    )) || this.state.selectedProduct.variants.edges[0]

    return variant?.node
  }

  getActiveVariant() {
    return this.getSwatchVariant(this.state.selectedProduct)
  }

  getLaceSize() {
    const size = this.props.mainProduct.variant_options[2]
    const gender = this.props.mainProduct.variant_options[0]
    let shoelaceSize

    if (gender == "Women") {
      if (size >= 5 && size <= 6.5) {
        shoelaceSize = 1
      } else if (size >= 7 && size <= 9) {
        shoelaceSize = 2
      } else if (size >= 9.5 && size <= 11.5) {
        shoelaceSize = 3
      } else if (size >= 12 && size <= 14.5) {
        shoelaceSize = 4
      }
    } else {
      if (size >= 3.5 && size <= 5) {
        shoelaceSize = 1
      } else if (size >= 5.5 && size <= 7.5) {
        shoelaceSize = 2
      } else if (size >= 8 && size <= 10) {
        shoelaceSize = 3
      } else if (size >= 10.5 && size <= 13) {
        shoelaceSize = 4
      }
    }

    return shoelaceSize
  }

  changeColor = (_index, data) => {
    this.setState({
      selectedProduct: data?.node,
      isAddedToCart: false
    })
  }

  addToCart = () => {
    const activeVariant = this.getActiveVariant()

    if (!activeVariant || !activeVariant.availableForSale) {
      return
    }

    this.setState({
      isAddingToCart: true
    })

    const variantId = activeVariant.id
    const size = this.getLaceSize()

    CartJS.addItem(variantId, 1, { size }, {
      error: () => {
        this.setState({
          isAddingToCart: false
        })
      },
      success: () => {
        const variantWithLace = JSON.parse(localStorage.getItem('variantWithLace')) || []
        const mainProductId = this.props.mainProduct.id + ''
        variantWithLace.push(mainProductId)
        localStorage.setItem('variantWithLace', JSON.stringify(variantWithLace))
        localStorage.setItem('variantAccessoriesID', JSON.stringify(variantId))
        window.setCurrentOpen = 'laces'
        window.currentProductId = mainProductId

        this.setState({
          isAddingToCart: false,
          isAddedToCart: true
        })
      }
    })
  }

  render() {
    const activeVariant = this.getActiveVariant()
    let buttonText
    let isButtonDisabled

    if (activeVariant && !activeVariant.availableForSale) {
      buttonText = 'Add to Order'
      isButtonDisabled = true
    } else {
      if (this.state.isAddingToCart) {
        buttonText = 'Adding to Order'
      } else {
        buttonText = this.state.isAddedToCart ? 'Added' : 'Add to Order'
      }

      isButtonDisabled = false
    }

    return (
      <div className="cart-upsell-popup__addon-product">
        {!this.state.loading && this.state.products && (
          <Fragment>
            <div className="cart-upsell-popup__addon-product__image">
              {this.state.selectedProduct.images.edges.length >= 1 && (
                <picture>
                  <img
                    className="cart-upsell-popup__addon-product__img"
                    src={this.state.selectedProduct.images.edges[0].node.originalSrc}
                    data-srcset=""
                    alt={this.state.selectedProduct.images.edges[0].node.altText}
                  />
                </picture>
              )}
            </div>

            <div className="cart-upsell-popup__addon-product__content">
              <div className="cart-upsell-popup__addon-product__title-price">
                <div className="cart-upsell-popup__addon-product__title">
                  {this.props.title}
                </div>
                <div className="cart-upsell-popup__addon-product__price">
                  {formatMoney(activeVariant.price)}
                </div>
              </div>

              <div className="cart-upsell-popup__addon-product__options cart-upsell-popup__addon-product__options--color">
                <OptionsContainer numItemsToMove={4}>
                  {this.state.products.map((productData, index) => {
                    const variant = this.getSwatchVariant(productData.node)

                    return (
                      <button
                        key={productData.node.id}
                        class={`cart-upsell-popup__addon-product__option
                          ${this.state.selectedProduct == productData.node ? 'is-active' : ''}
                          ${!variant.availableForSale ? 'is-disabled': ''}`}
                        data-key={productData.node.id}
                      >
                        <Swatch
                          productData={productData}
                          index={index}
                          changeColor={this.changeColor}
                        />
                      </button>
                    )
                  })}
                </OptionsContainer>
              </div>
            </div>

            <button
              className="cart-upsell-popup__addon-product__button"
              onClick={this.addToCart}
              disabled={isButtonDisabled}
            >
              {buttonText}
            </button>
          </Fragment>
        )}
      </div>
    )
  }
}

export default Laces
