export default function languageForcer() {
  const languageForm = document.getElementById('footer-language-form');
  const languageSelected = localStorage.getItem('languageSelected');
  const forceLanguage = window.forceLanguage

  if (!languageSelected && languageForm && forceLanguage && window.Shopify.locale !== forceLanguage) {
    languageForm.return_to.value = window.location
    languageForm.language_code.value = forceLanguage
    languageForm.submit()
  }
}