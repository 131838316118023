const init = (el) => {
  const carousel = el.querySelector('.swiper')
  const slides = el.querySelectorAll('.swiper-slide')

  const updateSlides = (activeHandle) => {
    let count = 0;
    let matched = false

    slides.forEach(slide => {
      count++

      if (slide.getAttribute('data-handle') === activeHandle) {
        matched = true
        slide.classList.add('hidden')
      } else if (!matched && count > 4) {
        slide.classList.add('hidden')
      } else {
        slide.classList.remove('hidden')
      }
    })
    const slidesShown = el.querySelectorAll('.swiper-slide:not(.hidden)')
    if (slidesShown && slidesShown.length > 3) {
      slidesShown[slidesShown.length - 1].style.paddingRight = 0
    }
  }

  document.addEventListener('productColorChange', (event) => {
    const product = event.detail.product

    updateSlides(product?.node?.handle)

    if (carousel?.swiper) {
      carousel.swiper.update()
    }
  })

  updateSlides(window.location.href.replace(/^.*\/products\/([^\/#]+).*$/, '$1'))
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-best-sellers:not(.is-initialized)').forEach(el => {
      el.classList.add('is-initialized')
      init(el)
    })
  })
}
