import Swiper, { Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

const init = el => {


  const swiper = new Swiper(el, {
    modules: [
      Pagination,
      Autoplay,
    ],
    cssMode: false,
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 7000
    },
    speed: 600,
    pagination: {
      el: el.querySelector('.swiper-pagination'),
      clickable: true
    },
    on: {
      init: function () {
        
        let activeVid = document.querySelector(".swiper-slide-active .c-heroVideo");
        if (activeVid) {
          activeVid.play();
        }
    
      },
      slideChange: function () {

        let allVideos = document.querySelectorAll(".c-heroVideo");
        if (allVideos) {
          allVideos.forEach((video) => {
            video.pause();
          });
        }

        setTimeout(() => {
          let activeVid = document.querySelector(".swiper-slide-active .c-heroVideo");
          if (activeVid) {
            activeVid.play();
          }
        }, 250);

      },
    },
  })
}

export default () => {
  document.querySelectorAll('.js-homeSlider').forEach(el => init(el))
}
