/** @jsx h */

import { h, Component, Fragment, createRef } from "preact";
import { graphQLRequest } from "../../collection/request";
import IBIAddToCart from "./ibiAddToCart";
import Swatch from "./swatch";
import { formatId, getCountryByCurrency } from "../../collection/utils";
import Cookies from "js-cookie";
import { getPriceWithoutCurrency } from "./utils";
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import getUrl from '@/scripts/utils/get-url'
import getLanguageCode from '@/scripts/utils/get-language-code'

class IBILaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lacesData: null,
      shoelaceSize : null,
      currentVariant : null,
      CurrentSelectedLace : null,
      errorMsg : null,
      CartBtnText : window.pdpLanguages?.add,
      Countryprice : null,
      CountrypriceWithoutTax : null
    };

    this.elRef = createRef()

    this.swiperIns = null
    this.swiperNavIns = null

    this.useDataBasic("ibi-laces");
  }

  getData = () => {
  };

  useDataBasic = (handle) => {
    const collectionDataQuery = /* GraphQL */ `
      query collectionBasicData($handle: String!) @inContext(country: ${getCountryByCurrency(Cookies.get("cart_currency"))}, language: ${getLanguageCode()}) {
        collectionByHandle(handle: $handle) {
          title
          products(first: 20) {
            edges {
              node {
                id
                title
                handle
                alternativeTitle: metafield(namespace: "c_f", key: "name") {
                  value
                }
                priceRange {
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                }
                images(first: 10) {
                  edges {
                    node {
                      originalSrc
                      altText
                    }
                  }
                }
                variants(first: 10) {
                  edges {
                    node {
                      id
                      sku
                      title
                      price {
                        amount
                        currencyCode
                      }
                      availableForSale
                      quantityAvailable
                      currentlyNotInStock
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                metafields(identifiers: [
                  { namespace: "swatch", key: "image" },
                  { namespace: "swatch", key: "color" }
                ]) {
                  namespace
                  value
                  key
                }
              }
            }
          }
        }
      }
    `;

    graphQLRequest(collectionDataQuery, { handle }, `${getUrl('/collections/')}${handle}?view=socks-data`)
      .then((data) => {
        if (data.errors && data.errors.length > 0) {
          console.log(
            "SHOPIFY API ERROR , CONTACT SHOPIFY SUPPORT OR ANATTA DEV TEAM"
          );
        } else {
          try{
            data.data.collectionByHandle.products.edges.forEach((each) => {
              each.node.variants.edges.forEach((variant) => {
                variant.node.id = formatId(variant.node.id);
              });
              each.node.id = formatId(each.node.id);
            });

            const countryPrice = getPriceWithoutCurrency(data.data.collectionByHandle.products.edges[0].node.priceRange.maxVariantPrice.amount, getCookie("cart_currency"))

            this.setState({
              lacesData: data.data.collectionByHandle,
              CurrentSelectedLace : data.data.collectionByHandle.products.edges[0],
              currentVariant: this.getCurrentVariantData(data.data.collectionByHandle.products.edges[0]),
              shoelaceSize: this.getShoeSize(),
              Countryprice: countryPrice.tax,
              CountrypriceWithoutTax: countryPrice.noneTax
            }, () => {
              this.resetSlider()
            });
          }catch(err){
              console.log('Laces Error ', err)
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  componentDidMount() {
    const _this = this;

    $(document).on('click', '.js-upsellOpener.js-upsellOpener--ibilaces', function() {
      _this.removeSwiper();
      _this.resetSlider();

      const firstLaceData = _this.state.lacesData.products.edges[0]

      setTimeout(function() {
        if (firstLaceData) {
          _this.setState({
            currentVariant: _this.getCurrentVariantData(firstLaceData)
          })
        }
      }, 500)

    })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState && this.state.CurrentSelectedLace !== nextState.CurrentSelectedLace) {
      this.setState({
        CurrentSelectedLace: nextState.CurrentSelectedLace
      });
      this.removeSwiper();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.CurrentSelectedLace !== prevState.CurrentSelectedLace) {
      this.resetSlider();
    }
  }

  resetSlider = () => {
    const _this = this
    setTimeout(function() {
      setTimeout(function() {
        _this.addNewSwiper();
      }, 500)
    }, 300)
  };

  removeSwiper = () => {
    this.swiperIns && this.swiperIns.destroy()
    this.swiperNavIns && this.swiperNavIns.destroy()
  };

  addNewSwiper = () => {
    if (!this.elRef.current) return

    const _this = this
    const slider = this.elRef.current.querySelector('.c-upsell__productImageIBI')
    const sliderNav = this.elRef.current.querySelector('.slider-nav-ibi-laces')

    if (!slider || slider.querySelectorAll('picture').length < 2 || (this.swiperIns && !this.swiperIns.hasOwnProperty('destroyed'))) return

    _this.swiperNavIns = new Swiper(sliderNav, {
      loop: sliderNav.querySelectorAll('picture').length > 3,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true
    })

    _this.swiperIns = new Swiper(slider.querySelector('.swiper'), {
      modules: [
        Navigation,
        Thumbs
      ],
      loop: true,
      slidesPerView: 1,
      speed: 600,
      navigation: {
        nextEl: _this.elRef.current.querySelector('.swiper-button-next'),
        prevEl: _this.elRef.current.querySelector('.swiper-button-prev')
      },
      thumbs: {
        swiper: _this.swiperNavIns
      }
    })
  };

  getShoeSize = (data) => {
    if (window.clickedUpsellProduct) {
      const { size, gender } = window.clickedUpsellProduct;
      let shoelaceSize ;
      if (gender == "Women") {
        if (size >= 5 && size <= 6.5) {
          shoelaceSize = 1;
        } else if (size >= 7 && size <= 9) {
          shoelaceSize = 2;
        } else if (size >= 9.5 && size <= 11.5) {
          shoelaceSize = 3;
        } else if (size >= 12 && size <= 14.5) {
          shoelaceSize = 4;
        }
      } else {
        //men
        if (size >= 3.5 && size <= 5) {
          shoelaceSize = 1;
        } else if (size >= 5.5 && size <= 7.5) {
          shoelaceSize = 2;
        } else if (size >= 8 && size <= 10) {
          shoelaceSize = 3;
        } else if (size >= 10.5 && size <= 13) {
          shoelaceSize = 4;
        }
      }
      return shoelaceSize
    } else {
      return ''
    }
  }

  getCurrentVariantData = (data) => {
    if (window.clickedUpsellProduct) {
      const shoelaceSize = this.getShoeSize()
      return data.node.variants.edges.filter((variant)=>  parseInt(variant.node.selectedOptions[2].value) == shoelaceSize);
    } else {
      return data.node.variants.edges
    }
  }

  checkAvailable = (data) => {
    let CorrectVariant = this.getCurrentVariantData(data)
    return ((CorrectVariant[0]) && (CorrectVariant[0].node.quantityAvailable > 0))
  }

  ChangeLaceColor = (index , laceData) => {
    if ((this.state.CurrentSelectedLace) && (laceData) && (this.state.CurrentSelectedLace.node.id !== laceData.node.id)) {
      $(".js-upsell--ibi-lace .c-productVariant__item__socks").removeClass("is-active");
      $(".js-upsell--ibi-lace .c-upsell__productBlock").removeClass("shown");
      $(`.js-upsell--ibi-lace .c-upsell__productBlock__${index}`).addClass("shown");
      $(`.js-upsell--ibi-lace .c-productVariant__item__socks__${index}`).addClass("is-active");

      this.setState({
        CurrentSelectedLace: laceData,
        currentVariant: this.getCurrentVariantData(laceData),
        shoelaceSize: this.getShoeSize()
      })
    }
  };

  render() {
    return (
      <Fragment>
        <div
          class="c-upsell js-upsell js-upsell--ibi-lace c-upsell__lace"
          data-cart-view="data-cart-view"
          rules="all"
        >
          <div class="c-upsell__header js-upsellClose">
            <span class="c-upsell__headerIcon">
              <img src={typeof SHOPIFY_CDN != 'undefined' && SHOPIFY_CDN + 'arrow-left_30x.png'} width="15" height="15" class="c-upsell__headerImg" />
            </span>
            BACK
          </div>
          <div class="c-upsell__productPrice">
            <p class="c-upsell__productPriceName">Extra Laces</p>
            <p class="c-upsell__productPriceName c-taxInclusive">{this.state.Countryprice!=null ? this.state.Countryprice : '' }</p>
            <p class="c-upsell__productPriceName c-taxExclusive">{this.state.CountrypriceWithoutTax!=null ? this.state.CountrypriceWithoutTax : '' }</p>
          </div>
          <div class="c-upsell__block">
            <div class="c-upsell__blockBorder">
              <div class="c-upsell__blockItem">
                <div class="c-upsell__product">
                  {this.state.CurrentSelectedLace != null ? (
                    <Fragment>
                      <div
                        key={this.state.CurrentSelectedLace.node.id}
                        class={`c-upsell__productBlock c-upsell__productBlock__${this.state.CurrentSelectedLace.node.id} shown`}
                        data-key={this.state.CurrentSelectedLace.node.id}
                        data-lace-id={this.state.CurrentSelectedLace.node.id}
                        ref={this.elRef}
                      >
                        <div class="c-upsell__productImageIBI">
                          <div class="swiper">
                            <div class="swiper-wrapper">
                              {this.state.CurrentSelectedLace.node.images.edges.map((image) => {
                                if (image.node.altText == null) {
                                  return (
                                    <div class="swiper-slide">
                                      <div class="image-el">
                                        <picture>
                                          <source
                                            srcset={image.node.originalSrc}
                                            media="(min-width: 900px)"
                                          />
                                          <source
                                            srcset={image.node.originalSrc}
                                            media="(min-width: 320px)"
                                          />
                                          <img
                                            class="c-upsell__innerImage"
                                            loading="lazy"
                                            src={image.node.originalSrc}
                                            alt="Cariuma "
                                          />
                                        </picture>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                          <div class="swiper-button swiper-button-prev"></div>
                          <div class="swiper-button swiper-button-next"></div>
                        </div>

                        <div
                          className={(this.state.CurrentSelectedLace.node.images.edges.length > 1) ? 'slider-nav-ibi-laces swiper' : 'hidden' }
                        >
                          <div class="swiper-wrapper">
                            {this.state.CurrentSelectedLace.node.images.edges.map((image) => {
                              if (image.node.altText == null) {
                                return (
                                  <div class="swiper-slide">
                                    <div class="image-el">
                                      <picture>
                                        <source srcset={image.node.originalSrc} media="(min-width: 900px)" />
                                        <source srcset={image.node.originalSrc} media="(min-width: 320px)" />
                                        <img class="c-upsell__innerImage"
                                          loading="lazy"
                                          src={image.node.originalSrc}
                                          srcset={image.node.originalSrc}
                                          alt="Laces Img" />
                                      </picture>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div class="c-upsell__productTitle">
                          <p class="c-cart__itemUpsell">
                            {this.state.CurrentSelectedLace.node.alternativeTitle ? this.state.CurrentSelectedLace.node.alternativeTitle.value : ''}
                          </p>
                        </div>
                      </div>
                    </Fragment>
                  ) : ''}
                  <span class="c-product__rowSelect">SELECT COLOR</span>
                  <div class="c-upsell__variantBlock">
                    {this.state.lacesData &&
                      this.state.lacesData.products.edges.map(
                        (laceData, index) => {
                          return (
                            <div
                              key={laceData.node.id}
                              class={`c-productVariant__item__socks c-productVariant__item__socks__${laceData.node.id} show ${
                                index == 0 ? "is-select is-active " : ""
                              } ${this.checkAvailable(laceData) ? '' : 'disabled' }`}
                              data-key={laceData.node.id}
                            >
                              <Swatch
                                productData={laceData}
                                index={index}
                                changeColor={this.ChangeLaceColor}
                              />
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
            <IBIAddToCart
              currentVariant={this.state.currentVariant}
              shoelaceSize={this.state.shoelaceSize}
              errorMsg={this.state.errorMsg}
              CartBtnText={this.state.CartBtnText}
              Countryprice={this.state.Countryprice}
              CountrypriceWithoutTax={this.state.CountrypriceWithoutTax}
            />
            <div class="c-upsell__description">
              <ul>
                <li>Made from recycled plastic</li>
                <li>Available in a variety of colors</li>
                <li>An easy way to update your shoes </li>
              </ul>
              <p>All Shoelace Purchases are Final Sale. <br/> No Returns.</p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default IBILaces;
