/**
 *
 * @prettier
 */

import { h, Fragment } from 'preact';
/** @jsx h */
import { useState } from 'preact/hooks';
import { memo } from 'preact/compat';
import Product from './Product';
import { curry } from 'lodash-es';
import classNames from "classnames";
import { formatMoney } from '../../../utils/money'

const skeleton = (
	<div class="c-collectionProductsGrid__item">
		<Product skeleton />
	</div>
);

const ProductsGrid = ({
	collection: { size, status, products },
	collectionHandle,
	hasBanner,
	bannerItems,
	productsVisibility,
	productNameHeadingLevel,
	superPlp,
	hasEcoBanner,
	plpEcoBanner,
	filterApplied,
  isMobile
}) => {
	if (status === 'ERROR') {
		return 'Unexpected error occurred. Please try again in a few minutes.';
	}

	const filterIsActive = document.getElementsByClassName('c-collectionFilters__active');
	const [isBannerDisabled, setIsBannerDisabled] = useState(false);

	if(filterIsActive.length) {
		setIsBannerDisabled(true);
	}

	const hasBannerNormalized = hasBanner && !isBannerDisabled;
	const items = hasBannerNormalized ? bannerItems : size;
	const hasProductsVisibility = Array.isArray(productsVisibility);
	const Heading = `h${productNameHeadingLevel || 3}`;
  let showBanner = true;
  if (window.__GLOBAL__ && window.__GLOBAL__.showBanner !== undefined) {
    showBanner = window.__GLOBAL__.showBanner;
  }

	let productsElements ;
  let findIndexProductHasBanner = -1
	if (status === 'LOADING') {
		productsElements = [...Array(hasEcoBanner ? (items + 1) : items)].map(() => skeleton);
	} else {
		const productsNormalized = hasBannerNormalized && showBanner ? products.slice(0, bannerItems) : products;
    findIndexProductHasBanner = productsNormalized.findIndex(product => product.tags.includes("has-banner-product-img"))
		if (hasEcoBanner) {
			productsNormalized.length > 5 ? plpEcoBanner.imageMobilePosition = 5 : plpEcoBanner.imageMobilePosition = (productsNormalized.length - 1)
			productsNormalized.length > 2 ? plpEcoBanner.imageDesktopPosition = 2 : plpEcoBanner.imageDesktopPosition = (productsNormalized.length - 1)
		}
		productsElements = productsNormalized.map((product, index) => (
			superPlp=="true" ?
				product.tags.includes("has-banner-img") ?
				<Fragment>
					<div class="c-collectionProductsGrid__item bannerImage" style={{ display: filterApplied ? 'none' : '' }}>
						<article class="c-collectionProductV2">
							<a
								href={product.targetPageUrl ? product.targetPageUrl.value : ''}
								class="c-collectionProductV2__link"
							>
								<div class="c-collectionProductV2__imageWrapper">
									<div class="c-collectionProductV2__imageSpacer">
										<div class="c-collectionProductV2__imageLayer">
											{product.imageBanner ?
											<img
												src={product.imageBanner.url}
												sizes="auto"
												data-skip-onload-load="true"
												alt={"product.imageHover.altText"}
												loading="lazy"
												class="c-collectionProductV2__image"
											/>
											: null}
										</div>
									</div>
								</div>
							</a>
						</article>
					</div>
				</Fragment>
				:
				product.tags.includes("has-banner-product-img") ?
					<Fragment>
						<div class="c-collectionProductsGrid__item bannerImageProduct" style={{ display: filterApplied ? 'none' : '' }}>
							<article class="c-collectionProductV2">
								<a
									href={product.targetPageUrl ? product.targetPageUrl.value : ''}
									class="c-collectionProductV2__link"
								>
									<div class="c-collectionProductV2__imageWrapper">
										<div class="c-collectionProductV2__imageSpacer">
											<div class="c-collectionProductV2__imageLayer">
												{product.imageBanner ?
												<img
													src={product.imageBanner.url}
													sizes="auto"
													data-skip-onload-load="true"
													alt={"product.imageHover.altText"}
													loading="lazy"
													class="c-collectionProductV2__image"
												/>
												: null}
											</div>
										</div>
									</div>
									<div class="c-collectionProductV2__bottom">
										<div class="c-collectionProductV2__titleWrapper">
											<Heading class="c-collectionProductV2__title">{product.targetProductTitle ? product.targetProductTitle.value : ''}</Heading>
										</div>

										<div class="c-collectionProductV2__priceWrapper">
											{formatMoney(product.price.amount, product.price.currencyCode, true)}
										</div>
									</div>
								</a>
							</article>
						</div>
					</Fragment>
					:
					<div
						class="c-collectionProductsGrid__item"
						style={{
							display: hasProductsVisibility && !productsVisibility[index] ? 'none' : '',
						}}
					>
						<Product
							product={product}
							collectionHandle={collectionHandle}
							key={product.handle}
							headingLevel={productNameHeadingLevel}
						/>
					</div>
				: (hasEcoBanner && plpEcoBanner.imageMobilePosition === index && plpEcoBanner.imageDesktopPosition === index) ?
					<Fragment>
						<div class="c-collectionProductsGrid__item" style={{ display: hasProductsVisibility && !productsVisibility[index] ? 'none' : '' }}>
							<Product
								product={product}
								collectionHandle={collectionHandle}
								key={product.handle}
								headingLevel={productNameHeadingLevel}
							/>
						</div>
						<div class={classNames('c-collectionProductsGrid__item c-collectionProductsGrid__itemEco', {'hide-desktop': !filterApplied})} style={{ display: filterApplied ? 'none' : '' }}>
							<div class="c-collectionProductsGrid__item-wrapper">
                <LinkWrapper linkUrl={plpEcoBanner.linkUrl}>
                  { plpEcoBanner.imageMobile.includes('.lottie')
                    ? <dotlottie-player	src={plpEcoBanner.imageMobile} background="transparent" autoplay loop></dotlottie-player>
                    : <img src={plpEcoBanner.imageMobile} alt="" /> }
                </LinkWrapper>
							</div>
						</div>
						<div class={classNames('c-collectionProductsGrid__item c-collectionProductsGrid__itemEco', {'hide-mobile': !filterApplied})} style={{ display: filterApplied ? 'none' : '' }}>
							<div class="c-collectionProductsGrid__item-wrapper">
                <LinkWrapper linkUrl={plpEcoBanner.linkUrl}>
                  { plpEcoBanner.imageDesktop.includes('.lottie')
                    ? <dotlottie-player	src={plpEcoBanner.imageDesktop} background="transparent" autoplay loop></dotlottie-player>
                    : <img src={plpEcoBanner.imageDesktop} alt="" /> }
                </LinkWrapper>
							</div>
						</div>
					</Fragment>
				: (hasEcoBanner && plpEcoBanner.imageMobilePosition === index) ?
					<Fragment>
						<div class="c-collectionProductsGrid__item" style={{ display: hasProductsVisibility && !productsVisibility[index] ? 'none' : '' }}>
							<Product
								product={product}
								collectionHandle={collectionHandle}
								key={product.handle}
								headingLevel={productNameHeadingLevel}
							/>
						</div>
						<div class={classNames('c-collectionProductsGrid__item c-collectionProductsGrid__itemEco', {'hide-desktop': !filterApplied})} style={{ display: filterApplied ? 'none' : '' }}>
							<div class="c-collectionProductsGrid__item-wrapper">
                <LinkWrapper linkUrl={plpEcoBanner.linkUrl}>
                  { plpEcoBanner.imageMobile.includes('.lottie')
                    ? <dotlottie-player	src={plpEcoBanner.imageMobile} background="transparent" autoplay loop></dotlottie-player>
                    : <img src={plpEcoBanner.imageMobile} alt="" /> }
                </LinkWrapper>
							</div>
						</div>
					</Fragment>
				: (hasEcoBanner && plpEcoBanner.imageDesktopPosition === index) ?
					<Fragment>
						<div class="c-collectionProductsGrid__item" style={{ display: hasProductsVisibility && !productsVisibility[index] ? 'none' : '' }}>
							<Product
								product={product}
								collectionHandle={collectionHandle}
								key={product.handle}
								headingLevel={productNameHeadingLevel}
							/>
						</div>
						<div class={classNames('c-collectionProductsGrid__item c-collectionProductsGrid__itemEco', {'hide-mobile': !filterApplied})} style={{ display: filterApplied ? 'none' : '' }}>
							<div class="c-collectionProductsGrid__item-wrapper">
                <LinkWrapper linkUrl={plpEcoBanner.linkUrl}>
                  { plpEcoBanner.imageDesktop.includes('.lottie')
                    ? <dotlottie-player	src={plpEcoBanner.imageDesktop} background="transparent" autoplay loop></dotlottie-player>
                    : <img src={plpEcoBanner.imageDesktop} alt="" /> }
                </LinkWrapper>
							</div>
						</div>
					</Fragment>
			:
				<div
					class="c-collectionProductsGrid__item"
					style={{
						display: hasProductsVisibility && !productsVisibility[index] ? 'none' : '',
					}}
				>
					<Product
						product={product}
						collectionHandle={collectionHandle}
						key={product.handle}
						headingLevel={productNameHeadingLevel}
					/>
				</div>
		));
	}

	return (
		<div class={classNames('c-collectionProductsGrid',`c-collectionProductsGrid--number-${productsElements.length}`,{'c-collectionProductsGrid--show-all' : productsElements.length === size}, {'c-collectionProductsGrid--has-banner-image': (findIndexProductHasBanner >= 0 && findIndexProductHasBanner <=7) } )}>
			<div class="c-collectionProductsGrid__grid o-flex">{productsElements}</div>

			{hasBannerNormalized && (
				<div class="c-collectionProductsGrid__banner">
					<div class="c-collectionProductsGrid__bannerGrid o-flex">
						{[...Array(isMobile ? 2 : 4)].map(() => (
							<div class="c-collectionProductsGrid__item">
								<div class="c-collectionProductsGrid__bannerItemBox" />
							</div>
						))}
					</div>
					<div class="c-collectionProductsGrid__bannerBottom">
						<button
							class="btn c-collectionProductsGrid__bannerBottomButton"
							onClick={() => setIsBannerDisabled(true)}
						>
							{ window.plpLanguages?.see_more_colors }
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

const LinkWrapper = ({ linkUrl, children }) => {
  if (!linkUrl) {
    return children;
  }

  return (
    <a href={linkUrl}>{children}</a>
  );
}

export default memo(ProductsGrid);
