/** @jsx h */

import { h } from 'preact';

const SizePickerModal = (props) => {
  const { currentCountrySizeData, currentCountrySizeActive, changeCountrySize } = props
  return (
    <div  
      role="dialog" 
      id="modal-size-dialog"
      aria-modal="true"
      class="c-sizePickerModal__overlay hidden"
    >
      <div class="c-sizePickerModal">
        {currentCountrySizeData.map((each) => (
          <div 
            class={`c-sizePickerModal__countryBox js-countryBox c-sizePickerModal__countryBox--${each.title} ${currentCountrySizeActive === each.title ? 'c-sizePickerModal__countryBox--active' : ''}`} 
            data-country={each.title}
            onClick={() => changeCountrySize(each.title)}
          >
            <span class="c-sizePickerModal__countrySizeTitle">{ each.text }</span>
            <span class={`c-sizePickerModal__tick ${currentCountrySizeActive === each.title ? 'ticked' : 'unticked'}`}>
              <svg class="c-sizePickerModal__svgTick"width="12px" height="9px" viewBox="0 0 12 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="UK-US-Toggle---Dropdown---US-Active" transform="translate(-282.000000, -531.000000)" fill="#66A437" stroke="#66A437" stroke-width="0.5">
                    <g id="sizing-dropdown" transform="translate(84.000000, 515.000000)">
                      <g id="check-mark-icon" transform="translate(199.000000, 17.000000)">
                        <path d="M9.83112742,0.496846392 L9.41813388,0.0853111651 C9.304094,-0.028437055 9.11918022,-0.028437055 9.00514034,0.0853111651 L3.67734871,5.39589473 C3.56330883,5.50964295 3.37839505,5.50964295 3.26435517,5.39589473 L0.911808649,3.05093143 C0.797768767,2.93718321 0.612563332,2.93718321 0.498815111,3.05093143 L0.0855299117,3.46246665 C-0.0285099706,3.57621487 -0.0285099706,3.76054532 0.0855299117,3.87429354 L3.05056685,6.82941397 C3.27835495,7.05661875 3.64789084,7.05691041 3.87626226,6.82999729 L9.83083575,0.908964944 C9.9451673,0.795216723 9.9451673,0.610594612 9.83112742,0.496846392" id="Icon"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SizePickerModal;
