import Swiper, { Pagination, Thumbs, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'

const init = el => {
  let swiper
  let swiper2

  const breakpointChecker = function() {
    if (window.innerWidth < 768) return enableSwiper()
    if (swiper !== undefined) swiper.destroy(true, true)
    if (swiper2 !== undefined) swiper2.destroy(true, true)
  }

  const enableSwiper = () => {
    if (swiper && !swiper.hasOwnProperty('destroyed')) return

    swiper = new Swiper(el.nextElementSibling, {
      modules: [
        EffectFade
      ],
      loop: false,
      slidesPerView: 1,
      freeMode: true,
      watchSlidesProgress: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    })
  
    swiper2 = new Swiper(el, {
      modules: [
        Pagination,
        Thumbs
      ],
      cssMode: false,
      slidesPerView: 1,
      loop: false,
      speed: 600,
      pagination: {
        el: el.parentElement.querySelector('.swiper-pagination'),
        clickable: true
      },
      thumbs: {
        swiper: swiper,
      }
    })
  }

  window.addEventListener('resize', () => breakpointChecker())
  breakpointChecker()
}

export default () => {
  document.querySelectorAll('.js-ibi-features-images').forEach(el => init(el))
}
