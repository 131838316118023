/** @jsx h */

import { h, Component, Fragment, createRef } from "preact";
import { graphQLRequest } from "../../collection/request";

class IBIAddToCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CartBtnText: this.props.CartBtnText ? this.props.CartBtnText : null,
      errorMsg : this.props.errorMsg ? this.props.errorMsg : null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentVariant !== prevProps.currentVariant) {
      this.setStatusToAdd()
    }
  }

  checkAvailable = () => {
    return ((this.props.currentVariant[0]) && (this.props.currentVariant[0].node.quantityAvailable > 0))
  }

  setStatusToAdd = () => {
    if (!this.checkAvailable()) {
      const errorMessage = ((window.OUT_STOCK_MESSAGE) && (window.OUT_STOCK_MESSAGE.laces_ibi)) ?
          window.OUT_STOCK_MESSAGE.laces_ibi : "Try a different color?"
      this.setState({
        errorMsg : errorMessage,
        CartBtnText : "Out of Stock"
      });
    } else {
      this.setState({
        errorMsg : "",
        CartBtnText : "ADD TO CART"
      });
    }
  }

  getShoeSize = (data) => {
    if (window.clickedUpsellProduct) {
      const { size, gender } = window.clickedUpsellProduct;
      let shoelaceSize ;
      if (gender == "Women") {
        if (size >= 5 && size <= 6.5) {
          shoelaceSize = 1;
        } else if (size >= 7 && size <= 9) {
          shoelaceSize = 2;
        } else if (size >= 9.5 && size <= 11.5) {
          shoelaceSize = 3;
        } else if (size >= 12 && size <= 14.5) {
          shoelaceSize = 4;
        }
      } else {
        //men
        if (size >= 3.5 && size <= 5) {
          shoelaceSize = 1;
        } else if (size >= 5.5 && size <= 7.5) {
          shoelaceSize = 2;
        } else if (size >= 8 && size <= 10) {
          shoelaceSize = 3;
        } else if (size >= 10.5 && size <= 13) {
          shoelaceSize = 4;
        }
      }
      return shoelaceSize
    } else {
      return ''
    }
  }

  LacesAddtoCart = (e) => {
    e.preventDefault();
    if (!this.checkAvailable()) return false;

    this.setState({
      CartBtnText : "ADDING TO CART",
      errorMsg : null
    })

    let variantID = this.props.currentVariant[0].node.id;
    let quantity=1;
    let ref = this;

    CartJS.addItem(variantID, quantity, {
      "size": this.getShoeSize()
    }, {
        "error": function(jqXHR, textStatus, errorThrown) {
          ref.setState({
            errorMsg : "We’re out of stock. Please select a different color." ,
            CartBtnText : "ADD TO CART"
          });
        },
        "success": function(jqXHR, textStatus, errorThrown) {
            let variantWithLace = JSON.parse(localStorage.getItem('variantWithLace')) || [];
            variantWithLace.push(window.clickedUpsellProduct.productID);
            ref.setState({
              CartBtnText : "ADD TO CART"
            },()=>{
              localStorage.setItem('variantWithLace', JSON.stringify(variantWithLace))
              localStorage.setItem('variantAccessoriesID', JSON.stringify(variantID)
            )})
        },
    })
  };

  render() {
    return (
      <Fragment>
        <div class="c-upsell__atc">
          <a class={`js-addtocartLaces c-btn ${this.state.CartBtnText == "ADD TO CART" ? '' : 'btn-disabled'}`} onClick = {this.LacesAddtoCart}>
            {this.state.CartBtnText}
            {' '}
            {this.state.CartBtnText == "ADD TO CART" ?
              <span>
                -{' '}
                <span class="c-taxInclusive">
                  {this.props.Countryprice}
                </span>
                <span class="c-taxExclusive">
                  {this.props.CountrypriceWithoutTax}
                </span>
              </span>
            : ''}
          </a>
        </div>
        <p className={this.state.errorMsg != null ? 'error-message' : 'hidden'}>{this.state.errorMsg !=null ? this.state.errorMsg : ''}</p>
      </Fragment>
    );
  }
}

export default IBIAddToCart;
