import Swiper, { Navigation, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

export default () => {
  window.initUpsellBestSellingCarousel = el => {
    const slidesCount = el.querySelectorAll('.swiper-slide').length

    return new Swiper(el.querySelector('.swiper'), {
      modules: [
        Navigation
      ],
      cssMode: false,
      slidesPerView: 2,
      slidesPerGroup: 1,
      loop: slidesCount > 3,
      speed: 600,
      spaceBetween: 14,
      navigation: {
        nextEl: el.querySelector('.swiper-button-next'),
        prevEl: el.querySelector('.swiper-button-prev')
      },
      breakpoints: {
        540: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 8
        }
      }
    })
  }

  let socksCarouselIns = null

  window.initSocksCarousel = el => {
    if (!el || (socksCarouselIns && !socksCarouselIns.hasOwnProperty('destroyed'))) return

    socksCarouselIns = new Swiper(el.querySelector(':scope > .swiper'), {
      modules: [
        Navigation,
        Autoplay,
      ],
      cssMode: false,
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 2500
      },
      speed: 600,
      navigation: {
        nextEl: el.querySelector(':scope > .swiper-button-next'),
        prevEl: el.querySelector(':scope > .swiper-button-prev')
      },
      spaceBetween: 10
    })
  }

  window.destroySocksCarousel = () => {
    socksCarouselIns && socksCarouselIns.destroy()
  }

  window.socksColorsIns = {}

  window.initSocksColors = (el) => {
    if (!el) return

    const totalItems = el.querySelectorAll('.swiper-slide').length

    if (totalItems < 4) return

    if (totalItems < 7) el.setAttribute('data-total-slides', totalItems)

    window.socksColorsIns[Date.now()] = new Swiper(el.querySelector('.swiper'), {
      modules: [
        Navigation,
      ],
      cssMode: false,
      slidesPerView: Math.min(3, totalItems),
      slidesPerGroup: Math.min(3, totalItems),
      speed: 600,
      loop: true,
      navigation: {
        nextEl: el.querySelector('.swiper-button-next')
      },
      allowTouchMove: false,
      breakpoints: {
        768: {
          slidesPerView: Math.min(6, totalItems),
          slidesPerGroup: Math.min(6, totalItems)
        }
      }
    })
  }

  window.destroySocksColors = () => {
    if (!window.socksColorsIns || typeof window.socksColorsIns !== 'object') return

    Object.keys(window.socksColorsIns).forEach(key => window.socksColorsIns[key].destroy())
  }

  window.initEmptyCartCarousel = el => {
    if (!el) {
      return
    }

    let options = {
      modules: [
        Autoplay
      ],
      cssMode: false,
      slidesPerView: 2,
      slidesPerGroup: 1,
      loop: true,
      autoplay: {
        delay: 5000
      },
      speed: 600,
      breakpoints: {
        540: {
          slidesPerView: 3
        }
      }
    }
    if ($ && $(el).closest('.c-tabsCon')) {
      options = {
        modules: [
          Navigation
        ],
        cssMode: false,
        slidesPerView: 'auto',
        watchOverflow: true,
        speed: 600,
        navigation: {
          nextEl: el.querySelector(':scope > .swiper-button-next'),
          prevEl: el.querySelector(':scope > .swiper-button-prev')
        }
      }
    }

    const realSlides = el.querySelectorAll('.swiper-slide:not(.is-clone')

    if (realSlides.length === 2) {
      const mql = window.matchMedia('(min-width: 540px)')
      let swiper = null

      const updateSwiper = () => {
        if (swiper) {
          swiper.destroy(true, true)
        }

        if (mql.matches) {
          swiper = new Swiper(el, {...options, loop: false })
        } else {
          swiper = new Swiper(el, { ...options, loop: true })
        }
      }

      updateSwiper()
      mql.addEventListener('change', updateSwiper)
    } else {
      return new Swiper(el, options)
    }
  }
}
