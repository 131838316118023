export const formatMoney = (amount, format = '${{ amount_no_decimals }}') => {
  return window.Shopify.formatMoney(amount, format)
}

export const attempDecode = id => {
  if (!id) {
    return null
  }

  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  let newId = base64regex.test(id) ? window.atob(id) : id
  newId = newId.substring(newId.indexOf('t/') + 2)

  return newId
}
