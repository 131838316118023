export default () => {
  const $ = window.$

  if ($) {
    const baseUrl = `${location.protocol}//${location.host}`

    $(document).on('cart.requestComplete', function(event, cart) {
      if (!window.crdl) {
        return
      }

      const cartData = cart.items.map(item => {
        const attr = item.options_with_values.reduce((obj, option) => {
          obj[option.name] = option.value

          return obj
        }, {})

        return {
          productID: item.product_id,
          sku: item.sku,
          category: item.product_type,
          name: item.product_title,
          images: [item.image],
          description: item.product_description,
          qty: item.quantity,
          itemPrice: item.price / 100,
          url: `${baseUrl}${item.url}`,
          attr,
          properties: { ...item.properties}
        }
      })

      window.crdl([
        ['cart', 'clear'],
        ['cartitem', 'add', cartData],
        ['event', 'cart']
      ])
    })
  }
}