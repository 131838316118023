import Swiper, { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const initLogosSlider = el => {
  let swiper

  const breakpointChecker = function() {
    if (window.innerWidth < 768) return enableSwiper()
    if (swiper !== undefined) swiper.destroy(true, true)
  }

  const enableSwiper = () => {
    if (swiper && !swiper.hasOwnProperty('destroyed')) return

    swiper = new Swiper(el.querySelector('.swiper'), {
      modules: [
        Pagination
      ],
      slidesPerView: 3,
      slidesPerGroup: 1,
      freeMode: true,
      speed: 600,
      spaceBetween: 24,
      pagination: {
        el: el.querySelector('.swiper-pagination'),
        clickable: true
      }
    })
  }

  const handleLogoClick = logo => {
    const key = logo.getAttribute('data-key')
    const partnerEl = logo.closest('.partners').querySelector(`.partner-slider__${key}`)

    el.querySelectorAll('.partner-logos').forEach(item => item.classList.remove('partner-logos__active'))
    logo.classList.add('partner-logos__active')

    if (!partnerEl) return

    logo.closest('.partners').querySelectorAll('.partner-slider').forEach(item => item.classList.add('partner-slider-hidden'))
    partnerEl.classList.remove('partner-slider-hidden')
  }

  el.querySelectorAll('.partner-logos').forEach(logo => logo.addEventListener('click', () => handleLogoClick(logo)))

  window.addEventListener('resize', () => breakpointChecker())
  breakpointChecker()
}

const initImagesSlider = el => {
  const totalSlides = el.querySelectorAll('.swiper-slide').length
  let swiper
  let isMobile = false

  if (totalSlides < 2) return

  const enableSwiper = () => {
    if (swiper && !swiper.hasOwnProperty('destroyed')) swiper.destroy(true, true)

    swiper = new Swiper(el, {
      modules: [
        Pagination,
        Navigation
      ],
      slidesPerView: 1,
      slidesPerGroup: 1,
      freeMode: true,
      loop: false,
      speed: 600,
      pagination: {
        el: el.querySelector('.swiper-pagination'),
        clickable: true
      },
      navigation: {
        nextEl: el.querySelector('.swiper-button-next'),
        prevEl: el.querySelector('.swiper-button-prev')
      },
      breakpoints: {
        768: {
          loop: true
        }
      }
    })
  
    const status = el.parentNode.querySelector('.slide-count-wrap')
  
    if (!status) return
  
    status.innerText = `${swiper.realIndex + 1}/${totalSlides}`
  
    swiper.on('slideChange', () => {
      status.innerText = `${swiper.realIndex + 1}/${totalSlides}`
    })
  }

  window.addEventListener('resize', () => {
    if ((window.innerWidth < 768 && !isMobile) || (window.innerWidth > 767 && isMobile)) {
      isMobile = !isMobile
      enableSwiper()
    }
  })

  enableSwiper()
}

export default () => {
  document.querySelectorAll('.logos-ctr').forEach(el => initLogosSlider(el))
  document.querySelectorAll('.js-image-slider').forEach(el => initImagesSlider(el))
}
