/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact';

class FourOceanMessage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Fragment>
        <div class="c-pdp-4OceanMessage">
          <div class="c-pdp-4OceanMessage__logo">
            <img alt="logo" src="https://cdn.shopify.com/s/files/1/2495/5044/files/4ocean.png?v=1622653690"/>
          </div>
          <div class="c-pdp-4OceanMessage__text">
            Buy 1 pair, remove 2lbs of trash from our oceans.
            <a class="c-pdp-4OceanMessage__learnmore">
              Learn More
              <span class="special-text__tooltip">
                Together with our friends at 4ocean, we’re committed to restoring our blue planet. For every pair sold, 4ocean’s professional crews will remove 2lbs of trash from our oceans.
              </span>
            </a>
          </div> 
        </div> 
			</Fragment>
		);
	}
}
export default FourOceanMessage;