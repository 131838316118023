import axios from 'axios';
import getUrl from '@/scripts/utils/get-url'

let endpoint = getUrl('/api/2024-04/graphql.json');
let nonCachedEndpoint = getUrl('/api/2024-04/graphql.json');
let accessToken = 'fa98051ab0dc8c6fe7c764ce8de9a983';

// Cache API request if Edgemesh instance
// /em-cgi/cache/{TTL}/{pathname}
if (window.edgemesh && window.Shopify?.theme?.role === 'main') {
  endpoint = '/em-cgi/cache/300/api/2022-10/graphql.json';
}

if (window.Shopify?.shop === 'cariuma-testnew.myshopify.com') {
  accessToken = 'a532ca5baee341e516e2b827927c6fef';
}

if (window.Shopify?.shop === 'int-cariuma.myshopify.com') {
  accessToken = '7f04160fc2d4644ec408c4295e0ba329';
}

if (window.Shopify?.shop === 'cn-cariuma.myshopify.com') {
  accessToken = 'ee06a6ef1a001b0d889df07f8af69c73';
}

if (window.Shopify?.shop === 'br-cariuma.myshopify.com') {
  accessToken = '107c91ecf43cc1c2e36420377cfda9dd';
}

if (window.Shopify?.shop === 'cariuma-jp.myshopify.com') {
  accessToken = '4521bf7c79c642a1394409d4508faa9d';
}

if (window.Shopify?.shop === 'cariuma-au.myshopify.com') {
	accessToken = 'ddd4725f2371080ce27f8ba3b8f45d6e';
  }

export const ajaxRequest = query => {
	return axios.get(query)
		.then(response => response)
		.catch(error => new Error(error));
}

export const graphQLRequest = (query, variables, ajaxQuery = null, nonCachedAPI = false) =>
	fetch(nonCachedAPI ? nonCachedEndpoint : endpoint, {
		method: 'POST',
		headers: {
			'x-shopify-storefront-access-token': accessToken,
			'content-type': 'application/json',
		},
		body: JSON.stringify({ query, variables }),
	}).then((res) => {
		if (res.ok) return res.json();
		if (res.status === 400 && ajaxQuery) return ajaxRequest(ajaxQuery)
		else throw new Error(`Response code: ${res.status}`);
	}).catch(error => {
		if (ajaxQuery) return ajaxRequest(ajaxQuery)
		else throw new Error(error)
	});
