/** @jsx h */

import { Fragment } from 'preact'
import { h, Component, createRef } from 'preact';
import Swiper, { Autoplay, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'
import getUrl from '@/scripts/utils/get-url'

export default class NoticeBar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			message: [],
		};

		this.isShow = window.noticeBar.isShow;
    this.autoplayTimeout = window.noticeBar.autoplayTimeout;
    this.twoTreesMessageIcon = window.noticeBar.twoTreesMessageIcon || '';
    this.twoTreesMessageCopy = window.noticeBar.twoTreesMessageCopy || '';
    this.twoTreesMessageUrl = window.noticeBar.twoTreesMessageUrl || '';
    this.carouselRef = createRef();
    this.noticeBarEl = document.querySelector('#notice-bar')
	}

	componentDidMount() {
		if (!this.isShow) return true;
		const messages = window.promoMessages.getDefaultMessage();
		let localMessage = [];

		const buildMessageMarkup = (text, link, greenStyling) => {
      let output = `<p${greenStyling ? ' class="is-green"' : ''}>`
      output += link ? `<a href="${getUrl(link)}">${text}</a>` : text
      output += '</p>'

      return output
    }

		if (messages.message1 !== '') localMessage.push(buildMessageMarkup(messages.message1, messages.message1Link, messages.message1GreenStyling))
		if (messages.message2 !== '') localMessage.push(buildMessageMarkup(messages.message2, messages.message2Link, messages.message2GreenStyling))
		if (messages.message3 !== '') localMessage.push(buildMessageMarkup(messages.message3, messages.message3Link, messages.message3GreenStyling))

		this.setState({
			message: localMessage
		})

		const sessionStorage = window.sessionStorage;
		sessionStorage.setItem('region', window.region);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.message !== this.state.message) {
			const body = document.querySelector('body');
			if (!this.state.message || this.state.message.length < 1) {
				body.classList.remove('has-notice-bar');
			} else {
				body.classList.add('has-notice-bar');
			}
		}

    if (this.state.message && this.state.message.length > 1) {
      const carouselEl = this.carouselRef.current;

      if (carouselEl) {
				window.noticeBarIns = new Swiper(carouselEl, {
					modules: [
						EffectFade,
						Autoplay,
					],
					cssMode: false,
					slidesPerView: 1,
					loop: true,
					autoplay: {
						delay: this.autoplayTimeout
					},
					speed: 500,
					effect: 'fade',
					fadeEffect: {
						crossFade: true
					}
				})
      }
    }
	}

	render() {
		if (!this.state.message || this.state.message.length < 1) return null;
		return (
		<div className = "notice-bar" >
      {this.twoTreesMessageCopy !== '' && (
        <div className="notice-bar__2trees-message">
          {this.twoTreesMessageIcon !== '' && (
            <Fragment>
              <img className="notice-bar__2trees-message-icon" src={this.twoTreesMessageIcon} alt="message icon" />
              <div className="notice-bar__2trees-message-copy">
                {this.twoTreesMessageUrl ? (
                  <a href={this.twoTreesMessageUrl}>{this.twoTreesMessageCopy}</a>
                ) : (
                  <Fragment>{this.twoTreesMessageCopy}</Fragment>
                )}
              </div>
            </Fragment>
          )}
        </div>
      )}

      {Array.isArray(this.state.message) && this.state.message.length > 1 ? (
        <div className="notice-bar-top-message-carousel swiper" ref={this.carouselRef}>
          <div className="swiper-wrapper">
            {this.state.message.map(message => (
              <div className="swiper-slide">
                <div className="notice-bar-top-message"
                  dangerouslySetInnerHTML = {
                    {
                    __html: message
                    }
                  }
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="notice-bar-top-message"
          dangerouslySetInnerHTML = {
            {
            __html: this.state.message[0]
            }
          }
        />
      )}
		</div>
		);
	}
}