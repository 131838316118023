/** @prettier */

import { useState, useEffect } from 'preact/hooks';

const isDesktop = window.matchMedia('(min-width: 768px)');

const isMobile = () => {
	const [isMobile, setIsMobile] = useState(() => !isDesktop.matches);

	useEffect(() => {
		setIsMobile(!isDesktop.matches);

		const listener = (e) => {
			setIsMobile(!e.matches);
		};

		isDesktop.addListener(listener);

		return () => isDesktop.removeListener(listener);
	}, []);

	return isMobile;
};

export default isMobile;
