const init = (playButton) => {
  const videoEl = playButton.parentNode.querySelector('video')

  playButton.addEventListener('click', () => {
    if (isVideoPlaying(videoEl)) {
      videoEl.pause()
    } else {
      videoEl.play()
    }

    videoEl.addEventListener('playing', () => {
      updatePlayButton(playButton, true)
    })

    videoEl.addEventListener('pause', () => {
      updatePlayButton(playButton, false)
    })
  })
}

const updatePlayButton = (playButton, isPlaying) => {
  if (isPlaying) {
    playButton.classList.add('is-playing')
    playButton.classList.remove('is-paused')
  } else {
    playButton.classList.remove('is-playing')
    playButton.classList.add('is-paused')
  }
}

const isVideoPlaying = video => !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-video-play-button:not(.is-initialized)').forEach(playButton => {
      playButton.classList.add('is-initialized')
      init(playButton)
    })
  })
}