export default function() {
  const init = el => {
    const updateStickyState = () => {
        const { y } = el.getBoundingClientRect()

        if (window.scrollY > y) {
          el.classList.add('is-stuck');
        } else {
          el.classList.remove('is-stuck');
        }
    }

    window.addEventListener('scroll', updateStickyState)
  }

  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-sticky-nav').forEach(el => {
      if (!el.classList.contains('is-initialized')) {
        el.classList.add('is-initialized')
        init(el)
      }
    })
  })
}