/** @jsx h */

import { h, Component, Fragment } from 'preact'

export default class AddonInsoleSizeSelector extends Component {
  componentDidMount() {
    this.setSelectedVariant()
  }

  componentDidUpdate(previousProps) {
    if (previousProps.selectedProduct !== this.props.selectedProduct) {
      this.setSelectedVariant()
    }
  }

  setSelectedVariant() {
    const size = this.props.mainProductSize
    const gender = this.props.mainProductGender
    const variants = this.props.selectedProduct?.node.variants.edges

    if (!variants) {
      return
    }

    const selectedVariant = variants.find((variant) => (
      variant.node.selectedOptions[2].value === size && variant.node.selectedOptions[0].value === gender
    )) ?? variants[0]

    this.props.onSelectVariant(selectedVariant)
  }

  render() {
    return (
      <Fragment>
        <div class="c-upsell__size">US - {this.props.mainProductSizeLabel}</div>
        <div class="c-upsell__productTitle">
          <p class="c-cart__itemUpsell">
            {window.globalLanguages?.addon.insole_addon}
          </p>
        </div>
      </Fragment>
    )
  }
}