import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const init = wrapper => {
  const carousels = wrapper.querySelectorAll('.swiper')

  carousels.forEach(carousel => {
    const parent = carousel.parentNode
    const prevEl = parent.querySelector('.swiper-button-prev')
    const nextEl = parent.querySelector('.swiper-button-next')
    const paginationEl = parent.querySelector('.swiper-pagination')

    carousel.swiper = new Swiper(carousel, {
      modules: [
        Navigation,
        Pagination
      ],
      cssMode: false,
      slidesPerView: 'auto',
      watchOverflow: true,
      speed: 600,
      navigation: {
        prevEl,
        nextEl
      },
      pagination: {
        el: paginationEl
      }
    })
  })
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-carousel').forEach(el => {
      if (!el.classList.contains('is-initialized')) {
        el.classList.add('is-initialized')
        init(el)
      }
    })
  })
}