import $ from 'jquery'
import waitFor from './utils/wait-for'
import getUrl from '@/scripts/utils/get-url'

const setPageTitle = page => {
  const currentTitle = document.title
  const newTitle = `Page ${page} ${currentTitle}`
  document.title = newTitle
}

const getTotalCount = container => {
  let result = 0

  container.each(function() {
    const total = $(this).children().length
    if (total > result) result = total
  })

  return result
}

const getCurrentPage = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('p')
  return page ? parseInt(page) : 1
}

const range = (start, end) => {
  let length = end - start + 1
  return Array.from({ length }, (_, idx) => idx + start)
}

const getPaginationRange = (totalCount, pageSize, siblingCount, currentPage) => {
  const dots = '...'
  const totalPageCount = Math.ceil(totalCount / pageSize)
  const totalPageNumbers = siblingCount + 5

  if (totalPageNumbers >= totalPageCount) {
    return range(1, totalPageCount)
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
  const rightSiblingIndex = Math.min(
    currentPage + siblingCount,
    totalPageCount
  )

  const shouldShowLeftDots = leftSiblingIndex > 2
  const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

  const firstPageIndex = 1
  const lastPageIndex = totalPageCount

  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 3 + 2 * siblingCount
    let leftRange = range(1, leftItemCount)

    return [...leftRange, dots, totalPageCount]
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 3 + 2 * siblingCount
    let rightRange = range(
      totalPageCount - rightItemCount + 1,
      totalPageCount
    );
    return [firstPageIndex, dots, ...rightRange]
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = range(leftSiblingIndex, rightSiblingIndex)
    return [firstPageIndex, dots, ...middleRange, dots, lastPageIndex]
  }
}

const buildPagination = (range, currentPage) => {
  const urlParams = new URLSearchParams(window.location.search)
  const queryString = urlParams.toString().replace(/((&*page=\d&*)*(&*p=\d&*)*)/g, '');
  let markup = ''

  if (currentPage > 1) markup += `<span class="prev"><a href="${getUrl('/search')}?p=${currentPage - 1}&${queryString}" title="" class="link-internal">‹</a></span>`

  range.forEach(page => {
    if (page === currentPage) {
      markup += `<span class="page current">${page}</span>`
    } else if (typeof page === 'string') {
      markup += `<span class="deco">${page}</span>`
    } else {
      markup += `<span class="page"><a href="${getUrl('/search')}?p=${page}&${queryString}" title="" class="link-internal">${page}</a></span>`
    }
  })

  if (currentPage < range[range.length - 1]) markup += `<span class="next"><a href="${getUrl('/search')}?p=${currentPage + 1}&${queryString}" title="" class="link-internal">›</a></span>`

  return `<div class="pagination">${markup}</div>`
}

const getImageSize = (url, size) => url.replace('_400x', `_${size}x`)

const productCard = product => {
  let priceWrapperHtml = ''
  if (window.gatedArchiveSale.isEnable() && product.isInviteSale != '') {
    priceWrapperHtml = `
    <p class="c-collectionProduct__price">
      ${product.taxInclusive ? '<span class="c-taxInclusive">'+ product.taxInclusive +'</span>' : ''}
      ${product.taxExclusive ? '<span class="c-taxExclusive">'+ product.taxExclusive +'</span>' : ''}
      <span>${product.priceSale ? product.priceSale : ''}</span>
      <span><s>${product.price ? product.price : ''}</s></span>
    </p>
    `
  } else {
    priceWrapperHtml = `
    <p class="c-collectionProduct__price">
      ${product.taxInclusive ? '<span class="c-taxInclusive">'+ product.taxInclusive +'</span>' : ''}
      ${product.taxExclusive ? '<span class="c-taxExclusive">'+ product.taxExclusive +'</span>' : ''}
      ${product.price ? product.price : ''}
    </p>
    `
  }
  return `
  <div class="c-collectionGrid__item c-collectionGrid__banner c-collectionProduct hide-section">
    <a class="c-collectionProductV2__link" href="${product.url}">
      <div class="c-collectionProduct__img">
        ${product.featuredImage ? `
        <picture>
          <source srcset="${getImageSize(product.featuredImage, 500)}" media="(min-width: 767px)">
          <source srcset="${getImageSize(product.featuredImage, 300)}" media="(min-width: 500px)">
          <img loading="lazy" src="${getImageSize(product.featuredImage, 400)}" alt="Hero image">
        </picture>
        ` : '' }
        ${product.soldOutLabel ? '<div class="c-collectionProduct__label c-collectionProductV2__label"><span>'+ product.soldOutLabel +'</span></div>'
        : product.label ? '<div class="c-collectionProduct__label c-collectionProductV2__label js-c-collectionProduct-label-default"><span>'+ product.label +'</span></div>' : ''}
      </div>
      <div class="c-collectionProduct__text">
        <h3 class="c-collectionProduct__title">${product.title}</h3>
        ${priceWrapperHtml}
      </div>
      ${product.hoverImage ?
      `<div class="c-collectionProduct__hover">
        <img loading="lazy" src="${product.hoverImage.src}" alt="${product.hoverImage.alt}" srcset="${product.hoverImage.srcset}" sizes="${product.hoverImage.sizes}" />
      </div>`
      : '' }
    </a>
  </div>`
}

const render = (data, el) => {
  const menEl = el.eq(0)
  const womenEl = el.eq(1)

  menEl.empty()
  womenEl.empty()

  data.forEach(({ results }) => {
    results.forEach(product => {
      if (product.handle.includes('-men')) menEl.append(productCard(product))
      else if (product.handle.includes('-women')) womenEl.append(productCard(product))
      else {
        menEl.append(productCard(product))
        womenEl.append(productCard(product))
      }
    })
  })

  window.requestAnimationFrame(() => window.setBadges())

  triggerCordialAbandonSearch(data)
}

const triggerCordialAbandonSearch = (data) => {
  const pageType = 'search_results'
  const url = window.location.href
  let results = []

  if (data?.[0]?.results) {
    results = data[0].results.filter(item => !item.url.includes('-men')).splice(0, 4).map(item => item.id)
  }

  waitFor(() => window.crdl, 500, 10000)
    .then(() => {
      window.crdl('event', 'search', {
        pageType,
        url,
        results
      })
  })
}

const fetchData = async requests => {
  const params = new URLSearchParams(window.location.search)
  const queryString = page => `${getUrl('/search')}?q=${params.get('q')}+-product_type:accessories&page=${page}&type=product&view=data-json`
  return await Promise.all(requests.map((_, index) => fetch(queryString(index + 1))))
    .then(async (res) => Promise.all(res.map(async (data) => await data.json())))
}

export default async function searchContent() {
	const searchResults = $('.js-search-results')
  const searchPagination = $('.js-search-pagination')
  if (searchResults.length < 1) return

  const totalCount = parseInt(searchPagination.attr('data-results-count') || 0)
  const pageSize = 24
  const siblingCount = 1

  const data = await fetchData([...Array(Math.ceil(totalCount / pageSize) + 5)])
  if (data.length < 1) return
  render(data, searchResults)

  let currentPage = getCurrentPage()

  if (currentPage > 1) setPageTitle(currentPage)

  const paginationRange = getPaginationRange(getTotalCount(searchResults), pageSize, siblingCount, currentPage)

  if (paginationRange.length > 1 && searchPagination) {
    const paginationMarkup = buildPagination(paginationRange, currentPage)
    searchPagination.html(paginationMarkup)
  }

  searchResults.each(function() {
    const items = $(this).children()
    const startIndex = currentPage === 1 ? 0 : (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    items.slice(startIndex, endIndex).removeClass('hide-section')
  })
}