/** @jsx h */

import { isError } from 'lodash-es'
import { h, Component, Fragment } from 'preact'

export default class AddonSizeAndCart extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAvailable: false,
      isError: false,
      isAdding: false
    }
  }

  componentDidMount() {
    this.checkAvailability()
  }

  componentDidUpdate(previousProps) {
    if (previousProps.selectedVariant !== this.props.selectedVariant) {
      this.checkAvailability()
    }
  }

  checkAvailability() {
    const isAvailable = this.props.selectedVariant && this.props.selectedVariant.node.availableForSale

    this.setState({
      isAvailable,
      isError: !isAvailable
    })

    return isAvailable
  }

  addToCart = (e) => {
    e.preventDefault()

    if (typeof this.props.onAddToCart === 'function') {
      this.setState({
        isAdding: true
      }, async () => {
        const success = await this.props.onAddToCart()

        if (success) {
          this.setState({
            isAvailable: true,
            isError: false
          })
        } else {
          this.setState({
            isAvailable: false,
            isError: true
          })
        }

        this.setState({
          isAdding: false
        })
      })
    }
  }

  render() {
    const cartButtonText = this.state.isAvailable ? window.globalLanguages?.addon.add : window.globalLanguages?.addon.out_of_stock
    const messages = window.OUT_STOCK_MESSAGE
    const errorMsg = this.state.isError ? (this.props.type in messages ? messages[this.props.type] : 'Try a different color?') : ''

    return (
      <Fragment>
        <div class="c-upsell__atc">
          <button
            class={`c-btn ${this.state.isAvailable ? '' : 'btn-disabled'}`}
            onClick={this.addToCart}
            disabled={!this.state.isAvailable || this.state.isAdding}
          >
            {cartButtonText}
            <span>
              {' - '}
              <span class="c-taxInclusive">
                {this.props.price}
              </span>
              <span class="c-taxExclusive">
                {this.props.priceWithoutTax}
              </span>
            </span>
          </button>
        </div>
        <p className={this.state.isError ? 'error-message' : 'hidden'}>{errorMsg}</p>
      </Fragment>
    )
  }
}