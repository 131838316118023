import $ from 'jquery';

export default function shoGun() {
    $(document).ready(function() {
      if ($('body').hasClass('template-shogun-default') || $('body').hasClass('template-shogun-raw-html')) {
          var url_string = window.location.href;
          var url = new URL(url_string);
          var activeTab = url.searchParams.get("tab");
          var tabEls = $('.shogun-tabs .shogun-tab');
          var reforestationPage = '/pages/reforestation'
          if (tabEls.length) {
              if (activeTab === 'reforestation') window.location.href = reforestationPage
              $.each( tabEls, function() {
                  var tabValue = $(this).find('.shogun-tab-title')[0].innerText.replace(/[ /_]/g, '-').toLowerCase();
                  if (tabValue === 'reforestation' || $(this).attr('data-tab') === 'reforestation') {
                      $(this).replaceWith($('<a class="shogun-tab" href=' + reforestationPage + '>' + this.innerHTML + '</a>'));
                  }
                  if (tabValue && activeTab && tabValue === activeTab) {
                      $(this).click();
                  };

                  $(this).on('click', function() {
                    url.searchParams.set("tab", tabValue);
                    window.history.pushState({}, '', url);
                  })
              });
          };
      }
    });
}
