/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact'

const languages = window.pdpLanguages ?? {}

export default class ModelPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canActivateAR: false
    }

    this.overlayRef = createRef()
    this.modelViewerRef = createRef()
  }

  componentDidMount() {
    this.loadModel()
  }

  componentDidUpdate(prevProps) {
    if (this.props.modelProjectId && (this.props.modelProjectId !== prevProps.modelProjectId || !window.mtrObject)) {
      this.loadModel()
    }
  }

  loadModel() {
    if (this.props.modelProjectId && window.MTR) {
      // this.injectCustomStyleToModelViewer()
      const config = {
        projectId: this.props.modelProjectId,
        divName: "mtr-ar-holder",
        verbose: true,
        loadedCallback: () => {
          console.log("SDK loaded");

          // hide the blush control panel (optional)
          const blushDiv = document.getElementById("blush");
          if (blushDiv) {
            blushDiv.style.display = "none";
          }

          this.updateModelViewer()
        },
      };
      window.mtrObject = new window.MTR(config);
    }
  }

  loadScript(id, scriptUrl, isTypeModule = false) {
    if (scriptUrl && !document.getElementById(id)) {
      const scriptEl = document.createElement('script')
      scriptEl.setAttribute('id', id)
      scriptEl.setAttribute('src', scriptUrl)

      if (isTypeModule) {
        scriptEl.setAttribute('type', 'module')
      }

      document.body.appendChild(scriptEl)
    }
  }

  updateModelViewer() {
    const modelViewerEl = window.mtrObject.modelViewerHolder

    if (modelViewerEl && modelViewerEl.shadowRoot) {
      const styleEl = document.createElement('style')

      styleEl.innerHTML = `
        .container {
          max-width: 100%;
        }

        .container canvas {
          max-width: 100%;
        }

        .ar-button {
          --ar-button-display: none;
        }
      `

      modelViewerEl.shadowRoot.appendChild(styleEl)

      modelViewerEl.addEventListener('load', () => {
        this.setState({ canActivateAR: modelViewerEl.canActivateAR })
      })
    }
  }

  onOverlayClick = ({ target }) => {
    if (target === this.overlayRef.current) {
      this.close()
    }
  }

  activeAR = () => {
    if (window.mtrObject) {
      window.mtrObject.modelViewerHolder.activateAR()
    }
  }

  close = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    if (!this.props.modelProjectId) {
      return ''
    }

    const disclaimer = window?.['3DModelDisclaimerCopy']?.[this.props.modelProjectId] ?? window?.['3DModelDisclaimerCopy']?.['_']

    return (
      <div class={`model-popup ${this.props.isOpen ? 'model-popup--open' : ''} ${this.state.canActivateAR ? 'model-popup--has-ar' : ''}`}>
        <div ref={this.overlayRef} class="model-popup__overlay" onClick={this.onOverlayClick}>
          <div class="model-popup__modal" role="dialog" aria-modal="true" aria-label="product 3d model viewer popup">
            <button class="model-popup__close-button" onClick={this.close}></button>
            <div class="model-popup__modal-inner">
              <div class="model-popup__model-viewer-wrapper">
                <div id="mtr-ar-holder"></div>
              </div>

              <div class="model-popup__bottom">
                <div class="model-popup__tip">{languages.touch_and_drag_to_rotate}</div>
                {disclaimer && <div className="model-popup__disclaimer">{disclaimer}</div>}
                <button
                  class="model-popup__ar-button"
                  onClick={this.activeAR}
                >{languages.see_in_your_room}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}