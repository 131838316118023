/**
 *
 * @prettier
 */

import { h } from 'preact';
/** @jsx h */
import { memo } from 'preact/compat';
import { useState, useEffect } from 'preact/hooks';
import classNames from 'classnames';
import { barData } from './data';
import { oppositeGender } from './utils';
import { graphQLRequest } from './request';
import getUrl from '@/scripts/utils/get-url'

const Bar = ({ collectionHandle }) => {
	const oppositeGenderHandle = oppositeGender(collectionHandle);
	const [isMen, setIsMen] = useState(false)
	const [isWomen, setIsWomen] = useState(false)

	useEffect(() => {
		const collectionDataQuery = /* GraphQL */ `
			query collectionBasicData($handle: String!) {
				collectionByHandle(handle: $handle) {
					id
				}
			}`
		;
		graphQLRequest(collectionDataQuery , { handle: oppositeGenderHandle } )
			.then((data) => {
				if(data.errors && data.errors.length > 0){
					setIsMen(barData.menCollections.includes(collectionHandle))
					setIsWomen(barData.womenCollections.includes(collectionHandle))
				} else {
					setIsMen(collectionHandle.includes('men') && !collectionHandle.includes('women'))
					setIsWomen(collectionHandle.includes('women'))
				}
			})
			.catch((e) => {
				console.error(e);
			});
	}, []);

	if (!(isMen || isWomen)) {
		return null;
	}

	const menLink = `${getUrl('/collections/')}${isMen ? collectionHandle : oppositeGenderHandle}`;
	const womenLink = `${getUrl('/collections/')}${isWomen ? collectionHandle : oppositeGenderHandle}`;

	return (
		<div class="c-collectionBarV2">
			<ul class="c-collectionBarV2__list">
				<li class={classNames('c-collectionBarV2__item', { isActive: isMen })}>
					<a class="c-collectionBarV2__itemLink" href={menLink}>
						<span class="c-collectionBarV2__itemText">{ window.plpLanguages?.men }</span>
					</a>
				</li>

				<li class={classNames('c-collectionBarV2__item', { isActive: isWomen })}>
					<a class="c-collectionBarV2__itemLink" href={womenLink}>
						<span class="c-collectionBarV2__itemText">{ window.plpLanguages?.women }</span>
					</a>
				</li>
			</ul>
		</div>
	);
};

export default memo(Bar);
