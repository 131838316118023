/** @jsx h */
import { h, Component, Fragment, createRef } from "preact"
import Product from "./Product"
import AddonProducts from "./AddonProducts"

class Popup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      justAddedCartItem: null
    }

    this.isActive = false
    this.overlayRef = createRef()
  }

  componentDidMount() {
    this.watchForItemAddedCart()
  }

  watchForItemAddedCart() {
    $(document).on('cart.individualRequestComplete', (_event, request, response) => {
      if (this.isEventAddToCart(request) && this.isUpsellPopupEnabled() && this.isProductHasUpsell(response)) {
        this.setState({
          isOpen: true,
          justAddedCartItem: response,
        })
      }
    })
  }

  isEventAddToCart(request) {
    return request.url.includes('/cart/add.js')
  }

  isUpsellPopupEnabled() {
    return !!window.isUpsellPopupEnabled
  }

  isProductHasUpsell(item) {
    // TODO: CAR-603 stage 1
    return !!item?.properties?._tags?.some((tag) => tag.startsWith('oca-low'))
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      $(document.body).addClass('is-cart-upsell-popup-active')
    } else {
      $(document.body).removeClass('is-cart-upsell-popup-active')
      $('.js-cartCanvas').offcanvas('close')
    }
  }

  closePopup = () => {
    this.setState({
      isOpen: false,
      justAddedCartItem: null,
    })
  }

  onOverlayClick = (e) => {
    if (e.target === this.overlayRef.current) {
      this.closePopup()
    }
  }

  render() {
    const item = this.state.justAddedCartItem

    return (
      <div
        ref={this.overlayRef}
        class={`cart-upsell-popup__overlay ${this.state.isOpen ? 'is-open' : ''}`}
        onClick={this.onOverlayClick}
      >
        <div
          class="cart-upsell-popup__wrapper"
          role="dialog"
          aria-modal="true"
          aria-label="Cart upsell popup"
        >
          <button
            class="cart-upsell-popup__close"
            aria-label="Close upsell popup"
            onClick={this.closePopup}
          >
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
              <path d="M12.736 1.538A.901.901 0 0 0 11.462.264L6.264 5.462a.9.9 0 0 0 0 1.274l5.198 5.199a.9.9 0 0 0 1.274-1.274L8.175 6.099l4.561-4.561Z" fill="#4A4A4A"/>
              <path d="M.264 1.538A.9.9 0 1 1 1.538.264l5.198 5.198a.9.9 0 0 1 0 1.274l-5.198 5.199A.9.9 0 1 1 .264 10.66l4.561-4.562L.264 1.538Z" fill="#4A4A4A"/>
            </svg>
          </button>

          <div class="cart-upsell-popup__inner">
            {item && (
              <Fragment>
                <Product product={item} onContinue={this.closePopup} />
                <AddonProducts title="Complete Your Order" product={item} />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Popup
