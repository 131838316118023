/**
 *
 * @prettier
 */

import { parseWithoutProcessing } from 'handlebars';
import { h } from 'preact';
/** @jsx h */
import { useState } from 'preact/hooks';
import ShippingEstimate from "../components/ShippingEstimate";

export const ShippingAndJumpSection = (props)=> {
    function ibiJump() {
      const target = $('.c-ibi-info');
      $('html, body').animate({
        scrollTop: $(target).offset().top - 100
      }, 700);
    }

    return (
      <div class="c-shippingDetail js-shipping-detail">
        <div class="c-abShippingBlock" >
          <span class="c-abShipping"> <span key={'ShoppingMsz'}>{window.promoMessages.getPdpMessage().message}</span></span>
        </div>
        <div class="js-shipping-estimate">
        {!props.variantAvailableForPreorder && props.variantAvailableForSale ?
          <ShippingEstimate/>
          : ""}
        </div>
      </div>);
}

export default ShippingAndJumpSection;




