/**
 *
 * @prettier
 */

import { h, Fragment } from 'preact';
/** @jsx h */

const FamilyCrossSell = () => {
  const data = window.pdpFamilyCrossSell

  if (!data || !data.text || !data.link) return <Fragment></Fragment>

  return (
    <div className="pdp-family-cross-sell hidden">
      <div className="pdp-family-cross-sell__inner">
        {data.image && <div className="pdp-family-cross-sell__image">
          <img src={data.image} alt={data.text} />
        </div>}
        <button type="button" onClick={() => { window.location.href=data.link; }} className="pdp-family-cross-sell__link">
          <span>{data.text}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M2.66675 8.58522H13.3334M8.66675 3.91855L13.3334 8.58522L8.66675 13.2519" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default FamilyCrossSell