import $ from 'jquery';


export default function offCanvas() {
    var checkingBar = setInterval(function () {
        if($('.notice-bar').length) {
            var noticeBarBanner = $('.c-mobileOffCanvas__noticeBar');
            var mszPushFrom = $('.notice-bar-short-message').text();
            noticeBarBanner.append(mszPushFrom)
            clearInterval(checkingBar)
        }
    }, 1000);

    $('.c-mobileOffCanvas__backArrow').hide()
    $('.c-mobileOffCanvas__top').css('border', 'none')

    $('.c-mobileOffCanvas__backArrow').click(function() {
        $('.c-mobileOffCanvas__form--top').show()
        $('.c-mobileOffCanvas__backArrow').hide()
        $('.c-mobileOffCanvas__top').css('border', 'none')
        $('.c-mobileOffCanvas__title').text('')

        $('.c-mobileOffCanvas__slideDefault').removeClass('c-mobileOffCanvas__defaultInactive')
        $('.c-mobileOffCanvas__slideDefault').addClass('defaultInactive__action')

        $('.c-mobileOffCanvas__slideInactive').each(function(index, el){
            $(el).removeClass('c-mobileOffCanvas__slideActive')
            $(el).addClass('slideInactive__action')
        })
    })

    $('.c-mobileOffCanvas__overlay').click(function(e) {
        if(e.target !== this) return
        $('.c-mobileOffCanvas__left').attr('aria-hidden', true)
        $('.c-mobileOffCanvas__left').addClass('slideOut')
        setTimeout(() => {
            $('.c-mobileOffCanvas__overlay').css('display', 'none')
        }, 500)
    })

    $('.c-mobileOffCanvas__slideLink').click(function(){
        const dataTitle = this.dataset.linkTitle.toLowerCase()
        const dataHandle = this.dataset.linkHandle.toLowerCase()
        $('.c-mobileOffCanvas__form--top').hide()
        $('.c-mobileOffCanvas__backArrow').show()
        $('.c-mobileOffCanvas__title').text(dataTitle)
        $('.c-mobileOffCanvas__top').css('border-bottom', '1px solid #D6D6D6')

        $('.c-mobileOffCanvas__slideDefault').removeClass('defaultInactive__action')
        $('.c-mobileOffCanvas__slideDefault').addClass('c-mobileOffCanvas__defaultInactive')

        $('.c-mobileOffCanvas__slideInactive').each(function(index, el){
            if(el.dataset.targetSlide.toLowerCase() === dataHandle) {
                $(el).removeClass('slideInactive__action')
                $(el).addClass('c-mobileOffCanvas__slideActive')
            } else {
                $(el).removeClass('c-mobileOffCanvas__slideActive')
            }
        })
    })

    $('.c-mobileOffCanvas__close').click(function() {
        $('.c-mobileOffCanvas__left').attr('aria-hidden', true)
        $('.c-mobileOffCanvas__left').addClass('slideOut')
        setTimeout(() => {
            $('.c-mobileOffCanvas__overlay').css('display', 'none')
        }, 500)
    })
}
