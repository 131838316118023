import $ from 'jquery';


export default function headerNav() {
	function loadingImages(root) {
		root.find('img[data-src]').each(function(){
			$(this).attr('src', $(this).data('src')).removeAttr('data-src');
		});
	}

	$('.c-header').mouseenter(function(e){
		$(".header-v1").addClass("is-white");
	})
	$('.c-header').mouseleave(function(e){
		$(".header-v1").removeClass("is-white");
		$('.c-headerNav__title.is-show').removeClass("is-show").data('clicked',false);
		$('.c-headerSubNav.is-show').removeClass("is-show");
	})
	$('.c-headerNav__title').click(function(e){
		if(!$(this).data('clicked')){
			loadingImages($(this).parent());
			const $mainel = $(this).closest('.c-headerNav').find('.is-show')
			if($mainel.length){
				$mainel.removeClass("is-show").data('clicked',false)
			}
			$(this).data('clicked',true);
			$(this).addClass("is-show");
			$(this).next().addClass("is-show");
		}else{
			$(this).data('clicked',false);
			$(this).removeClass("is-show");
			$(this).next().removeClass("is-show");
		}
	}).data('clicked',false)

	$('.c-headerSubNav__li').mouseenter(function(){
		//$(this).find('.c-headerSubNav__title').addClass('c-green');
		$(this).addClass('c-borderBottom')
	})

	$('.c-headerSubNav__li').mouseleave(function(){
		//$(this).find('.c-headerSubNav__title').removeClass('c-green');
		$(this).removeClass('c-borderBottom')

	})

	$('.triggerOffCanvas').click(function(){
		loadingImages($('.c-mobileOffCanvas__left'));
		$('.c-mobileOffCanvas__overlay').css('display', 'flex');
		$('.c-mobileOffCanvas__left').attr('aria-hidden', false);
		$('.c-mobileOffCanvas__left').removeClass('slideOut');
		$('#canvas-links').show();
	})

	$('.triggerOffCanvas__search').click(function(){
		loadingImages($('.c-mobileOffCanvas__left'));
		$('.c-mobileOffCanvas__overlay').css('display', 'flex');
		$('.c-mobileOffCanvas__left').attr('aria-hidden', false);
		$('.c-mobileOffCanvas__left').removeClass('slideOut');
		$('#canvas-links').show();
		 $('.mobile-nav .search-form #MiniSearchMobile').focus();
	})
}