/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact'
import { graphQLRequest } from '../../collection/request'
import { formatId, getCountryByCurrency } from '../../collection/utils'
import AddonImageSlider from './AddonImageSlider'
import AddonAddToCart from './AddonAddToCart'
import AddonSizeSelector from './AddonSizeSelector'
import addonCallback from './addon-callback'
import { getPriceWithoutCurrency } from '../AB/utils'
import getUrl from '@/scripts/utils/get-url'
import getLanguageCode from '@/scripts/utils/get-language-code'

const cache = {}

export default class Addon extends Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState()
    this.elRef = createRef()
  }

  async componentDidMount() {
    if (this.props.suffix === 't-shirt-tile') {
      this.elRef.current.classList.add('open', 'is-initialized')
      cartDrawer.offcanvas('open')
    }

    await this.resetState()
    this.fetchData(this.props.collectionHandle)

    window.jQuery(document).on('cart.requestComplete', () => {
      if (this.state.identifiers) {
        this.setState({
          identifiers: addonCallback('reorderIdentifiers', this.props.type, this.state.identifiers, this.state.originalIdentifiers)
        })
      }
    })
  }

  componentDidUpdate(previousProps) {
    if (previousProps.selectedId !== this.props.selectedId && this.state.data) {
      let selectedProduct = this.state.data.find(item => formatId(item.node.id.toString()) === this.props.selectedId)

      if (selectedProduct) {
        this.setState({ selectedProduct })
        return
      }

      for (let i = 0; i < this.state.identifiers.length; i++) {
        const data = this.setData(i)
        const selectedIndex = data.findIndex(item => formatId(item.node.id).toString() === this.props.selectedId)

        if (selectedIndex !== -1) {
          const selectedProduct = data[selectedIndex]
          const activeGroupIndex = i

          this.setState({ selectedProduct, activeGroupIndex })
          break
        }
      }
    }
  }

  async resetState() {
    return new Promise(resolve => {
      const initialState = this.getInitialState()

      this.setState(initialState, resolve)
    })
  }

  getInitialState() {
    let identifiers
    let identifierActive

    if (this.props.identifierActive) {
      identifierActive = this.props.identifierActive
    }

    if (this.props.identifiers) {
      identifiers = [...this.props.identifiers]
    } else {
      identifiers = window.addonUpsellSettings?.filter(({ type }) => type === this.props.type) || []
    }

    const originalIdentifiers = [...identifiers]
    identifiers = addonCallback('reorderIdentifiers', this.props.type, { identifiers, originalIdentifiers })

    const allProducts = identifiers.reduce((data, item) => {
      data[item.value] = []

      return data
    }, {})

    return {
      isLoading: true,
      data: null,
      selectedProduct: null,
      selectedVariant: null,
      price: null,
      priceWithoutTax: null,
      activeGroupIndex: 0,
      allProducts,
      identifierActive,
      identifiers,
      originalIdentifiers
    }
  }

  async fetchData(collectionHandle) {
    const query = /* GraphQL */ `
      query collectionData($handle: String!) @inContext(country: ${getCountryByCurrency(window.Shopify.currency.active)}, language: ${getLanguageCode()}) {
        collectionByHandle(handle: $handle) {
          title
          products(first: 50) {
            edges {
              node {
                id
                title
                handle
                alternativeTitle: metafield(namespace: "c_f", key: "name") {
                  value
                }
                footerText: metafield(namespace: "info", key: "upsell_slide_out_footer_text") {
                  value
                }
                treePlant: metafield(namespace: "info", key: "tree_plant") {
                  value
                }
                options {
                  name
                  values
                }
                priceRange {
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                }
                images(first: 10) {
                  edges {
                    node {
                      originalSrc
                      altText
                      thumbnail: transformedSrc(maxWidth: 75, maxHeight: 75, scale: 3${this.props.cropImage ? ', crop: CENTER' : ''})
                      thumbnailLarge: transformedSrc(maxWidth: 250 ,maxHeight: 250, scale: 3${this.props.cropImage ? ', crop: CENTER' : ''})
                    }
                  }
                }
                variants(first: 30) {
                  edges {
                    node {
                      id
                      sku
                      title
                      price {
                        amount
                        currencyCode
                      }
                      availableForSale
                      quantityAvailable
                      currentlyNotInStock
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                metafields(identifiers: [
                  { namespace: "swatch", key: "image" },
                  { namespace: "swatch", key: "color" }
                ]) {
                  namespace
                  value
                  key
                }
              }
            }
          }
        }
      }
    `

    try {
      let response

      if (collectionHandle in cache) {
        response = cache[collectionHandle]
      } else {
        response = await graphQLRequest(query, { handle: collectionHandle }, `${getUrl('/collections/')}${collectionHandle}?view=`)
        cache[collectionHandle] = response
      }

      if (response.errors?.length) {
        console.error(response.errors)
      }

      await this.splitData(response.data.collectionByHandle)

      let activeGroupIndex = 0

      if (this.props.identifierActive) {
        const index = this.state.identifiers?.findIndex(item => item.value && item.value === this.props.identifierActive)
        activeGroupIndex = index > -1 ? index : 0
      } else {
        if (this.isUnisex()) {
          const isMen = !window.location.pathname.includes('women')
          activeGroupIndex = this.state.identifiers.findIndex(({ value }) => value === (isMen ? 'men' : 'women'))

          if (activeGroupIndex === -1) {
            activeGroupIndex = 0
          }
        } else {
          for (let i = 0; i < this.state.identifiers.length; i++) {
            const data = this.setData(i)
            const selectedIndex = data.findIndex(item => formatId(item.node.id).toString() === this.props.selectedId)

            if (selectedIndex !== -1) {
              activeGroupIndex = i
              break
            }
          }
        }
      }

      this.setState({
        activeGroupIndex
      }, () => {
        this.mapData(this.setData(activeGroupIndex))
      })
    } catch (e) {
      console.error(e)
    }
  }

  isUnisex() {
    return this.state.identifiers.every(({ key, value }) => key === '*' && (value === 'men' || value === 'women'))
  }

  splitData(data) {
    return new Promise(resolve => {
      const productData = this.state.allProducts
      let allProducts = [...data.products.edges]
      let identifiers = this.state.originalIdentifiers

      if (identifiers.length) {
        const sortedIdentifiers = [...identifiers].sort((a, b) => b.key.join('').length - a.key.join('').length)

        data.products.edges.forEach((each) => {
          if (each.node?.handle) {
            for (const identifier of sortedIdentifiers) {
              if ((identifier.key.length === 1 && identifier.key.includes('*')) || identifier.key.some(key => each.node.handle.includes(key))) {
                productData[identifier.value].push(each)

                if (!identifier.key.includes('*')) {
                  break;
                }
              }
            }
          }
        })

        allProducts = Object.keys(productData).reduce((data, key) => {
          if (productData[key].length > 0) {
            data[key] = productData[key]
          }

          return data
        }, {})

        identifiers = this.state.identifiers.filter(item => Object.keys(allProducts).includes(item.value))
      }

      this.setState({
        allProducts,
        identifiers
      }, resolve)
    })
  }

  setData(index = 0) {
    if (!this.state.identifiers.length) {
      return this.state.allProducts
    }

    return this.state.allProducts[this.state.identifiers[index]?.value] ?? []
  }

  setProductGroup(index) {
    this.setState({
      activeGroupIndex: index
    }, () => {
      this.mapData(this.setData(index))
    })
  }

  mapData(data) {
    const selectedProduct = data.find(item => formatId(item.node.id.toString()) === this.props.selectedId) || data[0]

    this.setState({
      data,
      selectedProduct
    }, () => {
      if (this.props.suffix !== 't-shirt-tile') {
        this.elRef.current.classList.add('open', 'is-initialized')
        cartDrawer.offcanvas('open')
      }
    })
  }

  setSelectedProduct = (selectedProduct) => {
    this.setState({ selectedProduct })
  }

  setSelectedVariant = (selectedVariant) => {
    const price = this.getPrice(selectedVariant, true)
    const priceWithoutTax = this.getPrice(selectedVariant, false)
    this.setState({
      selectedVariant,
      price,
      priceWithoutTax
    })
  }

  addToCart = () => {
    return new Promise((resolve) => {
      const selectedVariant = this.state.selectedVariant

      if (!selectedVariant?.node.availableForSale) {
        return false
      }

      const options = addonCallback('cartProperties', this.props.type, {
        mainProductId: this.props.mainProductId,
        selectedProduct: this.state.selectedProduct,
        selectedVariant: this.state.selectedVariant
      })
      let variantId = selectedVariant.node.id

      if (variantId.includes('t/')) {
        variantId = variantId.substring(variantId.indexOf('t/') + 2)
      }

      CartJS.addItem(variantId, 1, options, {
        error: () => {
          resolve(false)
        },
        success: () => {
          resolve(true)

          addonCallback('postAddToCart', this.props.type, {
            mainProductId: this.props.mainProductId,
            variantId
          })
        }
      })
    })
  }

  getPrice(variant, taxInclusive) {
    const amount = variant?.node.price.amount
    const priceCurrency = variant?.node.price.currencyCode

    if (!amount) {
      return 0
    }

    const prices = getPriceWithoutCurrency(amount, priceCurrency)

    if (taxInclusive) {
      return prices.tax
    }

    return prices.noneTax
  }

  render() {
    if (!this.state.identifiers) {
      return
    }

    return (
      <Fragment>
        <div
          ref={this.elRef}
          class={`c-upsell js-upsell js-upsell--${this.props.type} c-upsell--${this.props.type} ${this.props.suffix ? 'c-upsell--' + this.props.suffix + ' js-upsell--' + this.props.suffix : ''}`}
          data-cart-view="data-cart-view"
          rules="all"
        >
          <div class="c-upsell__header js-upsellClose">
            <span class="c-upsell__headerIcon">
              <img src={typeof SHOPIFY_CDN != 'undefined' && SHOPIFY_CDN + 'arrow-left_30x.png'} width="15" height="15" class="c-upsell__headerImg" />
            </span>
            BACK
          </div>

          <Fragment>
            {this.state.identifiers.length > 1 ? (
              <div class="c-upsell__productTabs">
                <div class="c-upsell__productTabs_inner">
                  {this.state.identifiers.map((item, index) => (
                    <a
                      key={`addon-upsell-tab-${item.value}`}
                      className={`c-upsell__productTab ${this.state.activeGroupIndex === index ? 'active' : ''}`}
                      data-target={item.value}
                      onClick={() => { this.setProductGroup(index) }}
                    >{item.title}</a>
                  ))}
                </div>
              </div>
            ) : this.props.type !== 'kids' ? (
              <div class="c-upsell__productPrice">
                <p class="c-upsell__productPriceName">{ addonCallback('addonTitle', this.props.type) }</p>
                <p class="c-upsell__productPriceName c-taxInclusive">{ this.state.price }</p>
                <p class="c-upsell__productPriceName c-taxExclusive">{ this.state.priceWithoutTax }</p>
              </div>
            ) : null}
          </Fragment>

          <div class="c-upsell__block">
            <div class="c-upsell__blockBorder">
              <div class="c-upsell__blockItem">
                <div class="c-upsell__product">
                  {this.state.selectedProduct != null ? (
                    <Fragment>
                      <AddonImageSlider
                        type={this.props.type}
                        activeGroupIdx={this.state.activeGroupIndex}
                        data={this.state.data}
                        showSlideshowImageOnly={this.props.showSlideshowImageOnly}
                        selectedProduct={this.state.selectedProduct}
                        onSelectProduct={this.setSelectedProduct}
                      />
                      <AddonSizeSelector
                        type={this.props.type}
                        selectedProduct={this.state.selectedProduct}
                        selectedVariant={this.state.selectedVariant}
                        mainProductSize={this.props.mainProductSize}
                        mainProductGender={this.props.mainProductGender}
                        mainProductSizeLabel={this.props.mainProductSizeLabel}
                        price={this.state.price}
                        priceWithoutTax={this.state.priceWithoutTax}
                        onSelectVariant={this.setSelectedVariant}
                        onAddToCart={this.addToCart}
                        selectedVariantId={this.props.selectedVariantId}
                      />
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            {this.state.selectedProduct != null && (
              <AddonAddToCart
                type={this.props.type}
                mainProductId={this.props.mainProductId}
                selectedProduct={this.state.selectedProduct}
                selectedVariant={this.state.selectedVariant}
                price={this.state.price}
                priceWithoutTax={this.state.priceWithoutTax}
                onAddToCart={this.addToCart}
              />
            )}

            {this.state.selectedProduct != null && this.state.selectedProduct.node.footerText?.value && (
              <div className="c-upsell__description" dangerouslySetInnerHTML={{ __html: this.state.selectedProduct.node.footerText.value}}></div>
            )}
          </div>
        </div>
      </Fragment>
    )
  }
}
