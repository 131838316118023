/** @jsx h */

import { h, Component, Fragment } from 'preact'
import addonCallback from './addon-callback'
import { formatId } from '../../collection/utils'

export default class AddonDefaultSizeSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSizeGuidePopup: false
    }
  }

  componentDidMount() {
    this.setSelectedVariant()
  }

  componentDidUpdate(previousProps) {
    if (previousProps.selectedProduct !== this.props.selectedProduct) {
      this.setSelectedVariant()
    }
  }

  setSelectedVariant() {
    let selectedVariant = this.props.selectedProduct.node.variants.edges.find(variant => formatId(variant.node.id) === this.props.selectedVariantId)
    
    if (!selectedVariant) {
      selectedVariant = this.props.selectedProduct.node.variants.edges.find(variant => variant.node.availableForSale)
    }

    if (!selectedVariant) {
      selectedVariant = this.props.selectedProduct.node.variants.edges[0]
    }

    this.props.onSelectVariant(selectedVariant)
  }

  handleSizeGuide = () => {
    this.setState({
      showSizeGuidePopup: !this.state.showSizeGuidePopup,
    }, () => {
      if (this.state.showSizeGuidePopup) {
        $('.js-upsell--socks.open').addClass('cart-overflow-hidden')
        $('.js-upsell--t-shirt-tile').addClass('c-upsell-footer--hidden')
      } else {
        $('.js-upsell--socks.open').removeClass('cart-overflow-hidden')
        $('.js-upsell--t-shirt-tile').removeClass('c-upsell-footer--hidden')
      }
    });
  }

  getSizeLabel = variant => {
    return this.props.selectedProduct.node.options[2].values.length <= 1 && variant.node.selectedOptions[2].value === '1' ?
      'ONE SIZE' :
      variant.node.selectedOptions[2].value
  }

  getSizeDescription = variant => {
    const sizeLabel = this.getSizeLabel(variant)
    const description = addonCallback('sizeDescription', this.props.type, sizeLabel)

    return description || `US ${sizeLabel}`
  }

  render() {
    const isAvailable = this.props.selectedVariant && this.props.selectedVariant?.node.availableForSale
    const cartButtonText = isAvailable ? window.globalLanguages?.addon.add : window.globalLanguages?.addon.out_of_stock
    const messages = window.OUT_STOCK_MESSAGE
    const errorMsg = !isAvailable ? (this.props.type in messages ? messages[this.props.type] : 'Try a different color?') : ''

    return (
      <Fragment>
        <div class="c-upsell__selected-size">
          <div class="c-upsell__size-label">
            <span class="c-upsell__size-label-title">{window.globalLanguages?.addon.size_label}</span>
            {isAvailable ? (
              <b>
                {this.getSizeLabel(this.props.selectedVariant)}
              </b>
            ) : (
              <b>{window.globalLanguages?.addon.out_of_stock}</b>
            )}

            {this.props.selectedProduct?.node.alternativeTitle?.value && (
              <span class="c-upsell__product-title">
                {this.props.selectedProduct.node.alternativeTitle.value}
              </span>
            )}
          </div>
          <div className="c-upsell__size-guide" onClick={this.handleSizeGuide}>
            {window.globalLanguages?.addon.size_fit}
          </div>
        </div>
        <div class="c-upsell-size-options-wrapper">
          <div class="c-upsell-size-options">
            {this.props.selectedProduct != null
              ? this.props.selectedProduct.node.variants.edges.map((variant) => {
                  return (
                    <div
                      key={variant.node.id}
                      class={`c-upsell-size-box ${
                        this.props.selectedVariant != null ?
                          !variant.node.availableForSale ? 'disabled' :
                           variant.node.id == this.props.selectedVariant.node.id
                            ? "active"
                            : ""
                          : "disabled"
                      } `}
                      onClick={() => {
                        this.props.onSelectVariant(variant);
                      }}
                    >
                      {this.getSizeLabel(variant)}
                    </div>
                  );
                })
              : ""}
          </div>
          <div className="c-upsell__size-guide" onClick={this.handleSizeGuide}>
            {window.globalLanguages?.addon.size_fit_addon}
          </div>
        </div>
        <p className={errorMsg != null ? 'error-message' : 'hidden'}>{errorMsg}</p>
        {this.state.showSizeGuidePopup ? (
          <div class="c-upsell__size-guide-popup">
            <div class="close-icon" onClick={this.handleSizeGuide}>
              x
            </div>
            <div class="c-upsell__size-guide-popup-heading">{window.globalLanguages?.addon.size_fit}</div>
            <div class="c-upsell-size-options-popup">
              {this.props.selectedProduct != null
                ? this.props.selectedProduct.node.variants.edges.map((variant) => {
                    return (
                      <div
                        key={variant.node.id}
                        class={`c-upsell-size-box-popup ${
                          this.props.selectedVariant != null ?
                          !variant.node.availableForSale ? 'disabled' :
                             variant.node.id ==
                              this.props.selectedVariant.node.id
                              ? "active"
                              : ""
                            : ""
                        } `}
                        onClick={() => {
                          this.props.onSelectVariant(variant);
                        }}
                      >
                        <span class="radio__control"></span>
                        <b>{this.getSizeLabel(variant)}</b>
                        {this.getSizeDescription(variant)}
                      </div>
                    );
                  })
                : ""}
              <div class="c-upsell__atc">
                <a
                  class={`js-addtocartLaces c-btn ${isAvailable ? '' : 'btn-disabled'}`}
                  onClick={this.props.onAddToCart}
                >
                  {cartButtonText}
                  <span>
                    {' - '}
                    <span class="c-taxInclusive">
                      {this.props.price}
                    </span>
                    <span class="c-taxExclusive">
                      {this.props.priceWithoutTax}
                    </span>
                  </span>
                </a>
              </div>
              <p className={errorMsg != null ? 'error-message' : 'hidden'}>{errorMsg}</p>
            </div>
          </div>
        ) : null}
      </Fragment>
    )
  }
}