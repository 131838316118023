import $ from "jquery";
import Cookies from "js-cookie";

export default function modalExit() {
  var pexelPage = $("body").is(".page-pexels-voting, .page-pexels-signup, .template-ibi-slip-on, .template-salvas, .template-svb");
  var checkClose = false;

  //Check whether we are in mobile or desktop
  function handleResize() {
    let lastClicked = 0;
    // window.removeEventListener('scroll', handleCheckingUserScroll)
    $(document).on("click", (e) => {
      //if modal open don't catch any click event
      if ($(e.target).closest("#modal-layer").length) {
        return;
      }

      var timeNow = new Date().getTime();
      if (timeNow > lastClicked + 5000) {
        lastClicked = timeNow;
        let alreadyPopUp = Cookies.get("modalExitAlreadyPop");
        //using this to check pdp size popup is open or not
        let sizePopupValue=JSON.parse(localStorage.getItem("sizePopupIsShowing"));
        let sizePopupIsShowing =
            sizePopupValue !== null
            ? sizePopupValue
            : false;
        let CheckalreadyPopUp = true;
        if (typeof alreadyPopUp == "undefined") {
          CheckalreadyPopUp = false;
        } else {
          CheckalreadyPopUp = true;
        }
        console.log(
          "checker 1",
          CheckalreadyPopUp,
          pexelPage,
          sizePopupIsShowing
        );
        if (checkClose) {
        } else {
          if (!CheckalreadyPopUp && !pexelPage && !sizePopupIsShowing) {
            setTimeout(() => {
               sizePopupValue=JSON.parse(localStorage.getItem("sizePopupIsShowing"));
               sizePopupIsShowing =
                  sizePopupValue !== null
                  ? sizePopupValue
                  : false;
              if (!sizePopupIsShowing) {
                alreadyPopUp = Cookies.get("modalExitAlreadyPop");
                CheckalreadyPopUp = true;
                if (typeof alreadyPopUp == "undefined") {
                  CheckalreadyPopUp = false;
                } else {
                  CheckalreadyPopUp = true;
                }
                if (!CheckalreadyPopUp && !checkClose && !$('body').hasClass('offcanvas-open')) {
                  $("#modal-layer").addClass("c-modal__layer--open");
                }
              }
            }, 5000);
          }
        }
      }
    });
  }

  //Mobile functionality
  //   function openModalInMobileHelper() {
  //     let alreadyPopUp = Cookies.get("modalExitAlreadyPop");
  //     let CheckalreadyPopUp = true;
  //     if (typeof alreadyPopUp == "undefined") {
  //       CheckalreadyPopUp = false;
  //     } else {
  //       CheckalreadyPopUp = true;
  //     }
  //     $(document).on("click", () => {
  //       if (!CheckalreadyPopUp && !pexelPage && !sizePopupIsShowing) {
  //         setTimeout(() => {
  //           $("#modal-layer").addClass("c-modal__layer--open");
  //         }, 5000);
  //       }
  //     });
  //   }

  $("#c-modalCross").on("click", function() {
      checkClose = true;
      Cookies.set("modalExitAlreadyPop", true, { expires: 7 });
      $("#modal-layer").removeClass("c-modal__layer--open");
  });

   (function() {
    let country = Cookies.get("_shopify_country");
    if (
      window.location.pathname == "/" ||
      $(".js-collectionPageContent").length > 0 ||
      window.location.pathname.includes("size-fit-guide")
    ) {
      //don't show popup on plp page & size guid page.
    } else {
      if(window.countryCode && window.countryCode != "US" && window.countryCode != "CA"){
        handleResize();
      }
    }
  })();
}