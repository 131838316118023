/** @jsx h */

import { h, Component, createRef } from 'preact'
import Addon from './Addon'

export default class AddonWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: '',
      collectionHandle: '',
      identifiers: [],
      mainProductId: null,
      mainProductSize: null,
      mainProductGender: null,
      mainProductSizeLabel: null,
      showSlideshowImageOnly: false,
      updateCounter: 0
    }

    this.elRef = createRef()
  }

  componentDidMount() {
    document.addEventListener('click', ({ target }) => {
      const opener = this.findParent(target, 'js-upsell-opener')

      if (!opener || opener.classList.contains('disabled-upsell')) {
        return
      }

      const data = opener.dataset
      const suffix = data.suffix ?? null
      const type = data.type
      const collectionHandle = data.collectionHandle
      const identifierActive = data.target ?? ''
      const identifiers = data.identifiers ?? null
      const mainProductId = data.mainProductId ?? null
      const mainProductSize = data.mainProductSize ?? null
      const mainProductGender = data.mainProductGender ?? null
      const mainProductSizeLabel = data.mainProductSizeLabel ?? null
      const showSlideshowImageOnly = data.showSlideshowImageOnly ?? false
      const cropImage = data.cropImage ?? false
      const selectedId = data.selectedId ?? null
      const selectedVariantId = data.selectedVariantId ?? null
      const addonEl = this.elRef?.current.querySelector(':scope > .js-upsell')
      let updateCounter = this.state.updateCounter

      if (!addonEl) {
        updateCounter++
      }

      this.setState({
        suffix,
        type,
        collectionHandle,
        identifiers,
        mainProductId,
        mainProductSize,
        mainProductGender,
        mainProductSizeLabel,
        showSlideshowImageOnly,
        cropImage,
        selectedId,
        selectedVariantId,
        updateCounter,
        identifierActive
      })
    })
  }

  componentDidUpdate() {
    const addonEl = this.elRef?.current.querySelector(':scope > .js-upsell')

    if (addonEl) {
      if (addonEl.classList.contains('is-initialized')) {
        addonEl.classList.add('open')
        cartDrawer.offcanvas('open')
      }
    }
  }

  findParent(el, parentClass) {
    while (el) {
      if (el.classList?.contains(parentClass)) {
        return el
      }

      el = el.parentNode
    }

    return null
  }

  render() {
    return (
      <div ref={this.elRef} class="addon-popup">
        {this.state.collectionHandle && (
          <Addon key={`${this.state.collectionHandle}-${this.state.selectedId}-${this.state.updateCounter}-${this.state.identifierActive}-${this.state.mainProductId}`} {...this.state} />
        )}
      </div>
    )
  }
}
