import Swiper, { Navigation } from "swiper"

const init = (el) => {
  const carouselEl = el.querySelector('.collaboration-carousel__carousel')
  const mediaDesktop = window.matchMedia('(min-width: 768px)')
  let carousel = reInitCarousel(carouselEl, null, mediaDesktop.matches)

  mediaDesktop.addEventListener('change', () => {
    carousel = reInitCarousel(carouselEl, carousel, mediaDesktop.matches)
  })
}

const reInitCarousel = (carouselEl, carousel, isDesktop) => {
  if (carousel) {
    carousel.destroy()
  }

  if (isDesktop) {
    return new Swiper(carouselEl, {
      modules: [Navigation],
      cssMode: false,
      loop: false,
      speed: 600,
      centeredSlides: false,
      slidesPerView: 'auto',
      spaceBetween: 16,
      navigation: {
        prevEl: carouselEl.querySelector('.swiper-button-prev'),
        nextEl: carouselEl.querySelector('.swiper-button-next')
      },
      breakpoints: {
        1280: {
          spaceBetween: 20
        }
      }
    })
  } else {
    return new Swiper(carouselEl, {
      modules: [Navigation],
      cssMode: false,
      loop: true,
      speed: 600,
      centeredSlides: true,
      slidesPerView: 'auto',
      spaceBetween: 14,
      navigation: {
        prevEl: carouselEl.querySelector('.swiper-button-prev'),
        nextEl: carouselEl.querySelector('.swiper-button-next')
      },
      on: {
        slideNextTransitionStart: function() {
          carouselEl.classList.remove('is-swipe-prev')
          carouselEl.classList.add('is-swipe-next')
        },
        slidePrevTransitionStart: function() {
          carouselEl.classList.remove('is-swipe-next')
          carouselEl.classList.add('is-swipe-prev')
        },
      }
    })
  }
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-collaboration-carousel:not(.is-initialized)').forEach(el => {
      init(el)
    })
  })
}