const loadScriptsTimer = setTimeout(loadScripts, parseInt(window.YETT_TIMEOUT))
const userInteractionEvents = ["scroll", "mousedown", "mousemove", "click", "keydown", "touchstart"]
window.YETT_BLACKLIST.length && userInteractionEvents.forEach(function (event) {
  window.addEventListener(event, triggerScriptLoader, { passive: true })
})

function triggerScriptLoader (event) {
  if (event && !event.isTrusted) return
  loadScripts()
  clearTimeout(loadScriptsTimer)
  userInteractionEvents.forEach(function (event) {
    window.removeEventListener(event, triggerScriptLoader, { passive: true })
  })
}

function loadScripts () {
  if (window.YETT_BLACKLIST_RULES) {
    const rules = window.YETT_BLACKLIST_RULES

    for (const pattern in rules) {
      if (pageUrlMatched(rules[pattern])) {
        window.yett.unblock(pattern)
      }
    }
  } else {
    window.yett.unblock()
  }
}

function pageUrlMatched(pageUrls) {
  const pathName = window.location.pathname

  if (!pageUrls) {
    return true
  }

  if (!Array.isArray(pageUrls)) {
    return pageUrls === pathName
  }

  return pageUrls.length === 0 || pageUrls.some(pageUrl => pageUrl === '' || pageUrl === pathName)
}