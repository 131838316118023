import Swiper, { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

const init = el => {
  let swiper

  const breakpointChecker = function() {
    if (window.innerWidth < 1024) return enableSwiper()
    if (swiper !== undefined) swiper.destroy(true, true)
  }

  const enableSwiper = () => {
    if (swiper && !swiper.hasOwnProperty('destroyed')) return

    swiper = new Swiper(el.querySelector('.swiper'), {
      modules: [
        Pagination
      ],
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      freeMode: true,
      speed: 600,
      pagination: {
        el: el.querySelector('.swiper-pagination'),
        clickable: true
      },
      breakpoints: {
        600: {
          slidesPerView: 2,
          slidesPerGroup: 2
        }
      }
    })
  }

  window.addEventListener('resize', () => breakpointChecker())
  breakpointChecker()
}

export default () => {
  document.querySelectorAll('#js-collectionSlider').forEach(el => init(el))
}
