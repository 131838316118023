/** @prettier */

import { useState, useMemo, useEffect } from 'preact/hooks';

const ELEVAR_COLLECTION_LIMIT = 36;

const convertProductToDataLayerObject = (collectionTitle) => (product, index) => {
	const productId = parseInt(product.id.toString().includes('gid://shopify/Product/') ? product.id.replace('gid://shopify/Product/', '') : product.id);
	const variantId = parseInt(product.variant0.id.toString().includes('gid://shopify/ProductVariant/') ? product.variant0.id.replace('gid://shopify/ProductVariant/', '') : product.variant0.id);

	return {
		name: product.titleOriginal.replace("'", ''),
		id: product.variant0.sku,
		productId,
		variantId,
		shopifyId: `shopify_US_${productId}_${variantId}`,
		price: product.price.amount,
		brand: product.vendor.replace("'", ''),
		position: index,
		category: collectionTitle.replace("'", ''),
		list: 'Collection Page',
		handle: product.handle,
	};
};

// use global var as quick but not very nice to get data to track product clicks
let allProducts = [];
let shopCurrency = '';
export const useGtmTrackCollectionView = ({
	shopCurrency: localShopCurrency,
	collectionTitle,
	collections,
	collectionsBasicData,
}) => {
	shopCurrency = localShopCurrency;
	const [hasTracked, setHasTracked] = useState(false);
	const downloadedAllCollections = useMemo(
		() => collections.every(([handle]) => collectionsBasicData[handle].status !== 'LOADING'),
		[collections, collectionsBasicData],
	);

	useEffect(() => {
		if (!hasTracked && downloadedAllCollections) {
			setHasTracked(true);

			allProducts = []
				.concat(
					...collections.map(([handle]) => collectionsBasicData[handle].products || []),
				)
				.map(convertProductToDataLayerObject(collectionTitle));

			const impressions = allProducts.slice(0, ELEVAR_COLLECTION_LIMIT);

			window.dataLayer.push({
				event: 'collectionView',
				ecommerce: {
					currencyCode: shopCurrency,
					actionField: { list: 'Collection Page' },
					impressions,
				},
			});
		}
	}, [hasTracked, !downloadedAllCollections]);
};

export const trackProductCLick = (handle) => {
	const clickedProduct = allProducts
		.filter((product) => product.handle === handle)
		.map((product) => {
			const productCopy = { ...product };
			delete productCopy.list;
			delete productCopy.handle;
			return productCopy;
		});

	window.dataLayer.push({
		event: 'productClick',
		ecommerce: {
			currencyCode: shopCurrency,
			click: {
				actionField: { list: 'Collection Page' },
				products: clickedProduct,
			},
		},
	});
};
