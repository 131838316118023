import $ from 'jquery'

const BUNDLE_DISCOUNTS = {
  'USD': 25,
  'AUD': 35,
  'GBP': 20.7993,
  'CNY': 198,
  'JPY': 2513.6364,
  'HKD': 198,
  'SGD': 29,
  'EUR': 24.2631,
  'CHF': 25.0046,
  'NOK': 240,
  'SEK': 240,
  'DKK': 168.32,
  'FI-EUR': 23.4113,
  'BRL': 176
}

const BUNDLE_DISCOUNT_TYPE = 'flat';
let DEFAULT_BUNDLE_DISCOUNT = 25

if (window.Shopify?.shop === 'cariuma.myshopify.com') {
  DEFAULT_BUNDLE_DISCOUNT = 25
} else if (window.Shopify?.shop === 'int-cariuma.myshopify.com') {
  DEFAULT_BUNDLE_DISCOUNT = 35
} else if (window.Shopify?.shop === 'cn-cariuma.myshopify.com') {
  DEFAULT_BUNDLE_DISCOUNT = 20.7993
} else if (window.Shopify?.shop === 'br-cariuma.myshopify.com') {
  DEFAULT_BUNDLE_DISCOUNT = 176
}

const getBundleDiscountAmount = (currency, country = window.Shopify.country) => {
  if (country === 'FI') {
    currency = 'FI-EUR'
  }

  if (currency in BUNDLE_DISCOUNTS) {
    return BUNDLE_DISCOUNTS[currency]
  }

  return DEFAULT_BUNDLE_DISCOUNT
}

const cleanupCart = (cart) => {
  const bundles = {}

  for (const item of cart.items) {
    if (item.properties.__bundleId) {
      if (!(item.properties.__bundleId in bundles)) {
        bundles[item.properties.__bundleId] = { kids: null, adult: null }
      }

      bundles[item.properties.__bundleId][(item.properties.__isKids) ? 'kids' : 'adult'] = item
    }
  }

  const itemsToRemove = []

  for (const bundleId in bundles) {
    const bundle = bundles[bundleId]

    if (bundle.kids && !bundle.adult) {
      itemsToRemove.push(bundle.kids.key)
    }
  }

  if (window.CartJS && itemsToRemove.length > 0) {
    const updates = itemsToRemove.reduce((updates, id) => {
      updates[id] = 0

      return updates
    }, {})

    window.CartJS.updateItemQuantitiesById(updates)
  }
}

export {
  BUNDLE_DISCOUNT_TYPE,
  getBundleDiscountAmount,
  cleanupCart,
}