import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const init = el => {
  const swiper = new Swiper(el.querySelector('.swiper'), {
    modules: [
      Navigation,
      Pagination
    ],
    cssMode: false,
    slidesPerView: 1,
    loop: true,
    speed: 600,
    navigation: {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev')
    },
    pagination: {
      el: el.querySelector('.swiper-pagination'),
      clickable: true
    }
  })
}

export default () => {
  document.querySelectorAll('.js-review').forEach(el => init(el))
}
