/** @jsx h */
import { h, Component, Fragment } from 'preact'
import Laces from './Laces'
import Insole from './Insole'
import Socks from './Socks'

class AddonProducts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // TODO: CAR-603 stage 1
      products: [
        {
          type: 'insole',
          handle: 'insole-1',
          title: 'Extra Insole'
        },
        {
          type: 'laces',
          handle: 'oca-low-laces',
          title: 'Extra Laces'
        },
        {
          type: 'socks',
          handle: 'no-show',
          title: 'No Show Socks (2-pack)'
        },
        {
          type: 'socks',
          handle: 'socks',
          title: 'Crew Socks'
        }
      ]
    }
  }

  render() {
    return (
      <div className="cart-upsell-popup__addon-products">
        <div className="cart-upsell-popup__addon-products__title">
          Complete Your Order
        </div>
        <div className="cart-upsell-popup__addon-products__grid">
          {this.state.products.map(({ type, handle, title }) => (
            <Fragment>
              {type === 'laces' && (
                <Laces
                  mainProduct={this.props.product}
                  handle={handle}
                  title={title}
                />
              )}

              {type === 'insole' && (
                <Insole
                  mainProduct={this.props.product}
                  handle={handle}
                  title={title}
                />
              )}

              {type === 'socks' && (
                <Socks
                  mainProduct={this.props.product}
                  handle={handle}
                  title={title}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    )
  }
}

export default AddonProducts