/** @jsx h */

import { h, Component, Fragment, createRef } from "preact";

class Swatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: null,
      imgAlt: null,
      color: null
    };
  }

  componentDidMount() {
    this.setImage();
    this.setColor();
  };

  getMetafieldValue = (data, key, namespace) => {
    if ((data) && (data.node) && (data.node.metafields)) {
      let listMetafield = data.node.metafields.edges ? data.node.metafields.edges : data.node.metafields
      const getProperty = (object, property) => object.node ? object.node[property] : object[property]

      for (var idx = 0; idx < listMetafield.length; idx++) {
        if (
          listMetafield[idx] &&
          (getProperty(listMetafield[idx], 'key') == key) &&
          (getProperty(listMetafield[idx], 'namespace') == namespace)
        ) {
          return getProperty(listMetafield[idx], 'value');
        }
      }
    }

    return '';
  };

  isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  stringToJSON = (str) => {
    if (this.isJsonString(str)) {
      return JSON.parse(str);
    } else {
      return {};
    }
  }

  getMetaImageSrc = (data) => {
    const arrayValue = this.stringToJSON(data)
    if ((arrayValue.length > 0) && (arrayValue[0]) && (arrayValue[0]['cloudinary_src'])) {
      return arrayValue[0]['cloudinary_src'] + 'w_50'
    } else {
      return ''
    }
  };

  changeImg = (src, width) => {
    return src
      .replace('.png?v=', `_${width}x.png?v=`)
      .replace('.jpg?v=', `_${width}x.jpg?v=`)
  }

  setImage = () => {
    const metaImageData = this.getMetafieldValue(this.props.productData, 'image', 'swatch')
    const metaImage = this.getMetaImageSrc(metaImageData)
    // const metaImage = 'https://cdn.shopify.com/s/files/1/2495/5044/products/swatch-black-and-red.png?v=1627451014'
    if (metaImage) {
      this.setState({
        imgSrc : metaImage,
        imgAlt : 'Cariuma'
      })
    } else if ((this.props.productData) && (this.props.productData.node.images)) {
      this.props.productData.node.images.edges.forEach((image) => {
        if (image.node.altText == "swatch") {
          this.setState({
            imgSrc : changeImg(image.node.originalSrc, '50'),
            imgAlt : 'Cariuma'
          })
        }
      })
    }
  };

  setColor = () => {
    const metaColor = this.getMetafieldValue(this.props.productData, 'color', 'swatch')
    // const metaColor = '#000'
    if (metaColor) {
      this.setState({
        color : metaColor
      })
    }
  };

  render() {
    return (
      <Fragment>
        {
          this.state.imgSrc ?
            <div class="c-upsell__topImages"
              onClick={() => {
                this.props.changeColor(this.props.productData.node.id, this.props.productData);
              }}
            >
              <img
                class="c-upsell__topImage"
                loading="lazy"
                index={this.props.index}
                src={this.state.imgSrc}
                alt={this.state.imgAlt}
                width="24"
                height="24"
              />
            </div>
          :
            <div
              class={`color color-${this.props.index + 1} color-${this.props.productData.node.handle} ${this.state.color ? '' : 'color--border'}`}
              style={this.state.color ? { backgroundColor: this.state.color } : {}}
              onClick={() => {
                this.props.changeColor(this.props.productData.node.id, this.props.productData);
              }}
            >
            </div>
        }
      </Fragment>
    );
  }
}

export default Swatch;
