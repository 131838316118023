import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const init = el => {
  const totalSlides = el.querySelectorAll('.swiper-slide').length
  if (totalSlides < 4) el.setAttribute('data-total-slides', totalSlides)

  const swiper = new Swiper(el.querySelector('.swiper'), {
    modules: [
      Navigation,
      Pagination
    ],
    cssMode: false,
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: true,
    speed: 600,
    navigation: {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev')
    },
    pagination: {
      el: el.querySelector('.swiper-pagination'),
      clickable: true
    },
    breakpoints: {
      767: {
        slidesPerView: 2,
        slidesPerGroup: 2
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3
      }
    }
  })
}

export default () => {
  document.querySelectorAll('#js-instaSlider').forEach(el => init(el))
}
