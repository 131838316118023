import Swiper, { Autoplay, Pagination } from "swiper"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import inlineVideoPlayer from "./inline-video-player"

const init = (el) => {
  const slides = el.querySelectorAll('.swiper-slide')

  if (slides.length < 2) {
    const video = el.querySelector('video')

    if (video) {
      el.classList.add('has-video')
      inlineVideoPlayer()
      video.play()
    }

    return
  }

  const autoplayDelay = Number(el.dataset?.autoplayDelay)
  const carousel = new Swiper(el.querySelector('.swiper'), {
    modules: [Autoplay, Pagination],
    ...(autoplayDelay ? {
      autoplay: {
        delay: autoplayDelay * 1000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }
    }: {}),
    pagination: {
      el: el.querySelector('.swiper-pagination'),
      clickable: true
    },
    cssMode: false,
    slidesPerView: 1,
    loop: true,
    speed: 600
  })

  const videos = el.querySelectorAll('video')

  if (videos.length) {
    el.classList.add('has-video')
    inlineVideoPlayer()

    carousel.on('slideChangeTransitionEnd', () => {
      videos.forEach(video => video.pause())
      const currentSlide = carousel.slides[carousel.activeIndex];
      const video = currentSlide.querySelector('video')

      if (video) {
        video.play()
      }
    })

    const initialSlide = carousel.slides[carousel.activeIndex];
    const initialVideo = initialSlide.querySelector('video')

    if (initialVideo) {
      initialVideo.play()
    }
  }
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-home-hero:not(.is-initialized)').forEach(el => {
      el.classList.add('is-initialized')

      init(el)
    })
  })
}