/** @jsx h */

import { h, Component } from 'preact'

export default class AddonLacesSizeSelector extends Component {
  componentDidMount() {
    this.setSelectedVariant()
  }

  componentDidUpdate(previousProps) {
    if (previousProps.selectedProduct !== this.props.selectedProduct) {
      this.setSelectedVariant()
    }
  }

  setSelectedVariant() {
    const laceSize = this.getSize()
    const variants = this.props.selectedProduct?.node.variants.edges

    if (!variants) {
      return
    }

    const selectedVariant = variants.find((variant) => parseInt(variant.node.selectedOptions[2].value) === laceSize) ?? variants[0]

    this.props.onSelectVariant(selectedVariant)
  }

  getSize() {
    const size = parseInt(this.props.mainProductSize)
    const gender = this.props.mainProductGender
    let laceSize

    if (gender == "Women") {
      if (size >= 5 && size <= 6.5) {
        laceSize = 1
      } else if (size >= 7 && size <= 9) {
        laceSize = 2
      } else if (size >= 9.5 && size <= 11.5) {
        laceSize = 3
      } else if (size >= 12 && size <= 14.5) {
        laceSize = 4
      }
    } else {
      //men
      if (size >= 3.5 && size <= 5) {
        laceSize = 1
      } else if (size >= 5.5 && size <= 7.5) {
        laceSize = 2
      } else if (size >= 8 && size <= 10) {
        laceSize = 3
      } else if (size >= 10.5 && size <= 13) {
        laceSize = 4
      }
    }

    return laceSize
  }

  render() {
    return ''
  }
}