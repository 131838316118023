/** @jsx h */

import { h, Component, Fragment } from 'preact'
import { graphQLRequest } from '../../collection/request'
import OptionsContainer from './OptionsContainer'
import Swatch from '../AB/swatch'
import { formatMoney, attempDecode } from './utils'

class Socks extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: null,
      selectedProduct: null,
      selectedSize: '1',
      isAddedToCart: false,
      isAddingToCart: false
    }

    this.getData()
  }

  getData = () => {
    if (this.props.handle) {
      this.useDataBasic(this.props.handle)
    }
  }

  useDataBasic = async (handle) => {
    const collectionDataQuery = /* GraphQL */ `
      query collectionByHandle($handle: String!) {
        collectionByHandle(handle: $handle) {
          title
          products(first: 30) {
            edges {
              node {
                id
                title
                handle
                alternativeTitle: metafield(namespace: "c_f", key: "name") {
                  value
                }
                images(first: 20) {
                  edges {
                    node {
                      originalSrc
                      altText
                    }
                  }
                }
                variants(first: 30) {
                  edges {
                    node {
                      id
                      sku
                      title
                      price
                      availableForSale
                      quantityAvailable
                      currentlyNotInStock
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                metafields(first: 50) {
                  edges {
                    node {
                      namespace
                      key
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    `

    this.setState({ loading: true })
    const data = await graphQLRequest(collectionDataQuery, { handle: 'socks' })

    if (data.errors?.length > 0) {
      console.log('Shopify API Error')
    } else {
      try {
        const products = data.data.collectionByHandle.products.edges.filter(({ node: product }) => {
          return product.handle.startsWith(this.props.handle)
        }).map(({ node: product }) => {
          const clonedProduct = {
            ...product,
            id: attempDecode(product.id),
            variants: {}
          }

          clonedProduct.variants.edges = product.variants.edges.map(({ node: variant }) => {
            return {
              node: {
                ...variant,
                id: attempDecode(variant.id)
              }
            }
          })

          return {
            node: clonedProduct
          }
        })

        const selectedProduct = products[0].node

        this.setState({
          loading: false,
          products,
          selectedProduct
        })
      } catch (err) {
        console.log('Data Error ', err)
      }
    }
  }

  getSizeLabel(size) {
    const sizeLabelMap = {
      '1': 'XS',
      '2': 'S',
      '3': 'M',
      '4': 'L',
      '5': 'XL'
    }

    if (size in sizeLabelMap) {
      return sizeLabelMap[size]
    }

    return size
  }

  getActiveVariant() {
    if (!this.state.selectedProduct) {
      return null
    }

    const variant = this.state.selectedProduct.variants.edges.find(({ node: variant }) => (
      variant.selectedOptions[2].value === this.state.selectedSize
    ))

    return variant?.node
  }

  isSwatchDisabled(product) {
    return product.variants.edges.every(({ node: variant}) => !variant.availableForSale)
  }

  changeColor = (_index, data) => {
    this.setState({
      selectedProduct: data?.node,
      isAddedToCart: false
    })
  }

  changeSize = (value) => {
    this.setState({
      selectedSize: value,
      isAddedToCart: false
    })
  }

  addToCart = () => {
    const activeVariant = this.getActiveVariant()

    if (!activeVariant || !activeVariant.availableForSale) {
      return
    }

    this.setState({
      isAddingToCart: true
    })

    const variantId = activeVariant.id
    const size = this.getSizeLabel(activeVariant.selectedOptions[2].value)

    CartJS.addItem(variantId, 1, { size }, {
      error: () => {
        this.setState({
          isAddingToCart: false
        })
      },
      success: () => {
        localStorage.setItem('cachedSocks', JSON.stringify(variantId))

        this.setState({
          isAddingToCart: false,
          isAddedToCart: true
        }, () => {
          if (!$('.js-upsellOpener--socks').find('.hidden').hasClass('active')) {
            $('.js-upsellOpener--socks').find('.hidden').attr("class", "active");

            if(!$('.c-socks-ctr').hasClass('add-more-socks-active')) {
              $('.c-socks-ctr').addClass('add-more-socks-active')
            }
          }
        })
      }
    })
  }

  render() {
    const activeVariant = this.getActiveVariant()
    let buttonText
    let isButtonDisabled

    if (activeVariant && !activeVariant.availableForSale) {
      buttonText = 'Add to Order'
      isButtonDisabled = true
    } else {
      if (this.state.isAddingToCart) {
        buttonText = 'Adding to Order'
      } else {
        buttonText = this.state.isAddedToCart ? 'Added' : 'Add to Order'
      }

      isButtonDisabled = false
    }

    return (
      <div className="cart-upsell-popup__addon-product">
        {!this.state.loading && this.state.products && (
          <Fragment>
            <div className="cart-upsell-popup__addon-product__image">
              {this.state.selectedProduct.images.edges.length >= 1 && (
                <picture>
                  <img
                    className="cart-upsell-popup__addon-product__img"
                    src={this.state.selectedProduct.images.edges[0].node.originalSrc}
                    data-srcset=""
                    alt={this.state.selectedProduct.images.edges[0].node.altText}
                  />
                </picture>
              )}
            </div>

            <div className="cart-upsell-popup__addon-product__content">
              <div className="cart-upsell-popup__addon-product__title-price">
                <div className="cart-upsell-popup__addon-product__title">
                  {this.props.title}
                </div>
                <div className="cart-upsell-popup__addon-product__price">
                  {formatMoney(activeVariant.price)}
                </div>
              </div>

              <div className="cart-upsell-popup__addon-product__options cart-upsell-popup__addon-product__options--color">
                <OptionsContainer numItemsToMove={4}>
                  {this.state.products.map((productData, index) => {
                    return (
                      <button
                        key={productData.node.id}
                        class={
                          `cart-upsell-popup__addon-product__option
                          ${this.state.selectedProduct == productData.node ? 'is-active' : ''}
                          ${this.isSwatchDisabled(productData.node) ? 'is-disabled' : ''}`}
                        data-key={productData.node.id}
                      >
                        <Swatch
                          productData={productData}
                          index={index}
                          changeColor={this.changeColor}
                        />
                      </button>
                    )
                  })}
                </OptionsContainer>
              </div>

              <div class="cart-upsell-popup__addon-product__options cart-upsell-popup__addon-product__options--size">
                <OptionsContainer>
                  {this.state.selectedProduct.variants.edges.map(({ node: variant }) => {
                    const size = variant.selectedOptions[2].value

                    return (
                      <button
                        key={`size-${size}`}
                        class={`cart-upsell-popup__addon-product__option
                          ${this.state.selectedSize == size ? 'is-active' : ''}
                          ${!variant.availableForSale ? 'is-disabled' : ''}`}
                        data-key={`size-${size}`}
                        onClick={() => this.changeSize(size)}
                      >
                        {this.getSizeLabel(size)}
                      </button>
                    )
                  })}
                </OptionsContainer>
              </div>
            </div>

            <button
              className="cart-upsell-popup__addon-product__button"
              onClick={this.addToCart}
              disabled={isButtonDisabled}
            >
              {buttonText}
            </button>
          </Fragment>
        )}
      </div>
    )
  }
}

export default Socks
