/**
 *
 * @prettier
 */

import  { h, Fragment } from 'preact';
/** @jsx h */
import { useEffect, useRef } from 'preact/hooks';
import Product from './Product';
import Swiper, { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

const ProductSlider = ({
        collections: collections,
        productNameHeadingLevel : productNameHeadingLevel,
        collectionHandle : collectionHandle,
        superPlp,
        isMobile
})=>{
    if (collections.status === 'ERROR') {
		return 'Unexpected error occurred. Please try again in a few minutes.';
    }
    if (collections.status === 'LOADING') {
		return null;
    }else{
      const igCollections = collections.products.filter(each=>each.tags.includes('IGImage'));
      const sliderRef = useRef()

      useEffect(() => {
        if (sliderRef.current) {
          setTimeout(() => {
            const swiper = new Swiper(sliderRef.current.querySelector('.swiper'), {
              modules: [
                Pagination,
                Navigation
              ],
              cssMode: false,
              slidesPerView: 1,
              slidesPerGroup: 1,
              loop: true,
              speed: 600,
              pagination: {
                el: sliderRef.current.querySelector('.swiper-pagination'),
                clickable: true
              },
              navigation: {
                nextEl: sliderRef.current.querySelector('.swiper-button-next'),
                prevEl: sliderRef.current.querySelector('.swiper-button-prev')
              },
              breakpoints: {
                599: {
                  slidesPerView: 2,
                  slidesPerGroup: 2
                },
                1024: {
                  slidesPerView: 3,
                  slidesPerGroup: 3
                }
              }
            })
          }, 1000)
        }
	},[isMobile]);
    return (
      <div class="c-product-slider">
        <div class="c-lanInsta__slider__wrap">
          <div class="c-product-slider-title">
              <div class="collection-name c-h54">
                 {"@Cariuma"}
              </div>
              <div class="c-sneaker__headingPara">
                  {"Shop the Look"}
              </div>
          </div>
          <div class="c-lanInsta__slider">
            <div key={`slider-mobile-${isMobile}`} ref={sliderRef} id="js-productSlider" data-total-slides={igCollections.length}>
              <div class="swiper">
                <div class="swiper-wrapper">
                  {igCollections.map((product)=>{
                    return (
                      <div class="swiper-slide">
                        <div className="c-lanInsta__sliderItem">
                          <Product
                            product={product}
                            collectionHandle={collectionHandle}
                            key={product.handle}
                            headingLevel={productNameHeadingLevel}
                            imageIGProduct={true}
                            hideLabel={true}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="swiper-pagination"></div>
              <div className="swiper-button swiper-button-prev"></div>
              <div className="swiper-button swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductSlider;
