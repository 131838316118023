/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact';
import { getVariantInventoryQuantity, isRoundedCorner } from './utils'
import getUrl from '@/scripts/utils/get-url'

const languages = window.pdpLanguages ?? {}

class SizeDropdownUnisex extends Component {
	constructor(props) {
		super(props);
		this.selectRef = createRef();
		this.state = {
			dropdownOpen: false,
			currentSize: undefined,
			lastFewMessageMobile : false,
			lastOneMessageMobile : false
		};
	}

	componentDidMount() {
		this.handleSizeGuideClick()

		const initialFilteredAllNotAvaliable = this.props.product.node.variants.edges.filter(each => !each.node.availableForSale)
		const initialFilteredNotAvaliable = this.props.product.node.variants.edges.filter(each => each.node.availableForSale)
		document.addEventListener("keydown", this.keyDown, false);

		if (initialFilteredNotAvaliable.length > 0) {
			this.setState({
				currentSize: initialFilteredNotAvaliable[0].node.selectedOptions[2].value
			},()=>{
				this.props.CurrentVariant( initialFilteredNotAvaliable[0], "node")
			});
		} else {
			//if all product's size's are not availableForSale
			this.setState({
				currentSize: this.props.sizeOption.values[0]
			},()=>{
				this.props.CurrentVariant(initialFilteredAllNotAvaliable[0] , "node")
			});
		}

    this.variants = this.getVariants();

    if (this.props.currentSize) {
      const currentVariant = this.variants.filter(variant => variant.size == this.props.currentSize);
      this.sizeSelected(currentVariant[0])
      this.showMessage(currentVariant[0])

      return
    }

		if(localStorage.getItem('currentTshirtSize')){
      let currentSize = localStorage.getItem('currentTshirtSize');
			let currentVariant = this.variants.filter(variant => variant.size == currentSize);

      if (currentVariant.length) {
        this.sizeSelected(currentVariant[0]);
        this.showMessage(currentVariant[0]);
      }
	  }

    window.sizeSelector = {
      variants: this.variants
    }
    $(document).trigger('sizeselectorunisex:ready', [this.variants])
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.currentSize !== prevState.currentSize) {
			const select = this.selectRef.current;
			select.dispatchEvent(new Event('change'));
			localStorage.setItem('currentTshirtSize',this.state.currentSize );
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyDown, false);
	}

	keyDown = (event) => {
		if (event.keyCode === 27) {
			// Escape button pressed
			this.closeDropdown();
		}
	};

	isMen() {
		return !window.location.pathname.includes('women')
    }

	actualVariants = () => {
		let actualVariants = this.props.product.node.variants.edges;
		return actualVariants.map(variant => ({
			'id': variant.node.id,
			'size': variant.node.selectedOptions[2].value,
			'inventoryQuantity': variant.node.quantityAvailable,
			'inventoryPolicy': variant.node.currentlyNotInStock,
			'available': variant.node.availableForSale,
			'productID'  : this.props.product.node.id,
      'PRE_ORDER_DATE': variant.node.PRE_ORDER_DATE?.value,
      'BACKORDER_DATE': variant.node.BACKORDER_DATE?.value,
			'PROMISE_DATE': variant.node.PROMISE_DATE?.value,
      'price': variant.node.price
		}));
	};

	getVariants = () => {
			return [
				...this.actualVariants(),
			];
	};

	openDropdown = () => {
		this.setState({
			dropdownOpen: true
		});
	};

	closeDropdown = () => {
		this.setState({
			dropdownOpen: false
		});
	};

	toggleDropdown = () => {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	};

	dropdownClicked = () => {
		this.toggleDropdown();
	};

	sizeSelected = (variant) => {
		// console.log('variant', variant);
		// adding this for showing selected size to parent comm.
		this.setState({
			currentSize: variant.size,
		},()=>{
			this.props.CurrentVariant(variant,"notNode");
		});
		this.closeDropdown();
	};

	showMessage = (variant) => {
		const totalQuantity = getVariantInventoryQuantity(variant)

			if (totalQuantity <= 7 && totalQuantity >= 2) {
				console.log("i am in 7")
				this.setState({
					lastFewMessageMobile : true
				});
			} else {
				this.setState({
					lastFewMessageMobile : false
				})
			}

			if (totalQuantity == 1) {
				console.log("i am in 2")
				this.setState({
					lastOneMessageMobile : true
				})
			} else {
				this.setState({
					lastOneMessageMobile : false
				})
			}

	}
	getMessage = (variant) => {
		const totalQuantity = getVariantInventoryQuantity(variant)
		const quantityThreshold = 3;
		if (totalQuantity <= 0 && !variant.inventoryPolicy) {
			return 'Notify Me';
		} else if (totalQuantity <= quantityThreshold) {
			return `${totalQuantity} Left`;
		}
	};

	handleSizeGuideClick = () => {
		var $html = $('html'),
				$allPopups = $('.popup'),
				$overlay = $('.overlay');

		// Size Guide Popup
		var $sizeGuideTrigger = $('.js-size-guide-trigger'),
				$sizeGuidePopup = $('.popup__size-guide');

		// Trigger popup
		$sizeGuideTrigger.on('click', function(e) {
			e.preventDefault();
			$html.addClass('popup-shown');
			$allPopups.removeClass('shown');
			const target = $(this).attr('data-popup-target')
			if (target && $(`[data-popup="${target}"]`).length > 0) {
				$sizeGuidePopup.removeClass('shown');
				$(`[data-popup="${target}"]`).addClass('shown');
			} else {
				$sizeGuidePopup.addClass('shown');
			}
			$overlay.addClass('shown');
			slate.popUpShown = true;
		});

		// Close popups on button click
		$allPopups.on('click', 'a.close', function(e) {
			e.preventDefault();
			$html.removeClass('popup-shown');
			$allPopups.removeClass('shown');
			$overlay.removeClass('shown');
			slate.popUpShown = false;
		});
	}

	render() {
		return (
			<Fragment>
				<div class="c-pdp__sizeHead">
					<span class="c-pdp__label c-pdp__label--header">
						{this.props.isKids ? languages.size_label : languages.size_us_label}
            <span class="c-pdp__textUpper">
							{this.state.productData !== null ? "" : languages.select_size}
							{this.props.isKids && "KIDS US"} { this.state.currentSize}
							<span class="c-pdp__sizeText"></span>
						</span>
					</span>

					<div class="c-pdp__urgencyMessageContainer hidden-xs">
						{
					       this.state.lastFewMessageMobile ? <span className="c-pdp__lastFewMessage">{languages.stock_last_few_message}</span> : null
					    }
						{
					       this.state.lastOneMessageMobile ? <span className="c-pdp__lastOneMessage">{languages.stock_last_one_message}</span> : null
					    }
					</div>

					{this.props.isKids ?
						<div class="c-pdp__label--size-wrapper">
							<div class="fp-root pdp-alt-hide-mobile" data-product-id={this.props.product.node.id}></div>
							<span class="c-pdp__label c-pdp__label--size">
								<a href={getUrl('/pages/size-fit-guide')} class="js-size-guide-trigger c-pdp__sizeGuide" data-popup-target="kids">
                  {languages.size_fit_guide}
								</a>
							</span>
						</div>
					: 
						<span class="c-pdp__label c-pdp__label--size">
							<a href={getUrl('/pages/size-fit-guide')} class="js-size-guide-trigger c-pdp__sizeGuide">
                {languages.size_fit_guide}
							</a>
						</span>
					}
				</div>

				<div class="c-pdp__urgencyMessageContainer hidden-xs">
					{
				       this.state.lastFewMessageMobile ? <span className="c-pdp__lastFewMessage">{languages.stock_last_few_message}</span> : null
				    }
					{
				       this.state.lastOneMessageMobile ? <span className="c-pdp__lastOneMessage">{languages.stock_last_one_message}</span> : null
				    }
				</div>

				<select id={'SingleOptionSelector-2'}
					data-single-option-selector
					data-index="option3"
					ref={this.selectRef}
					value={this.state.currentSize}
					hidden={true}
				>
					{this.variants && this.variants.map((variant,index) => (
						<option key={variant.id + index} value={variant.size}>{variant.size}</option>
					))}
				</select>
				<div className={'dropdown'} tabIndex="0" style={{ outline: 'none' }} onBlur={this.closeDropdown}>
					<div className={'box'} onClick={this.dropdownClicked}>{this.state.currentSize}</div>
					<div className={'menu'} hidden={!this.state.dropdownOpen}>
						{this.variants && this.variants.map((variant, index) => (
							<div
							key={variant.id+index}
							 className={`${isRoundedCorner(this.variants.length, index + 1)} variant ${variant.size === this.state.currentSize && 'selected'} ${this.getMessage(variant) === 'Notify Me' ? 'soldout' : ''} `}  onClick={() => { this.sizeSelected(variant); this.showMessage(variant);}}>
								<span className={'size'}>{variant.size}</span>
								<span className={'message'}>{this.getMessage(variant)}</span>
							</div>
						))}
					</div>
				</div>
				{this.props.isKids && <div class="fp-root pdp-alt-hide-desktop" data-product-id={this.props.product.node.id}></div>}

			</Fragment>
		);
	}
}


export default SizeDropdownUnisex;
