/**
 *
 * @prettier
 */

import { h } from 'preact';
/** @jsx h */
import { useMemo } from 'preact/hooks';
import {  i18n, getCountrySize } from './utils';

let filterOptions;
if(document.getElementsByClassName('js-collectionPageFilters').length){
    filterOptions = JSON.parse(document.getElementsByClassName('js-collectionPageFilters')[0].dataset.filters).options;
}


export const ActiveFilter = ({activeFilters, clearFilters, setFilter, isDesktop}) => {
    const activeFiltersFlat = useMemo(
		() =>
			[].concat(
				...Object.entries(activeFilters).map(([key, val]) =>
					(val || []).map((option) => {
                        if(key.includes('color') && filterOptions){
                            for(let i = 0; i < filterOptions.length; i++){
                                if(filterOptions[i].name === option){
                                    return [key, option, filterOptions[i].color]
                                }
                            }
                        } else {
                            return [key, option]
                        }
					}),
				),
			),
		[activeFilters],
    );
    
    return(
        <div>
            {activeFiltersFlat.length > 0 && (
                <div class={`c-collectionFilters__active ${isDesktop ? 'c-collectionFilters__active--desktop' : ''}`}>
                    <div class="c-collectionFilters__activeLeft">
                        <div class={`c-collectionFilters__activeLabel ${isDesktop ? 'c-collectionFilters__activeLabel--desktop' : ''}`}>{ window.plpLanguages?.viewing }</div>
                        {activeFiltersFlat.map(([filter, option, color]) => option && (
                            <button
                                key={option}
                                onClick={() =>
                                    setFilter(
                                        filter,
                                        activeFilters[filter].filter((val) => val !== option),
                                    )
                                }
                                class={
                                    `c-collectionFilters__activeButton
                                    ${isDesktop ? 'c-collectionFilters__activeButton--desktop' : ''}
                                    ${color ? 'c-collectionFilters__activeButton--color' : ''}
                                `}
                            >
                                {color &&
                                    <div
                                        class="c-collectionFilters__activeButtonColor"
                                        data-color={color}
                                        style={{
                                            backgroundColor: color,
                                        }}
                                    />
                                }
                                {filter === 'size' ? getCountrySize(option) : option}
                            </button>
                        ))}
                    </div>

                    <div class="c-collectionFilters__activeRight">
                        <button
                            class={`c-collectionFilters__activeRightButton ${isDesktop ? 'c-collectionFilters__activeRightButton--desktop' : ''}`}
                            onClick={clearFilters}
                        >
                            {i18n('collections.general.clear_all')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}
