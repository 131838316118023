/** @prettier */

export const shopAllCollections = ['men', 'women' , 'skate-men', 'skate-women', 'gift-guide-men', 'gift-guide-women' ];

export const barData = {
	menCollections: [
		'all',
		'men',
		'the-oca-low',
		'the-oca-low-mens',
		'oca-low-super-plp-men',
		'the-catiba-low',
		'the-oca-high-for-men',
		'the-oca-therma-men',
		'the-catiba-low-mens',
		'the-catiba-high-for-men',
		'the-oca-high',
		'the-catiba-high',
		'the-ibi-mens',
		'pantone-men',
		'all-white-sneakers-men',
		'friends-men',
		'collaborations-men',
		'pantone-fall-men',
		'the-catiba-pro-mens',
		'neutral-sneakers-men',
		'the-ibi-high-mens',
		'vegan-mens',
		'the-berrics-catiba-pro-mens',
		'cold-weather-sneakers-men',
		'premium-leather-men',
		'vegan-oca-low-mens',
		'vegan-oca-high-mens',
		'vegan-skate-shoes-men',
		'skate-men',
		'catiba-pro-high-mens',
		'regular-stance-left-foot-mens',
		'goofy-stance-right-foot-mens',
		'single-skate-shoe-mens',
		'ibi-family-men',
		'colorful-sneakers-men',
		'black-sneakers-men',
		'grey-sneakers-men',
		'blue-sneakers-men',
		'rose-sneakers-men',
		'green-sneakers-men',
		'oca-family-men',
		'catiba-pro-family-men',
		'oca-low-super-plp-men',
		'ibi-slip-on-mens',
		't-shirt-mens',
		'extra',
		'sustainability-men',
		'extra-men',
		'neutral-sneakers-men',
		'camel-sneakers-men',
		'suede-sneakers-men',
		'4ocean-sneaker-men',
		'all-black-ibi-sneakers-men',
		'yellow-sneakers-men',
		'high-tops-men',
		'red-sneakers-men',
		'the-vegan-men',
		'the-vallely-men',
		'cariuma-mil-men',
		'the-ibi-mens-plp',
		'ibi-low-super-plp-men',
		'gift-guide-for-the-skater-men',
		'gift-guide-for-the-sneaker-loyalist-men',
		'gift-guide-for-the-env-men',
		'gift-guide-for-the-vintage-head-men',
		'gift-guide-for-the-connoisseur-men',
		'gift-guide-men',
		'caturi-men',
		'salvas-men',
		'cariuma-x-svb-men',
		'cariuma-x-razer-men',
		'pastel-sneakers-men',
		'vegan-collaborations-men',
    'slip-on-skate-pro-men',
    'canvas-sneakers-men',
    'skate-sneakers-men',
    'bamboo-sneakers-men',
    'new-arrivals-men',
    'last-chance-men',
    'peanuts-sneaker-men',
    'national-geographic-cariuma-men',
		'back-to-school-mens'
	],

	womenCollections: [
		'women',
		'the-oca-low-womens',
		'the-oca-low-womens-1',
		'oca-low-super-plp-women',
		'the-oca-high-for-women',
		'the-oca-therma-women',
		'the-catiba-low-womens',
		'the-catiba-high-for-women',
		'the-ibi-womens',
		'pantone-women',
		'all-white-sneakers-women',
		'friends-women',
		'collaborations-women',
		'pantone-fall-women',
		'the-catiba-pro-womens',
		'neutral-sneakers-women',
		'the-ibi-high-womens',
		'vegan-womens',
		'the-berrics-catiba-pro-womens',
		'cold-weather-sneakers-women',
		'premium-leather-women',
		'vegan-oca-low-womens',
		'vegan-oca-high-womens',
		'vegan-skate-shoes-women',
		'skate-women',
		'catiba-pro-high-womens',
		'regular-stance-left-foot-womens',
		'goofy-stance-right-foot-womens',
		'single-skate-shoe-womens',
		'ibi-family-women',
		'colorful-sneakers-women',
		'black-sneakers-women',
		'grey-sneakers-women',
		'blue-sneakers-women',
		'rose-sneakers-women',
		'green-sneakers-women',
		'oca-family-women',
		'catiba-pro-family-women',
		'oca-low-super-plp-women',
		'ibi-slip-on-womens',
		't-shirt-womens',
		'extra',
		'sustainability-women',
		'extra-women',
		'neutral-sneakers-women',
		'camel-sneakers-women',
		'suede-sneakers-women',
		'4ocean-sneaker-women',
		'all-black-ibi-sneakers-women',
		'yellow-sneakers-women',
		'high-tops-women',
		'red-sneakers-women',
		'the-vegan-women',
		'the-vallely-women',
		'cariuma-mil-women',
		'the-ibi-womens-plp',
		'ibi-low-super-plp-women',
		'gift-guide-for-the-skater-women',
		'gift-guide-for-the-sneaker-loyalist-women',
		'gift-guide-for-the-env-women',
		'gift-guide-for-the-vintage-head-women',
		'gift-guide-for-the-connoisseur-women',
		'gift-guide-women',
		'caturi-women',
		'salvas-women',
		'cariuma-x-svb-women',
		'cariuma-x-razer-women',
		'pastel-sneakers-women',
		'vegan-collaborations-women',
    'slip-on-skate-pro-women',
    'canvas-sneakers-women',
    'skate-sneakers-women',
    'bamboo-sneakers-women',
    'new-arrivals-women',
    'last-chance-women',
    'peanuts-sneaker-women',
    'national-geographic-cariuma-women',
		'back-to-school-womens'
	],
};

export const oppositeGender = {
	// for other men <-> women will be replaced in the handle

	all: 'women',
	'the-oca-low': 'the-oca-low-womens',
	'the-catiba-low': 'the-catiba-low-womens',
	'the-oca-low-womens-1': 'the-oca-low-mens',
};

export const collectionsList = [
	// if name is not provided names from above will be used
	// current gender will be linked

	{ handle: 'men', name: 'Shop All', active: ['all', 'men', 'women'] },
	{ handle: 'the-oca-low-mens', activeContains: 'the-oca-low' },
	{ handle: 'the-oca-high-for-men', activeContains: 'the-oca-high' },
	{ handle: 'the-oca-therma-men', activeContains: 'the-oca-therma-women' },
	{ handle: 'caturi-men',  activeContains: 'caturi-women'},
	{ handle: 'the-catiba-pro-mens', activeContains: 'the-catiba-pro' },
	{ handle: 'catiba-pro-high-mens', activeContains: 'catiba-pro-high-womens'},
	{ handle: 'the-vallely-men', activeContains: 'the-vallely-women'},
	{ handle:  'ibi-slip-on-mens', activeContains: 'ibi-slip-on-womens'},
	{ handle: 'the-ibi-mens', activeContains: 'the-ibi' },
	{ handle: 'the-ibi-high-mens', activeContains: 'the-ibi-high'},
	{ handle: 'collaborations-men', activeContains: 'collaborations' },
	{ handle: 'the-berrics-catiba-pro-mens', activeContains: 'the-berrics-catiba-pro-womens'},
	{ handle: 't-shirt-mens', activeContains: 't-shirt-womens'},
	{ handle: 'pantone-fall-men', activeContains: 'pantone' },
	// { handle: 'the-catiba-low-mens', activeContains: 'the-catiba-low' },
	// { handle: 'the-catiba-high-for-men', activeContains: 'the-catiba-high' },
	{ handle: 'cariuma-mil-men', activeContains: 'cariuma-mil-women'},
	{ handle: 'vegan-mens', activeContains: 'vegan-womens'},
	{ handle: 'cold-weather-sneakers-men', activeContains: 'cold-weather-sneakers-women'},
	{ handle: 'premium-leather-men', activeContains: 'premium-leather-women'},
	{ handle: 'vegan-oca-low-mens', activeContains: 'vegan-oca-low-womens'},
	{ handle: 'vegan-oca-high-mens', activeContains: 'vegan-oca-high-womens'},
	{ handle: 'skate-men', activeContains: 'skate-women'},
	{ handle: 'regular-stance-left-foot-mens', activeContains: 'regular-stance-left-foot-womens'},
	{ handle: 'goofy-stance-right-foot-mens', activeContains: 'goofy-stance-right-foot-womens' },
	{ handle: 'single-skate-shoe-mens', activeContains: 'single-skate-shoe-womens'},
	{ handle: 'ibi-family-men', activeContains: 'ibi-family-women'},
	{ handle: 'colorful-sneakers-men', activeContains: 'colorful-sneakers-women'},
	{ handle: 'black-sneakers-men', activeContains: 'black-sneakers-women'},
	{ handle: 'grey-sneakers-men', activeContains: 'grey-sneakers-women'},
	{ handle: 'blue-sneakers-men', activeContains: 'blue-sneakers-women'},
	{ handle: 'rose-sneakers-men', activeContains: 'rose-sneakers-women'},
	{ handle: 'green-sneakers-men', activeContains: 'green-sneakers-women'},
	{ handle: 'oca-family-men', activeContains: 'oca-family-women'},
	{ handle: 'catiba-pro-family-men', activeContains: 'catiba-pro-family-women'},
	{ handle: 'oca-low-super-plp-men', activeContains: 'oca-low-super-plp-women'},
	{ handle: 'extra', activeContains: 'extra'},
	{ handle: 'sustainability-men', activeContains: 'sustainability-women'},
	{ handle: 'extra-men', activeContains: 'extra-women'},
	{ handle: 'neutral-sneakers-men', activeContains: 'neutral-sneakers-women'},
	{ handle: 'camel-sneakers-men', activeContains: 'camel-sneakers-women'},
	{ handle: 'suede-sneakers-men', activeContains: 'suede-sneakers-women'},
	{ handle: '4ocean-sneaker-men', activeContains: '4ocean-sneaker-women'},
	{ handle: 'yellow-sneakers-men', activeContains: 'yellow-sneakers-women'},
	{ handle: 'high-tops-men', activeContains: 'high-tops-women'},
	{ handle: 'red-sneakers-men', activeContains: 'red-sneakers-women'},
	{ handle: 'all-black-ibi-sneakers-men', activeContains: 'all-black-ibi-sneakers-women'},
	{ handle: 'the-vegan-men', activeContains: 'the-vegan-women'},
	{ handle: 'vegan-skate-shoes-men', activeContains: 'the-vegan-women'},
	{ handle: 'the-ibi-mens-plp',  activeContains: 'the-ibi-womens-plp'},
	{ handle: 'ibi-low-super-plp-men',  activeContains: 'ibi-low-super-plp-women'},
	{ handle: 'gift-guide-for-the-skater-men',  activeContains: 'gift-guide-for-the-skater-women'},
	{ handle: 'gift-guide-for-the-sneaker-loyalist-men',  activeContains: 'gift-guide-for-the-sneaker-loyalist-women'},
	{ handle: 'gift-guide-for-the-env-men',  activeContains: 'gift-guide-for-the-env-women'},
	{ handle: 'gift-guide-for-the-vintage-head-men',  activeContains: 'gift-guide-for-the-vintage-head-women'},
	{ handle: 'gift-guide-for-the-connoisseur-men',  activeContains: 'gift-guide-for-the-connoisseur-women'},
	{ handle: 'gift-guide-men', name: 'Gift Guide', active: ['gift-guide-men', 'gift-guide-women'] },
	{ handle: 'cariuma-x-svb-men',  activeContains: 'cariuma-x-svb-women'},
	{ handle: 'salvas-men',  activeContains: 'salvas-women'},
	{ handle: 'cariuma-x-razer-men',  activeContains: 'cariuma-x-razer-women'},


];

export const filtersListTagBased = [
	{ id: 'color', tagPrefix: 'Color - ', label: window.globalLanguages?.filter_labels.color },
	{ id: 'material', tagPrefix: 'Material - ', label: window.globalLanguages?.filter_labels.material },
];

export const filtersList = [
	{
		id: 'size',
		label: window.globalLanguages?.filter_labels.size_us,
		options: [
			'5',
			'5.5',
			'6',
			'6.5',
			'7',
			'7.5',
			'8',
			'8.5',
			'9',
			'9.5',
			'10',
			'10.5',
			'11',
			'11.5',
			'12',
			'12.5',
			'13',
		].map((name) => ({ name, tag_name: name })),
	},
	...filtersListTagBased,
];

export const filterSizePhantomMenSizes = ['5', '5.5', '6', '6.5', '7', '7.5'];

export const filterSizePhantomWomenSizes = ['9.5', '10', '10.5', '11', '11.5', '12', '12.5', '13'];

export const filterSizeOptionIndex = 2;

export const filterSizeOtherMap = ['3.5|33|21.5','4|34|22.0','4.5|34.5|22.5','5|35|23.0','5.5|36|23.5','6|36.5|24.0','6.5|37|24.5','7|37.5|25.0','7.5|38|25.5','8|38.5|26.0','8.5|39|26.5','9|39.5|27.0','9.5|40|27.5','10|41|28.0','10.5|41.5|28.5','11|42|29.0','11.5|43|29.5','12|43.5|30','12.5|44|30.5','13|45|31'];