/** @jsx h */

import { h, Component, Fragment } from 'preact'
import { graphQLRequest } from '../../collection/request'
import { formatMoney, attempDecode } from './utils'

class Insole extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      data: null,
      selectedProduct: null,
      isAddedToCart: false,
      isAddingToCart: false
    }

    this.getData()
  }

  getData = () => {
    if (this.props.handle) {
      this.useDataBasic(this.props.handle)
    }
  }

  useDataBasic = async (handle) => {
    const collectionDataQuery = /* GraphQL */ `
      query collectionBasicData($handle: String!) {
        collectionByHandle(handle: $handle) {
          title
          products(first: 1) {
            edges {
              node {
                id
                title
                alternativeTitle: metafield(namespace: "c_f", key: "name") {
                  value
                }
                images(first: 10) {
                  edges {
                    node {
                      originalSrc
                      altText
                    }
                  }
                }
                variants(first: 30) {
                  edges {
                    node {
                      id
                      sku
                      title
                      price
                      availableForSale
                      quantityAvailable
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    this.setState({ loading: true })
    const data = await graphQLRequest(collectionDataQuery, { handle })

    if (data.errors?.length > 0) {
      console.log('Shopify API Error')
    } else {
      try {
        const products = data.data.collectionByHandle.products.edges.map(({ node: product }) => {
          const clonedProduct = {
            ...product,
            id: attempDecode(product.id),
            variants: {}
          }

          clonedProduct.variants.edges = product.variants.edges.map(({ node: variant }) => {
            return {
              node: {
                ...variant,
                id: attempDecode(variant.id)
              }
            }
          })

          return {
            node: clonedProduct
          }
        })

        const selectedProduct = products[0].node

        this.setState({
          loading: false,
          products,
          selectedProduct
        })
      } catch (err) {
        console.log('Data Error ', err)
      }
    }
  }

  getActiveVariant() {
    if (!this.state.selectedProduct) {
      return null
    }

    const size = this.props.mainProduct.variant_options[2]
    const gender = this.props.mainProduct.variant_options[0]

    const variant = this.state.selectedProduct.variants.edges.find(variant => (
      variant.node.selectedOptions[2].value === size
        && variant.node.selectedOptions[0].value === gender
    )) || this.state.selectedProduct.variants.edges[0]

    return variant?.node
  }

  addToCart = () => {
    const activeVariant = this.getActiveVariant()

    if (!activeVariant || !activeVariant.availableForSale) {
      return
    }

    this.setState({
      isAddingToCart: true
    })

    const variantId = activeVariant.id
    const size = this.props.mainProduct.currentSelectedSize

    CartJS.addItem(variantId, 1, { size }, {
      error: () => {
        this.setState({
          isAddingToCart: false
        })
      },
      success: () => {
        const variantWithInsole = JSON.parse(localStorage.getItem('variantWithInsole')) || []
        const mainProductId = this.props.mainProduct.id + ''
        variantWithInsole.push(mainProductId)
        localStorage.setItem('variantWithInsole', JSON.stringify(variantWithInsole))
        localStorage.setItem('variantAccessoriesID', JSON.stringify(variantId))
        window.setCurrentOpen = 'insole'
        window.currentProductId = mainProductId

        this.setState({
          isAddingToCart: false,
          isAddedToCart: true
        })
      }
    })
  }

  render() {
    const activeVariant = this.getActiveVariant()
    let buttonText
    let isButtonDisabled

    if (activeVariant && !activeVariant.availableForSale) {
      buttonText = 'Out of Stock'
      isButtonDisabled = true
    } else {
      if (this.state.isAddingToCart) {
        buttonText = 'Adding to Cart'
      } else {
        buttonText = this.state.isAddedToCart ? 'Added' : 'Add to Order'
      }

      isButtonDisabled = false
    }

    return (
      <div className="cart-upsell-popup__addon-product">
        {!this.state.loading && this.state.products && (
          <Fragment>
            <div className="cart-upsell-popup__addon-product__image">
              {this.state.selectedProduct.images.edges.length >= 1 && (
                <picture>
                  <img
                    className="cart-upsell-popup__addon-product__img"
                    src={this.state.selectedProduct.images.edges[0].node.originalSrc}
                    data-srcset=""
                    alt={this.state.selectedProduct.images.edges[0].node.altText}
                  />
                </picture>
              )}
            </div>

            <div className="cart-upsell-popup__addon-product__content">
              <div className="cart-upsell-popup__addon-product__title-price">
                <div className="cart-upsell-popup__addon-product__title">
                  {this.props.title}
                </div>
                <div className="cart-upsell-popup__addon-product__price">
                  {formatMoney(activeVariant.price)}
                </div>
              </div>
            </div>

            <button
              className="cart-upsell-popup__addon-product__button"
              onClick={this.addToCart}
              disabled={isButtonDisabled}
            >
              {buttonText}
            </button>
          </Fragment>
        )}
      </div>
    )
  }
}

export default Insole
