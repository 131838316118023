/** @jsx h */

import { h, Component, Fragment, createRef } from "preact";
import { graphQLRequest } from "../../collection/request";
import $ from "jquery";
import SocksSizeSelect from "./socksSizeSelect";
import SocksImageSlider from "./socksImageSlider";
import { getPriceWithoutCurrency } from "./utils";
import { formatId, getCountryByCurrency } from "../../collection/utils";
import Cookies from "js-cookie";
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import getUrl from '@/scripts/utils/get-url'
import getLanguageCode from '@/scripts/utils/get-language-code'

class SocksAB extends Component {
  constructor(props) {
    super(props);

    const identifiers = this.reorderIdentifiers([...(window.addonUpsellSettings?.filter(({ type }) => type === 'socks') ?? [])])
    const allProducts = identifiers.reduce((data, item) => {
      data[item.value] = []

      return data
    }, {})

    this.state = {
      socksData: null,
      shoelaceSize: null,
      currentSelected: null,
      selectedVariant: null,
      Countryprice : null,
      CountrypriceWithoutTax : null,
      activeGroupIdx: 0,
      allProducts,
      identifiers,
      showSizeGuidePopup: false
    };
    
    this.elRef = createRef()

    this.swiperIns = null
    this.swiperNavIns = null

    this.useDataBasic("socks");
  }

  useDataBasic = (handle) => {
    const collectionDataQuery = /* GraphQL */ `
      query collectionBasicData($handle: String!) @inContext(country: ${getCountryByCurrency(Cookies.get("cart_currency"))}, language: ${getLanguageCode()}) {
        collectionByHandle(handle: $handle) {
          title
          products(first: 20) {
            edges {
              node {
                id
                title
                handle
                alternativeTitle: metafield(namespace: "c_f", key: "name") {
                  value
                }
                priceRange {
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                }
                images(first: 10) {
                  edges {
                    node {
                      originalSrc
                      altText
                    }
                  }
                }
                variants(first: 10) {
                  edges {
                    node {
                      id
                      sku
                      title
                      price {
                        amount
                        currencyCode
                      }
                      availableForSale
                      quantityAvailable
                      currentlyNotInStock
                      selectedOptions {
                        name
                        value
                      }
                    }
                  }
                }
                metafields(identifiers: [
                  { namespace: "swatch", key: "image" },
                  { namespace: "swatch", key: "color" }
                ]) {
                  namespace
                  value
                  key
                }
              }
            }
          }
        }
      }
    `;
    graphQLRequest(collectionDataQuery, { handle }, `${getUrl('/collections/')}${handle}?view=socks-data`)
      .then((data) => {
        if (data.errors && data.errors.length > 0) {
          console.log(
            "SHOPIFY API ERROR , CONTACT SHOPIFY SUPPORT OR ANATTA DEV TEAM"
          );
        } else {
          this.splitData(data.data.collectionByHandle)
            .then(() => {
              this.MapSocksData(this.setData());
            })
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  componentDidMount() {
    window.jQuery(document).on('cart.requestComplete', () => {
      this.setState({
        identifiers: this.reorderIdentifiers(this.state.identifiers)
      })
    })
  }

  setData = (index=0) => {
    return this.state.allProducts[this.state.identifiers[index]?.value] ?? []
  }

  setProductGroup(idx) {
    this.setState({
      activeGroupIdx: idx
    }, () => {
      this.MapSocksData(this.setData(idx));
    })
  }

  splitData = (data) => {
    return new Promise(resolve => {
      var productData = this.state.allProducts
      data.products.edges.forEach((each) => {
        if ((each.node) && (each.node.handle)) {
          this.state.identifiers.forEach((idx) => {
            if (each.node.handle.indexOf(idx.key) == 0) {
              productData[idx.value].push(each);
            }
          })
        }
      });

      const allProducts = Object.keys(productData).reduce((data, key) => {
        if (productData[key].length > 0) {
          data[key] = productData[key]
        }

        return data
      }, {})

      const identifiers = this.state.identifiers.filter(item => Object.keys(allProducts).includes(item.value))

      this.setState({
        allProducts,
        identifiers
      }, () => {
        resolve()
      })
    })
  };

  MapSocksData = (data) => {
    try{
      var mappedData = data,
        result = [];

      mappedData.forEach((each) => {
        result = [];
        each.node.id = formatId(each.node.id);

        if (each.node.variants.edges) each.node.variants = each.node.variants.edges
        each.node.variants.forEach((variant) => {
          let actualSize = variant.node.selectedOptions[2].value;
          let actualSizeInDetail;
          switch (actualSize) {
            case "XS":
              actualSizeInDetail = "US W 5 - 7 / M 3.5 - 5.5";
              break;
            case "S":
              actualSizeInDetail = "US W 7.5 - 9 / M 6 - 7.5";
              break;
            case "M":
              actualSizeInDetail = "US W 9.5 - 12 / M 8 - 10.5";
              break;
            case "L":
              actualSizeInDetail = "US W 12.5 - 14.5 / M 11 - 13";
              break;
          }

          variant.node.selectedOptions.push({
            actualSize: actualSize,
            actualSizeInDetail: actualSizeInDetail,
          });
          result.push(variant);
        });
        each.node.variants.edges = result;
      });

      const countryPrice = getPriceWithoutCurrency(mappedData[0].node.priceRange.maxVariantPrice.amount, Cookies.get("cart_currency"))

      this.setState(
        {
          socksData: mappedData,
          currentSelected: mappedData[0],
          Countryprice: countryPrice.tax,
          CountrypriceWithoutTax: countryPrice.noneTax
        },
        () => {
          this.addNewSwiper();
        }
      );
    } catch(err) {
      this.setState({
        socksData: null,
        currentSelected : null
      })
    }
  };

  componentWillUpdate(nextProps, nextState) {
    if (this.state.currentSelected !== nextState.currentSelected) {
      // this.removeSwiper();
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.removeSwiper();
    this.addNewSwiper();
  }
  addNewSwiper = () => {
    const _this = this
    const slider = this.elRef.current.querySelector('.c-upsell__productImageSocks')
    const sliderNav = this.elRef.current.querySelector('.slider-nav-socks')

    if (!slider || slider.querySelectorAll('picture').length < 2 || (this.swiperIns && !this.swiperIns.hasOwnProperty('destroyed'))) return

    _this.swiperNavIns = new Swiper(sliderNav, {
      loop: sliderNav.querySelectorAll('picture').length > 3,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true
    })

    _this.swiperIns = new Swiper(slider.querySelector('.swiper'), {
      modules: [
        Navigation,
        Thumbs
      ],
      loop: true,
      slidesPerView: 1,
      speed: 600,
      navigation: {
        nextEl: _this.elRef.current.querySelector('.swiper-button-next'),
        prevEl: _this.elRef.current.querySelector('.swiper-button-prev')
      },
      thumbs: {
        swiper: _this.swiperNavIns
      }
    })
  };
  removeSwiper = () => {
    this.swiperIns && this.swiperIns.destroy()
    this.swiperNavIns && this.swiperNavIns.destroy()
  };
  getCurrentSelected = (currentSelected) => {
    this.setState({
      currentSelected: currentSelected,
    });
  };

  reorderIdentifiers(identifiers) {
    const skateIndex = identifiers.findIndex(item => item.key.includes('skate'))
    const skateStyles = ['berrics', 'catiba-pro', 'catiba-pro-high', 'naioca', 'vallely', 'slip-on-skate']

    if (skateIndex >= 0) {
      const hasSkateItem = (window.CartJS?.cart?.items ?? []).some(item => {
        return skateStyles.some(style => item.handle.includes(style))
      })

      if (hasSkateItem) {
        const newIdentifiers = [...identifiers]
        newIdentifiers.splice(skateIndex, 1)

        return [
          identifiers[skateIndex],
          ...newIdentifiers
        ]
      } else if (this.state) {
        const originalIdentifiers = ([...(window.addonUpsellSettings?.filter(({ type }) => type === 'socks') ?? [])]).filter(item => Object.keys(this.state.allProducts).includes(item.value))

        if (originalIdentifiers[0]?.value !== identifiers[0]?.value) {
          return originalIdentifiers
        }
      }
    }

    return identifiers
  }

  render() {
    const activeIdentifier = this.state.identifiers[this.state.activeGroupIdx] ?? {}

    return (
      <Fragment>
        <div
          class="c-upsell js-upsell js-upsell--socks c-upsell__socks"
          data-cart-view="data-cart-view"
          rules="all"
        >
          <div class="c-upsell__header js-upsellClose">
            <span class="c-upsell__headerIcon">
              <img src={typeof SHOPIFY_CDN != 'undefined' && SHOPIFY_CDN + 'arrow-left_30x.png'} width="15" height="15" class="c-upsell__headerImg" />
            </span>
            BACK
          </div>

          {this.state.identifiers.length === 0 ? '' : (
            <Fragment>
              {this.state.identifiers.length > 1 ? (
                <div class="c-upsell__productTabs">
                  <div class="c-upsell__productTabs_inner">
                    {this.state.identifiers.map((item, index) => (
                      <a
                        key={`socks-upsell-tab-${item.key}`}
                        className={`c-upsell__productTab ${this.state.activeGroupIdx === index ? 'active' : ''}`}
                        data-target={item.value}
                        onClick={() => { this.setProductGroup(index) }}
                      >{item.title}</a>
                    ))}
                  </div>
                </div>
              ) : (
                <div class="c-upsell__productPrice">
                  <p class="c-upsell__productPriceName">{activeIdentifier.slideTitle}</p>
                  <p class="c-upsell__productPriceName c-taxInclusive">{this.state.Countryprice!=null ? this.state.Countryprice : '' }</p>
                  <p class="c-upsell__productPriceName c-taxExclusive">{this.state.CountrypriceWithoutTax!=null ? this.state.CountrypriceWithoutTax : '' }</p>
                </div>
              )}
            </Fragment>
          )}

          <div class="c-upsell__block">
            <div class="c-upsell__blockBorder">
              <div class="c-upsell__blockItem">
                <div class="c-upsell__product" ref={this.elRef}>
                  {this.state.currentSelected != null ? (
                    <Fragment>
                      <SocksImageSlider
                        activeGroupIdx={this.state.activeGroupIdx}
                        socksData={this.state.socksData}
                        currentSelected={this.state.currentSelected}
                        getCurrentSelected={this.getCurrentSelected}
                      />
                      <SocksSizeSelect
                        activeGroupIdx={this.state.activeGroupIdx}
                        currentSelected={this.state.currentSelected}
                        currentPrice={this.state.Countryprice}
                        currentPriceWithoutTax={this.state.CountrypriceWithoutTax}
                      />
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {activeIdentifier && (
              <div class="c-upsell__description" dangerouslySetInnerHTML={{ __html: activeIdentifier.description}}></div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SocksAB;
