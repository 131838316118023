/** @jsx h */
import { h, Component, Fragment } from 'preact'
import { formatMoney } from './utils'
import getUrl from '@/scripts/utils/get-url'

class Product extends Component {
  constructor(props) {
    super(props)
  }

  getShorthandOfSize(size) {
    return size.toLowerCase()
      .replace('women', 'w')
      .replace('men', 'm')
  }

  render() {
    const product = this.props.product

    return (
      <div class="cart-upsell-popup__product-wrapper">
        <div class="cart-upsell-popup__product">
          <div class="cart-upsell-popup__product__image">
            <img class="cart-upsell-popup__product__img" src={product.image} alt={product.title} />
          </div>
          <div class="cart-upsell-popup__product__details">
            <div class="cart-upsell-popup__product__message">
              Added to Cart
            </div>

            <div class="cart-upsell-popup__product__title">
              {product.product_title}
            </div>

            {product?.properties?.size && (
              <div class="cart-upsell-popup__product__size">
                {this.getShorthandOfSize(product.properties.size)}
              </div>
            )}

            <div class="cart-upsell-popup__product__price">
              {formatMoney(product.price)}
            </div>
          </div>
        </div>

        <div className="cart-upsell-popup__cta-wrapper">
          <a href={getUrl('/checkout')} className="cart-upsell-popup__button cart-upsell-popup__button--primary">Checkout</a>
          <button
            className="cart-upsell-popup__button cart-upsell-popup__button--secondary"
            onClick={this.props.onContinue}
          >
            Continue Shopping
          </button>
          <div className="cart-upsell-popup__shipping-message">Free Shipping & Returns</div>
        </div>
      </div>
    )
  }
}

export default Product