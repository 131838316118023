/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact';
import axios from 'axios';
import { graphQLRequest } from '../collection/request';
import { Router, route } from 'preact-router';
import SizeAndCart from './SizeAndCart';
import ImageSlider from './ImageSlider';
import Cookies from "js-cookie";
import fetch from "cross-fetch";
import { formatId, getCountryByCurrency, getPaymentsEnabled } from '../collection/utils';
import { getPrice, getProductsData, getStyleCollection, getSwatchData, getTaxAndDutiesMessage, productHasModel, isModelHidden } from './utils';
import InstallmentPayments from './InstallmentPayments';
import { formatMoney, taxInclusiveCurrencies, getPriceAmount } from '../../../utils/money'
import ModelPopup from './ModelPopup';
import PdpAdultShoes from './PdpAdults';
import { kebabCase } from 'lodash-es';
import getUrl from '@/scripts/utils/get-url'
import getLanguageCode from '@/scripts/utils/get-language-code'

const styleInfoCache = {};
const languages = window.pdpLanguages ?? {}
// TODO: Make this configurable
const BUNDLE_DISCOUNT_TYPE = 'flat';
const BUNDLE_DISCOUNT = 19;

class PdpKids extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colors: null,
			isloading : true,
			products: null,
			productData: null,
			AllProductsMappedData: null,
			currentColor: null,
			currentColorHandle: null,
			allOppositeGenderData: null,
			isMen: null,
			product_category : null,
			product_category_link : null,
			featureImage1Src : '',
			featureImage3Src : '',
			sizeViewedImg : null,
			genderSwitcherWomenLink : '',
			genderSwitcherMenLink : '',
			isProductDefault: false,
			styleData: window.styleData || [],
      styleInfo: {},
			isModelPopupOpen: false,
			adultProductData: null,
      adultShoesCollection: null,
      isAdultShoesActive: false,
      bundleStep: 1,
      bundlingMessage: '',
      bundlingProductTitle: '',
      adultStyles: [],
      pdpDisplay: null,
      kidsLineItem: {},
		}
    this.firstLoad = true
		this.dummyImages = [];
		this.dummyThumbnailImages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

		const urlParams = new URLSearchParams(window.location.search);
		this.styleParam = urlParams.get('style');

		this.getData();
	}

	getDefaultColor = (product) => {
		let productColor = ''
		let productThumbnailImage = ''

		product.options.forEach(option => {
			if (option.name === 'Colorway') {
				productColor = option.values[0]
			}
		})

		product.images.edges.forEach(image => {
			if (
        (image.node.altText && image.node.altText.includes('pdpthumbnail'))
        || image.node.originalSrc.toLowerCase().includes('thumbnail')
      ) {
				productThumbnailImage = image.node.thumbnail
			}
		})

		if (!productThumbnailImage && product.images.edges.length > 0) {
			productThumbnailImage = product.images.edges[0].node.thumbnail
		}

		return {
			url: product.onlineStoreUrl,
			handle: product.handle,
			tags: product.tags,
			color: kebabCase(productColor),
			color_title: productColor,
			thumbnail_image: productThumbnailImage
		}
	}

	productQueryParams = () => {
		return `
			id
			onlineStoreUrl
			title
			productType
			vendor
			tags
			description
			descriptionHtml
			alternativeTitle: metafield(namespace: "c_f", key: "name") {
				value
			}
			reviewWudget: metafield(namespace: "judgeme", key: "widget") {
				value
			}
			descPantone: metafield(namespace: "c_f", key: "desc-pantone") {
				value
			}
			titlePantone: metafield(namespace: "c_f", key: "title-pantone") {
				value
			}
			subTitlePantone: metafield(namespace: "c_f", key: "subTitle-pantone") {
				value
			}
			pantoneNew: metafield(namespace: "c_f", key: "PantoneNew") {
				value
			}
			preorderMessage: metafield(namespace: "info", key: "preorder_message") {
				value
			}
			videoLinks: metafield(namespace: "c_f", key: "Video-ab") {
				value
				id
			}
			modelProjectId: metafield(namespace: "3d_model", key: "project_id") {
				value
			}
			restrictedCountries: metafield(namespace: "accentuate", key: "restricted_countries") {
				value
			}
      style: metafield(namespace: "info", key: "style") {
        value
      }
      forcePageReload: metafield(namespace: "info", key: "force_page_reload") {
        value
      }
      treePlant: metafield(namespace: "info", key: "tree_plant") {
        value
      }
			options {
				name
				values
			}
			review: metafield(namespace: "judgeme", key: "badge") {
				value
			}
			priceRange {
				minVariantPrice {
					amount
					currencyCode
				}
				maxVariantPrice {
					amount
					currencyCode
				}
			}
			handle
			images(first: 20) {
				edges {
					node {
						thumbnail: transformedSrc(maxWidth :75 ,maxHeight : 75 , scale: 3)
						altText
						originalSrc
					}
				}
			}
			variants(first: 15) {
				edges {
					node {
						id
						title
						price {
							amount
							currencyCode
						}
						availableForSale
						quantityAvailable
						currentlyNotInStock
						sku
						selectedOptions {
							name
							value
						}
            PRE_ORDER_DATE: metafield(namespace: "PRE_ORDER_DATE", key: "ORDER_DATE") {
              value
            }
            BACKORDER_DATE: metafield(namespace: "BACKORDER_DATE", key: "ORDER_DATE") {
              value
            }
						PROMISE_DATE: metafield(namespace: "HC_PREORDER", key: "PROMISE_DATE") {
							value
						}
					}
				}
			}
			seo {
				title
			}
		`
	}

	useDataDefault = (handle) => {
		const productDataQuery = /* GraphQL */ `
		query productDefaultData($handle: String!) @inContext(country: ${getCountryByCurrency(window.Shopify.currency.active)}, language: ${getLanguageCode()}) {
			productByHandle(handle: $handle) {
				${this.productQueryParams()}
			}
		}`;
		graphQLRequest(productDataQuery , { handle }, `${getUrl('/collections/')}${handle}?view=data`)
			.then((data) => {
				const productObject = data.data.productByHandle
				const colorObject = this.getDefaultColor(productObject)

				const products = {
					data: {
						collectionByHandle: {
							products: {
								edges: [{
									node: {
										...productObject
									}
								}]
							}
						}
					}
				}
				const AllProductsMappedData = [{
					product: {
						node: {
							...productObject
						}
					},
					...colorObject
				}]
				this.setState({
					colors: [colorObject],
					products,
					AllProductsMappedData: AllProductsMappedData,
					allOppositeGenderData: [],
					isProductDefault: true
				}, () => { this.currentProduct(); });
			})
			.catch((e) => {
				console.error(e);
			});
	}

	useDataBasic = (handle, nonCachedAPI) => {
		const collectionDataQuery = /* GraphQL */ `
		query collectionBasicData($handle: String!) @inContext(country: ${getCountryByCurrency(Cookies.get("cart_currency"))}, language: ${getLanguageCode()}) {
			collectionByHandle(handle: $handle) {
				id
				products(first: 250) {
					edges {
						node {
							${this.productQueryParams()}
						}
					}
				}
			}
		}`;
    getProductsData(collectionDataQuery, handle, this.isMen(), nonCachedAPI)
			.then((data) => {
				if(data.errors && data.errors.length>0){
						const devEmail = "maninder@anattadesign.com";
						const errorForm = `<form id="error_fallback" style="visibility: hidden;
						position: absolute;
						width: 0px;
						height: 0px;
						opacity: -1px;"
						target="_blank"
						novalidate="novalidate" class="klaviyo_styling klaviyo_gdpr_embed_XkWcwN" action="//manage.kmail-lists.com/subscriptions/subscribe" data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe" method="GET" target="_blank" novalidate="novalidate">
						<input type="hidden" name="g" value="XkWcwN">
						<input type="hidden" name="$fields" value="$consent">
						<input type="hidden" name="$list_fields" value="$consent">
						<div class="klaviyo_field_group">
						  <label for="k_id_email">Newsletter Sign Up</label>
						  <input class="" type="email" value="${devEmail}" name="email" id="k_id_email" placeholder="Your email" />
						  <div class="klaviyo_field_group klaviyo_form_actions">
							<div class="klaviyo_helptext"> How would you like to hear from us? (please select at least one option) </div>
							<input type="checkbox" name="$consent" id="consent-email" value="email">
							<label for="consent-email">Email</label><br>
							<input type="checkbox" name="$consent" id="consent-web" value="web">
							<label for="consent-web">Online advertisements</label>
							<div class="klaviyo_helptext klaviyo_gdpr_text"> We use email and targeted online advertising to send you product and services updates, promotional offers and other marketing communications based on the information we collect about you, such as
							  your email address, general location, and purchase and website browsing history. <br>
							  <br>
							  We process your personal data as stated in our Privacy Policy {Insert privacy policy link}. You may withdraw your consent or manage your preferences at any time by clicking the unsubscribe link at the bottom of any of our marketing emails, or
							  by emailing us at {insert customer support email address}.</div>
						  </div>
						</div>
						</div>
						<div class="klaviyo_messages">
						  <div class="success_message" style="display:none;"></div>
						  <div class="error_message" style="display:none;"></div>
						</div>
						<div class="klaviyo_form_actions">
						  <button type="submit" class="klaviyo_submit_button">Subscribe</button> </div>
					  </form>`;
					$('#MainContent').append(errorForm);
					  setTimeout(() => {
						KlaviyoSubscribe.attachToForms('#error_fallback', {
							hide_form_on_success: true,
							extra_properties: {
							  $source: '$embed',
							  $method_type: "Klaviyo Form",
							  $method_id: 'embed',
							  $consent_version: 'Embed default text'
							},
							success: function() {
								console.log('error sent to dev team');
							}
						  });
						$('#error_fallback').submit();
					  }, 5000);

				}else{
					this.setState({ products: data }, () => {this.mapProductData(); });
				}

			})
			.catch((e) => {
				console.error(e);
			});

	};

	getData= (nonCachedAPI = false) =>{
		// console.log('cartjs', CartJS);
		const Pdppage = document.querySelector('#js-pdpKidsPageContent');
    let handle = Pdppage.dataset.familyCollection;

		if (this.styleParam && this.state.styleData.length > 0) {
			const style = this.state.styleData.find(style => kebabCase(style.title) === this.styleParam);
			if (style) {
				handle = style.collection;
			}
		}

    if (!handle) {
      handle = 'test'
    }

    getSwatchData(handle, this.isMen())
      .then(data => {
        this.setState({ colors: data, isMen: this.isMen() }, () => {
          if (handle === 'test') {
            this.useDataDefault(window.location.pathname.split('/').pop())
          } else {
            this.useDataBasic(handle)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })

    const adultShoesCollection = Pdppage.dataset.adultCollection

    if (adultShoesCollection) {
      this.setState({ adultShoesCollection })
    }

    const adultStyles = this.parseAdultStylesData(Pdppage.dataset.adultStyles)

    if (adultStyles) {
      this.setState({ adultStyles })
    }

    const bundlingMessage = Pdppage.dataset.bundlingMessage
    const bundlingProductTitle = Pdppage.dataset.bundlingProductTitle
    const pdpDisplay = Pdppage.dataset.pdpDisplay
    this.setState({
      bundlingMessage,
      bundlingProductTitle,
      pdpDisplay,
    })
	}

	componentDidUpdate() {
		// console.log('updateeee', this);
		$(".changeGender").click(function(){
			// window.location.replace=$(this).attr("href");
			window.location.replace($(this).attr("href"));
			return false;
	   });
	   $(".c-collectionGrid__itemLink").click(function(){
			// window.location.replace=$(this).attr("href");
			window.location.replace($(this).attr("href"));
			return false;
		 });
		 $(".js-clickable_react").click(function(){
			// window.location.replace=$(this).attr("href");
			window.location.replace($(this).attr("href"));
			return false;
		 });
	   $(".footer-gift-card").click(function(){
		// window.location.replace=$(this).attr("href");
		window.location.replace($(this).attr("href"));
		return false;
		   });

	}
	setProductCategory(currentProduct = null){
    return new Promise(async (resolve) => {
      currentProduct = currentProduct ?? this.state.AllProductsMappedData.find(product => {
        if (window.location.pathname.includes(product.product.node.handle)) {
          return true;
        }
      });

      if (!currentProduct) {
        resolve();
        return;
      }

      const styleCollection = getStyleCollection(currentProduct.product.node.style?.value, this.state.isMen, true);

      if (!styleCollection) {
        this.setState({
          styleInfo: null,
          product_category: '',
          product_category_link: ''
        }, resolve)

        return;
      }

      const styleInfo = await this.getStyleInfo(styleCollection);
      const product_category = styleInfo.styleTitle;
      const product_category_full = styleInfo.styleTitle + (styleInfo.styleAlias ? ` (${styleInfo.styleAlias})` : '');
      const product_category_link = styleInfo.url;

      this.setState({
        styleInfo,
        product_category,
        product_category_full,
        product_category_link
      }, resolve)
    })
	}

  async getStyleInfo(collectionHandle) {
    return new Promise(resolve => {
      if (collectionHandle in styleInfoCache) {
        resolve(styleInfoCache[collectionHandle]);
        return;
      }

      axios.get(getUrl('/collections/') + collectionHandle + '?view=style-info')
        .then(response => {
          styleInfoCache[collectionHandle] = response.data
          resolve(styleInfoCache[collectionHandle]);
        })
        .catch(() => {
          resolve({});
        })
    })
  }

	mapProductData() {
		var me = this;
		var AllProductData = me.state.products
		// console.log('real data', me.state.products);
		var AllProductsMappedData = [];
		var oppositeGenderData = [];

		AllProductData.data.collectionByHandle.products.edges.map((product) => {
			product.node.id = formatId(product.node.id);
			if (product.node.options[0].values[0] === (this.state.isMen !== null ? this.state.isMen ? 'Men' : 'Women' : '') || product.node.handle.includes('-kids')) {
				me.state.colors.map((color) => {
					if (product.node.options[1].values[0] == color.color_title && product.node.handle.replace(/-\men|-\women/, '') == color.handle.replace(/-\men|-\women/, '')) {
            if (product.node.handle !== color.handle) {
              return;
            }

						const mapProduct = { product, ...color }
						AllProductsMappedData.push(mapProduct);
					}
				})
			}
			else { //women
				me.state.colors.map((color) => {
					if (product.node.options[1].values[0] == color.color_title && product.node.handle.replace(/-\men|-\women/, '') == color.handle.replace(/-\men|-\women/, '')) {
            if (product.node.handle !== color.handle) {
              return;
            }

						const mapProduct = { product, ...color }
						oppositeGenderData.push(mapProduct);
					}
				})
			}

		})
		this.setState({
			AllProductsMappedData: AllProductsMappedData,
			allOppositeGenderData: oppositeGenderData
		}, () => { this.currentProduct() })
	}
	getOppositeGenderVariants(color, handle) {
		// console.log('current color', this.state.allOppositeGenderData, color, handle);
		let oppositeGenderVariants = null;
		this.state.allOppositeGenderData.map((product) => {
			if (
        product.color === color &&
        product.handle.replace(/-\men|-\women/, '') === handle.replace(/-\men|-\women/, '')
      ) {
				oppositeGenderVariants = product.product.node;
			}
		})
    return oppositeGenderVariants;
  }
	//this is for changing colours
	changecolor = (Currentproduct) => {
		if (Currentproduct?.product.node.forcePageReload?.value === 'true' || this.state.productData?.product.node.forcePageReload?.value === 'true') {
			window.location = Currentproduct.product.node.onlineStoreUrl + (this.state.isAdultShoesActive ? '?bundle=true' : '');
		} else {

			// console.log('changeed product', Currentproduct.product.node.handle);
			if (Currentproduct.product.node.seo?.title) {
				document.title = `CARIUMA: ${Currentproduct.product.node.seo.title}`;
			} else {
				document.title = `CARIUMA: ${Currentproduct.product.node.title}`;
			}
			this.state.AllProductsMappedData.map((product) => {
				const productNode = product.product.node;
				if (productNode.handle === Currentproduct.product.node.handle) {
					if (!productNode.priceWithoutTax) {
						const isGiftCard = productNode.handle.includes('gift-card');
						const price = getPrice(productNode.priceRange, isGiftCard);

						productNode.priceRange = price.currencyBasePrice;
						productNode.priceWithoutTax = price.priceWithoutTax;
					}

					let videoLink = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('video.slideshow'));
					if (window.innerWidth > 767) {
						if (videoLink && !videoLink.includes('desktop')) {
							if (product.product.node.videoLinks != null && product.product.node.videoLinks.value) {
								let desktopVideoLink = [{
									node: {
										altText: product.product.node.videoLinks.value,
										originalSrc: product.product.node.videoLinks.value,
										thumbnail: product.product.node.videoLinks.value
									}
								}]
								videoLink = desktopVideoLink;
							}
						}
					}

					let oppGenderProduct = this.getOppositeGenderVariants(product.color, product.handle);
					let featureImage1Src = product.product.node.images.edges.filter(image => image.node.originalSrc.includes('feature-1'));
					let featureImage3Src = product.product.node.images.edges.filter(image => image.node.originalSrc.includes('feature-3'));
					let slideImages = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('slideshow') || image.node.originalSrc.toLowerCase().includes('.kids'));
					let slideBundleImages = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('slideshow') || image.node.originalSrc.toLowerCase().includes('.bundle'));
					let sizeViewedImg = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('side-view'));
					let descPantone = product.product.node.descPantone ? product.product.node.descPantone.value : null;
					let titlePantone = product.product.node.titlePantone ? product.product.node.titlePantone.value : null;
					let subTitlePantone = product.product.node.subTitlePantone ? product.product.node.subTitlePantone.value : null;
					let pantoneNew = product.product.node.pantoneNew ? product.product.node.pantoneNew.value : null;

					product = {
						...product,
						"oppGenderProduct": oppGenderProduct,
						"videoLink": videoLink
					}
					// console.log('ccccccc', product);
					product.product.node.slideImages = {
						edges: slideImages
					};

					product.product.node.slideBundleImages = {
						edges: slideBundleImages
					};

					if (window.location.pathname.includes('women')) {
						var genderSwitcherWomenLink = product.url;
						var genderSwitcherMenLink = product.url.replace(/women/g, 'men');
					} else {
						var genderSwitcherMenLink = product.url;
						var genderSwitcherWomenLink = product.url.replace(/men/g, 'women');
					}

					this.setState({
						productData: product,
						currentColor: product.color_title,
						currentColorHandle: product.handle,
						featureImage1Src: featureImage1Src,
						featureImage3Src: featureImage3Src,
						sizeViewedImg: sizeViewedImg,
						genderSwitcherWomenLink: genderSwitcherWomenLink,
						genderSwitcherMenLink: genderSwitcherMenLink,
						descPantone: descPantone,
						titlePantone: titlePantone,
						subTitlePantone: subTitlePantone,
						pantoneNew: pantoneNew,
						isModelPopupOpen: false
					}, () => {
						this.setProductCategory(product)
							.then(() => {
								this.createMarkup();
								this.updateProductDetails();
							});

						if (typeof window.getRecentPdp == 'function') {
							window.getRecentPdp()
						}
						if (typeof window.setRecentlyViewedPdp == 'function') {
							window.setRecentlyViewedPdp()
						}
					});
				}
			})
		}
	}
	//this function is just for first time load
	async currentProduct() {
		await this.setProductCategory();
		this.setState({
			isloading : false
		})

    if (window.location.hash === '#reviews') {
      document.getElementById('judgeme_product_reviews')?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }

		// console.log('route', window.location.pathname);
		this.state.AllProductsMappedData.map((product) => {
			const productNode = product.product.node;
			if (window.location.pathname.includes(productNode.handle)) {
				if (!productNode.priceWithoutTax) {
					const isGiftCard = productNode.handle.includes('gift-card');
					const price = getPrice(productNode.priceRange, isGiftCard);

					productNode.priceRange = price.currencyBasePrice;
					productNode.priceWithoutTax = price.priceWithoutTax;
				}

				// console.log('cuerrent prod', product.product.node.images.edges);
				let videoLink = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('video.slideshow'));
				if(window.innerWidth>767){
					if(videoLink && !videoLink.includes('desktop')){
						if(product.product.node.videoLinks !=null && product.product.node.videoLinks.value){
						let desktopVideoLink=[{
							node: {
								altText : product.product.node.videoLinks.value,
								originalSrc : product.product.node.videoLinks.value ,
								thumbnail : product.product.node.videoLinks.value
							}
						}]
						videoLink = desktopVideoLink;
						}
					}
				}
				let oppGenderProduct = this.getOppositeGenderVariants(product.color, product.handle);
				let featureImage1Src = product.product.node.images.edges.filter(image => image.node.originalSrc.includes('feature-1'));
				let sizeViewedImg = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('side-view'));
				let featureImage3Src = product.product.node.images.edges.filter(image => image.node.originalSrc.includes('feature-3'));
				let slideImages = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('slideshow') || image.node.originalSrc.toLowerCase().includes('.kids'));
				let slideBundleImages = product.product.node.images.edges.filter(image => image.node.originalSrc.toLowerCase().includes('slideshow') || image.node.originalSrc.toLowerCase().includes('.bundle'));
				let descPantone = product.product.node.descPantone ? product.product.node.descPantone.value : null;
				let titlePantone = product.product.node.titlePantone ? product.product.node.titlePantone.value : null;
				let subTitlePantone = product.product.node.subTitlePantone ? product.product.node.subTitlePantone.value : null;
				let pantoneNew = product.product.node.pantoneNew ? product.product.node.pantoneNew.value : null;

				product={ ...product, "oppGenderProduct": oppGenderProduct, "videoLink": videoLink}
				product.product.node.slideImages ={
          edges: slideImages
        };

				product.product.node.slideBundleImages ={
          edges: slideBundleImages
        };

				if(window.location.pathname.includes('women')){
					var genderSwitcherWomenLink = product.url;
					var genderSwitcherMenLink = product.url.replace(/women/g, 'men');
				}
				else {
					var genderSwitcherMenLink = product.url;
					var genderSwitcherWomenLink = product.url.replace(/men/g, 'women');
				}

        const searchParams = new URLSearchParams(window.location.search);
        const isAdultShoesActive = (searchParams.get('bundle') === 'true' && this.shouldBundlesTabVisible())
          || !this.shouldKidsTabVisible();

				this.setState({
					productData: product,
					currentColor: product.color_title,
					currentColorHandle: product.handle,
					featureImage1Src: featureImage1Src,
					featureImage3Src: featureImage3Src,
					sizeViewedImg:sizeViewedImg,
					genderSwitcherWomenLink: genderSwitcherWomenLink,
					genderSwitcherMenLink : genderSwitcherMenLink,
					descPantone : descPantone,
					titlePantone : titlePantone,
					subTitlePantone : subTitlePantone,
					pantoneNew : pantoneNew,
          isModelPopupOpen: false,
          isAdultShoesActive,
				}, ()=>{this.createMarkup();
				});
			}
		});

	}
	isMen() {
		return !window.location.pathname.includes('women');
	}

	createMarkup() {
		document.querySelectorAll('.c-stickyBarPdp__title')[0].innerText=document.querySelectorAll('.c-pdp__productName')[0].innerText;
    window.requestAnimationFrame(() => {
      const stickyBarSubHeadlineEl = document.querySelector('.js-c-stickyBarPdp__subheadline')
      const productCatEl = document.querySelector('.c-pdp__productCat')
      if (stickyBarSubHeadlineEl) stickyBarSubHeadlineEl.innerText = productCatEl.innerText;
    })

		document.querySelectorAll('.review_badge_ctr').forEach(reviewCtr => {
      console.log(reviewCtr)
      reviewCtr.style.display = 'block';
      reviewCtr.addEventListener('click', () => {
        document.getElementById('judgeme_product_reviews')?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      })
    })
	}
	updateProductDetails=()=>{
		let me=this;
		var readyStateCheckInterval = setInterval(function() {
			if (document.readyState === "complete") {
				clearInterval(readyStateCheckInterval);

          let productCategory = `${me.state.product_category}`
          if (productCategory) {
            $('.c-stickyBarPdp .c-stickyBarPdp__subheadline').text(productCategory)
          }

					if($('c-featuredCol__30Col')){
          if(me.state.descPantone && me.state.subTitlePantone) {
						let categoryCopy = `
              <h2 class="c-featured__title">${me.state.titlePantone}</h2>
	            <p class="c-featured__subTitle pantone">${me.state.subTitlePantone}</p>
							${me.state.descPantone}
            `;

						$('.c-featuredCol__30Col').first().empty();
						$('.c-featuredCol__30Col').first().prepend(categoryCopy);
					}
					else if(me.state.descPantone) {
						let categoryCopy = `
              <h2 class="c-featured__title">${me.state.titlePantone}</h2>
							${me.state.descPantone}
            `;

						$('.c-featuredCol__30Col').first().empty();
						$('.c-featuredCol__30Col').first().prepend(categoryCopy);
					}
					else if(me.state.pantoneNew) {
						let pantoneNewData = $('.c-pantone-new-datastore').data();

						let categoryCopy =
							`<h2 class="c-featured__title">${pantoneNewData.pantoneNewTitle}</h2>
	                        <p class="c-featured__subTitle">${pantoneNewData.pantoneNewSubtitle}</p>
							${pantoneNewData.pantoneNewText}`;

						$('.c-featuredCol__30Col').first().empty();
						$('.c-featuredCol__30Col').first().prepend(categoryCopy);
					}
				}
				if($('.c-featuredCol__70')){
					if($('.c-featuredCol__70').first().find('source').length>0 && $('.c-featuredCol__70').first().find('source')?.attr("srcset")?.includes('feature-1')){
							let featureImage1 =$('.c-featuredCol__70').first().find('source');
							if(me.state.featureImage1Src &&  (me.state.featureImage1Src.length>0 && me.state.featureImage1Src[0].node)){
								featureImage1.attr("srcset", me.state.featureImage1Src[0].node.originalSrc);
								let imageTag=
								`<div class="ratio ratio-feature-1">
                  <picture>
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 1440px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 1024px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 900px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 767px)">
  									<source srcset="${me.state.featureImage1Src[0].node.originalSrc}" media="(min-width: 500px)">
  									<img loading="lazy"
  										src="${me.state.featureImage1Src[0].node.originalSrc}"
  										alt="Hero image">
  								</picture>
                </div>`;
								$('.c-featuredCol__70').first().find('div.ratio-feature-1').remove();
								$('.c-featuredCol__70').first().prepend(imageTag);
							}
							console.log(me.state.descPantone);
					}
				}
				if($('.c-featuredCol__50')){
					if($('.c-featuredCol__50').first().find('source').length>0 && $('.c-featuredCol__50').first().find('source')?.attr("srcset")?.includes('feature-3')){
						let featureImage3 =$('.c-featuredCol__50').first().find('source');
						if(me.state.featureImage3Src &&  (me.state.featureImage3Src.length>0 && me.state.featureImage3Src[0].node)){
								featureImage3.attr("srcset", me.state.featureImage3Src[0].node.originalSrc);
								let imageTag3=
								`<div class="ratio ratio-feature-block-2">
									<picture>
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 1440px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 1024px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 900px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 767px)">
										<source srcset="${me.state.featureImage3Src[0].node.originalSrc}" media="(min-width: 500px)">
										<img loading="lazy"
											src="${me.state.featureImage3Src[0].node.originalSrc}"
											alt="Hero image">
									</picture>
								</div>`;
								$('.c-featuredCol__50').first().find('.ratio-feature-block-2').remove();
								$('.c-featuredCol__50').first().prepend(imageTag3);
						}
					}
				}
				if($('.c-featuredCol__50')){
					if($('.c-featuredCol__50').find('.c-featured__description')){
            let descriptionSplit = me.state.productData.product.node.descriptionHtml.split('<p>[ split ]</p>');
						let description = descriptionSplit[0];
            let productSpecs = descriptionSplit[1];

						$('.c-featuredCol__50').find('.c-featured__description').html(description);

            if (productSpecs) {
              $('.c-featuredCol__50').find('.c-pdpContent__specCont').html(productSpecs);
            }
					}
				}
			}
		}, 10);

	}

  onSizeAndCartUpdate = () => {
    if (this.firstLoad) {
      this.firstLoad = false

      const scrollToCenter = ($container, $activeEl) => {
        if ($container.length && $activeEl.length) {
          const scrollLeft = $container.scrollLeft() + $container.offset().left + $activeEl.offset().left
            - ($container.width() - $activeEl.width()) / 2 + parseInt($activeEl.css('paddingLeft'))

          $container.scrollLeft(scrollLeft)
        }
      }

      setTimeout(() => {
        const $swatches = $('.js-swatch')
        const $activeSwatch = $swatches.find('.is-active')
        scrollToCenter($swatches, $activeSwatch)

        const $sizes = $('.c-pdp__size .menu')
        const $activeSize = $sizes.find('.selected')
        scrollToCenter($sizes, $activeSize)
        window.sizeScrollLeft = $sizes.scrollLeft()

        $('.jdgm-prev-badge').on('click', () => {
          const $mobileReviews = $('.c-mobileReview:not(:hidden)')

          if ($mobileReviews.length) {
            $mobileReviews[0].scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            })
          }
        })
      }, 0)
    } else {
      const $sizes = $('.c-pdp__size .menu')

      if (!$sizes.hasClass('is-initialized')) {
        $sizes.addClass('is-initialized')
        $sizes.on('scroll', function() {
          window.sizeScrollLeft = $sizes.scrollLeft()
        })
      }

      if (window.sizeScrollLeft) {
        $sizes.scrollLeft(window.sizeScrollLeft)
      }
    }
  }

	refreshProductInfo = () => {
		return this.getData(true)
	}

	openModelPopup = () => {
		this.setState({
			isModelPopupOpen: true
		})
	}

	closeModelPopup = () => {
		this.setState({
			isModelPopupOpen: false
		})
	}

	getStyleLink = url => {
    if (this.state.isAdultShoesActive) {
      return `${url}?bundle=true`
    }

		return url
	}

	handleOnAdultColorChanged = product => {
		if (!product) return
		this.setState({
			adultProductData: product
		})
	}

  handleTabClick = (e) => {
    e.preventDefault()

    if (e.target.href.includes('bundle=true')) {
      this.setState({
        isAdultShoesActive: true,
      })
    } else {
      this.setState({
        isAdultShoesActive: false,
        bundleStep: 1,
      })
    }
  }

  switchToBundleTab = (url) => {
    this.setState({
      isAdultShoesActive: true,
    })

    window.history.replaceState({}, document.title, url)
  }

  parseAdultStylesData = (stylesData) => {
    return stylesData.split('\n').map((style) => {
      let [key, label = '', adultTitle = ''] = style.split(':')

      key = key.trim()

      if (!label) {
        label = key
      }

      if (!adultTitle) {
        adultTitle = label
      }

      return { key, label, adultTitle }
    })
  }

  shouldKidsTabVisible = () => {
    return !this.state.adultShoesCollection || this.state.pdpDisplay !=='show_bundles_only'
  }

  shouldBundlesTabVisible = () => {
    return this.state.adultShoesCollection && this.state.pdpDisplay !== 'show_kids_only'
  }

	render() {
    const hasModel = productHasModel(this.state.productData)
    const modelHidden = isModelHidden(this.state.productData)
    const productUrl = this.state.productData ? getUrl(`/products/${this.state.productData.product.node.handle}`) : window.location.path
    const bundleUrl = `${productUrl}?bundle=true`

		return (
			<Fragment>
				{this.state.isloading ?
				<div class="c-pdp" >
					<div class="c-liveContent">
						<div class="container">
							<div class="c-pdp__breadCrumb">
								<div class="shimmer" style="width: 100px; height: 18px; vertical-align: middle;"></div>
							</div>
						</div>
					</div>
					<div class="container container--mobile-no-pad">
						<div class="c-pdp__grid c-pdpGrid">
							<div class="c-pdp__gridImg c-pdpGrid__item c-pdpSlider">
                <div class="shimmer" style="width: 100%; padding-bottom: 64.7%"></div>
							</div>
							<div class="c-pdp__gridInfo c-pdpGrid__item">
								<div class="c-pdp__productHead">
									<div class="c-pdp__productHeadItem c-pdp__productHeadLeft">
										<h1 class="c-pdp__productName shimmer" style="width: 232px; height: 36px;"></h1>
									</div>
									<div class="c-pdp__productHeadItem c-pdp__productHeadRight">
										<h4 class="c-pdp__productCat shimmer" style="width: 100px; height: 21px;"></h4>
										<div class="c-pdp__productReview c-pdp__productReview--nonAB-test">
											<div class="shimmer" style="width: 160px; height: 20px;"></div>
										</div>
									</div>
								</div>
								<h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent">
									<span class="shimmer" style="width :100px ; height : 21px;">&nbsp;</span>
								</h4>
								<div class="shimmer" style="width: 100%; height: 20px; vertical-align: middle;"></div>
								<div class="c-pdp__gridBox">
									<div class="c-pdp__gender">
										<div class="c-pdp__genderWrap shimmer" style="height: 47px;">
											&nbsp;
										</div>
									</div>
									<div class="c-pdp__gridBoxWrap" >
										<div class="c-material-ab">
											<span class="c-material__title shimmer">&nbsp;</span>
											<div class="c-material__tabs">
												<div class="shimmer" style="width: 145px; height: 42px;">&nbsp;</div>
												<div class="shimmer" style="width: 145px; height: 42px;">&nbsp;</div>
											</div>
										</div>
										{/* {% if has_swatches %} */}
										<div className="c-swatches c-swatchesDesktop ">
											<span className="c-pdp__label c-pdp__labelDyanmically c-pdp__label--colorText shimmer">
											&nbsp;
											</span>

												<div className="c-swatch js-swatch c-swatch__imageThumbnail  " style="margin: 0;">
													{this.dummyThumbnailImages.map((index) => {
														return <a style="width: calc(100% / 6) ; height: 77px" key={index} className='swatch-image-thumbnail shimmer'
														>
														</a>
													})}
												</div>
										</div>
											<div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
												<div class="size-quantity c-pdp__size">
													<div class="selector-wrapper js{% if option.values.size == 1 %} hide{% endif %}">
														<div class="c-pdp__sizeHead shimmer">
															<div class="c-pdp__label c-pdp__label--header" style="width: 100px;">&nbsp;</div>
														</div>
														<div className={'dropdown'} tabIndex="0" >
															<div className={'box'} ></div>
															<div className={'menu'} >
																{this.dummyThumbnailImages.map((variant, index) => (
																	<div className={`shimmer`} style="width: calc(calc(100% / 9) - 1px); height: 55px;"></div>
																))}
															</div>
														</div>
													</div>
													<label id="quantitySKUMsg" style="margin-top: 5px; color: red; text-align: center; font-weight: normal; display: none; text-transform:unset">
													</label>
													<input type="number" value="{{cart.item_count}}" style="display: none;" id="productPageCartQty" />

												</div>
												<div class="send">
													<input id="variantCart-{{cartItem.variant_id}}" value="{{cartItem.quantity}}" class="d-none" />
													<button
														type="submit"
														id="addCartBt"
														name="add"
														class="btn btn-full c-abBtnAtc pdp-addCartBtn-sticky "
														data-add-to-carts
														onClick={this.AddtoCart}>
														<span data-add-to-cart-text>
															Add to Bag
														</span>
													</button>
												</div>
												<div class="c-pdp__ecoMessageContain">
													<div class="c-pdp__ecoMessage shimmer" style="width: 100%; height: 23px;"></div>
												</div>
											</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				:
				<div class={`c-pdp kids-product bundle-step--${this.state.bundleStep} ${this.state.adultShoesCollection ? 'kids-product--has-bundle' : ''}`} data-section-id="{{ section.id }}" data-section-type="product" data-enable-history-state="true" itemscope itemtype="http://schema.org/Product">
					<div class="c-liveContent">
						<div class="container">
							<div class="c-pdp__breadCrumb c-pdp__breadCrumb--secondary">
                <a href={getUrl('/collections/kids')} class="link-internal">
                  <span class="c-pdp__breadCrumbText c-pdp__breadCrumbText--secondary">{languages.kids}</span>
                </a>

                <span class="c-pdp__breadCrumbIcon c-pdp__breadCrumbIcon--secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 7 10">
                    <path stroke="#4A4A4A" stroke-width="2" d="m1 1 4 4-4 4"/>
                  </svg>
                </span>
								<a href={this.state.product_category_link!==null ? getUrl(this.state.product_category_link) : ""} class="link-internal"><span class="c-pdp__breadCrumbText">{this.state.product_category!==null ? this.state.product_category : ""}</span></a>
							</div>
						</div>
					</div>
					<div class="container container--mobile-no-pad">
          <div class="c-pdp__productHead pdp-alt-hide-desktop-flex">
              <div class="c-pdp__productHeadItem c-pdp__productHeadLeft">
                {this.state.productData !== null ?
                  <h2 class="c-pdp__productName">
                    {this.state.productData.product.node.alternativeTitle ? this.state.productData.product.node.alternativeTitle.value : '' }
                  </h2>
                  :
                  <h1 class="c-pdp__productName shimmer" style="width: 232px; height: 30px;"></h1>
                }

                {this.state.productData !== null ?
                  <h4 class="c-pdp__productCat">{this.state.product_category_full !== null ? this.state.product_category_full : ""}</h4>
                  :
                  <h4 class="c-pdp__productCat shimmer" style="width : 100px;"></h4>
                }
              </div>
              <div class="c-pdp__productHeadItem c-pdp__productHeadRight">
                {this.state.productData !== null ?
                  <h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent" data-price-wrapper>
                    {this.state.productData.product.node.priceRange.maxVariantPrice && (
                      <Fragment>
                        {taxInclusiveCurrencies.includes(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode) ? (
                          <span class="mobile-text" data-product-price="">
                            <span class="c-taxInclusive">
                              {formatMoney(this.state.productData.product.node.priceRange.maxVariantPrice.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              <span class="product_vatText">
                                {getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              </span>
                            </span>
                            <span class="c-taxExclusive">
                              {formatMoney(this.state.productData.product.node.priceWithoutTax.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, false)}
                              <span class="product_vatText">
                                {` ${getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, true)}`}
                              </span>
                            </span>
                          </span> 
                        ) : (
                          <span class="mobile-text" data-product-price="">
                            {formatMoney(this.state.productData.product.node.priceRange.maxVariantPrice.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </h4>
                  :
                  <h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent shimmer" style="width :100px ; height : 21px;"></h4>
                }

                <div class='c-pdp__productReview jdgm-widget jdgm-preview-badge c-pdp__productReview--nonAB-test review_badge_ctr'  style="display : block">
                  {this.state.productData !== null && !this.state.productData.product.node.review?.value.includes("No reviews") && !window.pdpHideReviews ?
                    <div id="review_badge" dangerouslySetInnerHTML={{ __html: this.state.productData.product.node.review?.value}} />
                    :
                    ''
                  }
                </div>
              </div>
            </div>

						<div class="c-pdp__grid c-pdpGrid">
							{this.state.productData !== null ?
								this.state.productData.product.node.slideImages.edges.length > 1 || this.state.productData.product.node.images.edges.length > 0 ?
									<ImageSlider
										key={'image'}
										images={this.state.isAdultShoesActive && this.state.bundleStep === 2 && this.state.adultProductData?.product.node.slideImages.edges.length
											? this.state.adultProductData.product.node.slideImages.edges
											: this.state.isAdultShoesActive && this.state.bundleStep === 1 && this.state.productData.product.node.slideBundleImages.edges.length
												? this.state.productData.product.node.slideBundleImages.edges
												: this.state.productData.product.node.slideImages.edges.length
													? this.state.productData.product.node.slideImages.edges
													: this.state.productData.product.node.images.edges
										}
										video={this.state.productData.videoLink.length!==0 ? this.state.productData.videoLink : null}
                    tags={this.state.productData.product.node.tags}
										hasModel={hasModel}
                    isModelHidden={modelHidden}
										onOpenModelButtonClick={this.openModelPopup}
									/>
									: " " :
									<div class="c-pdp__gridImg c-pdpGrid__item c-pdpSlider shimmer" >
									</div>
							}
							<div class="c-pdp__gridInfo c-pdpGrid__item">
								<div class="c-pdp__productHead hide-mobile">
									<div class="c-pdp__productHeadItem c-pdp__productHeadLeft">
									{this.state.productData !== null ?
										<h1 class="c-pdp__productName">
											{this.state.productData.product.node.alternativeTitle ? this.state.productData.product.node.alternativeTitle.value : '' }
										</h1>
										:
										<h1 class="c-pdp__productName shimmer" style="width: 232px;
										height: 30px;">
										</h1>
										}
									</div>
									<div class="c-pdp__productHeadItem c-pdp__productHeadRight">
										{this.state.productData !== null ?
											<h4 class="c-pdp__productCat">{this.state.product_category_full !== null ? this.state.product_category_full : ""}</h4>
											: <h4 class="c-pdp__productCat shimmer" style="width : 100px;"></h4>}
										<div class='c-pdp__productReview jdgm-widget jdgm-preview-badge c-pdp__productReview--nonAB-test review_badge_ctr' style="display : block"
										>
											{this.state.productData !== null && !this.state.productData.product.node.review?.value.includes("No reviews") && !window.pdpHideReviews ?
											<div id="review_badge" dangerouslySetInnerHTML={{
												__html: this.state.productData.product.node.review?.value}} />
												:
												'' }
										</div>
									</div>
								</div>
								{this.state.productData !== null ?
									<h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent hide-mobile" data-price-wrapper>
                    {this.state.productData.product.node.priceRange.maxVariantPrice && (
                      <Fragment>
                        {taxInclusiveCurrencies.includes(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode) ? (
                          <span class="mobile-text" data-product-price="">
                            <span class="c-taxInclusive">
                              {formatMoney(this.state.productData.product.node.priceRange.maxVariantPrice.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              <span class="product_vatText">
                                {getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                              </span>
                            </span>
                            <span class="c-taxExclusive">
                              {formatMoney(this.state.productData.product.node.priceWithoutTax.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, false)}
                              <span class="product_vatText">
                                {` ${getTaxAndDutiesMessage(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, true)}`}
                              </span>
                            </span>
                          </span> 
                        ) : (
                          <span class="mobile-text" data-product-price="">
                            {formatMoney(this.state.productData.product.node.priceRange.maxVariantPrice.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                          </span>
                        )}
                      </Fragment>
                    )}
									</h4>
									:
									<h4 class="c-pdp__productPrice c-pdp__productPriceRegular c-liveContent shimmer" style="width :100px ; height : 21px;">

									</h4>
								}

                {(this.state.productData && getPaymentsEnabled(this.state.productData.product.node.priceRange.minVariantPrice.currencyCode).showPayments) && (
                  <div class="pdp-alt-hide-mobile payments-wrapper">
                    <InstallmentPayments
                      currency={this.state.productData.product.node.priceRange.minVariantPrice.currencyCode}
                      amount={taxInclusiveCurrencies.includes(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)
                        ? getPriceAmount(this.state.productData.product.node.priceRange.maxVariantPrice.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, true, window.Shopify.country)
                        : this.state.productData.product.node.priceRange.maxVariantPrice.amount
                      }
                    />
                  </div>
                )}

								<div class="c-pdp__gridBox">
									<Router>
										<Profile path={getUrl("/products/:user?v")} item={this.state.productData !== null ? this.state.productData : ''} />
									</Router>
                  <ul class="product-tabs">
                    {this.shouldKidsTabVisible() && (
                      <li class={`product-tabs__item ${this.state.isAdultShoesActive ? '' : 'is-active'}`}>
                        <a href={productUrl} class="product-tabs__link" onClick={this.handleTabClick}>{languages.kids}</a>
                      </li>
                    )}
                    {this.shouldBundlesTabVisible() && (
                      <li class={`product-tabs__item kids-bundle-ab-control ${this.state.isAdultShoesActive ? 'is-active' : ''}`}>
                        <a href={bundleUrl} class="product-tabs__link" onClick={this.handleTabClick}>{languages.parents_and_kids}</a>
                      </li>
                    )}
                  </ul>

                  <div class={`c-pdp__group ${this.state.adultShoesCollection && this.state.isAdultShoesActive && this.state.bundleStep === 2 ? 'step-2' : ''}`}>
                    <div class={`c-pdp__gridBoxWrap c-pdp__group-col`}>
                      {this.state.isAdultShoesActive && this.state.bundleStep === 1 && <div class="adult-product__head">
                        <div class="adult-product__discount-message">
                          {this.state.bundlingMessage}
                        </div>
                        <div class="adult-product__title-price">
                          <div class="adult-product__title">
                            {this.state.bundlingProductTitle}
                          </div>
                          <div class="adult-product__price">
                            <span class="adult-product__discount_price">
                              {this.state.productData.product.node.priceRange.maxVariantPrice && (
                                <Fragment>
                                  {taxInclusiveCurrencies.includes(this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode) ? (
                                    <span class="mobile-text" data-product-price="">
                                      <span class="c-taxInclusive">
                                        {formatMoney(this.state.productData.product.node.priceRange.maxVariantPrice.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                                      </span>
                                      <span class="c-taxExclusive">
                                        {formatMoney(this.state.productData.product.node.priceWithoutTax.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode, false)}
                                      </span>
                                    </span> 
                                  ) : (
                                    <span class="mobile-text" data-product-price="">
                                      {formatMoney(this.state.productData.product.node.priceRange.maxVariantPrice.amount, this.state.productData.product.node.priceRange.maxVariantPrice.currencyCode)}
                                    </span>
                                  )}
                                </Fragment>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>}

                      {/* {% if has_swatches %} */}
                      {this.state.styleData.length ?
                        <div class="c-material-ab">
                          <div class="c-material__title">
                          {languages.style_label}
                          <span> {this.state.styleData.filter(item => this.styleParam ? kebabCase(item.title) === this.styleParam : item.isCurrent)[0]?.title || ''}</span>
                          </div>
                          <div class="c-material__tabs">
                            {this.state.styleData.map(item => (
                              <a href={this.getStyleLink(item.url)} key={`${item.url}:${this.state.isAdultShoesActive}`} class={`c-material__tab ${(this.styleParam ? kebabCase(item.title) === this.styleParam : item.isCurrent) ? 'c-material__tab--active' : ''}`}>
                                {item.title}
                              </a>
                            ))}
                          </div>
                        </div>
                      : ''
                      }

                      <div className="c-swatches c-swatchesDesktop ">
                        <div className={this.state.currentColor ? 'c-pdp__label c-pdp__labelDyanmically c-pdp__label--colorText' : 'hide-section'}>
                          {languages.color_label}
                          <span class="c-pdp__colorText c-pdp__textUpper">{this.state.currentColor !== null ? this.state.currentColor : ''}</span>
                          {this.state.styleData.length ?
                            <div class="c-material c-cleanup-ab">
                              <div class="c-material__tabs">
                                {this.state.styleData.map(item => (
                                  <a href={item.url} key={item.url} class={`c-material__tab ${(this.styleParam ? kebabCase(item.title) === this.styleParam : item.isCurrent) ? 'c-material__tab--active' : ''}`}>
                                    {item.title}
                                  </a>
                                ))}
                              </div>
                            </div>
                          : ''
                          }
                        </div>
                        {this.state.AllProductsMappedData !== null ?
                        <div key='new' className={this.state.currentColor ? 'c-swatch js-swatch c-swatch__imageThumbnail lazyloaded' : 'hide-section'}>
                          {this.state.AllProductsMappedData.map((product, index) => {
                            return <a key={product.color + index} className={product.color_title === this.state.currentColor && product.handle.replace(/-\men|-\women/, '') === this.state.currentColorHandle.replace(/-\men|-\women/, '') ? "is-active swatch-image-thumbnail  " : 'swatch-image-thumbnail '}
                              href={product.url + (this.state.isAdultShoesActive ? '?bundle=true' : '')} onClick={() => { this.changecolor(product) }}>
                              <img src={product.thumbnail_image} />
                            </a>
                          })}
                        </div>
                        :  <div className="c-swatch js-swatch c-swatch__imageThumbnail  ">
                            {this.dummyThumbnailImages.map((index) => {
                              return <a style="width : 70px ; height : 70px" key={index} className='swatch-image-thumbnail shimmer'
                              >
                              </a>
                            })}
                          </div>
                          }
                      </div>

                      {this.state.productData !== null ?
                        <SizeAndCart
                          key={this.state.productData.product.node.handle+Math.random()*9080}
                          product={this.state.productData.product}
                          current_size={this.state.productData.current_size}
                          oppGenderProduct={this.state.productData.oppGenderProduct}
                          sizeOption={this.state.productData.product.node.options[2]}
                          productCategory={this.state.product_category}
                          sizeViewedImg = {this.state.sizeViewedImg}
                          product_category = {this.state.product_category}
                          refreshProductInfo={this.refreshProductInfo}
                          currencyCode={this.state.productData.product.node.priceRange.minVariantPrice.currencyCode}
                          onUpdate={this.onSizeAndCartUpdate}
                          isAlt={true}
                          isKids={true}
                          showBundle={this.state.isAdultShoesActive}
                          afterAddedBundle={(kidsLineItem) => this.setState({ kidsLineItem, bundleStep: 2 })}
                          hasBundle={this.shouldBundlesTabVisible()}
                          onBundleButtonClick={() => this.switchToBundleTab(bundleUrl)}
                        />
                        :
                        <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
                          <div class="size-quantity c-pdp__size">
                            <div class="selector-wrapper js{% if option.values.size == 1 %} hide{% endif %}">
                              <div class="c-pdp__sizeHead shimmer">
                              </div>
                              <div className={'dropdown'} tabIndex="0" >
                                <div className={'box'} ></div>
                                <div className={'menu'} >
                                  {this.dummyThumbnailImages.map((variant, index) => (
                                    <div className={`variant shimmer`}>
                                      <span className={'size shimmer'}></span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <label id="quantitySKUMsg" style="margin-top: 5px; color: red; text-align: center; font-weight: normal; display: none; text-transform:unset">
                            </label>
                            <input type="number" value="{{cart.item_count}}" style="display: none;" id="productPageCartQty" />
                            <div class="send hidden-min-desktop">
                              <input id="variantCart-{{cartItem.variant_id}}" value="{{cartItem.quantity}}" class="d-none" />
                              <button
                                type="submit"
                                id="addCartBt"
                                name="add"
                                class="btn btn-full c-abBtnAtc pdp-addCartBtn-sticky "
                                data-add-to-carts
                                onClick={this.AddtoCart}>
                                <span data-add-to-cart-text>
                                  {languages.add_to_cart}
                                </span>
                              </button>
                            </div>
                            <div class="c-pdp__ecoMessageContain">
                              <span class="c-pdp__ecoMessage">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 20 20"
                                >
                                  <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                    <g fill="#77C043" fillRule="nonzero" transform="translate(-177 -527)">
                                      <g transform="translate(177 527)">
                                        <path d="M3.438 16.25c.475 0 .938-.103 1.367-.302v3.27H1.68a.39.39 0 000 .782h16.64a.39.39 0 000-.781h-3.125v-3.27c.43.198.892.301 1.367.301 1.864 0 3.438-1.52 3.438-3.32 0-1.436-1.043-2.75-2.472-3.178a2.75 2.75 0 00-1.403-2.146 3.9 3.9 0 00.124-2.307.39.39 0 00-.757.19 3.12 3.12 0 01-.098 1.84 2.738 2.738 0 00-3.313 2.423c-.671.201-1.258.598-1.69 1.111V7.67l2.238-1.066a.39.39 0 00-.336-.706l-1.902.906V3.906a.39.39 0 00-.782 0v1.725l-1.902-.906a.39.39 0 00-.336.706L9.61 6.497v4.366a3.549 3.549 0 00-1.69-1.11A2.738 2.738 0 004.606 7.33a3.098 3.098 0 01-.192-1.08 3.1 3.1 0 012.652-3.076.39.39 0 00.325-.315C7.605 1.694 8.75.781 10 .781c1.248 0 2.395.913 2.61 2.078.03.164.16.29.324.315.16.025.319.062.473.11a.39.39 0 10.236-.744 3.917 3.917 0 00-.327-.088C12.923 1.037 11.545 0 10 0S7.077 1.037 6.684 2.452a3.896 3.896 0 00-2.81 5.154 2.75 2.75 0 00-1.402 2.146C1.042 10.18 0 11.494 0 12.93c0 1.8 1.574 3.32 3.438 3.32zm2.148-.302c.429.199.892.302 1.367.302 1.057 0 2.02-.489 2.656-1.24v4.209H5.586v-3.27zm4.805 3.27V15.01c.636.751 1.6 1.24 2.656 1.24.475 0 .938-.103 1.367-.302v3.27h-4.023zm2.153-8.776a.393.393 0 00.308-.46 1.955 1.955 0 011.953-1.935c1.079 0 1.943.874 1.953 1.936a.393.393 0 00.308.459c1.227.246 2.153 1.316 2.153 2.488 0 1.376-1.217 2.539-2.657 2.539-.484 0-.953-.142-1.367-.412v-1.3l2.128-1.064a.39.39 0 00-.35-.699l-1.778.89V10a.39.39 0 00-.78 0v1.4l-.506-.504a.39.39 0 00-.552.552l1.057 1.058v2.551c-.414.27-.883.412-1.367.412-1.44 0-2.656-1.163-2.656-2.54 0-1.171.925-2.24 2.153-2.487zm-9.61 0a.393.393 0 00.308-.46 1.954 1.954 0 011.953-1.935c1.071 0 1.944.867 1.953 1.936a.393.393 0 00.308.459c1.228.246 2.153 1.316 2.153 2.488 0 1.376-1.216 2.539-2.656 2.539-.484 0-.953-.142-1.367-.412v-.208l1.057-1.057a.39.39 0 00-.552-.553l-.505.505v-2.572a.39.39 0 00-.781 0v1.712l-1.779-.89a.39.39 0 10-.35.7l2.129 1.063v1.3c-.414.27-.883.412-1.367.412-1.44 0-2.657-1.163-2.657-2.54 0-1.171.926-2.24 2.153-2.487z"></path>
                                        <circle cx="14.762" cy="4.286" r="1"></circle>
                                      </g>
                                    </g>
                                  </g>
                                </svg>

                              </span>
                            </div>
                            <div class="send">
                              <input id="variantCart-{{cartItem.variant_id}}" value="{{cartItem.quantity}}" class="d-none" />
                              <button
                                type="submit"
                                id="addCartBt"
                                name="add"
                                class="btn btn-full c-abBtnAtc pdp-addCartBtn-sticky "
                                data-add-to-carts
                                onClick={this.AddtoCart}>
                                <span data-add-to-cart-text>
                                  {languages.add_to_cart}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                    {this.state.adultShoesCollection && (
                      <div class="c-pdp__group-col">
                        <PdpAdultShoes
                          collection={this.state.adultShoesCollection}
                          onColorChanged={this.handleOnAdultColorChanged}
                          kidsPrice={this.state.productData.product.node.priceRange.maxVariantPrice.amount}
                          bundlingMessage={this.state.bundlingMessage}
                          styles={this.state.adultStyles}
                          kidsLineItem={this.state.kidsLineItem}
                        />
                      </div>
                    )}
									</div>
								</div>
							</div>
						</div>
					</div>
						<div class="c-ozyContent">
							{"include 'ozy-content' "}
						</div>

            {hasModel && (
              <ModelPopup
                isOpen={this.state.isModelPopupOpen}
                modelProjectId={this.state.productData?.product?.node?.modelProjectId?.value}
                onClose={this.closeModelPopup}
              />
            )}
					</div>
				}
			</Fragment>
				);
			}
		}


		/** handles /profile and /profile/:user */
const Profile = ({user, ...props }) => (
	<section class="profile">
	</section>
);

export default PdpKids;
