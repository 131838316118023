import AddonDefaultSizeSelector from './AddonDefaultSizeSelector'
import AddonLacesSizeSelector from './AddonLacesSizeSelector'
import AddonInsoleSizeSelector from './AddonInsoleSizeSelector'
import AddonShoeCareSizeSelector from './AddonShoeCareSizeSelector'
import getUrl from '@/scripts/utils/get-url'

const socksSizeDescriptionMap = {
  XS: '(US W 5-7 / M 3.5 - 5.5)',
  S: '(US W 7.5 - 9 / M 6 - 7.5)',
  M: '(US W 9.5 - 12 / M 8 - 10.5)',
  L: '(US W 12.5 - 14.5 / M 11 - 13)'
}

const skateStyles = ['berrics', 'catiba-pro', 'catiba-pro-high', 'naioca', 'vallely', 'slip-on-skate']

const addonCallbacks = {
  sizeSelector(type) {
    switch (type) {
      case 'laces':
      case 'shoebag':
        return AddonLacesSizeSelector
      case 'insole':
        return AddonInsoleSizeSelector
      case 'shoe-care':
        return AddonShoeCareSizeSelector
    }

    return AddonDefaultSizeSelector
  },
  cartProperties(type, { selectedProduct, selectedVariant }) {
    let size = selectedVariant?.node.selectedOptions[2]?.value

    if (type === 'insole') {
      let fake_gender = '' // gender of current product page
      let real_gender = '' // gender from which the real shoe size will be returned
      let fake_size = 0 // set fake size based on whether object returned is variant or custom
      let real_size = 0 // fake size +/- 1.5

      if (selectedVariant?.node.selectedOptions[0].value === 'Women') {
        // Women Size Selection
        fake_gender = 'M'
        real_gender = 'W'
        real_size= parseFloat(selectedVariant?.node.selectedOptions[2].value)
        fake_size = (parseFloat(real_size) - 1.5)
      } else {
        // Men Size Selection
        fake_gender = 'W'
        real_gender = 'M'
        real_size=parseFloat(selectedVariant?.node.selectedOptions[2].value)
        fake_size = (parseFloat(real_size) + 1.5)
      }

      size = real_gender + ' ' + real_size + ' / ' + fake_gender + ' ' + fake_size
    } else if (type === 'shoebag' || type === 'shoe-care') {
      size = ''
    }

    let options = {
      Size: size
    }

    if (type !== 'laces' && type !== 'insole' && type !== 'shoebag') {
      options = {
        ...options,
        _tags: selectedProduct.node.tags,
        ...(selectedVariant.node.currentlyNotInStock ? {
          _PREORDER: 'PREORDER',
          _preorderestimate: selectedProduct.node.preorder?.value || ''
        }: {})
      }
    }

    if (type === 'gwp') {
      const existingItem = window.CartJS?.cart.items.find(item => item.discounts.some(discount => discount.title === window.gwpSettings?.discountTitle))

      if (existingItem) {
        options = {
          ...existingItem.properties,
          ...options
        }
      }
    }

    let treePlant = selectedProduct.node.treePlant?.value ?? 0

    if (type === 'kids') {
      treePlant = selectedProduct.node.treePlant?.value ?? 2
    }

    if (treePlant > 0) {
      options = {
        _treePlant: treePlant * (window.__GLOBAL__['10_trees_promo'] ? 5 : 1),
        ...options
      }
    }

    return options
  },
  postAddToCart(type, { mainProductId, variantId }) {
    if (type === 'laces') {
      const variantWithLace = JSON.parse(localStorage.getItem('variantWithLace')) || []
      variantWithLace.push(mainProductId)
      localStorage.setItem('variantWithLace', JSON.stringify(variantWithLace))
      localStorage.setItem('variantAccessoriesID', JSON.stringify(variantId))
    } else if (type === 'insole') {
      const variantWithInsole = JSON.parse(localStorage.getItem('variantWithInsole')) || []
      variantWithInsole.push(mainProductId)
      localStorage.setItem('variantWithInsole', JSON.stringify(variantWithInsole))
      localStorage.setItem('variantAccessoriesID', JSON.stringify(variantId))
    } else if (type === 'gwp') {
      const existingItem = window.CartJS?.cart.items.find(item => `${item.id}` === `${mainProductId}`)

      if (existingItem) {
        window.fetch(getUrl('/cart/update.js'), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            updates: {
              [existingItem.id]: 0
            }
          })
        })
        .then(() => {
          CartJS.getCart()
        })
      }
    } else if (type === 'shoebag') {
      const variantWithShoeBag = JSON.parse(localStorage.getItem('variantWithShoeBag')) || []
      variantWithShoeBag.push(mainProductId)
      localStorage.setItem('variantWithShoeBag', JSON.stringify(variantWithShoeBag))
      localStorage.setItem('variantAccessoriesID', JSON.stringify(variantId))
    } else if (type === 'shoe-care') {
      const variantWithShoeCare = JSON.parse(localStorage.getItem('variantWithShoeCare')) || []
      variantWithShoeCare.push(mainProductId)
      localStorage.setItem('variantWithShoeCare', JSON.stringify(variantWithShoeCare))
      localStorage.setItem('variantAccessoriesID', JSON.stringify(variantId))
    }
  },
  addonTitle(type) {
    if (type === 'laces') {
      return window.globalLanguages?.addon.extra_lace
    }

    if (type === 'insole') {
      return window.globalLanguages?.addon.extra_insole
    }

    if (type === 'shoebag') {
      return window.globalLanguages?.addon.travel_bag
    }

    return ''
  },
  sizeDescription(type, label) {
    if (type === 'socks') {
      if (label in socksSizeDescriptionMap) {
        return socksSizeDescriptionMap[label]
      }
    }

    return ''
  },
  addonColorTitle(type) {
    if (type === 'shoe-care') {
      return window.globalLanguages?.addon.select_kit
    }

    return window.globalLanguages?.addon.select_color
  },
  reorderIdentifiers(type, { identifiers, originalIdentifiers }) {
    if (type === 'socks') {
      const skateIndex = identifiers.findIndex(item => item.key.includes('skate'))

      if (skateIndex >= 0) {
        const hasSkateItem = (window.CartJS?.cart?.items ?? []).some(item => {
          return skateStyles.some(style => item.handle.includes(style))
        })

        if (hasSkateItem) {
          const newIdentifiers = [...identifiers]
          newIdentifiers.splice(skateIndex, 1)

          return [
            identifiers[skateIndex],
            ...newIdentifiers
          ]
        } else {
          return [...originalIdentifiers]
        }
      }
    }

    return identifiers
  }
}

const addonCallback = (event, type, data) => {
  if (typeof addonCallbacks[event] === 'function') {
    return addonCallbacks[event](type, data)
  }

  return null
}

export default addonCallback