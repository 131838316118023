const init = (el) => {
  const menus = [...el.querySelectorAll('.footer__menu:not(.footer__menu--support)')]

  const expandMenu = (activeMenu, isInit = false) => {
    menus.forEach(menu => {
      const trigger = menu.querySelector('.footer__menu-trigger')
      const content = menu.querySelector('.footer__menu-content')
      const contentInner = content.querySelector('.footer__menu-items')

      if (activeMenu === menu && (isInit || !menu.classList.contains('is-expanded'))) {
        menu.classList.add('is-expanded')
        trigger.setAttribute('aria-expanded', 'true')
        content.style.height = `${contentInner.clientHeight}px`
      } else {
        menu.classList.remove('is-expanded')
        trigger.setAttribute('aria-expanded', 'false')
        content.style.height = 0
      }
    })
  }

  menus.forEach(menu => {
    const trigger = menu.querySelector('.footer__menu-trigger')

    trigger.addEventListener('click', () => {
      expandMenu(menu)
    })
  })

  const activeMenu = menus.find(menu => menu.classList.contains('is-expanded'))

  if (activeMenu) {
    expandMenu(activeMenu, true)
  }
}

export default () => {
  document.querySelectorAll('.js-footer-menu').forEach(el => {
    if (!el.classList.contains('is-initialized')) {
      el.classList.add('is-initialized')
      init(el)
    }
  })
}