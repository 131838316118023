import $ from 'jquery'
import Cookies from 'js-cookie'
import getUrl from '@/scripts/utils/get-url'
import axios from 'axios'
import getCollectionProducts from './utils/get-collection-products'

export default function global() {
  $(document).ready(function () {
    var $html = $('html'),
      $body = $('body'),
      $allPopups = $('.popup'),
      $overlay = $('.overlay'),
      $miniCart = $('.mini-cart'),
      $mediaQuery = $('.mq'),
      $mainHeader = $('.main-header'),
      browserWidth = 'small',
      headerHeight = parseInt($body.css('padding-top'), 10),
      previousScroll = 0,
      resizeTimer,
      preventErasingInput,
      scrollTimer

    function stickyNav() {
      var currentScroll = $(window).scrollTop()
      $mainHeader = $('.main-header')

      if (currentScroll > headerHeight) {
        // Scroll down
        if (
          currentScroll > previousScroll &&
          !$body.hasClass('page-skim-landing')
        ) {
          $body.addClass('sticky-nav')
          // Scroll up
        } else {
          $body.removeClass('sticky-nav')
        }
        $mainHeader.removeClass('white-header')
      } else {
        $body.removeClass('sticky-nav')

        if ($mainHeader.hasClass('has-white-header')) {
          $mainHeader.addClass('white-header')
        }
      }

      previousScroll = currentScroll
    }

    stickyNav()

    function checkSearchValue(value) {
      let list = ['tshirts', 'shirt', 'tshirt']
      return list.includes(value)
    }

    $(window).on('scroll', function () {
      stickyNav()
    })

    // Size Guide Popup
    var $sizeGuideTrigger = $('.js-size-guide-trigger'),
      $sizeGuidePopup = $('.popup__size-guide'),
      $sizeGuidePages = $('.popup__size-guide-page')

    // Trigger popup
    let counterInterval
    $sizeGuideTrigger.on('click', function (e) {
      e.preventDefault()
      //don't show exit popup on pdp page over size guid popup.
      localStorage.setItem('sizePopupIsShowing', true)
      $html.addClass('popup-shown')
      $allPopups.removeClass('shown')
      const target = $(this).attr('data-popup-target')
      if (target && $(`[data-popup="${target}"]`).length > 0) {
        $sizeGuidePopup.removeClass('shown');
        $(`[data-popup="${target}"]`).addClass('shown');
      } else {
        $sizeGuidePopup.addClass('shown');
      }
      $overlay.addClass('shown')

      slate.popUpShown = true
    })

    // Close popups on button click
    $allPopups.on('click', 'a.close', function (e) {
      e.preventDefault()
      $html.removeClass('popup-shown')
      $allPopups.removeClass('shown')
      $overlay.removeClass('shown')
      slate.popUpShown = false
      localStorage.setItem('sizePopupIsShowing', false)
    })

    // Hide search autocomplete/mini cart on body click

    $body.on('click', function (e) {
      if (!preventErasingInput) {
        $('input[name="q"').val('')
      }
      $('.search-autocomplete').removeClass('shown')

      $mainHeader.find('li.help-nav').removeClass('shown')
      // Don't close mini cart if user has clicked inside it
      if (!$(e.target).closest('.c-drawer__body').length) {
        $miniCart.removeClass('shown')
      }

      preventErasingInput = false
    })

    $('.c-header__searchBtn').keypress((e) => {
      var key = e.which
      if (key == 13) {
        preventErasingInput = true
        $('.search-autocomplete').removeClass('shown')
      }
    })

    $('.search-page-submit').keypress((e) => {
      var key = e.which
      if (key == 13) {
        var inputValue = $('.search-page-submit #Search').val()

        if (checkSearchValue(inputValue.toLowerCase())) {
          $('.search-page-submit #Search').val('t-shirt')
        }

        preventErasingInput = true
        $('.search-autocomplete').removeClass('shown')
      }
    })

    $('.search-page-submit').submit(function (e) {
      e.preventDefault()
      var inputValue = $('.search-page-submit #Search').val()
      return window.location.replace(`${getUrl('/search')}?q=${inputValue}&type=product`)
    })

    $('.c-header__searchForm').submit(function (e) {
      e.preventDefault()
      var inputValue = $('.c-header__searchInput').val()
      return window.location.replace(`${getUrl('/search')}?q=${inputValue}&type=product`)
    })

    $('.c-mobileOffCanvas__form--top').submit(function (e) {
      e.preventDefault()
      var inputValue = $(
        '.c-mobileOffCanvas__form--top input[type="search"]',
      ).val()
      return window.location.replace(`${getUrl('/search')}?q=${inputValue}&type=product`)
    })

    $('.c-mobileOffCanvas__form, .mobile-menu-search-form').keypress((e) => {
      var key = e.which
      if (key == 13) {
        preventErasingInput = true
        $('.search-autocomplete').removeClass('shown')
      }
    })

    $('.c-header__searchBtn').click(function (e) {
      var inputEl = $(this).parent().find('.c-header__searchInput')
      var inputValue = inputEl.val()

      if (checkSearchValue(inputValue.toLowerCase())) {
        inputEl.val('t-shirt')
      }

      e.stopPropagation()
    })

    $('.c-footerNav__searchBtn').click(function (e) {
      e.stopPropagation()
    })

    $('.search-page-button').click(function (e) {
      e.stopPropagation()
    })

    $('#menu').click(function (e) {
      preventErasingInput = true
      $('.search-autocomplete').removeClass('shown')
    })

    $('.js-top-nav-search-form')
      .on('keypress', function (e) {
        if (e.which === 13) {
          preventErasingInput = true
          $('.search-autocomplete').removeClass('shown')
        }
      })
      .on('submit', function (event) {
        event.preventDefault()
        const searchTerm = $(this).find('input[type="search"]').val()

        return window.location.replace(`/search?q=${searchTerm}&type=product`)
      })

    $('.js-top').click(function () {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        600,
      )
      return false
    })

    $(window).scroll(function (e) {
      var scrollTop = $(window).scrollTop()
      var docHeight = $(document).height()
      var winHeight = $(window).height()
      var scrollPercent = scrollTop / (docHeight - winHeight)
      var scrollPercentRounded = Math.round(scrollPercent * 100)

      if (scrollPercentRounded >= 12) {
        $('.js-top').addClass('visible')
      } else {
        $('.js-top').removeClass('visible')
      }
    })

    /*================ Home Page ================*/
    if (
      $body.hasClass('template-index') ||
      $body.hasClass('template-skate') ||
      $body.hasClass('template-surf') ||
      $body.hasClass('template-reforestation')
    ) {
      console.log('You on surf page')
      $(window).scroll(function () {
        var scroll = $(window).scrollTop()
        if (scroll >= 20) {
          $('.header-v1').addClass('is-white')
          $('body.template-index').addClass('is-white')
          $('body.template-skate').addClass('is-white')
          $('body.template-surf').addClass('is-white')
          $('body.template-reforestation').addClass('is-white')
        } else {
          $('.header-v1').removeClass('is-white')
          $('body.template-index').removeClass('is-white')
          $('body.template-surf').removeClass('is-white')
          $('body.template-skate').removeClass('is-white')
          $('body.template-reforestation').removeClass('is-white')
        }
      })
    }

    function searchAutocomplete($this, isMobile) {
      // Current Ajax request.
      var currentAjaxRequest = null

      // Find location to append results
      if (isMobile) {
        var $appendLocation = $body
      } else {
        var $appendLocation = $this.parent()
      }

      // Adding a list for showing search results.
      $('<div class="search-autocomplete"></div>').appendTo($appendLocation)

      // Grabbing text input.
      var $input = $this.find('input[name="q"]'),
        initialQuery = $input.val()

      // Disable search button if no query
      if (!initialQuery) {
        $this.find('.btn').addClass('disabled')
      }

      // $input.closest('.search-form').on('submit', function(e) {
      //     var term = $input.val();

      //     // Prevent submission if no text
      //     if(!term) {
      //         e.preventDefault();
      //     } else {
      //
      //         e.stopPropagation();
      //     }
      // });

      // Listening to keyup and change on the text field within these search forms.
      $input.attr('autocomplete', 'off').on('keyup change', function () {
        // What's the search term?
        var term = $(this).val()

        // Enable search button if query
        if (term) {
          $this.find('.btn').removeClass('disabled')
        } else {
          $this.find('.btn').addClass('disabled')
        }

        // What's the search URL?
        var searchURL = getUrl('/search') + '?type=product&q=' + term
        // What's the search results list?
        var $resultsList = $appendLocation.children('.search-autocomplete')
        var $resultsContent = $('<div class="overflow-scroll"><ul></ul></div>')
        // If that's a new term and it contains at least 2 characters.
        if (term.length > 2 && term != $(this).attr('data-old-term')) {
          // Saving old query.
          $(this).attr('data-old-term', term)
          // Killing any Ajax request that's currently being processed.
          if (currentAjaxRequest != null) currentAjaxRequest.abort()
          // Pulling results.
          currentAjaxRequest = $.getJSON(searchURL + '&view=json', function (
            data,
          ) {
            // Reset results.
            $resultsList.empty()
            // If we have no results.
            if (data.results_count == 0) {
              // $resultsList.html('<li><span class="title">No results.</span></li>');
              // $resultsList.fadeIn(200);
              $resultsList.removeClass('shown')
            } else {
              $resultsList.append('<h3 class="subtitle">Products:</h3>')
              // If we have results.
              $.each(data.results, function (index, item) {
                var $link = $('<a></a>').attr('href', item.url)
                $link.append(
                  '<span class="thumbnail"><img src="' +
                    item.thumbnail +
                    '" srcset="' +
                    item.thumbnailSrcset +
                    '" sizes="64px" /></span>',
                )
                $link.append(
                  '<span class="title"><h2>' +
                    item.title +
                    '</h2><p class="price">' +
                    item.price +
                    '</p></span>',
                )
                $link.wrap('<li></li>')
                $resultsContent.find('ul').append($link.parent())
              })
              $resultsList.append($resultsContent)
              // The Ajax request will return at the most 10 results.
              // If there are more than 3, let's link to the search results page.
              if (data.results_count > 3) {
                $resultsList.append(
                  '<h3 class="subtitle see-all"><a href="' +
                    searchURL +
                    '">See all results (' +
                    data.results_count +
                    ')</a></h3>',
                )
              }
              $resultsList.addClass('shown')
              // Hide mini cart so they don't overlap
              $miniCart.removeClass('shown')
            }
          })
        }
      })
    }
    /*================ Search Autocomplete / Disable Button ================*/

    // $('.main-header .search-form').each(function() {
    //     searchAutocomplete($(this), false);
    // });

    // $('.mobile-nav .search-form').each(function() {
    //     searchAutocomplete($(this), true);
    // });

    function onInputChange(input) {
      if ($(input).val()) {
        $(input).parent().addClass('has-value')
      } else {
        $(input).parent().removeClass('has-value')
      }
    }

    function handleBlueCoreForm(_this) {
      $(_this)
        .find('input[type="email"]')
        .each(function () {
          onInputChange(this)

          $(this).on('propertychange input', function () {
            onInputChange(this)
            $('.email-error').remove()
          })
        })

      $(_this)
        .find('button[type="button"')
        .each(function () {
          var button = $(this)
          button.parent().on('click', function () {
            if (button.attr('data-submit') == 'true') return

            var email = $(_this).find('input[type="email"]')

            if (
              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.val())
            ) {
              button.css('pointer-events', 'auto')
              button.attr('data-submit', true)
              button.trigger('click')
              return
            }

            if ($(_this).find('.email-error').length < 1) {
              $(
                '<p class="email-error">Please enter a valid email address</p>',
              ).insertAfter(email)
            }
          })
        })
    }

    $('.c-mainBluecoreForm').on('DOMSubtreeModified', function () {
      handleBlueCoreForm(this)
    })

    $('.footerBluecoreSignup, .klaviyoSignupForm').on(
      'DOMSubtreeModified',
      function () {
        handleBlueCoreForm(this)
      },
    )

    customRedirections()
  })

  /**
   * Shopify does NOT allow redirections from non-404 page.
   *
   * Custom Redirection Function
   */
  function customRedirections() {
    const redirectionList = [
      {from: '/search?q=vote&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=4ocean&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=crooked&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=razer&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=sneki&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=sizing&type=product', to: '/pages/size-fit-guide'},
      {from: '/search?q=size&type=product', to: '/pages/size-fit-guide'},
      {from: '/search?q=narrow&type=product', to: '/pages/size-fit-guide'},
      {from: '/search?q=shipping&type=product', to: '/pages/faq'},
      {from: '/search?q=unsubscribe&type=product', to: '/pages/faq'},
      {from: '/search?q=exchange&type=product', to: '/pages/returns'},
      {from: '/search?q=refer&type=product', to: '/pages/refer'},

      // IOS Adds + between spaces
      {from: '/search?q=m+i+l&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=4+ocean&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=sneki+snek&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=size+chart&type=product', to: '/pages/size-fit-guide'},
      {from: '/search?q=customer+service&type=product', to: '/pages/faq'},
      {from: '/search?q=return+policy&type=product', to: '/pages/returns'},
      {from: '/search?q=free+returns&type=product', to: '/pages/returns'},

      // Desktop Adds %20 between spaces
      {from: '/search?q=m%20i%20l&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=sneki%20snek&type=product', to: '/collections/collaborations-men'},
      {from: '/search?q=size%20chart&type=product', to: '/pages/size-fit-guide'},
      {from: '/search?q=customer%20service&type=product', to: '/pages/faq'},
      {from: '/search?q=return%20policy&type=product', to: '/pages/returns'},
      {from: '/search?q=free%20returns&type=product', to: '/pages/returns'},
      {from: '/search?q=4%20ocean&type=product', to: '/collections/collaborations-men'},

      {from: '/search?q=can%20gogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=can%20gough&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=gogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=starry%20night&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=sun%20flowers&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=sunflower&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=sunflowers&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20fogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20g&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20go&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20goh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20ghogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20gogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20gogh%20collection&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20gogh%20museum&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20gogh%20shoes&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20gogh%20sneakers&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20gogh%20sunflowers&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=van%20gough&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=vangogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=vgm&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=vincent&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=vincent%20van%20gogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=women%20van%20gogh&type=product', to: '/collections/collaborations-women'},
      {from: '/search?q=womens%20van%20gogh&type=product', to: '/collections/collaborations-women'},
      
      {from: '/search?q=vegan&type=product', to: '/collections/vegan-sneakers-women'},
    ]

    redirectionList.forEach(redirection => {

      // Bail if any of the required props are null|undefined
      // OR
      // If the "to" url does NOT starts with / in order to complete the URL correctly
      if (!redirection.from || !redirection.to || !redirection.to.startsWith('/')) return

      const windowUrl = window.location.href.toLowerCase()

      if (windowUrl.includes(redirection.from)) {
        document.location.assign(window.location.origin.concat(redirection.to))
      }
    })
  }

  ;(function () {
    const getScrollbarWidth = () => {
      document.documentElement.style.setProperty('--scrollbar-width', '0px')
      const outer = document.createElement('div')
      outer.style.visibility = 'hidden'
      outer.style.width = '100px'
      document.body.appendChild(outer)
  
      const widthNoScroll = outer.offsetWidth
      outer.style.overflow = 'scroll'
  
      const inner = document.createElement('div')
      inner.style.width = '100%'
      outer.appendChild(inner)
  
      const widthWithScroll = inner.offsetWidth
      outer.parentNode.removeChild(outer)
  
      document.documentElement.style.setProperty('--scrollbar-width', `${(widthNoScroll - widthWithScroll) / 2}px`)
    }

    getScrollbarWidth()

    window.addEventListener('resize', () => getScrollbarWidth())
  })()

  const getCollectionProductsInfo = async (handle) => {
    const oppositeGenderHandle = handle.replace('-women','-men').replace('-men','-women')
    let data = await getCollectionProducts(handle, 'handle-style')

    if (oppositeGenderHandle !== handle) {
      const oppositeGenderData = await getCollectionProducts(oppositeGenderHandle, 'handle-style')
      data = [...data,...oppositeGenderData]
    }

    const output = {}

    for (const product of data) {
      if (!(product.style in output)) {
        output[product.style] = []
      }

      output[product.style].push(product.handle)
    }

    return output
  }

  document.addEventListener('DOMContentLoaded', async () => {
    window.adultProducts = []
    window.kidsProducts = []
    
    if (window.kidsCollection) {
      window.kidsProducts = await getCollectionProductsInfo(window.kidsCollection);
    }

    if (window.adultCollection) {
      window.adultProducts = await getCollectionProductsInfo(window.adultCollection);
    }
  })
}
