/**
 *
 * @prettier
 */

import { h, render } from 'preact';
/** @jsx h */
import CollectionPage from './collection/Page';
import { shopAllCollections, filtersList } from './collection/data';
import classNames from 'classnames';
import { setI18nData, fromEntries, getSetting } from './collection/utils';

const readBoolean = (str) => (str === 'true' ? true : false);

(() => {
	const collectionPageElement = document.querySelector('.js-collectionPage');
	if (!collectionPageElement) return;
	const collectionPageContentElement = collectionPageElement.querySelector(
		'.js-collectionPageContent',
	);
	if (!collectionPageContentElement) return;

	const {
		handle: collectionHandle,
		shopCurrency,
		title: collectionTitle,
		superPlp : superPlp,
	} = collectionPageElement.dataset;
	const hasSubCollections = readBoolean(collectionPageElement.dataset.hasSubCollections);
	const expandFirstSubCollection = readBoolean(collectionPageElement.dataset.expandFirstSubCollection);
	const isShopAll = shopAllCollections.includes(collectionHandle);
	const translations = JSON.parse(collectionPageElement.dataset.translations);
	const bannerImages = collectionPageElement.dataset.bannerImages ? JSON.parse(collectionPageElement.dataset.bannerImages) : null;
	setI18nData(translations);
  const collectionProductsCount = parseInt(collectionPageElement.dataset.products);

	const collections = !hasSubCollections
		? [
				[
					collectionHandle,
          collectionProductsCount,
					collectionPageElement.dataset.title,
				],
		  ]
		: JSON.parse(collectionPageElement.dataset.subCollections);

	const collectionsHandles = collections.map((collection) => collection[0]);

	const SubCollectionHeading = isShopAll
		? ({ children }) => (
				<h2
					class={classNames(
						'c-collectionPage__heading-collection',
						'c-collectionPage__heading-collection--secondary',
					)}
				>
					{children}
				</h2>
		  )
		: ({ children, isMainHeading }) => isMainHeading ?
      <h1 class="c-collectionPage__heading-collection">{children}</h1>
      :
      <h2 class="c-collectionPage__heading-collection">{children}</h2>

	const productNameHeadingLevel = isShopAll ? 4 : 3;

	const filtersElement = collectionPageElement.querySelector('.js-collectionPageFilters');
	let filters = null;

	if (filtersElement) {
		const filtersDataRaw = JSON.parse(filtersElement.dataset.filters);

		setI18nData(filtersDataRaw.translations);

		const options = {};
		filtersDataRaw.options.forEach((option) => {
			if (!options[option.type]) {
				options[option.type] = [];
			}

			options[option.type].push(option);

			const optionsMap = fromEntries(
				Object.entries(options).map(([key, value]) => [key.replace('_option', ''), value]),
			);

			if (optionsMap.style) {
				// we're not using filtering by style
				delete optionsMap.style;
			}

			filters = filtersList.map((filter) => ({
				...filter,
				options: filter.options || optionsMap[filter.id],
			}));
		});
	}

  const shopAllText = getSetting(collectionHandle, 'shopAllText', window.plpLanguages?.shop_all);

	const props = {
		shopCurrency,
		collectionHandle,
		collectionTitle,
		collections,
		SubCollectionHeading,
		hasSubCollections,
		expandFirstSubCollection,
		isShopAll,
    hasProducts: collectionProductsCount > 0,
		filters,
		productNameHeadingLevel,
		superPlp,
		bannerImages,
    shopAllText
	};

	// clear before render
	while (collectionPageContentElement.firstChild) {
		collectionPageContentElement.lastChild.remove();
	}

	render(<CollectionPage {...props} />, collectionPageContentElement);
})();
