import Cookies from 'js-cookie';

const init = (el) => {
  const selectValue = el.querySelector('.js-country-modal-select-value')
  const selectValueText = selectValue.querySelector('.js-country-modal-select-value-text')
  const select = el.querySelector('.js-country-modal-select')

  const updateSelect = () => {
    const selectedOption = select.options[select.selectedIndex]
    const flag = selectedOption.getAttribute('data-flag')
    const title = selectedOption.text

    if (flag) {
      selectValue.style.setProperty('--flag-url', `url(${flag})`)
    } else {
      selectValue.style.setProperty('--flag-url', '')
    }

    selectValueText.innerText = title
  }

  const initSelectValue = () => {
    const options = select.options
    let selectedOption = 0

    for (const option of options) {
      if (option.dataset.countryCode === window.countryCode) {
        selectedOption = option
        break
      }
    }

    if (!selectedOption) {
      for (const option of options) {
        if (option.dataset.countryCode === window.continentCode) {
          selectedOption = option
          break
        }
      }
    }

    if (!selectedOption) {
      return
    }

    select.value = selectedOption.value
  }

  const attemptToRedirect = () => {
    // Do redirection only when customer does not select currency
    // and the url does not contain currency or country code query
    if (Cookies.get('currency_selected')
      || window.location.href.match(/currency=([^&]*)/)
      || window.location.href.match(/country_code=([^&]*)/)
    ) {
      return
    }

    const url = select[select.selectedIndex]?.value

    if (url.includes('http')) {
      return
    }

    // Do redirection only when url is relative (without domain) url
    const currency = url.match(/currency=([^&]*)/)
    const countryCode = url.match(/country_code=([^&]*)/) 
    const emCache = url.match(/em-cache=([^&]*)/)

    if (!currency && !countryCode) {
      return
    }

    if (currency && currency[1] === window.Shopify?.currency?.active) {
      return
    }

    if (countryCode && countryCode[1] === window.Shopify?.country) {
      return
    }

    // Do redirection only when url contains currency or country code
    // and current currency is not equal to selected currency
    const urlParams = new URLSearchParams(window.location.search)

    if (currency?.length) {
      urlParams.set('currency', currency[1])
    }

    if (countryCode?.length) {
      urlParams.set('country_code', countryCode[1])
    }

    if (emCache?.length) {
      urlParams.set('em-cache', emCache[1])
    }

    let { origin, pathname } = window.location
    window.location.href = origin + pathname + '?' + urlParams.toString()
  }

  initSelectValue()
  attemptToRedirect()
  updateSelect()
  select.addEventListener('change', updateSelect)

  const confirmButton = el.querySelector('.js-country-modal-button')
  confirmButton.addEventListener('click', (e) => {
    e.preventDefault()
    handleConfirmButtonClick(select[select.selectedIndex].value)
  })
}

const handleConfirmButtonClick = (href) => {
  const currency = href.match(/currency=([^&]*)/)
  const countryCode = href.match(/country_code=([^&]*)/)
  const emCache = href.match(/em-cache=([^&]*)/)

  if (!currency || !currency.length) window.location.href = href

  const urlParams = new URLSearchParams(window.location.search)
  urlParams.set('currency', currency[1])

  if (countryCode?.length) {
    urlParams.set('country_code', countryCode[1])
  }

  if (emCache?.length) {
    urlParams.set('em-cache', emCache[1])
  }

  let { origin, pathname } = window.location

  // Clear out cart/currency cookies with wildcard domain
  if (href.includes('http')) {
    const url = new URL(href)
    origin = url.origin

    Cookies.remove('localization', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
    Cookies.remove('currency_selected', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
    Cookies.remove('cart_currency', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
    Cookies.remove('cart', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
  } else {
    const countryForm = document.getElementById('footer-country-form')

    if (countryForm && countryCode?.length) {
      Cookies.set('currency_selected', true, {
        expires: 7
      });

      countryForm.country_code.value = countryCode[1]
      countryForm.submit()

      return
    }
  }

  window.location.href = origin + pathname.replace(Shopify?.routes?.root ?? '/', '/') + '?' + urlParams.toString()
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-country-popup:not(.is-initialized)').forEach(el => {
      el.classList.add('is-initialized')
      init(el)
    })
  }) 
}