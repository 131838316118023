import $ from 'jquery';

export default function custmerAccount() {	

	/*================ Templates ================*/
	/**
	 * Customer Addresses Script
	 * ------------------------------------------------------------------------------
	 * A file that contains scripts highly couple code to the Customer Addresses
	 * template.
	 *
	 * @namespace customerAddresses
	 */

	theme.customerAddresses = (function() {
		var $newAddressForm = $('#AddressNewForm');

		if (!$newAddressForm.length) {
			return;
		}

		// Initialize observers on address selectors, defined in shopify_common.js
		if (Shopify) {
			new Shopify.CountryProvinceSelector('AddressCountryNew', 'AddressProvinceNew', {
				hideElement: 'AddressProvinceContainerNew'
			});
		}

		// Initialize each edit form's country/province selector
		$('.address-country-option').each(function() {
			var formId = $(this).data('form-id');
			var countrySelector = 'AddressCountry_' + formId;
			var provinceSelector = 'AddressProvince_' + formId;
			var containerSelector = 'AddressProvinceContainer_' + formId;

			new Shopify.CountryProvinceSelector(countrySelector, provinceSelector, {
				hideElement: containerSelector
			});
		});

		// Toggle new/edit address forms
		$('.address-new-toggle').on('click', function(e) {
			e.preventDefault();
			
			$newAddressForm.toggleClass('hide');
		});

		$('.address-edit-toggle').on('click', function(e) {
			var formId = $(this).data('form-id');

			e.preventDefault();

			$('#EditAddress_' + formId).toggleClass('hide');
		});

		$('.address-delete').on('click', function(e) {
			var $el = $(this);
			var formId = $el.data('form-id');
			var confirmMessage = $el.data('confirm-message');

			e.preventDefault();

			if (confirm(confirmMessage || 'Are you sure you wish to delete this address?')) {
				Shopify.postLink('/account/addresses/' + formId, {parameters: {_method: 'delete'}});
			}
		});
	})();


	/**
	 * Password Template Script
	 * ------------------------------------------------------------------------------
	 * A file that contains scripts highly couple code to the Password template.
	 *
	 * @namespace password
	 */

	theme.customerLogin = (function() {
		var config = {
			recoverPasswordForm: '#RecoverPassword',
			hideRecoverPasswordLink: '#HideRecoverPasswordLink'
		};

		if (!$(config.recoverPasswordForm).length) {
			return;
		}

		checkUrlHash();
		resetPasswordSuccess();

		$(config.recoverPasswordForm).on('click', onShowHidePasswordForm);
		$(config.hideRecoverPasswordLink).on('click', onShowHidePasswordForm);

		function onShowHidePasswordForm(evt) {
			evt.preventDefault();
			toggleRecoverPasswordForm();
		}

		function checkUrlHash() {
			var hash = window.location.hash;

			// Allow deep linking to recover password form
			if (hash === '#recover') {
				toggleRecoverPasswordForm();
			}
		}

		/**
		 *  Show/Hide recover password form
		 */
		function toggleRecoverPasswordForm() {
			$('#RecoverPasswordForm').toggleClass('hide');
			$('#CustomerLoginForm').toggleClass('hide');
		}

		/**
		 *  Show reset password success message
		 */
		function resetPasswordSuccess() {
			var $formState = $('.reset-password-success');

			// check if reset password form was successfully submited.
			if (!$formState.length) {
				return;
			}

			// show success message
			$('#ResetSuccess').removeClass('hide');
		}
	})();
}