import md5 from 'md5'

class Popup {
  constructor(el) {
    this.el = el
    this.popups = this.processPopups(el.dataset.popups)
    this.isGatedArchiveSale = el.getAttribute('data-type')
  }

  processPopups(popups_data) {
    const popups = this.parseJSON(popups_data, [])

    return popups.map(popup => {
      const conditionValues = popup.conditionValues.split('\n')

      return {
        ...popup,
        conditionValues
      }
    })
  }

  parseJSON(str, defaultValue = null) {
    try {
      return JSON.parse(str)
    } catch (_e) {
      return defaultValue
    }
  }

  async init() {
    this.countryCode = this.getCountryCode()
    const matchedPopups = this.popups.filter(popup => {
      return this.matchCondition(popup)
    })

    for (const popup of matchedPopups) {
      await this.buildPopup(popup)
    }
  }

  getCountryCode() {
    return window.countryCode || ''
  }

  matchCondition(popup) {
    const checkFunctions = {
      'page_url': this.pageUrlMatched,
      'query_param': this.queryParamMatched,
      'country_code': this.countryCodeMatched
    }

    if (popup.conditionType in checkFunctions) {
      return checkFunctions[popup.conditionType].bind(this)(popup.conditionValues)
    }

    return false
  }

  pageUrlMatched(conditionValues) {
    const pathName = window.location.pathname
    console.log(conditionValues)

    return conditionValues.some(value => value === '*' || value === pathName)
  }

  queryParamMatched(conditionValues) {
    const { searchParams } = new URL(window.location)

    return conditionValues.some(value => {
      const [param, paramValue = null] = value.split('=')

      if (!paramValue) {
        return searchParams.has(param)
      } else {
        return searchParams.get(param) === paramValue
      }
    })
  }

  countryCodeMatched(conditionValues) {
    return conditionValues.some(value => value === this.countryCode)
  }

  async buildPopup(popup) {
    return new Promise(resolve => {
      if (!popup || (!popup.reopenPopup !== 'always' && this.hasCookie(popup))) {
        resolve()
        return
      }

      const wrapper = this.createElement('div', `popup__overlay popup--${popup.type}`, document.body)
      const modal = this.createElement('div', 'popup__modal', wrapper, {
        role: 'dialog',
        'aria-modal': true,
        'aria-label': this.stripHtml(popup.title)
      })
      const modalInner = this.createElement('div', 'popup__modal-inner', modal)

      if (popup.type !== 'password') {
        const closeButton = this.createElement('button', 'popup__modal-close', modalInner)
        closeButton.textContent = '✕'

        closeButton.addEventListener('click', () => {
          this.closeModal(wrapper, popup)
          resolve()
        })

        wrapper.classList.add('is-clickable')
        wrapper.addEventListener('click', (event) => {
          if (event.target === wrapper) {
            this.closeModal(wrapper, popup)
            resolve()
          }
        })
      } else if (popup.bypassParameter) {
        const url = new URL(window.location)
        const params = new URLSearchParams(url.search)

        for (const [key, value] of params.entries()) {
          if (md5(key) === popup.bypassParameter || md5(`${key}=${value}`) === popup.bypassParameter) {
            this.closeModal(wrapper, popup)

            if(this.isGatedArchiveSale && this.isGatedArchiveSale === 'gated-archive-sale') {
              this.saveCookieGatedArchiveSale(popup)
              location.reload()
            }

            resolve()
            return
          }
        }
      }

      if (popup.image) {
        const modalImage = this.createElement('img', 'popup__modal-image', modalInner, {
          src: popup.image,
          alt: popup.imageAlt
        })
      }

      if (popup.imageMaxWidthMobile > 0) {
        wrapper.style.setProperty('--popup-image-max-width-mobile', popup.imageMaxWidthMobile + 'px')
      }

      if (popup.imageMaxWidthDesktop > 0) {
        wrapper.style.setProperty('--popup-image-max-width-desktop', popup.imageMaxWidthDesktop + 'px')
      }

      if (popup.title) {
        const modalTitle = this.createElement('div', 'popup__modal-title', modalInner)
        modalTitle.innerHTML = popup.title
      }

      if (popup.text) {
        const modalText = this.createElement('div', 'popup__modal-text', modalInner)
        modalText.innerHTML = popup.text
      }

      if (popup.type === 'password') {
        const passwordForm = this.createElement('form', 'popup__password-form', modalInner)

        if (popup.passwordLabel) {
          const passwordLabel = this.createElement('label', 'popup__password-label', passwordForm, {
            for: 'popup-password-input'
          })
          passwordLabel.innerHTML = popup.passwordLabel
        }

        const passwordInput = this.createElement('input', 'popup__password-input', passwordForm, {
          type: 'password',
          id: 'popup-password-input',
          name: 'password',
          placeholder: popup.passwordPlaceholder
        })

        if (popup.errorMessage) {
          const errorMessage = this.createElement('div', 'popup__error-message', passwordForm)
          errorMessage.innerHTML = popup.errorMessage
        }

        const submitButton = this.createElement('button', 'popup__modal-button', passwordForm, {
          type: 'submit'
        })
        submitButton.textContent = popup.buttonText || 'Access Now'

        passwordForm.addEventListener('submit', (event) => {
          event.preventDefault()

          const formData = new FormData(passwordForm)
          const password = formData.get('password')

          if (popup.password === md5(password.toLowerCase())) {
            passwordForm.classList.remove('is-error')
            this.closeModal(wrapper, popup)
            if(this.isGatedArchiveSale && this.isGatedArchiveSale === 'gated-archive-sale') {
              this.saveCookieGatedArchiveSale(popup)
              location.reload()
            }
            resolve()
          } else {
            passwordForm.classList.add('is-error')
          }
        })
      } else {
        if (popup.buttonText) {
          if (popup.buttonUrl && popup.buttonUrl != document.location.pathname) {
            const modalButtonLink = this.createElement('a', 'popup__modal-button', modalInner, {
              href: popup.buttonUrl
            })

            modalButtonLink.textContent = popup.buttonText || 'Visit'

            // <a> tag from createElement lacks linking functionality
            modalButtonLink.addEventListener('click', () => {
              document.location.href = popup.buttonUrl
            })
          } else {
            const modalButton = this.createElement('button', 'popup__modal-button', modalInner, {
              type: 'button'
            })
            modalButton.textContent = popup.buttonText || 'Close'

            modalButton.addEventListener('click', () => {
              this.closeModal(wrapper, popup)
              resolve()
            })
          }
        }
      }

      if (popup.note) {
        const modalNote = this.createElement('div', 'popup__modal-note', modalInner)
        modalNote.innerHTML = popup.note
      }

      if (popup.linkText) {
        if (popup.linkUrl && popup.linkUrl != '/') {
          const modalLink = this.createElement('a', 'popup__modal-button-close', modalInner, {
            href: popup.linkUrl
          })

          modalLink.textContent = popup.linkText

          // <a> tag from createElement lacks linking functionality
          modalLink.addEventListener('click', () => {
            document.location.href = popup.linkUrl
          })
        } else {
          const modalLinkClose = this.createElement('button', 'popup__modal-button-close', modalInner, {
            type: 'button'
          })
          modalLinkClose.textContent = popup.linkText

          modalLinkClose.addEventListener('click', () => {
            this.closeModal(wrapper, popup)
            resolve()
          })
        }
      }

      if (!this.openModal(wrapper, popup)) {
        resolve()
      }
    })
  }

  createElement(tagName, className, parent = null, attributes = {}) {
    const el = document.createElement(tagName)
    el.className = className

    for (const property in attributes) {
      el.setAttribute(property, attributes[property])
    }

    if (parent) {
      parent.appendChild(el)
    }

    return el
  }

  stripHtml(html) {
    const el = document.createElement('div')
    el.innerHTML = html

    return el.textContent
  }

  openModal(wrapper, popup) {
    if (popup.reopenPopup === 'always' || !this.hasCookie(popup)) {
      if (popup.type === 'password') {
        document.body.classList.add('is-popup-active')
      }

      wrapper.classList.add('is-open')

      return true
    }

    return false
  }

  closeModal(wrapper, popup) {
    document.body.classList.remove('is-popup-active')
    wrapper.classList.remove('is-open')

    this.saveCookie(popup)
  }

  saveCookie(popup) {
    if (popup.reopenPopup !== 'always') {
      const date = new Date()
      date.setTime(date.getTime() + popup.numberOfDays * 24 * 60 * 60 * 1000)

      document.cookie = `popup-${popup.id}=1; expires=${date.toUTCString()}; path=/`
    }
  }

  hasCookie(popup) {
    if (popup.reopenPopup === 'always') {
      return false
    }

    return document.cookie.match(new RegExp(`(^| )popup-${popup.id}=([^;]+)`));
  }

  saveCookieGatedArchiveSale(popup) {
    let date
    if(popup.expiresDate && popup.expiresDate != '') {
      date = new Date(popup.expiresDate).toLocaleString("en-US", {timeZone: "America/Los_Angeles"})
    } else {
      date = new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"})
    }
    document.cookie = `is_invite_user=true; expires=${new Date(date).toUTCString()}; path=/`
  }
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const popupEl = document.querySelector('.js-popup')

    if (popupEl && !popupEl.classList.contains('is-initialized')) {
      popupEl.classList.add('is-initialized')
      const popup = new Popup(popupEl)
      popup.init()
    }
  })
}
