/**
 *
 * @prettier
 */

import { h } from 'preact';
/** @jsx h */
import { useState, useCallback, useMemo } from 'preact/hooks';
import classNames from 'classnames';
import { ActiveFilter } from './ActiveFilter';
import { fromEntries, omit, getCountrySize } from './utils';

const productsMatchingFilters = (products, filters) => {
	let productsFiltered = products;

	Object.entries(filters).forEach(([name, values]) => {
		if (!values || values.length === 0) return;
		productsFiltered = productsFiltered.filter((product) =>
			(product.filters[name] || []).some((val) => values.includes(val)),
		);
	});

	return productsFiltered;
};

const extractFilterFromProduct = (filter, products) => {
	const values = new Set();

	products.forEach((product) => {
		(product.filters[filter] || []).forEach((val) => values.add(val));
	});

	return [...values];
};

const computeFiltersAvailability = ({ activeFilters, filters, dataFilters }) => {
	if (dataFilters.status !== 'LOADED') {
		return dataFilters.status;
	}

	const products = Object.values(dataFilters.products);

	return fromEntries(
		filters.map((filter) => [
			filter.id,
			extractFilterFromProduct(
				filter.id,
				productsMatchingFilters(products, omit(activeFilters, [filter.id])),
			),
		]),
	);
};

const computeProductsVisibility = ({ activeFilters, dataBasic, dataFilters }) => {
	const products = Object.values(dataFilters.products);

	const visibleProductsHandles = productsMatchingFilters(products, activeFilters).map(
		(product) => product.handle,
	);

	return fromEntries(
		Object.entries(dataBasic).map(([handle, { products }]) => {
			if (!products) return [handle, []];
			const productsVisibility = products.map((product) =>
				visibleProductsHandles.includes(product.handle),
			);
			const areAllInvisible = productsVisibility.every((val) => val === false);
			return [handle, areAllInvisible ? false : productsVisibility];
		}),
	);
};



export const useFilters = ({ filters, dataBasic, dataFilters }) => {
	const [activeFilters, setActiveFilters] = useState(() => ({}));
	const filtersAvailability = useMemo(
		() => computeFiltersAvailability({ activeFilters, filters, dataFilters }),
		[activeFilters, filters, dataFilters],
	);
	const productsVisibility = useMemo(
		() => computeProductsVisibility({ activeFilters, dataBasic, dataFilters }),
		[activeFilters, dataBasic, dataFilters],
	);

	const setFilter = useCallback((key, value) => {
		setActiveFilters((val) => ({ ...val, [key]: value }));
	}, []);

	const clearFilters = useCallback(() => {
		setActiveFilters({});
	}, []);

	return { activeFilters, filtersAvailability, setFilter, clearFilters, productsVisibility };
};

const FiltersDropdownOption = ({ activeFilters, openFilter, option, checkboxChange, isDesktop }) => {
	// console.log(option ,12)
	return(
	<li class="c-collectionFilters__dropdownListItem">
		<label class="c-collectionFilters__dropdownLabel">
			<input
				type="checkbox"
				class="c-collectionFilters__dropdownCheckbox"
				data-filter={openFilter.id}
				data-filter-color={option.color}
				value={option.tag_name}
				checked={Boolean(
					activeFilters[openFilter.id] &&
						activeFilters[openFilter.id].includes(option.tag_name),
				)}
				onChange={checkboxChange}
			/>

			<div class={`c-collectionFilters__dropdownLabelInner ${isDesktop ? 'c-collectionFilters__dropdownLabelInner--desktop': ''} `}>
				<div class="c-collectionFilters__dropdownLabelInnerInner">
					<div class="c-collectionFilters__dropdownLabelInnerInnerInner">
						{option.color && (
							<div
								class="c-collectionFilters__dropdownLabelColor"
								data-color={option.color}
								style={{
									backgroundColor: option.color,
								}}
							/>
						)}
						<div class="c-collectionFilters__dropdownLabelText">{openFilter.id === 'size' ? getCountrySize(option.name) : option.name}</div>
					</div>
				</div>
			</div>
		</label>
	</li>
)};

export const Filters = ({
	filters,
	setFilter,
	clearFilters,
	filtersAvailability,
	activeFilters,
	isDesktop,
}) => {
	const checkboxChange = (e) => {
		const { filter } = e.target.dataset;
		const { value, checked } = e.target;
		const current = activeFilters[filter] || [];
		setFilter(filter, checked ? [...current, value] : current.filter((val) => val !== value));
	};

	const [openFilter, setOpenFilter] = useState(null);

	const toggleFilterClick = (e) => {
		const button = e.target.closest('button');
		const { filter: filterId } = button.dataset;

		setOpenFilter(
			openFilter && openFilter.id === filterId
				? null
				: filters.find((filter) => {
					if (filterId === 'size') {
						return filter.options = filterHalfSizes(filter.options);
					}
					return filter.id === filterId
				}),
		);
	};

	const filterHalfSizes = (sizes) => {
		if (window.Shopify?.shop === 'br-cariuma.myshopify.com') {
			return sizes && sizes.filter(s => !getCountrySize(s.name).includes('.'));
		}
		return sizes
	}

	if (!filtersAvailability || typeof filtersAvailability === 'string') {
		if (filtersAvailability === 'LOADING') {
			return (
				<aside class="c-collectionFilters">
					<div class="c-collectionFilters__loader" />
				</aside>
			);
		}

		return null;
	}

	return (
		<aside class="c-collectionFilters">
			<div class={`c-collectionFilters__inner ${isDesktop ? 'c-collectionFilters__inner--desktop': ''}`}>
				<div class="c-collectionFilters__bar o-flex">
					<div class={`c-collectionFilters__barLeft ${isDesktop ? 'c-collectionFilters__barLeft--desktop': ''}`}>{ window.plpLanguages?.filter_by}</div>
					<div class="c-collectionFilters__barRight">
						<div class={`c-collectionFilters__barRightRow o-flex ${isDesktop ? 'c-collectionFilters__barRightRow--desktop': '' }`}>
							{filters.map((filter) => (
								<div class="c-collectionFilters__barRightCol" key={filter.id}>
									<button
										class={`c-collectionFilters__barButton ${isDesktop ? 'c-collectionFilters__barButton--desktop': '' }`}
										aria-expanded={String(
											Boolean(openFilter && openFilter.id === filter.id),
										)}
										data-filter={filter.id}
										onClick={toggleFilterClick}
									>
										<div class="c-collectionFilters__barButtonInner">
											{filter.label}
										</div>
									</button>
								</div>
							))}
						</div>
					</div>
				</div>

				{openFilter && (
					<div
						class={classNames(
							'c-collectionFilters__dropdown',
							`c-collectionFilters__dropdown--${openFilter.id}`,
							{'c-collectionFilters__dropdown--desktop': isDesktop}
						)}
						key={openFilter.id}
					>
						<div class="c-collectionFilters__dropdownInner">
							<ul class="c-collectionFilters__dropdownList">
								{openFilter.options.map((option) =>
									filtersAvailability[openFilter.id] &&
									filtersAvailability[openFilter.id].includes(option.tag_name) ? (
										<FiltersDropdownOption
											activeFilters={activeFilters}
											openFilter={openFilter}
											option={option}
											checkboxChange={checkboxChange}
											key={option.tag_name}
											isDesktop={isDesktop}
										/>
									) : null,
								)}
							</ul>
						</div>
					</div>
				)}
			</div>
			
			{ !isDesktop &&
				<ActiveFilter
					clearFilters={clearFilters}
					activeFilters={activeFilters}
					setFilter={setFilter}
				/>
			}
		</aside>
	);
};
