/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact'
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import Swatch from '../AB/swatch'
import addonCallback from './addon-callback'

export default class AddonImageSlider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProduct: this.props.selectedProduct ?? null,
      selectedProductImages: this.props.selectedProduct?.node.images.edges.filter((image) => {
        return image.node.altText == null
          && (!this.props.showSlideshowImageOnly || image.node.thumbnailLarge.includes('slideshow'))
          && !image.node.thumbnailLarge.includes('.bundle')
      })
    }

    this.elRef = createRef()
    this.imagesCarousel = null
    this.thumbsCarousel = null
  }

  componentDidMount() {
    this.initCarousels()
  }

  componentWillUpdate(nextProps) {
    if (nextProps && nextProps.selectedProduct !== this.props.selectedProduct) {
      this.setState({
        selectedProduct: nextProps.selectedProduct,
        selectedProductImages: nextProps.selectedProduct?.node.images.edges.filter((image) => {
          return image.node.altText == null
            && (!this.props.showSlideshowImageOnly || image.node.thumbnailLarge.includes('slideshow'))
            && !image.node.thumbnailLarge.includes('.bundle')
        })
      })
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.selectedProduct !== prevState.selectedProduct) {
      this.destroyCarousels()

      setTimeout(() => {
        this.initCarousels()
      }, 50)
    }
  }

  initCarousels() {
    if (!this.elRef.current) {
      return
    }

    const self = this
    const imagesCarouselEl = this.elRef.current.querySelector('.js-addon-images-carousel')
    const thumbsCarouselEl = this.elRef.current.querySelector('.js-addon-thumbs-carousel')
    const itemsCount = imagesCarouselEl?.querySelectorAll('picture').length || 0

    if ( !imagesCarouselEl || itemsCount < 2 || (this.imagesCarousel && !('destroy' in this.imagesCarousel))) {
      return
    }

    thumbsCarouselEl.classList.toggle('addon-thumbs-carousell--center', itemsCount < 3)

    this.thumbsCarousel = new Swiper(thumbsCarouselEl, {
      loop: itemsCount > 3,
      slidesPerView: 3,
      freeMode: true,
      watchSlidesProgress: true
    })

    this.imagesCarousel = new Swiper(imagesCarouselEl, {
      modules: [
        Navigation,
        Thumbs
      ],
      loop: true,
      slidesPerView: 1,
      speed: 600,
      navigation: {
        nextEl: self.elRef.current.querySelector('.swiper-button-next'),
        prevEl: self.elRef.current.querySelector('.swiper-button-prev')
      },
      thumbs: {
        swiper: self.thumbsCarousel
      }
    })
  }

  destroyCarousels() {
    this.imagesCarousel?.destroy()
    this.thumbsCarousel?.destroy()

    this.imagesCarousel = null
    this.thumbsCarousel = null
  }

  changeColor = (id, data) => {
    if (id === this.state.selectedProduct.node.id) {
      return
    }

    this.setState({
      selectedProduct: data
    }, () => {
      if (typeof this.props.onSelectProduct === 'function') {
        this.props.onSelectProduct(this.state.selectedProduct)
      }
    })
  }

  render() {
    return (
      <div class="addon-image-slider">
        {this.state.selectedProduct != null ? (
          <Fragment>
            <div
              key={this.state.selectedProduct.node.id}
              class={`c-upsell__productBlock__socks c-upsell__SocksBlock__${
                this.state.selectedProduct.node.id
              } shown`}
              data-key={this.state.selectedProduct.node.id}
              ref={this.elRef}
            >
              <div class="c-upsell__productImageSocks">
                <div class="swiper js-addon-images-carousel">
                  <div class="swiper-wrapper">
                    {this.state.selectedProductImages?.map((image) => {
                      return (
                        <div class="swiper-slide">
                          <div class="image-el">
                            <picture>
                              <source
                                srcset={image.node.thumbnailLarge}
                                media="(min-width: 900px)"
                              />
                              <source
                                srcset={image.node.thumbnailLarge}
                                media="(min-width: 320px)"
                              />
                              <img
                                class="c-upsell__innerImage"
                                loading="lazy"
                                src={image.node.thumbnailLarge}
                                alt="Cariuma "
                              />
                            </picture>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {this.state.selectedProductImages?.length > 1 &&
                  <Fragment>
                    <div class="swiper-button swiper-button-prev"></div>
                    <div class="swiper-button swiper-button-next"></div>
                  </Fragment>
                }
              </div>
              <div
                className={(this.state.selectedProductImages?.length > 1) ? 'slider-nav-socks swiper js-addon-thumbs-carousel' : 'hidden' }
              >
                <div class="swiper-wrapper">
                  {this.state.selectedProductImages?.map((image) => {
                    return (
                      <div class="swiper-slide">
                        <div class="image-el">
                          <picture>
                            <source
                              srcset={image.node.thumbnailLarge}
                              media="(min-width: 900px)"
                            />
                            <source
                              srcset={image.node.thumbnailLarge}
                              media="(min-width: 320px)"
                            />
                            <img
                              class="c-upsell__innerImage"
                              loading="lazy"
                              src={image.node.thumbnailLarge}
                              alt="Cariuma "
                            />
                          </picture>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              {this.props.data && this.props.data.length > 0 && (
                <div class="c-upsell__productTitle c-upsell__productTitle__socks">
                  <div class="c-cart__itemUpsell__socks">
                    {addonCallback('addonColorTitle', this.props.type)}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        ) : (
          <div class="c-upsell__productBlock__socks shimmer shown"></div>
        )}
        {this.props.data && this.props.data.length > 0 &&
          <div class={`c-upsell__variantBlock c-upsell__productGroup__${this.props.activeGroupIdx}`}>
            {this.props.data.map((each, index) => {
              return (
                <div
                  key={each.node.id}
                  class={`c-productVariant__item__socks c-productVariant__item__socks__${each.node.id}  show ${
                    each.node.id === this.state.selectedProduct.node.id ? "is-select is-active " : ""
                  }`}
                  data-key={each.node.id}
                >
                  <Swatch
                    productData={each}
                    index={index}
                    changeColor={this.changeColor}
                  />
                </div>
              );
            })}
          </div>
        }
      </div>
    )
  }
}