import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

const init = el => {
  let swiper

  const breakpointChecker = function() {
    if (window.innerWidth < 768) return enableSwiper()
    if (swiper !== undefined) swiper.destroy(true, true)
  }

  const enableSwiper = () => {
    if (swiper && !swiper.hasOwnProperty('destroyed')) return

    swiper = new Swiper(el.querySelector('.swiper'), {
      modules: [
        Navigation
      ],
      cssMode: false,
      slidesPerView: 'auto',
      watchOverflow: true,
      loop: false,
      speed: 600,
      navigation: {
        nextEl: el.querySelector('.swiper-button-next'),
        prevEl: el.querySelector('.swiper-button-prev'),
      }
    })
  }

  window.addEventListener('resize', () => breakpointChecker())
  breakpointChecker()
}

export default () => {
  document.querySelectorAll('.js-tshirt-title').forEach(el => init(el))
}
