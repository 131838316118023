/** @jsx h */

import { h, Component, Fragment, createRef } from 'preact';
import SizeDropdown from './SizeDropdown';
import $ from 'jquery';
import SizeDropdownUnisex from './SizeDropdownUnisex';
import ShippingAndJumpSection from './ShippingAndJumpSection'
import FourOceanMessage from '../components/FourOceanMessage';
import WorldOceanDayMessage from '../components/WorldOceanDayMessage';
import classNames from "classnames";
import fetch from "cross-fetch";
import { graphQLRequest } from '../collection/request';
import moment from 'moment';
import { formatId } from '../collection/utils';
import SizeSelector from './SizeSelector';
import SizeSelectorUnisex from './SizeSelectorUnisex';
import { toNumber, ceil } from 'lodash-es';
import InstallmentPayments from './InstallmentPayments';
import Cookies from 'js-cookie';
import { getPriceWithoutCurrency } from '../cart/AB/utils';
import { formatMoney, taxInclusiveCurrencies, getPriceAmount } from '../../../utils/money'
import { getTaxAndDutiesMessage } from './utils';
import productDetailAccordion from '@/scripts/product-detail-accordion';
import { getVariantInventoryQuantity } from './utils'
import { BUNDLE_DISCOUNT_TYPE, getBundleDiscountAmount } from './kids-bundle';
import AddonUpsell from './AddonUpsell';
import FamilyCrossSell from './FamilyCrossSell'
import getUrl from '@/scripts/utils/get-url'
import PersonalizationModal from './PersonalizationModal';
import translate from '../../../utils/translate'

const PRE_ORDER_TAG = 'HC:Pre-Order'
const BACKORDER_TAG = 'HC:Backorder'

const productInfoCache = {};
const languages = window.pdpLanguages ?? {};

export default class SizeAndCart extends Component {
	constructor(props) {
		super(props);

		this.state = {
			VariantForAddTOcart: null,
			showCheckout: false,
			variantAvailableForSale: false,
			productData: null,
			variantAvailableForPreOrder: false,
			productName : null,
			selectedVariantSize : null,
      		selectedVariantTranslatedSize: null,
			cartCount : null,
			singleShoe: false,
			isClothing: false,
     		isHoodie: false,
			isBackpack: false,
			lastOneMessageMobile : false,
			lastFewMessageMobile : false,
			includeFitPredictor: false,
			razerEcoMessage: false,
			enableCustomTreePlant: false,
			is4oceanTemplate: false,
			isWorldOceanDayTemplate: false,
			shipsToCountries: null,
			restrictedCountries: null,
			addOnUpsellData: window.pdpAddOnUpsell,
			isAddedToCart: false,
			shippingRestrictionMessage: null,
			personalizeModalOpen: false,
    		personalizationText: ''
		};

		this.waitListFormRef = createRef()
		this.afterPayRef = createRef()
		this.productDetailsRef = createRef()
	}


	shipsToCountries = () => {
		fetch('/search?view=shop')
		.then(response => response.json())
		.then(json => {
			this.setState({
				shipsToCountries: json.shop.shipsToCountries
			}, () => {
				this.checkCurrentCountry()
			})
		})
		.catch((e) => {
			console.error(e);
		});
	};

	componentDidMount() {
    	this.checkProductAddedToCart()

		/* SSP */
		this.sspRender()

    	this.getRestrictedCountries()

    	this.shipsToCountries()

		const isSingleShoe =  this.props.product.node.tags.includes('Single shoe');
		const isClothing =  this.props.product.node.tags.includes('clothing');
		const isBackpack =  this.props.product.node.tags.includes('backpack');
    	const isHoodie = this.props.product.node.handle.includes('hoodie');
		const razerShoe = window.location.href.includes('razer');

		const is4oceanTemplate = document.body.classList.contains('template-4ocean');
		const isWorldOceanDayTemplate = document.body.classList.contains('template-world-ocean-day');

    	const enableCustomTreePlant = window.__GLOBAL__['10_trees_promo'];

		if ( isSingleShoe ) {
			this.setState({
				singleShoe : true
			})
		}

		if ( isClothing ) {
			this.setState({
				isClothing : true
			})
		}

		if ( isBackpack ) {
			this.setState({
				isBackpack : true
			})
		}

		if (isHoodie) {
			this.setState({
				isHoodie: true
			})
		}

		if ( razerShoe ) {
			this.setState({
				razerEcoMessage : true
			})
		}

		if ( is4oceanTemplate ) {
			this.setState({
				is4oceanTemplate : is4oceanTemplate
			})
		}

		if (isWorldOceanDayTemplate) {
			this.setState({
				isWorldOceanDayTemplate
			})
		}

		this.setState({
			enableCustomTreePlant : enableCustomTreePlant
		})

		var me=this;

		$('.c-popupClose').on("click", function () {
			$('.c-popup').removeClass('open');
			$('.c-popupOverlay').removeClass('open');
		});

		var notificationCallback = function(data) {
			if (data.status == 'Error') {
				const { errors } = data
				$('.jsBisError').show();

				if(errors.phone_number){
					$('.jsBisError').text(errors.phone_number)
				} else if(errors.email){
					$('.jsBisError').text(errors.email)
				} else if(errors.base){
					$('.jsBisError').text(errors.base)
				}
			}

			if (data.status == 'OK') {

				$('.jsBisSuc').show();
				$('.jsBisError').hide();

				setTimeout(function() {
					$('.js-popup').removeClass('open');
					$('.c-popupOverlay').removeClass('open');
				}, 2000 );

			}
		}

		$('.c-popupBody_number').on("change", function(){
			if($(this).val().length > 0){
				$('.email').attr("disabled", true)
				$('.email').addClass("input-disabled")
			} else {
				$('.email').attr("disabled", false)
				$('.email').removeClass("input-disabled")
			}
		})

		$('.c-popupBody_number').on("focus", function(){
			$('.email').attr("disabled", true)
			$('.email').addClass("input-disabled-focus")
		})

		$('.c-popupBody_number').on("focusout", function(){
			$('.email').attr("disabled", false)
			$('.email').removeClass("input-disabled-focus")
		})

		$('.email').on("change", function(){
			if($(this).val().length > 0){
				$('.c-popupBody_number').attr("disabled", true)
				$('.c-popupBody_number').addClass("input-disabled")
			} else {
				$('.c-popupBody_number').attr("disabled", false)
				$('.c-popupBody_number').removeClass("input-disabled")
			}
		})

		$('.email').on("focus", function(){
			$('.c-popupBody_number').attr("disabled", true)
			$('.c-popupBody_number').addClass("input-disabled-focus")
		})

		$('.email').on("focusout", function(){
			$('.c-popupBody_number').attr("disabled", false)
			$('.c-popupBody_number').removeClass("input-disabled-focus")
		})

		$('.JSsend').on("click", function() {
			var variantID = me.state.VariantForAddTOcart !==null ? me.state.VariantForAddTOcart.id : '';
			var $popup = $(this).closest('.js-popup');
			var capEmail = $popup.find('.email').val();
			var capPhone = $popup.find('.c-popupBody_number').val()
			if (capPhone[0] != "+") {
				capPhone = "+" + capPhone;
			}

			var productID=me.state.VariantForAddTOcart.productID;
			if(capEmail.length > 0){
				BIS.create(capEmail, variantID, productID).then(notificationCallback);
			} else {
				BIS.create(null, variantID, productID, {phone_number: capPhone}).then(notificationCallback)
			}
		});

		document.addEventListener('cart-empty', () => {
			me.setState({
				showCheckout: false
			})
		});

		this.props.product.node.variants.edges.forEach(variant => {
			variant.node.id = formatId(variant.node.id);
			variant.node = { ...variant.node }
		});

		((this.props.oppGenderProduct !== null) && (this.props.oppGenderProduct.variants)) ? this.props.oppGenderProduct.variants.edges.forEach(variant => {
			variant.node.id = formatId(variant.node.id);
			return variant.node = { ...variant.node }

		}) : null;
		this.setState({ productData: "dd" })
	}

	componentDidUpdate() {
		setTimeout( () => {
			const afterPay = this.afterPayRef.current
			const style = document.createElement('style')

			style.innerHTML = `
				.afterpay-paragraph {
				font-size: 12px !important;
				line-height: 14px;
				font-family: "HCo Gotham";
				color: #4A4A4A;
				font-weight: 300;
				margin-top: 0;
				}

				@media only screen and (max-width: 767px) {
					.afterpay-paragraph {
						font-size: 10px !important;
					}
				}

				@media only screen and (min-width: 768px) {
					.afterpay-paragraph {
						font-size: 12px !important;
					}
				}
				.afterpay-logo {
					padding-top: 0;
					width: auto !important;
					margin: 0;
					height: 14px;
					cursor: pointer;
				}

				.payment-amount + span {
					padding-right :0;
				}

				.afterpay-logo svg {
					display: inline;
					max-width: 70px;
					height: 14px;
					width: 70px !important;
					margin: 0;
				}

				.afterpay-logo-link {
					position: relative;
				}

				.afterpay-link {
					width: 80px;
					height: 14px;
					position: absolute;
					left: 0;
					opacity: 0;
				}

				.c-afterpay .afterpay-logo.brand-afterpay svg {
					max-width: 80px;
				}
				`

			if (afterPay !== null) {
				if(afterPay && afterPay.shadowRoot && (afterPay.shadowRoot.children.length < 3)) {
					afterPay.shadowRoot.appendChild(style)
				}
			}
		}, 0)

		if (this.state.VariantForAddTOcart?.id) {
			if (this.props.onUpdate) {
				this.props.onUpdate()
			}
		}

		if (this.state.productData !== null) {
			// update accordion eco message
			let message = !this.state.enableCustomTreePlant ?
				(this.state.singleShoe ? languages.eco_message_single_shoe
					: this.state.razerEcoMessage ? languages.eco_message_razer
					: this.state.isClothing ? (this.state.isHoodie ? languages.eco_message_hoodie : languages.eco_message_t_shirt)
					: this.state.isBackpack ? languages.eco_message_backpack
					: languages.eco_message_default
				)
				: 
				(this.state.singleShoe ? languages.eco_message_custom_single_shoe
					: this.state.razerEcoMessage ? languages.eco_message_custom_razer
					: this.state.isClothing ? (this.state.isHoodie ? languages.eco_message_custom_hoodie : languages.eco_message_custom_t_shirt)
					: this.state.isBackpack ? languages.eco_message_custom_backpack
					: `<img class="c-pdp__ecoMessageGif l93" src="https://cdn.shopify.com/s/files/1/2495/5044/files/Animation-RO-v3.gif?v=1606315995" width="250" height="35" alt="${languages.eco_message_custom_default}"/>`
				)

      if ((this.props.product.node.treePlant?.value ?? '') !== '') {
				const treePlantMessage = (this.state.singleShoe ? languages.eco_message_tree_plant_single_shoe
					: this.state.razerEcoMessage ? languages.eco_message_tree_plant_razer
					: this.state.isClothing ? (this.state.isHoodie ? languages.eco_message_tree_plant_hoodie : languages.eco_message_tree_plant_t_shirt)
					: this.state.isBackpack ? languages.eco_message_tree_plant_backpack
					: languages.eco_message_tree_plant_default
				)

        const treePlant = Number(this.props.product.node.treePlant.value) * (this.state.enableCustomTreePlant ? 5 : 1)
        message = translate(treePlantMessage, { tree_plant: `${treePlant}` })
      }

			$('.js-product-eco-message').html(message)

			if (message.includes('<img')) {
				$('.product-detail-accordion__item--eco').addClass('product-detail-accordion__item--eco-gif')
			} else {
				$('.product-detail-accordion__item--eco').removeClass('product-detail-accordion__item--eco-gif')
			}

      // move product details to buy box
      if (!window.Shopify.designMode) {
        let productDetailSelector

        if (this.props.isKids) {
          productDetailSelector = '.js-product-detail-accordion[data-type="kids"]'
        } else if (this.props.isAdult) {
          productDetailSelector = `.js-product-detail-accordion[data-type="adult"][data-style="${this.props.adultStyle}"]`
        } else {
          productDetailSelector = '.js-product-detail-accordion'
        }

        const productDetailsAccordionEl = document.querySelector(productDetailSelector)

				if (this.productDetailsRef.current?.innerHTML === '' && productDetailsAccordionEl) {
					// hide the old one
					productDetailsAccordionEl.parentNode.style.display = 'none'

					// init the new one
					this.productDetailsRef.current.innerHTML = productDetailsAccordionEl.outerHTML
					const newProductDetailsAccordionEl = this.productDetailsRef.current.querySelector('.product-detail-accordion')
					const items = this.productDetailsRef.current.querySelectorAll('.product-detail-accordion__item')

					items.forEach(item => item.classList.remove('is-active'))

					const textEl = this.productDetailsRef.current.querySelector('.js-product-detail-accordion-text')
					const splitDescription = this.props.product.node.descriptionHtml?.split('<p>[ split ]</p>') || []
					let text = ''
					if (splitDescription.length > 0 && textEl) {
						text += splitDescription[0] ? splitDescription[0].replaceAll('<p> </p>', '').replaceAll('<p></p>') : ''
						text += splitDescription[1] ? splitDescription[1].replaceAll('<p> </p>', '').replaceAll('<p></p>') : ''
						let pdpTitle = this.props.product.node.title
						const seoTxt = ['Men', 'Women', 'Kids']
						seoTxt.forEach(txt => {
							if (pdpTitle.includes(txt)) {
								const pdpTitleNew = pdpTitle.replace(txt, '')

								if (pdpTitle.includes('Kids')) {
								pdpTitle = txt + '\' ' + pdpTitleNew
								} else {
								pdpTitle = txt + '\'s ' + pdpTitleNew
								}
							}
						})
						text += `<p test>Shop the ${pdpTitle}</p>`
						textEl.innerHTML = text
					}

					newProductDetailsAccordionEl.style.display = ''
					newProductDetailsAccordionEl.classList.remove('is-initialized')
					productDetailAccordion()
				}
			}

			// update video
			document.querySelectorAll('.js-pdp-product-video').forEach(videoWrapper => {
				const videoLink = this.props.product.node?.videoLinks?.value

				if (videoLink) {
					videoWrapper.style.display = ''
					videoWrapper.querySelectorAll('video').forEach(videoEl => {
						if (videoEl.src !== videoLink) {
							videoEl.src = videoLink
						}
					})
				} else {
					videoWrapper.style.display = 'none'
				}
			})
		}
 	}

	checkProductAddedToCart = () => {
		const cartItems = CartJS.cart.items
		const productId = this.props.product.node.id
		if (cartItems && productId) {
			const index = cartItems.findIndex(each => each.product_id && each.product_id === parseInt(productId))
			if (index > -1) {
				this.setState({
					isAddedToCart : true
				})
			}
		}
	}

	checkCurrentCountry = () => {
		const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
		if (window.countryCode) {
			const currentCountryCode = window.countryCode
			let isRestrictCountry = false
			if (!this.state.shipsToCountries.includes(currentCountryCode)) {
				isRestrictCountry = true
			}

			if (this.state.restrictedCountries && this.state.restrictedCountries.includes(currentCountryCode)) {
				isRestrictCountry = true
			}

			if (GLOBAL.shippingRestrictionMessage && isRestrictCountry) {
				const currentCountryName = regionNamesInEnglish.of(currentCountryCode)
				const message = currentCountryName ? GLOBAL.shippingRestrictionMessage.replace('[[country]]', currentCountryName): ''
				this.setState({
					shippingRestrictionMessage: message
				})
			}
		}
	}

	getRestrictedCountries = () => {
		if (this.props.product.node.restrictedCountries && this.props.product.node.restrictedCountries.value) {
			this.setState({
				restrictedCountries: this.props.product.node.restrictedCountries.value.split('|').map(item => item.trim())
			})
		}
	}

	OpenBISForm = () => {
		if (!this.waitListFormRef?.current) {
		return
		}

		// Close popups on button click
		$(this.waitListFormRef.current).addClass('open');
		$('.c-popupOverlay').addClass('open');
		// $('.js-popup input.name').val("");
		$('.js-popup .email').val("");
		$('.jsBisSuc').hide();
	}

	sspRender = () => {
		if ((window.SSPSTATUS) && (window.SSPSTATUS.inited) && (typeof window.sspRenderPredictor == 'function')) {
			window.sspRenderPredictor()
			this.setState({
				'includeFitPredictor': true
			})
		}
	}

	sspAddToCart = (productId, variantId, quantity) => {
		if ((window.SSPSTATUS) && (window.SSPSTATUS.rendered) && (typeof window.sspAddToCartPredictor == 'function')) {
			window.sspAddToCartPredictor(productId, variantId, quantity)
		}
	}

	getProductInfo = async (productHandle) => {
		return new Promise(resolve => {
			if (productHandle in productInfoCache) {
				resolve(productInfoCache[productHandle])
				return
			}

			fetch(getUrl(`/products/${productHandle}?view=info`))
			.then(response => response.json())
			.then(json => {
			productInfoCache[productHandle] = json
			resolve(productInfoCache[productHandle])
			})
			.catch(() => resolve({}))
		})
	}

	AddtoCart = async () => {
		let me = this;
		let size = this.state.VariantForAddTOcart.size;
		let promiseObject = this.state.VariantForAddTOcart.PROMISE_DATE ? JSON.parse(this.state.VariantForAddTOcart.PROMISE_DATE) : null
    	let promiseDate, note

		if (promiseObject?.promise_date) {
			promiseDate = promiseObject.promise_date
			note = promiseObject.preorderType
		} else {
			promiseDate = this.state.VariantForAddTOcart.PRE_ORDER_DATE ?? this.state.VariantForAddTOcart.BACKORDER_DATE
			note = this.state.VariantForAddTOcart.PRE_ORDER_DATE ? 'PRE_ORDER' : 'BACKORDER'
		}

		let cid = formatId(this.state.VariantForAddTOcart.id)
		let gender = window.location.pathname.includes('women') ? 'Women' : 'Men';
		let preorderestimate =$('.c-abPreOrder').first().text();

		const info = await this.getProductInfo(this.props.product.node.handle)
		const laceUpsellCollection = info.laceUpsellCollection

		let size_otherCountrySize = '', size_otherCountry = '';

		if (this.state.VariantForAddTOcart.multiCountrySize) {
			const multiCountrySize = this.state.VariantForAddTOcart.multiCountrySize
			size_otherCountrySize = gender == "Men" ? `M ${multiCountrySize.sizeMen} / W ${multiCountrySize.sizeWomen}` : `W ${multiCountrySize.sizeWomen} / M ${multiCountrySize.sizeMen}`;
			switch (multiCountrySize.sizeCountry) {
				case 'us_size':
				break;
				case 'uk_size':
					size_otherCountry = "Size UK"
				break;
				case 'eur_size':
					size_otherCountry = "Size EU"
				break;
				case 'aus_size':
					size_otherCountry = "Size AUS"
				break;
				case 'br_size':
					size_otherCountry = "Size BR"
				break;
				case 'jp_size':
					size_otherCountry = "Size JP"
				break;
			}
		}

		let cartProperties = {
			"Size": size,
			"_size": size,
			"_reviewBadge" : me.props.product.node.review?.value,
			"_tags" : me.props.product.node.tags,
			"_laceUpsell": laceUpsellCollection,
			"_Gender" : gender
		}

		if (me.props.product.node?.compareAtPriceRange?.maxVariantPrice?.amount) {
			cartProperties["_compare_at_price"] = me.props.product.node?.compareAtPriceRange?.maxVariantPrice?.amount;
		}

		if ((size_otherCountry) && (size_otherCountrySize)) {
			cartProperties['_size_otherCountry'] = size_otherCountry
			cartProperties['_size_otherCountrySize'] = size_otherCountrySize

      		const multiCountrySize = this.state.VariantForAddTOcart.multiCountrySize

      if (multiCountrySize.sizeCountry == 'br_size' || multiCountrySize.sizeCountry == 'jp_size') {
        delete cartProperties['Size']
        cartProperties[languages.size_other_label] = gender === 'Men' ? multiCountrySize.sizeMen : multiCountrySize.sizeWomen
      } else {
        cartProperties[size_otherCountry] = size_otherCountrySize
      }
    } else {
      if (Shopify.locale === 'ja') {
        delete cartProperties['Size']
        cartProperties[languages.size_us_label] = size.replace(/women/i, 'W').replace(/men/i, 'M')
      }
    }

		if (window.gatedArchiveSale.isEnable() && me.props.product.node.tags.includes('_gated_archive_sale')) {
			cartProperties['_gatedArchiveSale'] = 'true'
			cartProperties['_is_final_sale'] = 'true'
		}

		let treePlant = 0
    	let coralPlant = 0

		if (!this.props.product.node.tags.includes('4Ocean') && !this.state.is4oceanTemplate && !this.state.isWorldOceanDayTemplate || this.state.isClothing || this.state.isBackpack) {
			if (!this.state.enableCustomTreePlant) {
        if ((this.props.product.node.treePlant?.value ?? '') !== '') {
          treePlant = Number(this.props.product.node.treePlant.value)
        } else if (this.state.singleShoe || this.state.isClothing || this.state.isBackpack) {
					treePlant = 1
				} else if (this.state.razerEcoMessage) {
					treePlant = 10
				} else {
					treePlant = 2
				}
			} else {
        if ((this.props.product.node.treePlant?.value ?? '') !== '') {
          treePlant = Number(this.props.product.node.treePlant.value) * 5
				} else if (this.state.singleShoe || this.state.isClothing || this.state.isBackpack) {
					treePlant = 5
				} else if (this.state.razerEcoMessage) {
					treePlant = 10
				} else {
					treePlant = 10
				}
			}
		}

		if (this.state.isWorldOceanDayTemplate) {
		coralPlant = 10
		}

		const matchedVariantInCart = CartJS.cart.items.filter(item => item.id === parseInt(cid))

		if (treePlant > 0) {
			if (matchedVariantInCart.length > 0 && matchedVariantInCart[0].properties.hasOwnProperty('_treePlant')) {
				cartProperties['_treePlant'] = parseInt(matchedVariantInCart[0].properties._treePlant) + treePlant
			} else {
				cartProperties['_treePlant'] = treePlant
			}
		}

    	if (coralPlant > 0) {
			if (matchedVariantInCart.length > 0 && matchedVariantInCart[0].properties.hasOwnProperty('_coralPlant')) {
				cartProperties['_coralPlant'] = parseInt(matchedVariantInCart[0].properties._coralPlant) + coralPlant
			} else {
				cartProperties['_coralPlant'] = coralPlant
			}
		}

		let addItem = (...args) => CartJS.addItem(...args)
    let addItems = (...args) => CartJS.addItems(...args)
		let quantity = 1
		let id = cid

		if (matchedVariantInCart.length > 0) {
			addItem = (...args) => CartJS.updateItem(...args)
			quantity = matchedVariantInCart[0].quantity + 1
			id = CartJS.cart.items.findIndex(x => x.id === matchedVariantInCart[0].id) + 1
		}

		cartProperties['__timestamp'] = new Date().getTime()
		cartProperties['__inventoryQuantity'] = this.state.VariantForAddTOcart.inventoryQuantity
		cartProperties['__inventoryPolicy'] = this.state.VariantForAddTOcart.inventoryPolicy
		cartProperties['__promiseDateRaw'] = this.state.VariantForAddTOcart.PROMISE_DATE

    const addItemOptions = {
      "success": () => {
        /* SSP */
        me.sspAddToCart(me.props.product.node.id, cid, 1)
        me.setState({ isAddedToCart: true, showCheckout: true })
      },
      "error": function (jqXHR, textStatus, errorThrown) {
        window.addToCartFailed = true
        me.props.refreshProductInfo()
        console.log('Error: ', errorThrown, textStatus, jqXHR);
      }
    }

		if (this.state.variantAvailableForPreOrder) {
			cartProperties['_PREORDER'] = 'PREORDER'
			cartProperties['_preorderestimate'] = preorderestimate
			cartProperties['__note'] = note
			cartProperties['__promiseDate'] = promiseDate

      if (this.props.showBundle && typeof this.props.afterAddedBundle === 'function') {
        this.props.afterAddedBundle({ id, quantity, properties: cartProperties })
        return
      }

      const { id: kidsLineItemId, quantity: kidsLineItemQuantity, properties: kidsLineItemProperties } = this.props.kidsLineItem || {}

      if (kidsLineItemId) {
        const bundleId = new Date().getTime()

        addItems([
          {
            id: kidsLineItemId,
            quantity: kidsLineItemQuantity,
            properties: {
              ...kidsLineItemProperties,
              __bundleId: bundleId,
              __isKids: true,
            }
          },
          {
            id,
            quantity,
            properties: {
              ...cartProperties,
              __bundleId: bundleId,
              __isAdult: true,
            }
          }
        ], addItemOptions)
      } else {
        addItem(id, quantity, cartProperties, addItemOptions);
      }
		} else {
			if(this.props.productCategory !== 'CATIBA HIGH'){
        if (this.props.showBundle && typeof this.props.afterAddedBundle === 'function') {
          this.props.afterAddedBundle({ id, quantity, properties: cartProperties })
          return
        }

        const { id: kidsLineItemId, quantity: kidsLineItemQuantity, properties: kidsLineItemProperties } = this.props.kidsLineItem || {}

        if (kidsLineItemId) {
          const bundleId = new Date().getTime()

          addItems([
            {
              id: kidsLineItemId,
              quantity: kidsLineItemQuantity,
              properties: {
                ...kidsLineItemProperties,
                __bundleId: bundleId,
                __isKids: true,
              }
            },
            {
              id,
              quantity,
              properties: {
                ...cartProperties,
                __bundleId: bundleId,
                __isAdult: true,
              }
            }
          ], addItemOptions)
        } else {
          addItem(id, quantity, cartProperties, addItemOptions);
        }
			} else {
				const freeInsole = window.freeInsoleData;
				const sizeAndGenderArr = this.state.selectedVariantSize.split('/');
				const currentSelectedSize = sizeAndGenderArr[1].trim();
				const currentSelectedGender = sizeAndGenderArr[0].trim();

				let foundFreeInsoleVariant = freeInsole.variants.find(each => {
					if(currentSelectedGender=="Men"){
						//for men
						if(currentSelectedSize >= 8){
							if(each.option3 == currentSelectedSize && each.option1 === currentSelectedGender){
								return each;
							} else{
								return undefined;
							}
						}else{
							let currentSelectedSizeWomen=Number(currentSelectedSize)+1.5;
							if(each.option3 == currentSelectedSizeWomen  && each.option1 === "Women"){
								return each;
							} else{
								return undefined;
							}
						}
					}else{
						//for women
						if(currentSelectedSize< 8){
							if(each.option3 == currentSelectedSize && each.option1 === currentSelectedGender){
								return each;
							} else{
								return undefined;
							}
						}else{
							let currentSelectedSizeMen=Number(currentSelectedSize)-1.5;
							if(each.option3 == currentSelectedSizeMen  && each.option1 === "Men"){
								return each;
							} else{
								return undefined;
							}
						}
					}

				})

				if(foundFreeInsoleVariant.inventory_quantity <= 0){
					foundFreeInsoleVariant = undefined
				}

				cartProperties['_linkWithInsole'] = foundFreeInsoleVariant ? foundFreeInsoleVariant.id : undefined

        if (this.props.showBundle && typeof this.props.afterAddedBundle === 'function') {
          this.props.afterAddedBundle({ id, quantity, properties: cartProperties })
          return
        }

        const { id: kidsLineItemId, quantity: kidsLineItemQuantity, properties: kidsLineItemProperties } = this.props.kidsLineItem || {}

        if (kidsLineItemId) {
          const bundleId = new Date().getTime()

          addItems([
            {
              id: kidsLineItemId,
              quantity: kidsLineItemQuantity,
              properties: {
                ...kidsLineItemProperties,
                __bundleId: bundleId,
                __isKids: true,
              }
            },
            {
              id,
              quantity,
              properties: {
                ...cartProperties,
                __bundleId: bundleId,
                __isAdult: true,
              }
            }
          ], addItemOptions)
        } else {
          addItem(id, quantity, cartProperties, addItemOptions);
        }
			}
		}
	}

	handdleFreeInsoleAddOn = (quantityToUpdate, currentSizeProperty, foundFreeInsoleVariant, productId) => {
		let isAlreadyInCart = false;
		let updateIndex;
		const cartItems = CartJS.cart.items;

		if(foundFreeInsoleVariant && foundFreeInsoleVariant.inventory_quantity > 0){
			for(let i = 0; i < cartItems.length; i++){
				const current = cartItems[i];
				if(current.id == foundFreeInsoleVariant.id && current.properties._linkWith == productId){
					isAlreadyInCart = true;
					updateIndex = i + 1;
					break;
				}
			}

			if(isAlreadyInCart){
				CartJS.updateItem(updateIndex, undefined,  {
					"Size": currentSizeProperty,
					'_linkWith': productId,
					'freeInsoleCount': parseInt(quantityToUpdate),
				},
					{
						"error": function(jqXHR, textStatus, errorThrown){},
						"success": function(jqXHR, textStatus, errorThrown){},
				});
			} else {
				CartJS.addItem(foundFreeInsoleVariant.id, 1, {
					"Size": currentSizeProperty,
					'_linkWith': productId,
					'freeInsoleCount': 1,
				})
			}
		}
	}

	CurrentVariant = (selectedVariant, VariantObjectType) => {
		let VariantForAddTOcart = {
			"id": null,
			"quantity": '1',
			"Size": null,
			"productId" : null,
			"multiCountrySize" : null
		}

		let found ;
		const totalQuantity = getVariantInventoryQuantity(selectedVariant)
		if (totalQuantity == 1) {
			this.setState({
				lastOneMessageMobile : true
			})
		} else {
			this.setState({
				lastOneMessageMobile : false
			})
		}

		if (totalQuantity <= 7 && totalQuantity >= 2) {
			this.setState({
				lastFewMessageMobile : true
			});
		} else {
			this.setState({
				lastFewMessageMobile : false
			})
		}

		if (VariantObjectType === "node") {
			if (getVariantInventoryQuantity({id: selectedVariant.node.id, inventoryQuantity: selectedVariant.node.quantityAvailable}) > 0) {
				//add to cart
				this.setState({ variantAvailableForSale: selectedVariant.node.availableForSale, variantAvailableForPreOrder: false })
			} else {
				if (selectedVariant.node.currentlyNotInStock) {
					//pre order
					this.setState({ variantAvailableForSale: true, variantAvailableForPreOrder: true })
				}
				else {
					this.setState({ variantAvailableForSale: false })
				}
			}
			 found= this.props.product.node.variants.edges.find(variant => variant.node.id==selectedVariant.node.id )
			VariantForAddTOcart.id = selectedVariant.node.id;
			VariantForAddTOcart.size = selectedVariant.node.selectedOptions[2].value;
			VariantForAddTOcart.sku = selectedVariant.node.sku;
			VariantForAddTOcart.title = selectedVariant.node.title;
			VariantForAddTOcart.price = selectedVariant.node.price.amount ?? selectedVariant.node.price;
			VariantForAddTOcart.PRE_ORDER_DATE = selectedVariant?.node?.PRE_ORDER_DATE?.value
			VariantForAddTOcart.BACKORDER_DATE = selectedVariant?.node?.BACKORDER_DATE?.value
			VariantForAddTOcart.PROMISE_DATE = selectedVariant?.node?.PROMISE_DATE?.value;
			VariantForAddTOcart.inventoryQuantity = selectedVariant.node?.inventoryQuantity;
			VariantForAddTOcart.inventoryPolicy = selectedVariant.node?.inventoryPolicy;
		} else {

			if (getVariantInventoryQuantity(selectedVariant) > 0) {
				//add to cart
				this.setState({ variantAvailableForSale: selectedVariant.available, variantAvailableForPreOrder: false })
			} else {
				if (selectedVariant.inventoryPolicy) {
					//pre order
					this.setState({ variantAvailableForSale: true, variantAvailableForPreOrder: true })
				}
				else {
					this.setState({ variantAvailableForSale: false })
				}
			}
			if(selectedVariant.multiCountrySize){
				VariantForAddTOcart.multiCountrySize = selectedVariant.multiCountrySize;
			}
			found= this.props.product.node.variants.edges.find(variant => variant.node.id==selectedVariant.id )
			VariantForAddTOcart.id = selectedVariant.id;
			VariantForAddTOcart.size = selectedVariant.size;
			VariantForAddTOcart.sku = selectedVariant.sku;
			VariantForAddTOcart.title = selectedVariant.title;
			VariantForAddTOcart.price = selectedVariant.price.amount ?? selectedVariant.price;
			VariantForAddTOcart.productID=selectedVariant.productID;
      		VariantForAddTOcart.PRE_ORDER_DATE = selectedVariant?.PRE_ORDER_DATE
      		VariantForAddTOcart.BACKORDER_DATE = selectedVariant?.BACKORDER_DATE
			VariantForAddTOcart.PROMISE_DATE = selectedVariant?.PROMISE_DATE;
      		VariantForAddTOcart.inventoryQuantity = selectedVariant?.inventoryQuantity;
      		VariantForAddTOcart.inventoryPolicy = selectedVariant?.inventoryPolicy;
		}
			
		// for notify me
		var Men = "Men";
		var Women = "Women";
		var progender = this.props.product.node.options[0].values[0];
		var prosize = VariantForAddTOcart.size;
		VariantForAddTOcart.actualSize=prosize;
		var size;
		let translatedSize;

		if (this.props.product.node.handle.indexOf(Men) != -1) {
			//women
			var upTitle = progender.replace("Men", "Women");
			var upSizeNum = parseFloat(prosize);

			var upSizeCorrect = upSizeNum + 1.5;

			size=upTitle + ' / ' + upSizeCorrect;

			if (VariantForAddTOcart.multiCountrySize) {
				translatedSize = languages.women + ' / ' + VariantForAddTOcart.multiCountrySize.sizeWomen;
			} else {
				translatedSize = size
			}

		} else if (this.props.product.node.handle.indexOf(Women) != -1) {
			//men
			var upTitleMen = progender.replace("Women", "Men");

			var upSizeNumMen = parseFloat(prosize);

			var upSizeCorrectMen = upSizeNumMen - 1.5;
			size=upTitleMen + ' / ' + upSizeCorrectMen;

			if (VariantForAddTOcart.multiCountrySize) {
			translatedSize = languages.men + ' / ' + VariantForAddTOcart.multiCountrySize.sizeWomen;
			} else {
			translatedSize = size
			}
		} else {
			size=progender + ' / ' + prosize;

			if (VariantForAddTOcart.multiCountrySize) {
				switch (progender) {
					case "Men":
					translatedSize = languages.men + ' / ' + VariantForAddTOcart.multiCountrySize.sizeMen;
					break;
					case "Women":
					translatedSize = languages.women + ' / ' + VariantForAddTOcart.multiCountrySize.sizeWomen;
					break;
					default:
					translatedSize = progender + ' / ' + prosize;
				}
			} else {
				translatedSize = size;
			}
		}

		this.setState({
			selectedVariantSize: size,
			selectedVariantTranslatedSize: translatedSize
		})

		if (
			this.props.product.node.handle.includes('-men') ||
			this.props.product.node.handle.includes('-women')
    	) {
			//for size in addtocart
			var fake_gender = ''; // gender of current product page
			var real_gender = ''; // gender from which the real shoe size will be returned
			var fake_size = 0; // set fake size based on whether object returned is variant or custom
			var real_size = 0; // fake size +/- 1.5

			if(typeof(found) === "undefined"){
				//not from the same gender
				if(this.props.product.node.handle.includes('women')){
					// Women Size Selection
					fake_gender = 'M';
					real_gender = 'W';
					real_size = parseFloat(VariantForAddTOcart.size);
					fake_size= (parseFloat(real_size) - 1.5);
				}else{
					// Men Size Selection
					fake_gender = 'W';
					real_gender = 'M';
					real_size=parseFloat(VariantForAddTOcart.size);
					fake_size = (parseFloat(real_size) + 1.5);
				}
			}else{
				//same gender
				if(this.props.product.node.handle.includes('women')){
					// Women Size Selection
					fake_gender = 'M';
					real_gender = 'W';
					real_size= parseFloat(VariantForAddTOcart.size)
					fake_size = (parseFloat(real_size) - 1.5);
				}else{
					// Men Size Selection
					fake_gender = 'W';
					real_gender = 'M';
					real_size=parseFloat(VariantForAddTOcart.size)
					fake_size = (parseFloat(real_size) + 1.5);
				}
			}

			VariantForAddTOcart.size = real_gender + ' ' + real_size + ' / ' + fake_gender + ' ' + fake_size;
		}

		this.setState({ VariantForAddTOcart: VariantForAddTOcart, showCheckout: false , productName : this.props.product.node.title });
		let cartCount=0;
		if(CartJS.cart.items){
			CartJS.cart.items.map((each)=>{
				if(!(each.handle.toLowerCase().includes("lace") || each.handle.toLowerCase().includes("insole")  || each.handle.toLowerCase().includes("shirt") || each.handle.toLowerCase().includes("socks"))){
					if(each.quantity>1){
						cartCount = cartCount + each.quantity;
					}else{
						cartCount++;
					}
				}
			});
		}
		this.setState({cartCount :cartCount}, ()=>{this.setLastviewed()})
	}

	isMen() {
		return !window.location.pathname.includes('women');
	}

	setLastviewed = () => {
		//Init Empty Array for PDP data
		try {
			let ismen = this.isMen();
			const productArr = [];

			let jsonResp, jsonRespArr, jsonRespArrStr;

			const currentCurrency = Cookies.get('cart_currency');
			const defaultPrice = getPriceWithoutCurrency(this.props.product.node.priceRange.minVariantPrice.amount, currentCurrency);
      		let priceSale = defaultPrice

			if (this.props.product.node.tags.includes('_gated_archive_sale')) {
				priceSale = getPriceWithoutCurrency(Math.ceil((this.props.product.node.priceRange.minVariantPrice.amount - (this.props.product.node.priceRange.minVariantPrice.amount *  parseInt(window.gatedArchiveSale.discount.value)) / 100)), currentCurrency);
			}

			// Number of Products which we want to show under Recent view Tab
			const numberOfProduct = 10;
			const label = this.props.product.node.tags.filter(tag => tag.includes('Label - '))
			const isInviteSale = this.props.product.node.tags.includes('_gated_archive_sale') ? true : false
			let compareAtPrice;
			if (this.props.product.node?.compareAtPriceRange?.maxVariantPrice?.amount) {
				compareAtPrice = this.props.product.node.compareAtPriceRange.maxVariantPrice.amount;
			}
			const pdpData = {
				productTitle: this.props.product.node.alternativeTitle!=null ? this.props.product.node.alternativeTitle.value : '' || "",
				productImg:
					(this.props.sizeViewedImg && this.props.sizeViewedImg.length > 0 &&
						this.props.sizeViewedImg[0].node.originalSrc) ||
					"",
				productPrice: {
					[Shopify.currency.active]: defaultPrice.tax
				},
				productPriceSale: {
					[Shopify.currency.active]: priceSale.tax
				},
				...(compareAtPrice && {
					compareAtPrice: {
						[Shopify.currency.active]: "$" + compareAtPrice
					}
				}),
				isInviteSale: isInviteSale,
				productCategory: this.props.productCategory || "",
				productUrl: window.location.origin + "/products/" + this.props.product.node.handle || "",
				productType: this.props.product.node.productType,
				label: label.length > 0 ? label[0].replace('Label - ', '') : '',
				timestamp: new Date().getTime()
			}
			if (pdpData.productImg.length > 0) {
				productArr.push(pdpData);
				const currPdpTitle = pdpData.productTitle;
				const pdpDataString = JSON.stringify(productArr);
				const localData = localStorage.getItem("recently_viewedPDP");

				if (localData == null) {
					localStorage.setItem("recently_viewedPDP", pdpDataString);
				} else if (localData != null) {
					const oldPdpData = localStorage.getItem("recently_viewedPDP");
					const countPdpData = (oldPdpData.match(/productTitle/g) || []).length;
					const reVisitPdp = oldPdpData.includes(currPdpTitle);

					if (countPdpData < numberOfProduct && reVisitPdp == false) {
						jsonResp = JSON.parse(oldPdpData);
						jsonRespArr = jsonResp.concat(productArr);
						jsonRespArrStr = JSON.stringify(jsonRespArr);
						localStorage.setItem("recently_viewedPDP", jsonRespArrStr);
					} else if (countPdpData >= numberOfProduct && reVisitPdp == false) {
						jsonResp = JSON.parse(oldPdpData);
						jsonResp.shift();
						jsonRespArr = jsonResp.concat(productArr);
						jsonRespArr = JSON.stringify(jsonRespArr);
						localStorage.setItem("recently_viewedPDP", jsonRespArr);
					}
				}
			}
		} catch (e) {
			console.log("Error on adding last viewed pdp", e);
		}
	};

	createMarkup(msg) {
		return { __html: msg };
	}

  	preorderMessage() {
		let message
		let date = null

		if (this.state.VariantForAddTOcart?.PROMISE_DATE) {
		try {
			const promiseDate = JSON.parse(this.state.VariantForAddTOcart?.PROMISE_DATE)
			date = promiseDate.promise_date
		} catch {}
		}

		if (!date) {
		date = this.state.VariantForAddTOcart?.PRE_ORDER_DATE ?? this.state.VariantForAddTOcart?.BACKORDER_DATE
		}

		message = this.props.product.node.preorderMessage && this.props.product.node.preorderMessage.value
		? this.props.product.node.preorderMessage.value
		: date && Date.parse(date) ? window.hotwax_preorder_message.replace(/HOTWAX_DATE/, moment(new Date(date)).format('MMMM Do, YYYY')) : false

    	return message
  	}

	productUpsells() {
		if (this.props.product_category && this.state.addOnUpsellData && this.state.addOnUpsellData.logic) {
			const logic = this.state.addOnUpsellData.logic.find(logic => {
				return logic.collections.findIndex(collection => collection.toLowerCase() === this.props.product_category.toLowerCase()) > -1
			})
			return logic && logic.productsUpsellTitle ? logic.productsUpsellTitle : null
		}
		return null
	}

  	getPriceInformation() {
		if (!this.props.product?.node) {
		return ''
		}
		let price = this.props.product.node.priceRange.maxVariantPrice.amount
		const currency = this.props.product.node.priceRange.maxVariantPrice.currencyCode
		const bundleDiscount = getBundleDiscountAmount(currency)

		if (price && this.props.isAdult) {
			if (BUNDLE_DISCOUNT_TYPE === 'percentage') {
				price = parseFloat(price) * (1 - bundleDiscount / 100)
			} else {
				price = parseFloat(price) - bundleDiscount
			}

			if (this.props.kidsPrice) {
				price = parseFloat(this.props.kidsPrice) + price
			}
		}

    	return <span>
		{' — '}
		<Fragment>
			{taxInclusiveCurrencies.includes(this.props.product.node.priceRange.maxVariantPrice.currencyCode) ? (
			<span class="mobile-text" data-product-price="">
				<span class="c-taxInclusive">
				{formatMoney(price, this.props.product.node.priceRange.maxVariantPrice.currencyCode, true)}
				<span class="product_vatText">
					{getTaxAndDutiesMessage(this.props.product.node.priceRange.maxVariantPrice.currencyCode)}
				</span>
				</span>
				<span class="c-taxExclusive">
				{formatMoney(this.props.product.node.priceWithoutTax.amount, this.props.product.node.priceRange.maxVariantPrice.currencyCode, false)}
				<span class="product_vatText">
					{` ${getTaxAndDutiesMessage(this.props.product.node.priceRange.maxVariantPrice.currencyCode, true)}`}
				</span>
				</span>
			</span>
			) : (
			<span class="mobile-text" data-product-price="">
							{window.gatedArchiveSale.isEnable() && this.props.product?.node.tags.includes('_gated_archive_sale') ?
								(
									<span>
										<span>{formatMoney(price - (price * (parseInt(window.gatedArchiveSale.discount.value) / 100)), this.props.product.node.priceRange.maxVariantPrice.currencyCode, false)}</span>
										<span>
											{' '}
											<s>{formatMoney(price, this.props.product.node.priceRange.maxVariantPrice.currencyCode, false)}</s>
										</span>
									</span>
								) :
								formatMoney(price, this.props.product.node.priceRange.maxVariantPrice.currencyCode, false)}
			</span>
			)}
		</Fragment>
    	</span>
  	}

	handleCustomizeClick = async () => {
		const productQueryParams = this.props.product.node;
	
		const { VariantForAddTOcart, selectedVariantSize } = this.state;
		let cid = formatId(this.state.VariantForAddTOcart.id)
		let gender = window.location.pathname.includes('women') ? 'Women' : 'Men';
		let preorderestimate =$('.c-abPreOrder').first().text();

		const info = await this.getProductInfo(this.props.product.node.handle)
		const laceUpsellCollection = info.laceUpsellCollection

		const properties = {
			"Size": VariantForAddTOcart.size,
			"_size": VariantForAddTOcart.size,
			"_reviewBadge": this.props.product.node.review?.value,
			"_tags": this.props.product.node.tags, 
			"_laceUpsell": laceUpsellCollection,
			"_Gender": gender,
			"__timestamp": new Date().getTime(),
			"__inventoryQuantity": VariantForAddTOcart.inventoryQuantity,
			"__inventoryPolicy": VariantForAddTOcart.inventoryPolicy,
			"__promiseDateRaw": VariantForAddTOcart.PROMISE_DATE
		};

		let treePlant = 0

		if (!this.props.product.node.tags.includes('4Ocean') && !this.state.is4oceanTemplate && !this.state.isWorldOceanDayTemplate || this.state.isClothing || this.state.isBackpack) {
			if (!this.state.enableCustomTreePlant) {
        if ((this.props.product.node.treePlant?.value ?? '') !== '') {
          treePlant = Number(this.props.product.node.treePlant.value)
				} else if (this.state.singleShoe || this.state.isClothing || this.state.isBackpack) {
					treePlant = 1
				} else if (this.state.razerEcoMessage) {
					treePlant = 10
				} else {
					treePlant = 2
				}
			} else {
        if ((this.props.product.node.treePlant?.value ?? '') === '') {
          treePlant = Number(this.props.product.node.treePlant.value) * 5
				} else if (this.state.singleShoe || this.state.isClothing || this.state.isBackpack) {
					treePlant = 5
				} else if (this.state.razerEcoMessage) {
					treePlant = 10
				} else {
					treePlant = 10
				}
			}
		}

		const matchedVariantInCart = CartJS.cart.items.filter(item => item.id === parseInt(cid))

		if (treePlant > 0) {
			if (matchedVariantInCart.length > 0 && matchedVariantInCart[0].properties.hasOwnProperty('_treePlant')) {
				properties['_treePlant'] = parseInt(matchedVariantInCart[0].properties._treePlant) + treePlant
			} else {
				properties['_treePlant'] = treePlant
			}
		}

		let promiseObject = VariantForAddTOcart.PROMISE_DATE ? JSON.parse(VariantForAddTOcart.PROMISE_DATE) : null
    	let promiseDate, note

		if (promiseObject?.promise_date) {
			promiseDate = promiseObject.promise_date
			note = promiseObject.preorderType
		} else {
			promiseDate = VariantForAddTOcart.PRE_ORDER_DATE ?? VariantForAddTOcart.BACKORDER_DATE
			note = VariantForAddTOcart.PRE_ORDER_DATE ? 'PRE_ORDER' : 'BACKORDER'
		}

		if (this.state.variantAvailableForPreOrder) {
			properties['_PREORDER'] = 'PREORDER'
			properties['_preorderestimate'] = preorderestimate
			properties['__note'] = note
			properties['__promiseDate'] = promiseDate
		};

		// Check if the function is defined to avoid errors
		if (typeof window.showCustomizationModal === 'function') {
			window.showCustomizationModal(VariantForAddTOcart.id,productQueryParams, properties);
		}
	};

	render() {
    	const preorderMessage = this.preorderMessage()

		return (
			<Fragment>
				<div itemprop="offers"  class={`${this.props.product.node.options[2]?.values.length === 1 ? "c-pdp__offers-one-size" : ""} ${this.state.shippingRestrictionMessage ? "c-pdp-restriction" : ""}`}>
					{ this.props.sizeOption ?
            <div class= {classNames((this.state.includeFitPredictor) ? 'size-quantity c-pdp__size include-fit-predictor' : 'size-quantity c-pdp__size', {'c-pdp__size--restriction': this.state.shippingRestrictionMessage}) }>
              <div class="selector-wrapper">

                {/* <SizeDropdown /> */}
                {this.state.productData !== null ?
                  this.props.oppGenderProduct !==null ?
                  <SizeDropdown
                    key={`size-dropdown-${this.state.VariantForAddTOcart?.id}`}
                    product={this.props.product}
                    oppGenderProduct={this.props.oppGenderProduct}
                    sizeOption={this.props.sizeOption}
                    CurrentVariant={this.CurrentVariant}
                    currentSize={this.state.VariantForAddTOcart?.actualSize}
                    isMen={this.props.isMen ?? this.isMen()}
                    isAdult={this.props.isAdult}
                  /> :
                  <SizeDropdownUnisex
                    key={`size-dropdown-${this.state.VariantForAddTOcart?.id}`}
                    product={this.props.product}
                    sizeOption={this.props.sizeOption}
                    CurrentVariant={this.CurrentVariant}
                    currentSize={this.state.VariantForAddTOcart?.actualSize}
                    isKids={this.props.isKids}
                  />
                  : ""}
              </div>
            </div>
					: '' }
			
			<label id="quantitySKUMsg" style="margin-top: 5px; color: red; text-align: center; font-weight: normal; display: none; text-transform:unset">
			</label>
			
            {this.props.oppGenderProduct !==null ?
            <div class="c-pdp__ecoMessageContain l580 hidden-xs">
              <span class="c-pdp__ecoMessage">
                  { (this.state.razerEcoMessage || this.state.enableCustomTreePlant) ?
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <g fill="#77C043" fillRule="nonzero" transform="translate(-177 -527)">
                          <g transform="translate(177 527)">
                            <path
                              d="M3.438 16.25c.475 0 .938-.103 1.367-.302v3.27H1.68a.39.39 0 000 .782h16.64a.39.39 0 000-.781h-3.125v-3.27c.43.198.892.301 1.367.301 1.864 0 3.438-1.52 3.438-3.32 0-1.436-1.043-2.75-2.472-3.178a2.75 2.75 0 00-1.403-2.146 3.9 3.9 0 00.124-2.307.39.39 0 00-.757.19 3.12 3.12 0 01-.098 1.84 2.738 2.738 0 00-3.313 2.423c-.671.201-1.258.598-1.69 1.111V7.67l2.238-1.066a.39.39 0 00-.336-.706l-1.902.906V3.906a.39.39 0 00-.782 0v1.725l-1.902-.906a.39.39 0 00-.336.706L9.61 6.497v4.366a3.549 3.549 0 00-1.69-1.11A2.738 2.738 0 004.606 7.33a3.098 3.098 0 01-.192-1.08 3.1 3.1 0 012.652-3.076.39.39 0 00.325-.315C7.605 1.694 8.75.781 10 .781c1.248 0 2.395.913 2.61 2.078.03.164.16.29.324.315.16.025.319.062.473.11a.39.39 0 10.236-.744 3.917 3.917 0 00-.327-.088C12.923 1.037 11.545 0 10 0S7.077 1.037 6.684 2.452a3.896 3.896 0 00-2.81 5.154 2.75 2.75 0 00-1.402 2.146C1.042 10.18 0 11.494 0 12.93c0 1.8 1.574 3.32 3.438 3.32zm2.148-.302c.429.199.892.302 1.367.302 1.057 0 2.02-.489 2.656-1.24v4.209H5.586v-3.27zm4.805 3.27V15.01c.636.751 1.6 1.24 2.656 1.24.475 0 .938-.103 1.367-.302v3.27h-4.023zm2.153-8.776a.393.393 0 00.308-.46 1.955 1.955 0 011.953-1.935c1.079 0 1.943.874 1.953 1.936a.393.393 0 00.308.459c1.227.246 2.153 1.316 2.153 2.488 0 1.376-1.217 2.539-2.657 2.539-.484 0-.953-.142-1.367-.412v-1.3l2.128-1.064a.39.39 0 00-.35-.699l-1.778.89V10a.39.39 0 00-.78 0v1.4l-.506-.504a.39.39 0 00-.552.552l1.057 1.058v2.551c-.414.27-.883.412-1.367.412-1.44 0-2.656-1.163-2.656-2.54 0-1.171.925-2.24 2.153-2.487zm-9.61 0a.393.393 0 00.308-.46 1.954 1.954 0 011.953-1.935c1.071 0 1.944.867 1.953 1.936a.393.393 0 00.308.459c1.228.246 2.153 1.316 2.153 2.488 0 1.376-1.216 2.539-2.656 2.539-.484 0-.953-.142-1.367-.412v-.208l1.057-1.057a.39.39 0 00-.552-.553l-.505.505v-2.572a.39.39 0 00-.781 0v1.712l-1.779-.89a.39.39 0 10-.35.7l2.129 1.063v1.3c-.414.27-.883.412-1.367.412-1.44 0-2.657-1.163-2.657-2.54 0-1.171.926-2.24 2.153-2.487z"></path>
                            <circle cx="14.762" cy="4.286" r="1"></circle>
                          </g>
                        </g>
                      </g>
                    </svg>
                    :
                    <svg width="24" height="23" viewBox="0 0 24 23">
                      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <g
                          fill="#66a443"
                          fillRule="nonzero"
                          stroke="#66a443"
                          strokeWidth="0.75"
                          transform="translate(-144 -868)"
                        >
                          <g transform="translate(145 865)">
                            <g transform="translate(0 4.126)">
                              <path
                                d="M10.063 16.077c-.847 0-1.68-.209-2.429-.606A5.201 5.201 0 012.57 6.388a5.204 5.204 0 1110.131 0 5.203 5.203 0 01-2.637 9.69h0zm-2.429-1.35c.062 0 .122.017.175.048a4.508 4.508 0 104.327-7.906.347.347 0 01-.173-.405 4.51 4.51 0 10-8.657 0 .347.347 0 01-.174.405 4.508 4.508 0 104.328 7.906.348.348 0 01.174-.047h0z"></path>
                              <path
                                d="M7.634 20.125a.347.347 0 01-.347-.347V7.634a.347.347 0 01.694 0v12.144a.347.347 0 01-.347.347z"></path>
                              <path
                                d="M7.634 11.22a.347.347 0 01-.245-.593l2.429-2.429a.347.347 0 01.49.49l-2.428 2.43a.346.346 0 01-.246.101zM7.634 13.648a.346.346 0 01-.192-.058l-2.429-1.62a.347.347 0 01.385-.577l2.429 1.62a.347.347 0 01-.193.635h0zM17.596 17.723a4.274 4.274 0 01-1.966-.481 4.274 4.274 0 01-1.966.48.347.347 0 010-.693 3.58 3.58 0 001.792-.483.348.348 0 01.348 0 3.583 3.583 0 103.44-6.285.346.346 0 01-.173-.404 3.579 3.579 0 00-3.44-4.587.347.347 0 010-.694 4.285 4.285 0 014.279 4.28c0 .311-.035.622-.104.925a4.278 4.278 0 01-2.21 7.942z"></path>
                              <path
                                d="M15.63 21a.347.347 0 01-.347-.347v-3.47a.347.347 0 01.694 0v3.47a.347.347 0 01-.347.347z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  }

                { !this.state.enableCustomTreePlant ?
                  (this.state.singleShoe ? languages.eco_message_single_shoe
                      : this.state.razerEcoMessage ? languages.eco_message_razer
                      : languages.eco_message_default
                  )
                  : (this.state.singleShoe ? languages.eco_message_custom_single_shoe
                      : this.state.razerEcoMessage ? languages.eco_message_custom_razer
                      : <img class="c-pdp__ecoMessageGif l93" src="https://cdn.shopify.com/s/files/1/2495/5044/files/Animation-RO-v3.gif?v=1606315995" width="250" height="35" alt={languages.eco_message_custom_default} />
                  )
                }
              </span>
            </div>
            : null}
          { this.state.shippingRestrictionMessage ?
            <Fragment>
              <div class="c-pdp__shipping-restriction">
                <div className="c-pdp__shipping-restriction-wrapper">
                  <div class="c-pdp__shipping-restriction-content" dangerouslySetInnerHTML={this.createMarkup(this.state.shippingRestrictionMessage)} />
                </div>
              </div>
            </Fragment>
            : ''
          }

					{this.state.variantAvailableForPreOrder && preorderMessage && this.state.variantAvailableForSale  ?
							<div class="c-abPreOrder hidden-min-desktop"  dangerouslySetInnerHTML={this.createMarkup(preorderMessage)} />
						: ''}

					{this.props.canCustomize && (
						<div class="c-abPreOrder c-CustomShoesShippingTime hidden-min-desktop">{languages.custom_shoes_shipping_time_message}</div>
					)}

					{
				       this.state.lastOneMessageMobile ? <span className="c-pdp__lastOneMessage block text-center hidden-min-desktop">{languages.stock_last_one_message}</span> : null
				    }

				    <div class="c-pdp__urgencyMessageContainer hidden-min-desktop">
						{
						   this.state.lastFewMessageMobile ? <span className="c-pdp__lastFewMessage">{languages.stock_last_few_message}</span> : null
						}
					</div>

					{/*
					{this.props.canCustomize && ( 
						<div className="c-pdp__personalize">
							<label htmlFor="personalizeInput">Personalize</label>
							<input
							type="text"
							id="personalizeInput"
							placeholder="Choose text"
							onClick={this.handlePersonalizeClick}
							value={this.state.personalizationText}
							readOnly
							/>
						</div>
					)}
					*/
					}
					
					<div class="send c-pdpSend">
						{this.state.variantAvailableForSale && (
							this.state.showCheckout ? (
								<Fragment>
									<div class="js-pdp-react-checkout pdp-addCartBtn-sticky">
										<a href={getUrl('/checkout')}><span>{languages.checkout}</span>
											<svg width="9" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M2 .544L.456 2.088l4.912 4.914-4.912 4.91L2 13.456 8.456 7z" fill="#FFF" fill-rule="nonzero"></path></svg>
										</a>
									</div>
									{/* <div class="c-liveContent">
										<div class="js-express-react-button">
										<a class="shopify-payment-button__button" href={getUrl('/checkout')}>
											<span>Buy it now</span>
										</a>
											<button type="button" class="shopify-payment-button__button shopify-payment-button__button--unbranded _2ogcW-Q9I-rgsSkNbRiJzA _2EiMjnumZ6FVtlC7RViKtj _2-dUletcCZ2ZL1aaH0GXxT GZHgfzSnue0w98GtPtQ3D" data-testid="Checkout-button" disabled="">Buy it now</button>
										</div>
									</div> */}
								</Fragment>
							)	: 	(
								<Fragment>
									{this.props.canCustomize && (
										<button onClick={this.handleCustomizeClick} class={`btn btn-full c-abBtnAtc  pdp-addCartBtn-sticky ${this.state.variantAvailableForSale ? '' : "btn--disabled"}`}>Customize</button>
									)}	

									{/*}
										<button
											type="button"
											class={`btn btn-full c-abBtnAtc  pdp-addCartBtn-sticky ${this.state.variantAvailableForSale ? '' : "btn--disabled"}`}
											disabled={this.state.variantAvailableForSale ? '' : "disabled"}
											onClick={this.handlePersonalizeClick}>
											<span data-personalize-text>
												{this.state.variantAvailableForPreOrder ? languages.pre_order : 'Customize'}
											</span>
										</button>
									*/}

									<button
										type="submit"
										id="addCartBtn-1"
										name="add"
										class={`btn btn-full c-abBtnAtc  pdp-addCartBtn-sticky ${this.state.variantAvailableForSale ? '' : "btn--disabled"} ${this.props.canCustomize ? 'visually-hidden' : ''}`}
										disabled={this.state.variantAvailableForSale ? '' : "disabled"}
										data-add-to-carts
										onClick={this.AddtoCart}>
										<span data-add-to-cart-text>
										{!this.props.isKids && !this.props.isAdult && this.state.variantAvailableForPreOrder ?
											languages.pre_order
										:
											<Fragment>
												{this.props.isAdult ?
													languages.add_bundle_to_cart
													: this.props.showBundle ? languages.add_to_bundle : languages.add_to_cart}

												{!this.props.showBundle && <span class={!this.props.isAdult && "pdp-alt-hide-desktop-inline"}>
													{this.getPriceInformation()}
												</span>}
											</Fragment>
										}
										</span>
									</button>

									{this.props.hasBundle && this.props.onBundleButtonClick && !this.props.showBundle && (
										<button
										type="button"
										class="btn btn-secondary btn-full btn-make-bundle kids-bundle-ab-control"
										onClick={this.props.onBundleButtonClick}
										>{languages.bundle_and_save}</button>
									)}

									{this.props.isAlt && (
										<Fragment>
										{this.state.productData && (
											<div class="pdp-alt-hide-desktop">
											<InstallmentPayments
												key={JSON.stringify(this.state.productData)}
												align="center"
												currency={this.props.product.node.priceRange.minVariantPrice.currencyCode}
												amount={taxInclusiveCurrencies.includes(this.props.product.node.priceRange.maxVariantPrice.currencyCode)
												? getPriceAmount(this.props.product.node.priceRange.maxVariantPrice.amount, this.props.product.node.priceRange.maxVariantPrice.currencyCode, true, window.Shopify.country)
												: this.props.product.node.priceRange.maxVariantPrice.amount
												}
											/>
											</div>
										)}
										</Fragment>
									)}
								</Fragment>
							)
						)}
					
					</div>
					<span class="pdp-outstock-note block text-center hidden-min-desktop" style={this.state.variantAvailableForSale ? "display : none" : ''}>{languages.out_of_stock}</span>
					<div class="c-pdpSend">
					<span class="btn btn-full pdp-addCartBtn-sticky" id="BIS_trigger_custom" onClick={this.OpenBISForm} style={this.state.variantAvailableForSale ? "display : none" : ''} >{languages.notify_me}</span>
					</div>
                    {this.state.variantAvailableForPreOrder && preorderMessage !==null && this.state.variantAvailableForSale  ?
							<div class="c-abPreOrder hidden-xs"  dangerouslySetInnerHTML={this.createMarkup(preorderMessage)} />
						: ''}
					{(!this.props.product.node.tags.includes('4Ocean') && !this.state.is4oceanTemplate && !this.state.isWorldOceanDayTemplate || this.state.isClothing || this.state.isBackpack)?

					<div class="c-pdp__ecoMessageContain l580 hidden-min-desktop">

						<span class={classNames('c-pdp__ecoMessage', (this.state.enableCustomTreePlant && !this.state.razerEcoMessage && !this.state.singleShoe) ? 'c-pdp__ecoMessage--hide-svg' : '')}>

                  { (this.state.razerEcoMessage || this.state.enableCustomTreePlant) ?

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="35"
                      height="35"
                      viewBox="0 0 20 20"
                    >
                      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <g fill="#77C043" fillRule="nonzero" transform="translate(-177 -527)">
                          <g transform="translate(177 527)">
                            <path
                              d="M3.438 16.25c.475 0 .938-.103 1.367-.302v3.27H1.68a.39.39 0 000 .782h16.64a.39.39 0 000-.781h-3.125v-3.27c.43.198.892.301 1.367.301 1.864 0 3.438-1.52 3.438-3.32 0-1.436-1.043-2.75-2.472-3.178a2.75 2.75 0 00-1.403-2.146 3.9 3.9 0 00.124-2.307.39.39 0 00-.757.19 3.12 3.12 0 01-.098 1.84 2.738 2.738 0 00-3.313 2.423c-.671.201-1.258.598-1.69 1.111V7.67l2.238-1.066a.39.39 0 00-.336-.706l-1.902.906V3.906a.39.39 0 00-.782 0v1.725l-1.902-.906a.39.39 0 00-.336.706L9.61 6.497v4.366a3.549 3.549 0 00-1.69-1.11A2.738 2.738 0 004.606 7.33a3.098 3.098 0 01-.192-1.08 3.1 3.1 0 012.652-3.076.39.39 0 00.325-.315C7.605 1.694 8.75.781 10 .781c1.248 0 2.395.913 2.61 2.078.03.164.16.29.324.315.16.025.319.062.473.11a.39.39 0 10.236-.744 3.917 3.917 0 00-.327-.088C12.923 1.037 11.545 0 10 0S7.077 1.037 6.684 2.452a3.896 3.896 0 00-2.81 5.154 2.75 2.75 0 00-1.402 2.146C1.042 10.18 0 11.494 0 12.93c0 1.8 1.574 3.32 3.438 3.32zm2.148-.302c.429.199.892.302 1.367.302 1.057 0 2.02-.489 2.656-1.24v4.209H5.586v-3.27zm4.805 3.27V15.01c.636.751 1.6 1.24 2.656 1.24.475 0 .938-.103 1.367-.302v3.27h-4.023zm2.153-8.776a.393.393 0 00.308-.46 1.955 1.955 0 011.953-1.935c1.079 0 1.943.874 1.953 1.936a.393.393 0 00.308.459c1.227.246 2.153 1.316 2.153 2.488 0 1.376-1.217 2.539-2.657 2.539-.484 0-.953-.142-1.367-.412v-1.3l2.128-1.064a.39.39 0 00-.35-.699l-1.778.89V10a.39.39 0 00-.78 0v1.4l-.506-.504a.39.39 0 00-.552.552l1.057 1.058v2.551c-.414.27-.883.412-1.367.412-1.44 0-2.656-1.163-2.656-2.54 0-1.171.925-2.24 2.153-2.487zm-9.61 0a.393.393 0 00.308-.46 1.954 1.954 0 011.953-1.935c1.071 0 1.944.867 1.953 1.936a.393.393 0 00.308.459c1.228.246 2.153 1.316 2.153 2.488 0 1.376-1.216 2.539-2.656 2.539-.484 0-.953-.142-1.367-.412v-.208l1.057-1.057a.39.39 0 00-.552-.553l-.505.505v-2.572a.39.39 0 00-.781 0v1.712l-1.779-.89a.39.39 0 10-.35.7l2.129 1.063v1.3c-.414.27-.883.412-1.367.412-1.44 0-2.657-1.163-2.657-2.54 0-1.171.926-2.24 2.153-2.487z"></path>
                            <circle cx="14.762" cy="4.286" r="1"></circle>
                          </g>
                        </g>
                      </g>
                    </svg>
                    :
                    <svg width="24" height="23" viewBox="0 0 24 23">
                      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <g
                          fill="#66a443"
                          fillRule="nonzero"
                          stroke="#66a443"
                          strokeWidth="0.75"
                          transform="translate(-144 -868)"
                        >
                          <g transform="translate(145 865)">
                            <g transform="translate(0 4.126)">
                              <path
                                d="M10.063 16.077c-.847 0-1.68-.209-2.429-.606A5.201 5.201 0 012.57 6.388a5.204 5.204 0 1110.131 0 5.203 5.203 0 01-2.637 9.69h0zm-2.429-1.35c.062 0 .122.017.175.048a4.508 4.508 0 104.327-7.906.347.347 0 01-.173-.405 4.51 4.51 0 10-8.657 0 .347.347 0 01-.174.405 4.508 4.508 0 104.328 7.906.348.348 0 01.174-.047h0z"></path>
                              <path
                                d="M7.634 20.125a.347.347 0 01-.347-.347V7.634a.347.347 0 01.694 0v12.144a.347.347 0 01-.347.347z"></path>
                              <path
                                d="M7.634 11.22a.347.347 0 01-.245-.593l2.429-2.429a.347.347 0 01.49.49l-2.428 2.43a.346.346 0 01-.246.101zM7.634 13.648a.346.346 0 01-.192-.058l-2.429-1.62a.347.347 0 01.385-.577l2.429 1.62a.347.347 0 01-.193.635h0zM17.596 17.723a4.274 4.274 0 01-1.966-.481 4.274 4.274 0 01-1.966.48.347.347 0 010-.693 3.58 3.58 0 001.792-.483.348.348 0 01.348 0 3.583 3.583 0 103.44-6.285.346.346 0 01-.173-.404 3.579 3.579 0 00-3.44-4.587.347.347 0 010-.694 4.285 4.285 0 014.279 4.28c0 .311-.035.622-.104.925a4.278 4.278 0 01-2.21 7.942z"></path>
                              <path
                                d="M15.63 21a.347.347 0 01-.347-.347v-3.47a.347.347 0 01.694 0v3.47a.347.347 0 01-.347.347z"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  }

              { !this.state.enableCustomTreePlant ?
                (this.state.singleShoe ? 'Buy 1 shoe, plant a tree.'
                    : this.state.razerEcoMessage ? 'Every pair of Cariuma x Razer sneakers purchased saves 10 trees around the world.'
                      : this.state.isClothing ?  `Buy A ${this.state.isHoodie ? 'Hoodie' : 'T-shirt'}, plant a tree.`
												: this.state.isBackpack ?  'Buy A Backpack. Plant A Tree.'
													: 'Buy 1 pair, plant 2 trees.'
                )
                : (this.state.singleShoe ? 'Buy 1 shoe, plant 5 trees.'
                    : this.state.razerEcoMessage ? 'Every pair of Cariuma x Razer sneakers purchased saves 10 trees around the world.'
                      : this.state.isClothing ?  `Buy A ${this.state.isHoodie ? 'Hoodie' : 'T-shirt'}, plant 5 trees.`
												: this.state.isBackpack ?  'Buy A Backpack. Plant 5 Trees.'
													: <img class="c-pdp__ecoMessageGif l93" src="https://cdn.shopify.com/s/files/1/2495/5044/files/Animation-RO-v3.gif?v=1606315995" width="250" height="35" alt="Get a Pair of Sneakers, Plant 10 Trees"/>
                )
              }

						</span>
					</div>
            :
            <Fragment>
              {this.state.isWorldOceanDayTemplate ? (
                this.props.product.node.productType === 'Footwear' && (
                  <WorldOceanDayMessage
                    key={this.props.key}
                    product={this.props.product}
                  />
                )
              ) : (
                this.props.product.node.productType === 'Footwear' &&
                <FourOceanMessage
                  key={this.props.key}
                  product={this.props.product}
                />

              )}
            </Fragment>
            }
					{/* <div class="hide">
						<div class="js-express js-preShip">{'product.metafields.c_f.express'}</div>
						<div class="js-standard js-preShip">{'product.metafields.c_f.standard'}</div>
					</div> */}
					{/* <input name="properties[_reviewBadge]" value={this.props.product.node.review?.value} hidden="true" /> */}
					{/* <div id="dd-restock-notification-container"></div> */}
				</div>

				{(!this.props.canCustomize && 
					(!this.props.product?.node?.tags?.includes('_gated_archive_sale') || !window.gatedArchiveSale?.isEnable())) && (
					<ShippingAndJumpSection
						product_category = {this.props.product_category}
						variantAvailableForPreorder = {this.state.variantAvailableForPreOrder}
						variantAvailableForSale = {this.state.variantAvailableForSale}
					/>
				)}

				{this.props.canCustomize && (
					<div class="c-shippingDetail js-shipping-detail">
						<div class="c-abShippingBlock" >
							<span class="c-abShipping"> 
								<span>{languages.custom_shoes_shipping_price_message}</span>
							</span>
						</div>
					</div>
				)}

				<div class="c-popupOverlay">

				</div>
				<div ref={this.waitListFormRef} class="c-popup js-popup">
					<div class="c-popupBody">
						<span class="c-popupClose js-popupClose">
							<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" /></svg>
						</span>
						<div class="c-popupBody__head">
							<h3 class="c-popupBody__headTitle">{languages.notify_modal_title}</h3>
							<p>{languages.notify_modal_description}</p>
						</div>
						<div class="c-popupBody__content">
							<h3 class="c-popupBody__name">
							{this.state.productName !==null ? this.state.productName : ''}
							</h3>
							<div class="c-popupBody__dynamic">
								<input class="name" type="text" name="" value={this.state.selectedVariantTranslatedSize !== null ? this.state.selectedVariantTranslatedSize : ''} disabled />
								<input class="email" type="email" placeholder={languages.notify_modal_email} required />
								<input class="c-popupBody_number" type="text" placeholder={languages.notify_modal_phone} required style={(typeof BISConfig !== 'undefined' && BISConfig?.show_phone_number_field) ? { display: 'block' } : { display: 'none' }} />
								<button type="submit" class="JSsend btn btn-full">{languages.notify_modal_button_text}</button>
							</div>
							<div class="c-popupBody__footer">
								<p>{languages.notify_modal_footer_description}</p>
								<p class="c-popupBody__footerSuccess jsBisSuc" style="display: none;">{languages.notify_modal_success_message}</p>
								<p class="c-popupBody__footerError jsBisError" style="display: none;"></p>
							</div>
						</div>
					</div>
				</div>

        {this.state.productData !== null && this.state.VariantForAddTOcart != null && <Fragment>
          <div class={`hide-desktop`}>
            <div class="sticky-add-to-cart hidden-sticky-block">
              {this.props.sizeOption && (
                <div class="sticky-add-to-cart__size-selector">
                  {this.props.oppGenderProduct !== null ? (
                    <SizeSelector
                      key={`size-selector-${this.state.VariantForAddTOcart.id}`}
                      CurrentVariant={this.CurrentVariant}
                      currentSize={this.state.VariantForAddTOcart.actualSize}
                      isMen={this.props.isMen ?? this.isMen()}
                    />
                  ) : (
                    <SizeSelectorUnisex
                      key={`size-selector-${this.state.VariantForAddTOcart.id}`}
                      CurrentVariant={this.CurrentVariant}
                      currentSize={this.state.VariantForAddTOcart.actualSize}
                    />
                  )}
                </div>
              )}

              {this.state.showCheckout ? (
                <a href={getUrl('/checkout')} class="btn sticky-add-to-cart__button">
                  Checkout
                </a>
              ) : (
				<Fragment>
					{this.props.canCustomize && (
						<button onClick={this.handleCustomizeClick} class='btn sticky-add-to-cart__button' disabled={this.state.variantAvailableForSale ? '' : 'disabled'}>Customize</button>
					)}	

					<button
					type="button"
					class={`btn sticky-add-to-cart__button ${this.props.canCustomize ? 'visually-hidden' : ''}`}
					disabled={this.state.variantAvailableForSale ? '' : 'disabled'}
					data-add-to-carts
					onClick={this.AddtoCart}
					>
					<span data-add-to-cart-text>
						{this.state.variantAvailableForPreOrder ? languages.pre_order : languages.add_to_cart}
					</span>
					</button>
				</Fragment>
              )}
            </div>
          </div>
          {this.state.addOnUpsellData && this.state.addOnUpsellData.enable && <AddonUpsell
            data={this.state.addOnUpsellData}
            productHandle={this.props.product.node.handle}
            selectedVariant={this.state.VariantForAddTOcart}
            isMen={this.props.isMen ?? this.isMen()}
            isAddedToCart={this.state.isAddedToCart}
          />}
        </Fragment>}

        <div ref={this.productDetailsRef} class="pdp-product-details-wrapper"></div>
        <div class="u-hideTabletLargeUp">
          <FamilyCrossSell />
        </div>
			</Fragment>
		);
	}
}
