import $ from 'jquery';
import Cookies from 'js-cookie';
import { formatMoney } from './utils/money'
import getUrl from '@/scripts/utils/get-url'

export default function searchHeader() {
	var vwoCount = 0

	$('.c-header__searchInput').change(function(){
		if(this.value.length > 0){
			$('.c-header__searchBtn').attr('disabled', false);
		} else {
			$('.c-header__searchBtn').attr('disabled', true);
		}
	})

  $('.top-nav__search-input').on('change', function() {
    if (this.value.length > 0) {
      $('.top-nav__search-button').attr('disabled', false);
    } else {
      $('.top-nav__search-button').attr('disabled', true);
    }
  })

	function handleCurrencyPrice(price, currency, isGiftCard, isDiscountPrice) {
    return formatMoney(price, currency, !isGiftCard, isDiscountPrice)
	}

	function searchAutocomplete($this, isMobile, isVwoTest = null) {
		// Current Ajax request.
		var $body = $('body');
		var currentAjaxRequest = null;
		// Find location to append results
		if(isMobile) {
			var $appendLocation = $body;
		} else {
			var $appendLocation = $this.parent();
		}

		// Adding a list for showing search results.
		if(isVwoTest){
			$('<div class="search-autocomplete search-autocomplete__new-version AB-test"></div>').appendTo($appendLocation);
		} else {
			$('<div class="search-autocomplete"></div>').appendTo($appendLocation);
		}

		// Grabbing text input.
		var $input = $this.find('input[name="q"]'),
		initialQuery = $input.val();

		// Disable search button if no query
		if(!initialQuery) {
			$this.find('.btn').addClass('disabled');
			$('.c-header__searchBtn').attr('disabled', true);
      $('.top-nav__search-button').attr('disabled', true);
		}

		// Listening to keyup and change on the text field within these search forms.
		$input.attr('autocomplete', 'off').on('keyup change', function() {
			// What's the search term?
			var term = $(this).val();
			// Enable search button if query
			var termInput = $(this)
			var cartCurrency = Cookies.get('cart_currency')

      var changeValue = ['tshirts', 'shirt', 'tshirt']
			if(term) {
				$this.find('.btn').removeClass('disabled');
			} else {
				$this.find('.btn').addClass('disabled');
			}
      if (changeValue.includes(term.toLowerCase())) {
        term = 't-shirt'
      }

      // What's the search URL?
			var searchURL = getUrl('/search') + '?type=product&q=' + term;
			// What's the search results list?
			var $resultsList = $appendLocation.children('.search-autocomplete');
			var $resultsContent = $('<div class="overflow-scroll"><ul></ul></div>');

			// If that's a new term and it contains at least 2 characters.
			if (term.length > 2 && term != $(this).attr('data-old-term')) {
				//Saving old query.
				$(this).attr('data-old-term', term);
				// Killing any Ajax request that's currently being processed.
				if (currentAjaxRequest != null) currentAjaxRequest.abort();
				// Pulling results.
				currentAjaxRequest = $.getJSON(searchURL + '+-product_type:accessories&view=json', function(data) {
					// Reset results.
					$resultsList.empty();

          // Trigger Cordial abandon search
          const pageType = 'search_results'
          const url = window.location.origin + searchURL
          const results = data.results.filter(item => !item.url.includes('-men')).splice(0, 4).map(item => item.id);

          if (window.crdl) {
            window.crdl('event', 'search', {
              pageType,
              url,
              results
            })
          }

					// If we have no results.
					if(data.results_count == 0) {
						// $resultsList.html('<li><span class="title">No results.</span></li>');
						// $resultsList.fadeIn(200);
						$resultsList.removeClass('shown');


					} else {
						if(isVwoTest){
							if(data.results_count < 10){
								$resultsList.append(`<div class="subtitle subtitle--v2">Showing ${data.results_count} results <span class="searchModal_close"></span></div>`);
								$('.searchModal_close').click(() =>{
									if (currentAjaxRequest != null) currentAjaxRequest.abort();
									$resultsList.removeClass('shown');
									termInput.val("")
								})
							} else {
								$resultsList.append(`<div class="subtitle subtitle--v2">Showing 10 of ${data.results_count} results <span class="searchModal_close"></span></div>`);
								$('.searchModal_close').click(() =>{
									if (currentAjaxRequest != null) currentAjaxRequest.abort();
									$resultsList.removeClass('shown');
									termInput.val("")
								})
							}
						} else {
							$resultsList.append('<h3 class="subtitle">' + window.globalLanguages?.search.products + '</h3>');
						}

						// If we have results.
						$.each(data.results, function(index, item) {
							var $link = $('<a></a>').attr('href', item.url);

							if(isVwoTest){
								$link.append('<span class="thumbnail"><img src="' + item.thumbnail + '" srcset="' + item.thumbnailSrcset + '"/></span>');
							} else {
								$link.append('<span class="thumbnail"><img src="' + item.thumbnail + '" srcset="' + item.thumbnailSrcset + '" sizes="64px" /></span>');
							}
							if (item.isInviteSale === 'true' && window.gatedArchiveSale.isEnable()) {
								$link.append('<div class="title"><h2>' + item.title + '</h2><p class="price price-gated-archive-sale"><span>' + handleCurrencyPrice(item.priceSale, cartCurrency, item.url.includes('gift-card'), true) + '</span><span><s>' + handleCurrencyPrice(item.price, cartCurrency, item.url.includes('gift-card')) + '</s></span></p></div>');
							} else {
								$link.append('<div class="title"><h2>' + item.title + '</h2><p class="price">' + handleCurrencyPrice(item.price, cartCurrency, item.url.includes('gift-card')) + '</p></div>');
							}
							$link.wrap('<li></li>');
							$resultsContent.find('ul').append($link.parent());
						});
						$resultsList.append($resultsContent);
						// The Ajax request will return at the most 10 results.
						// If there are more than 3, let's link to the search results page.
						if(data.results_count > 3) {
							if(isVwoTest){
								$resultsList.append('<div class="subtitle see-all"><a class="see-all-button-link" href="' + searchURL + '">' + window.globalLanguages?.search.modal_results + '</a></div>');
							} else {
								$resultsList.append('<div class="subtitle see-all"><a href="' + searchURL + '">' + window.globalLanguages?.search.modal_results + ' (' + data.results_count + ')</a></div>');
							}
						}
						$resultsList.addClass('shown');

						// Hide mini cart so they don't overlap
						// if($miniCart){
						// 	$miniCart.removeClass('shown');
						// }
					}
				});
			} else if(term.length <= 2){
				if (currentAjaxRequest != null) currentAjaxRequest.abort();
				$resultsList.removeClass('shown');
			}
		});
	}

	/*================ Search Autocomplete / Disable Button ================*/

	$('.main-header .search-form').each(function() {
		searchAutocomplete($(this), false, false);
	});

	var searchInterval = setInterval(() => {
		if(vwoCount <= 4){
			//If it's variation 1
			if(window.searchModalVariation){
				$('.mobile-nav .search-form').each(function() {
					searchAutocomplete($(this), true, true);
				});

				$('.c-header__searchForm').each(function(){
					searchAutocomplete($(this), false, true);
				});

        $('.js-top-nav-search-form').each(function() {
          searchAutocomplete($(this), false, true);
        })

				clearInterval(searchInterval)
			}

			//If it's control
			if(!window.searchModalVariation && vwoCount === 4){
				$('.mobile-nav .search-form').each(function() {
					searchAutocomplete($(this), true, false);
				});

				$('.c-header__searchForm').each(function(){
					searchAutocomplete($(this), false, false);
				});

        $('.js-top-nav-search-form').each(function() {
          searchAutocomplete($(this), false, false);
        })

				clearInterval(searchInterval)
			}
		}
		vwoCount += 1
	}, 2000)
}