class CarouselScroll {
  constructor(el) {
    this.el = el

    this.addButtons()
    this.updateButtonsVisibility()
    this.el.addEventListener('scroll', this.updateButtonsVisibility.bind(this))
    this.el.updateButtonsVisibility = this.updateButtonsVisibility.bind(this)
  }

  addButtons() {
    this.prevButton = this.addButton(false)
    this.nextButton = this.addButton(true)

    this.el.prepend(this.prevButton)
    this.el.append(this.nextButton)
  }

  addButton(isNext = true) {
    const button = document.createElement('button')
    button.className = `carousel-scroll__button carousel-scroll__button--${isNext ? 'next' : 'prev'}`
    button.setAttribute('aria-label', `Scroll ${isNext ? 'right' : 'left'}`)

    button.addEventListener('click', (event) => {
      event.preventDefault()

      if (isNext) {
        this.scrollRight()
      } else {
        this.scrollLeft()
      }
    })

    return button
  }

  scrollRight() {
    let foundInViewItem = false;
    const { x: parentLeft, width: parentWidth } = this.el.getBoundingClientRect()

    const scrollToItem = this.getChildItems().find(item => {
      const { x: left, width } = item.getBoundingClientRect()

      if (left >= 0 && left + width <= parentLeft + parentWidth) {
        foundInViewItem = true
      } else if (foundInViewItem) {
        return true
      }

      return false
    })

    if (scrollToItem) {
      scrollToItem.scrollIntoView({
        block: 'nearest',
        inline: 'start',
        behavior: 'smooth'
      })
    }
  }

  scrollLeft() {
    let foundInViewItem = false;
    const { x: parentLeft, width: parentWidth } = this.el.getBoundingClientRect()

    const scrollToItem = this.getChildItems().findLast(item => {
      const { x: left, width } = item.getBoundingClientRect()

      if (left >= 0 && left + width <= parentLeft + parentWidth) {
        foundInViewItem = true
      } else if (foundInViewItem) {
        return true
      }

      return false
    })

    if (scrollToItem) {
      scrollToItem.scrollIntoView({
        block: 'nearest',
        inline: 'end',
        behavior: 'smooth'
      })
    }
  }

  getChildItems() {
    const itemSelector = this.el.getAttribute('data-item-selector') ?? ':scope > :not(.carosel-scroll__button)'

    return [...this.el.querySelectorAll(itemSelector)]
  }

  updateButtonsVisibility() {
    this.prevButton.classList.toggle('is-visible', this.el.scrollLeft > 5)
    this.nextButton.classList.toggle('is-visible', this.el.scrollLeft + this.el.clientWidth < this.el.scrollWidth - 5)
  }
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-carousel-scroll').forEach(el => {
      if (!el.classList.contains('carousel-scroll-enabled')) {
        el.classList.add('carousel-scroll-enabled')

        const instance = new CarouselScroll(el)
      }
    })
  })
}