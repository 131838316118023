import Swiper, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

export default function() {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-content-links').forEach(el => {
      if (!el.classList.contains('is-initialized')) {
        el.classList.add('is-initialized')
        init(el)
      }

      if (!document.querySelectorAll('.js-ibi-end').length > 0) {
        el.classList.add('content-links--pdp-no-ibi-end')
      }
    })
  })

  const init = el => {
    const carousel = el.querySelector('.js-content-links-carousel')

    const swiper = new Swiper(carousel, {
      modules: [
        Navigation
      ],
      cssMode: false,
      slidesPerView: 'auto',
      watchOverflow: true,
      speed: 600,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    })

    if (swiper.isLocked) {
      carousel.classList.add('swiper--locked')
    }

    swiper.on('lock', () => {
      carousel.classList.add('swiper--locked')
    })

    swiper.on('unlock', () => {
      carousel.classList.remove('swiper--locked')
    })
  }
}