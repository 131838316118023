import axios from "axios"
import getUrl from "./get-url"

export default async function getCollectionProducts(handle, view, maxPage = 5) {
  let page = 1
  let data = []
  let hasNextPage = true

  while (hasNextPage && page <= maxPage) {
    const response = await axios.get(`${getUrl('/collections/')}/${handle}/?view=${view}&page=${page}`)
    data = [...data, ...(response?.data.products || [])]
    hasNextPage = response?.data.current_page < response?.data.pages
    page++
  }

  return data
}