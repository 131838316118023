/** @prettier */

import { oppositeGender as oppositeGenderData, filterSizeOtherMap } from './data';

export const oppositeGender = (collectionHandle) => {
	if (oppositeGenderData[collectionHandle]) {
		return oppositeGenderData[collectionHandle];
	}

	if (collectionHandle.includes('women')) {
		return collectionHandle.replace('women', 'men');
	}

	if (collectionHandle.includes('men')) {
		return collectionHandle.replace('men', 'women');
	}

	return undefined;
};

const i18Data = {};
export const setI18nData = (variables) => {
	Object.assign(i18Data, variables);
};

export const i18n = (key, vars = {}) => {
	return (i18Data[key] || '').replace(/{{\s*([^\s}]+)\s*}}/g, (_, varKey) => vars[varKey] || '');
};

export const fromEntries = (iterable) =>
	[...iterable].reduce((obj, [key, val]) => {
		obj[key] = val;
		return obj;
	}, {});

export const omit = (obj, keys) => {
	const keysExcept = Object.keys(obj).filter((key) => !keys.includes(key));
	const ret = {};
	keysExcept.forEach((key) => {
		ret[key] = obj[key];
	});
	return ret;
};

export const svgUrl = (name) => {
  const badges = window.GLOBAL && window.GLOBAL.badges ? JSON.parse(window.GLOBAL.badges) : ''
  if (!badges.length) return ''
  const selectedItem = badges.find(badge => badge.labels.includes(name.toLowerCase()))
  return selectedItem ? selectedItem.svg : ''
}

export const getSetting = (handle, key, defaultValue = '') => {
  if (window.collectionSettings) {
    const settings = window.collectionSettings
    const setting = handle in settings.collections ? settings.collections[handle] : settings.default

    if (key in setting) {
      return setting[key]
    }
  }

  return defaultValue
}

export const formatId = id => id.indexOf("t/") >= 0 ? id.substring(id.indexOf("t/") + 2) : id

export const getCountryByCurrency = currency => {
  if (window.Shopify.country) {
    return window.Shopify.country
  }

	switch (currency) {
		case 'AUD':
			return 'AU'
    case 'NZD':
      return 'NZ'
		case 'JPY':
			return 'JP'
		case 'CNY':
			return 'CN'
		case 'GBP':
			return 'GB'
		case 'EUR':
			return 'EE'
		case 'USD':
			return 'US'
		case 'CHF':
			return 'CH'
		case 'HKD':
			return 'HK'
		case 'SGD':
			return 'SG'
    case 'NOK':
      return 'NO'
    case 'SEK':
      return 'SE'
    case 'DKK':
      return 'DK'
		default:
			return 'US'
	}
}

export const getPaymentsEnabled = (currency = '', payments = ['AfterPay', 'Klarna', 'ShopPay', 'PayPal', 'Catch']) => {
	const country = (window.countryCode ?? 'US').toUpperCase()
	const settings = window.installmentPaymentSettings || {}
	const paypalSupportedEUCountries = ['DE', 'FR', 'IT', 'ES']
	const paypalSupportedCountries = ['US', 'GB', 'AU', ...paypalSupportedEUCountries]

	const results = {}

	const isPaymentEnabled = (payment) => {
		if (payment in settings) {
			if ('*' in settings[payment]) {
				return true
			}

			if (country in settings[payment]) {
				return settings[payment][country].length === 0 || settings[payment][country].includes(currency)
			}
		}

		return false
	}

	payments.forEach(payment => {
		results[`show${payment}`] = payment !== 'PayPal'
		? isPaymentEnabled(payment.toLowerCase())
		: isPaymentEnabled(payment.toLowerCase()) && (paypalSupportedCountries.includes(getCountryByCurrency(currency)) || (currency === 'EUR' && paypalSupportedEUCountries.includes(country)))
	})

	return {
		...results,
		showPayments: Object.values(results).includes(true)
	}
}

export const getCountrySize = (size) => {
	if (window.Shopify?.shop === 'br-cariuma.myshopify.com' && filterSizeOtherMap) {
		const sizes = filterSizeOtherMap
		return sizes.find(item => item.split('|')[0] == size)?.split('|')[1]
	} else if (window.Shopify?.shop === 'cariuma-jp.myshopify.com' && filterSizeOtherMap) {
		const sizes = filterSizeOtherMap
		return sizes.find(item => item.split('|')[0] == size)?.split('|')[2]
	} else {
		return size;
	}
};