import Swiper, {Navigation} from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

const init = el => {
  const carousel = el.querySelector('.swiper')
  if (carousel) {
    let swiper

    const breakpointChecker = function() {
      if (window.innerWidth >= 768) return enableSwiper()
      if (swiper !== undefined) swiper.destroy(true, true)
    }

    const enableSwiper = () => {
      if (swiper && !swiper.hasOwnProperty('destroyed')) return

      const parent = carousel.parentNode
      const prevEl = parent.querySelector('.swiper-button-prev')
      const nextEl = parent.querySelector('.swiper-button-next')
      swiper = new Swiper(carousel, {
        modules: [
          Navigation
        ],
        cssMode: false,
        slidesPerView: 'auto',
        watchOverflow: true,
        speed: 600,
        navigation: {
          prevEl,
          nextEl
        }
      })
    }

    let windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      if (window.innerWidth != windowWidth) {
        windowWidth = window.innerWidth
        breakpointChecker()
      }
    })
    breakpointChecker()
  }
}

export default () => {
  document.querySelectorAll('.js-homepage-product-category').forEach(el => init(el))
}