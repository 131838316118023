const init = (wrapper) => {
  const successMessage = wrapper.querySelector('.newsletter-signup-form__success')
  const innerEl = wrapper.querySelector('.newsletter-signup-form__inner')

  if (successMessage && !innerEl) {
    wrapper.classList.add('newsletter-signup-form--success')
    return
  }

  const input = wrapper.querySelector('.js-newsletter-signup-form-input')

  updateInputStatus(input)

  setTimeout(() => {
    updateInputStatus(input)
  }, 500)

  input.addEventListener('input', (event) => {
    updateInputStatus(event.target)
    wrapper.classList.remove('has-error')
  })

  wrapper.addEventListener('submit', (event) => {
    if (!wrapper.checkValidity()) {
      event.preventDefault()
      wrapper.classList.add('has-error')
    }
  })

  const isAjax = wrapper.classList.contains('is-ajax')

  if (isAjax) {
    wrapper.addEventListener('submit', (event) => {
      event.preventDefault()

      const formData = new FormData(wrapper)

      fetch(wrapper.getAttribute('action'), {
        method: 'POST',
        body: formData
      }).then((response) => {
        if (response.ok) {
          if (response.url.endsWith('/challenge')) {
            window.location.href = response.url
          } else {
            wrapper.classList.add('newsletter-signup-form--success')
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    })
  }
}

const updateInputStatus = (input) => {
  const parent = input.parentNode

  if (input.value !== '') {
    parent.classList.add('has-value')
  } else {
    parent.classList.remove('has-value')
  }
}

export default () => {
  document.querySelectorAll('.js-newsletter-signup-form').forEach(wrapper => {
    if (!wrapper.classList.contains('is-initialized')) {
      wrapper.classList.add('is-initialized')

      init(wrapper)
    }
  })
}