
const init = (el) => {
  const select = el.querySelector('.js-currency-selector-popup-select')
  const selectValue = el.querySelector('.js-currency-selector-popup-select-value')
  const selectValueText = selectValue.querySelector('.js-currency-selector-popup-select-value-text')

  const updateSelect = () => {
    const selectedOption = select.options[select.selectedIndex]
    const flag = selectedOption.getAttribute('data-flag')
    const title = selectedOption.text

    if (flag) {
      selectValue.style.setProperty('--flag-url', `url(${flag})`)
    } else {
      selectValue.style.setProperty('--flag-url', '')
    }

    selectValueText.innerText = title
  }

  const initSelectValue = () => {
    const options = select.options
    let selectedOption = 0

    for (const option of options) {
      if (option.dataset.countryCode === window.countryCode) {
        selectedOption = option
        break
      }
    }

    if (!selectedOption) {
      for (const option of options) {
        if (option.dataset.countryCode === window.continentCode) {
          selectedOption = option
          break
        }
      }
    }

    if (!selectedOption) {
      return
    }

    select.value = selectedOption.value
  }

  initSelectValue()
  updateSelect()
  select.addEventListener('change', updateSelect)

  const form = el.querySelector('.js-currency-selector-popup-form')
  form.addEventListener('submit', (e) => {
    e.preventDefault()

    const url = select[select.selectedIndex].value
    window.location.replace(url)
  })
}

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-currency-selector-popup:not(.is-initialized').forEach(el => {
      el.classList.add('is-initialized')
      init(el)
    })
  })
}