/**
 *
 * @prettier
 */

import { h } from 'preact';
/** @jsx h */
import { memo } from 'preact/compat';
import { useRef, useEffect } from 'preact/hooks';
import $ from 'jquery';
import classNames from 'classnames';

const CollectionsList = ({ collectionHandle, data }) => {
  let collectionsListData = ''
  if (data) {
    collectionsListData =  collectionHandle.includes('women') ? data['womenMav'] : data['menNav']
  }

	const scrollableList = useRef(null);

	const iconClickHandler = () => {
		const list = scrollableList.current;
		const $list = $(list);
		$list.animate({ scrollLeft: list.scrollWidth - list.offsetWidth }, 1000);
	};

  const getActive = (item) => {
    return item ? item.toString().split('/').pop() === collectionHandle : false
  }

	useEffect(() => {
		const list = scrollableList.current;
		const activeEl = list.querySelector('li.isActive');
		if (!activeEl) return;

		const arrowSize = 40;
		const { width, left } = activeEl.getBoundingClientRect();

		if (!(width + left > list.offsetWidth - arrowSize)) return;

		// set list scroll position to center on active element
		list.scrollLeft = left + width / 2 - list.offsetWidth / 2;
	}, []);

	return (
		<div class="c-collectionCollectionsList u-hideTabletUp">
			<div
				class="c-collectionCollectionsList__icon"
				aria-hidden="true"
				onClick={iconClickHandler}
			>
				<svg
					class="c-collectionCollectionsList__iconSvg"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 12 7"
				>
					<path fill="#4A4A4A" d="M11.078.094L6 5.2.984.094 0 1l6 6 6-6z" />
				</svg>
			</div>
			<div class="c-collectionCollectionsList__listWrapper" ref={scrollableList}>
				<ul class="c-collectionCollectionsList__list">
					{collectionsListData.map((item) => {
						const isActive = getActive(item.url)
						return (
							<li
								class={classNames(`c-collectionCollectionsList__listItem`, {
									isActive,
								})}
							>
								<a
									href={`${item.url}`}
									class="c-collectionCollectionsList__listItemLink"
								>
									{item.title}
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default memo(CollectionsList);
