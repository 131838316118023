/** @jsx h */

import { h, Component, createRef } from 'preact';
import Cookies from 'js-cookie';
import $ from 'jquery'

export default class Footer extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const countryCode = window.countryCode;
		const continentCode = window.continentCode;

		if (window.location.href.indexOf('currency=') > -1) {
			Cookies.set('currency_selected', true, {
				expires: 7
			});
    }

		const isCurrencySelected = Cookies.get('currency_selected');

    if (window.location.host !== 'www.cariuma.com') {
      Cookies.remove('localization', { domain: 'www.cariuma.com' })
      Cookies.remove('currency_selected', { domain: 'www.cariuma.com' })
      Cookies.remove('cart_currency', { domain: 'www.cariuma.com' })
      Cookies.remove('cart', { domain: 'www.cariuma.com' })
    }

    const openCurrencyPopup = () => {
      const isOutsideVisitor = window.shouldOpenRegionSelectorPopup?.() ?? true
      const isReferralPage = document.body.classList.contains('page-refer')
      const isLoyaltyPage = document.body.classList.contains('template-loyalty-signup')

      if (!isCurrencySelected && isOutsideVisitor && !isReferralPage && !isLoyaltyPage && !window.Shopify?.designMode) {
        $('body').addClass('popup-open');
      }
    }

    if (window.isGeoReady) {
      openCurrencyPopup();
    } else {
      window.addEventListener('geo-data-ready', openCurrencyPopup);
    }

		const handleCurrencyClick = el => {
			const href = el.getAttribute('href')
			const currency = href.match(/currency=([^&]*)/)
      const countryCode = href.match(/country_code=([^&]*)/)
			if (!currency || !currency.length) window.location.href = href

			const urlParams = new URLSearchParams(window.location.search)
			urlParams.set('currency', currency[1])

      if (countryCode?.length) {
        urlParams.set('country_code', countryCode[1])
      }

			let { origin, pathname } = window.location

      // Clear out cart/currency cookies with wildcard domain
			if (href.includes('http')) {
				const url = new URL(href)
				origin = url.origin

				Cookies.remove('localization', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
        Cookies.remove('currency_selected', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
        Cookies.remove('cart_currency', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
        Cookies.remove('cart', window.location.host !== 'www.cariuma.com' && { domain: '.cariuma.com' })
			} else {
        const countryForm = document.getElementById('footer-country-form')

        if (countryForm && countryCode?.length) {
          Cookies.set('currency_selected', true, {
            expires: 7
          });

          countryForm.country_code.value = countryCode[1]
          countryForm.submit()

          return
        }
      }

			window.location.href = origin + pathname + '?' + urlParams.toString()
		}

		const currencySelectors = document.querySelectorAll('.js-currency-selector')
		if (!currencySelectors || !currencySelectors.length) return

		currencySelectors.forEach(el => {
			el.addEventListener('click', e => {
				e.preventDefault()
				handleCurrencyClick(el)
			})
		})
	}
}
