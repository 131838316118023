/** @prettier */
import { useEffect, useState } from 'preact/hooks';
import { graphQLRequest } from './request';
import {
	filtersListTagBased,
	filterSizeOptionIndex,
	filterSizePhantomMenSizes,
	filterSizePhantomWomenSizes,
} from './data';
import { fromEntries, oppositeGender, getCountryByCurrency } from './utils';
import { getPrice } from '../pdp/utils';
import Cookies from 'js-cookie';
import getUrl from '@/scripts/utils/get-url'
import getLanguageCode from '@/scripts/utils/get-language-code'

const collectionDataQuery = /* GraphQL */ `
	query collectionBasicData($handle: String!) @inContext(country: ${getCountryByCurrency(Cookies.get("cart_currency"))}, language: ${getLanguageCode()}) {
		collectionByHandle(handle: $handle) {
			title
			description
			products(first: 250) {
				edges {
					node {
						id
						title
						handle
						tags
						availableForSale
						vendor

						# Requires metafield to be whitelisted for storefront api access
						# https://shopify.dev/tutorials/retrieve-metafields-with-storefront-api
						alternativeTitle: metafield(namespace: "c_f", key: "name") {
							value
						}
						videoPlp: metafield(namespace: "info", key: "video_plp") {
							value
						}
						targetProductTitle : metafield(namespace: "target", key: "product_title") {
							value
						}
						targetPageUrl : metafield(namespace: "target", key: "page_url") {
							value
						}
						excludeFromCollection: metafield(namespace: "info", key: "exclude_from_collection") {
						  value
						}
						priceRange {
							minVariantPrice {
								amount
								currencyCode
							}

							maxVariantPrice {
								amount
								currencyCode
							}
						}

						compareAtPriceRange {
							minVariantPrice {
								amount
								currencyCode
							}
						}

						images(first: 100) {
							edges {
								node {
									url
									src1x1: transformedSrc(maxWidth: 1, maxHeight: 1)
									altText
								}
							}
						}

						variants(first: 1) {
							edges {
								node {
									id
									sku
									title
								}
							}
						}
					}
				}
			}
		}
	}
`;

//Due to high run-time complexity of mapping 2 objects, we sperated it to improve mapping object to a hashtable run-time to O(n),
const colorHashedToRegular = {
	'All Black/Ivory': 'Black',
	'Black Contrast/Ivory': 'Black',
	'Stone Black': 'Black',
	'All Black': 'Black',
	'Stripe Black': 'Black',
	'Stripe Washed Black Contrast Thread' : 'Black',
	'Triple Black': 'Black',
	'Grey Contrast/Ivory': 'Grey',
	'Stone Grey': 'Grey',
	'Bungee Cord': 'Grey',
	'Cloud Grey': 'Grey',
	'Stripe Charcoal Grey': 'Grey',
	'Stripe Grey': 'Grey',
	'Zebra Print Grey': 'Grey',
	'Off-White/Black': 'White',
	'Off-White Gum/Black': 'White',
	'Off-White': 'White',
	'Off White': 'White',
	'Off White Baron Von Fancy': 'White',
	'Stripe Off-White': 'White',
	'Stripe Off White': 'White',
	'Snow White': 'White',
	'Sand Beige': 'Nude',
	'Picante': 'Red',
	'Mineral Blue': 'Blue',
	'Navy': 'Blue',
	'Pantone Classic Blue Knit': 'Blue',
	'Moonless Night': 'Black',
	'Blueprint': 'Blue',
	'Stripe Blue': 'Blue',
	'Stripe Blue Mirage': 'Blue',
	'Raw Red': 'Red',
	'Rose': 'Red',
	'Rose Red': 'Red',
	'Stripe Burgundy Red': 'Red',
	'Stripe Samba Red & Contrast Thread': 'Red',
	'Camouflage': 'Green',
	'Sun Yellow': 'Yellow',
	"Camel": 'Brown',
	"All Camel": 'Brown',
	'Leopard Print': 'Brown',
	'Stripe Yellow': 'Yellow'
}

const getInitialDataBasic = (collections) => {
	const res = {};
	collections.forEach((collection) => {
		res[collection[0]] = {
			name: collection[2] || '',
			size: collection[1],
			status: 'LOADING',
			products: null,
			description : null
		};
	});

	return res;
};

const processDataBasic = (data, oppCollection) =>
	data.data.collectionByHandle.products.edges.map(({ node: product }) => {
		if (product.targetPageUrl && window.bannerImageProducts && window.bannerImageProducts.length > 0) {
			const handle = product.targetPageUrl.value.replace('products/', '').replace('/', '')
			const bannerImageProduct = window.bannerImageProducts.filter(product => product.handle === handle)
			if (bannerImageProduct.length > 0) {
				product.priceRange = bannerImageProduct[0].priceRange
			}
		}

		const isGiftCard = product.handle.includes('gift-card');
		const productPrice = getPrice(product.priceRange, isGiftCard);

		product.priceRange = productPrice.currencyBasePrice;
		const priceWithoutTax = productPrice.priceWithoutTax;
		const price = product.priceRange.minVariantPrice;
		const priceMax = product.priceRange.maxVariantPrice;
		const compareAtPrice = product.compareAtPriceRange.minVariantPrice;

		price.amount = parseFloat(price.amount);
		priceWithoutTax.amount = parseFloat(priceWithoutTax.amount);
		priceMax.amount = parseFloat(priceMax.amount);
		// priceMax.amount = 90;
		compareAtPrice.amount = parseFloat(compareAtPrice.amount);
		// compareAtPrice.amount = 100;

		const priceVaries = price.amount !== priceMax.amount && isGiftCard;
		const variant0 = (product.variants.edges[0] || {}).node || {};

		const oppProductHandle = oppositeGender(product.handle)
		let availableForSale = product.availableForSale
		const isMen = product.handle.includes('-men');
		const isWomen = product.handle.includes('-women');
		if (oppCollection && oppCollection[oppProductHandle]) {
			const getAvailableForSale = oppCollection[oppProductHandle].variants.filter(variant => variant.availableForSale)
			const sizes = getAvailableForSale.length > 0 ? getAvailableForSale.map(el => parseFloat(el.selectedOptions[filterSizeOptionIndex]?.value || 0)) : []
			const genderSizes = sizes.length > 0 ? sizes.map(el => isMen ? el - 1.5 : el + 1.5) : []
			if (isMen && filterSizePhantomMenSizes.filter(el => genderSizes.includes(parseFloat(el))).length > 0) {
				availableForSale = true
			}
			if (isWomen && filterSizePhantomWomenSizes.filter(el => genderSizes.includes(parseFloat(el))).length > 0) {
				availableForSale = true
			}
		}
  
		const videoPLPUrl = product.videoPlp && product.videoPlp.value
  
		return {
			titleOriginal: product.title,
			title:
				(product.alternativeTitle && product.alternativeTitle.value) ||
				product.title.replace(/(?:OCA|Low|High|CATIBA)/g, '').trim(),

			id: product.id,
			handle: product.handle,
			tags: product.tags,
			availableForSale,
			targetProductTitle: product.targetProductTitle,
			targetPageUrl: product.targetPageUrl,
			vendor: product.vendor,
      excludeFromCollection: product.excludeFromCollection !== null,
			price: isGiftCard ? price : priceMax,
			compareAtPrice,
			priceWithoutTax,
			priceVaries,
			variant0: {
				...variant0,
				id: variant0.id,
			},
			videoPLPUrl,
			image: (product.images.edges[0] || {}).node,
			imageHover: (
				product.images.edges
					.reverse() // to be consistent with older implementation
					.find((image) => image.node.url.toLowerCase().includes('.hover')) || {}
			).node,
			imageBanner: (
				product.images.edges
					.reverse()
					.find((image) => image.node.altText && image.node.altText.toLowerCase().includes('has-banner-product-img')) || {}
			).node,
			imageIG: (
				product.images.edges
					.reverse()
					.find((image) => image.node.altText && image.node.altText.toLowerCase().includes('igimage')) || {}
			).node,
		};
	}).filter(product => {
    return !product.excludeFromCollection
  });

export const useDataBasic = (collections) => {
	const [data, setData] = useState(() => getInitialDataBasic(collections));

	useEffect(() => {
		collections.forEach(async ([handle]) => {
			const oppCollection = await getOppositeGenderData(handle)
			graphQLRequest(collectionDataQuery, { handle }, `${getUrl('/collections/')}${handle}?view=basic-data`)
				.then((data) => {
					setData((state) => ({
						...state,
						[handle]: {
							...state[handle],
							status: 'LOADED',
							description : data.data.collectionByHandle.description,
							size: data.data.collectionByHandle.products.edges.length,
							products: processDataBasic(data, oppCollection),
						},
					}));
				})
				.catch((e) => {
					console.error(e);
					setData((state) => ({
						...state,
						[handle]: {
							...state[handle],
							status: 'ERROR',
						},
					}));
				});
		});
	}, []);

	return data;
};

const collectionDataFiltersQuery = /* GraphQL */ `
	query collectionFiltersData($handle: String!) {
		collectionByHandle(handle: $handle) {
			title
			description
			products(first: 250) {
				edges {
					node {
						handle
						tags
						availableForSale

						variants(first: 250) {
							edges {
								node {
									availableForSale
									selectedOptions {
										name
										value
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const getCurrentGenderData = (handle) =>
	graphQLRequest(collectionDataFiltersQuery, { handle }, `${getUrl('/collections/')}${handle}?view=filters-data`).then(({ data }) =>
		fromEntries(
			data.collectionByHandle.products.edges.map(({ node: product }) => [
				product.handle,
				{
					handle: product.handle,
					filters: fromEntries(
						filtersListTagBased.map(({ id, tagPrefix }) => [
							id,
							product.tags
								.filter((tag) => tag.startsWith(tagPrefix))
								.map((tag) => tag.replace(tagPrefix, '')),
						]),
					),
					variants: product.variants.edges.map((edge) => edge.node),
				},
			]),
		),
	);

const getOppositeGenderData = (handle) => {
	const oppositeGenderHandle = oppositeGender(handle);
	if (!oppositeGenderHandle) {
		return {};
	}

	return graphQLRequest(collectionDataFiltersQuery, {
		handle: oppositeGenderHandle,
	}, `${getUrl('/collections/')}${oppositeGenderHandle}?view=filters-data`)
		.then(({ data }) => {
			if (!(data && data.collectionByHandle && data.collectionByHandle.products)) {
				return {};
			}

			return fromEntries(
				data.collectionByHandle.products.edges.map(({ node: product }) => [
					product.handle,
					{ variants: product.variants.edges.map((edge) => edge.node) },
				]),
			);
		})
		.catch(() => ({}));
};

const mergeCollections = (cols) => Object.assign({}, ...cols);

// const getOppositeGenderSizes =

const mergeGenders = (current, opposite) =>
	fromEntries(
		Object.entries(current).map(([handle, product]) => {
			const isMen = handle.includes('-men');
			const isWomen = handle.includes('-women');
			const isGendered = isMen || isWomen;

			const sizes = product.variants
				.filter((variant) => variant.availableForSale)
				.map(
					(variant) =>
						variant.selectedOptions[filterSizeOptionIndex] &&
						variant.selectedOptions[filterSizeOptionIndex].value,
				);

			let oppositeGenderSizes = [];
			if (isGendered) {
				const oppositeGenderHandle = isMen
					? handle.replace('-men', '-women')
					: handle.replace('-women', '-men');
				const oppositeGenderProduct = opposite[oppositeGenderHandle];

				if (oppositeGenderProduct) {
					const oppositeGenderDiff = isMen ? -1.5 : 1.5;
					const oppositeGenderPhantom = isMen
						? filterSizePhantomMenSizes
						: filterSizePhantomWomenSizes;

					oppositeGenderSizes = oppositeGenderProduct.variants
						.filter((variant) => variant.availableForSale)
						.map((variant) =>
							String(
								parseFloat(
									variant.selectedOptions[filterSizeOptionIndex] &&
										variant.selectedOptions[filterSizeOptionIndex].value,
								) + oppositeGenderDiff,
							),
						)
						.filter((size) => oppositeGenderPhantom.includes(size));
				}
			}

			return [
				handle,
				{
					...product,
					filters: {
						...product.filters,
						size: [...sizes, ...oppositeGenderSizes],
					},
				},
			];
		}),
	);

export const useDataFilters = ({ hasFilters, collections }) => {
	const [status, setStatus] = useState('INITIAL'); // INITIAL, LOADING, LOADED, ERROR
	const [data, setData] = useState(() => ({ products: {} }));

	useEffect(() => {
		if (!(hasFilters && status === 'INITIAL')) {
			return;
		}

		setStatus('LOADING');

		Promise.all([
			Promise.all(collections.map(([handle]) => getCurrentGenderData(handle))).then(
				mergeCollections,
			),
			Promise.all(collections.map(([handle]) => getOppositeGenderData(handle))).then(
				mergeCollections,
			),
		])
			.then(([current, opposite]) => {
				const products = mergeGenders(current, opposite);
				setStatus('LOADED');
				for(let each in products){
					if(products[each].filters.color.length > 0 && colorHashedToRegular[products[each].filters.color[0]]){
						products[each].filters.color[0] = colorHashedToRegular[products[each].filters.color[0]]
					}
				}
				setData({ products });
				
			})
			.catch((err) => {
				console.error(err);
				setStatus('ERROR');
			});
	}, [status, hasFilters]);

	return {
		status,
		products: data.products,
	};
};
