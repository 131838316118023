import Cookies from 'js-cookie'
import { toNumber, ceil, merge } from 'lodash-es';
import axios from "axios"
import { graphQLRequest } from "../collection/request"
import { getPriceAmount } from '../../../utils/money'
import getUrl from '@/scripts/utils/get-url'
import translate from '@/scripts/utils/translate';

export const getStyleCollection = (style, isMen, isUnisex = false) => {
  if (!style) {
    return null
  }

  let collection = window.styleCollectionMap[style.toLowerCase() + (isMen ? ' - men' : ' - women')]

  if (isUnisex && !collection) {
    collection = window.styleCollectionMap[style.toLowerCase()]
  }

  return collection
}

export const getPrice = (priceRange, isGiftCard) => {
  const priceWithoutTax = {
    ...priceRange.maxVariantPrice
  }

  const currencyBasePrice = {
    ...priceRange
  }

  return {
    priceWithoutTax,
    currencyBasePrice
  }
}

const countryLabels = {
  GBP: 'UK',
  EUR: 'EU',
  JPY: 'JP',
  CHF: 'CH',
  NOK: 'NO',
  SEK: 'SE',
  DKK: 'DK',
  BRL: 'BR'
}

export const getTaxAndDutiesMessage = (currency, noTax = false) => {
  const country = currency in countryLabels ? countryLabels[currency] + ' ' : ''

  if (noTax) {
    return translate(window.globalLanguages?.tax_message_exclusive, { country })
  }

  return translate(window.globalLanguages?.tax_message_inclusive, { country })
}

export const getSwatchData = async (handle, isMen = true) => {
  if (handle === 'test') {
    return await getSwatchDataFromHandle(handle)
  } else {
    handle = isMen ? handle.replace('-women', '-men') : handle.replace('-men', '-women')
    let data = await getSwatchDataFromHandle(handle)
    const oppositeGenderHandle = isMen ? handle.replace('-men', '-women') : handle.replace('-women', '-men')

    if (handle !== oppositeGenderHandle) {
      try {
        const oppositeGenderData = await getSwatchDataFromHandle(oppositeGenderHandle)

        data = [...data, ...oppositeGenderData]
      } catch {}
    }

    return data
  }
}

const getSwatchDataFromHandle = async (handle) => {
  let page = 1
  let data = []
  let hasNextPage = true

  while (hasNextPage && page <= 5) {
    const response = await axios.get(`${getUrl('/collections/')}${handle}/?view=swatch&page=${page}`)
    data = [...data, ...(response?.data.products || [])]
    hasNextPage = response?.data.current_page < response?.data.pages
    page++
  }

  return data
}

export const getProductsData = async (collectionDataQuery, handle, isMen = true, nonCachedAPI = false) => {
  let data = await graphQLRequest(collectionDataQuery, { handle }, `${getUrl('/collections')}/${handle}?view=data`, nonCachedAPI)
  const oppositeGenderHandle = handle.includes('-men') ? handle.replace('-men', '-women') : handle.replace('-women', '-men')

  if (handle !== oppositeGenderHandle) {
    try {
      const oppositeGenderData = await graphQLRequest(collectionDataQuery, { handle: oppositeGenderHandle }, `${getUrl('/collections/')}${oppositeGenderHandle}?view=data`, nonCachedAPI)
      data = merge({}, data)
      data.data.collectionByHandle.products.edges = [...data.data.collectionByHandle.products.edges, ...oppositeGenderData.data.collectionByHandle.products.edges]
    } catch (error) {
      console.log(error)
    }
  }

  return data
}

export const productHasModel = (productData) => {
  return productData?.product.node.modelProjectId?.value
}

export const isModelHidden = (productData) => {
  return window.disable3DModelProjectIds && window.disable3DModelProjectIds.includes(productData?.product.node.modelProjectId?.value)
}

export const getVariantInventoryQuantity = variant => {
  const quantityInCart = CartJS?.cart?.items ? CartJS.cart.items.filter(item => item.id === parseInt(variant.id))[0]?.quantity || 0 : 0
  return variant.inventoryQuantity - quantityInCart
}

export const isRoundedCorner = (total, current) => {
  // Sets max element per rows on devices
  const rowLimits = [
    { screen: "desktop", max: 9 }, 
    { screen: "tablet", max: 8 }
  ];

  // Class constants
  const topRight = '-top-right';
  const bottomLeft = '-bottom-left';
  const bottomRight = '-bottom-right';

  let value = '';

  rowLimits.forEach(viewport => {
    const lastCompleteRow = Math.floor(total / viewport.max);
    const lastIncompleteRow = Math.ceil(total / viewport.max);
    const lastRow = Math.max(lastCompleteRow, lastIncompleteRow);
    const lastItem = viewport.max * lastCompleteRow;
    const firstItem = viewport.max * (lastRow - 1) + 1

    // Top right corner
    if (current === viewport.max || (total < viewport.max && current === total)) {
      value = value + ' ' + viewport.screen + topRight;
    }

    // Bottom right corner
    if (lastCompleteRow > 0 && current === lastItem) {
      value = value + ' ' + viewport.screen + bottomRight;   
    }

    // Bottom Left corner
    if (lastRow > 0 && current === firstItem) {
      value = value + ' ' + viewport.screen + bottomLeft;   
    }
  });
  
  return value;
}